import React from "react";

import { deetsCard } from "./index";
import "./index.css";

const VcardComponent = ({ userData, defaultTheme }) => {
  const data = { profile: userData };
  const { profile_id, full_name, phone_number, email } = userData;
  const vCard = deetsCard();

  //   const getBase64String = (value) => {
  //     return value.replace("data:", "").replace(/^.+,/, "");
  //   };

  //   const fetchLink = async (link, cb) => {
  //     try {
  //       const res = await (await fetch(link)).blob();

  //       const imageType = res.type.replace("image/", "");

  //       const reader = new FileReader();

  //       reader.onloadend = () => {
  //         cb(reader.result, imageType);

  //         console.log(reader.result, 'this is the result from reader");
  //       };

  //       reader.readAsDataURL(res);
  //     } catch (err) {
  //       console.log(
  //         "error while processing image",
  //         err?.response ? err?.response : err
  //       );
  //     }
  //   };

  //   React.useEffect(()=>{
  //      fetchLink(data.profile.profile_image_url, (res, imageType) => {
  //        vCard.photo.embedFromString(getBase64String(res), imageType);
  //      })
  //   }, [])

  //   Promise.all([
  //    (fetchLink(data.profile.profile_image_url, (res, imageType) => {
  //      vCard.photo.embedFromString(getBase64String(res), imageType);
  //    })
  //    ]).catch((err) => console.log(err, "from all promise"));

  const triggerFetch = async () => {
    fetch(data.profile.profile_image_url)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64data = reader.result.replace(/^data:.+;base64,/, "");
          vCard.photo.embedFromString(base64data, "png");
          console.log(base64data); // The base64-encoded string of the object data
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => console.log(error, "changed"));
  };

  React.useEffect(() => {
    triggerFetch();
    console.log(userData, "dataaasaa");
  }, []);

  vCard.uid = profile_id;
  // vCard.uid = data?.id;
  vCard.firstName = full_name;
  // vCard.middleName = data?.profile?.middle_name;
  // vCard.lastName = data?.profile?.last_name;
  // vCard.organization = data?.profile?.company_name;

  // vCard.birthday = new Date(1985, 0, 1);
  // vCard.title = data?.profile?.job_title;
  // vCard.url = data?.profile?.contact?.website_url;
  // vCard.workUrl = data?.profile?.contact?.website_url;
  // vCard.note = data?.profile?.about;

  // vCard.nickname = data?.profile_code;
  // vCard.namePrefix = 'Mr.';
  // vCard.nameSuffix = 'JR';
  // vCard.gender = 'M';
  // vCard.anniversary = new Date(2004, 0, 1);
  // vCard.role = data?.profile?.job_title;

  //set other phone numbers
  vCard.homePhone = phone_number;
  // vCard.cellPhone = data?.profile?.contact?.phone[0];
  // vCard.pagerPhone = '312-555-1515';

  //set fax/facsimile numbers
  // vCard.homeFax = '312-555-1616';
  // vCard.workFax = '312-555-1717';

  //set email addresses
  vCard.email = email;
  // vCard.workEmail = data?.profile?.contact?.email[0];

  //set address information
  // vCard.homeAddress.label = data?.profile?.contact?.address;
  // vCard.homeAddress.street = '123 Main Street';
  // vCard.homeAddress.city = 'Chicago';
  // vCard.homeAddress.stateProvince = 'IL';
  // vCard.homeAddress.postalCode = '12345';
  // vCard.homeAddress.countryRegion = 'United States of America';

  // vCard.workAddress.label = 'Work Address';
  // vCard.workAddress.street = '123 Corporate Loop\nSuite 500';
  // vCard.workAddress.city = 'Los Angeles';
  // vCard.workAddress.stateProvince = 'CA';
  // vCard.workAddress.postalCode = '54321';
  // vCard.workAddress.countryRegion = 'United States of America';
  vCard.version = "3.0";

  const handleSave = (e) => {
    e.preventDefault();
    var a = document.createElement("a");
    a.href = window.URL.createObjectURL(
      new Blob([vCard.getFormattedString()], { type: "text/vcard" })
    );
    a.download = `${full_name}.vcf`;
    a.click();
  };

  return (
    <button
      style={{
        background: defaultTheme === "" ? `#36A6A4` : `${defaultTheme}`,
      }}
      className="saveConnects_btnV"
      onClick={handleSave}
    >
      Save contact
    </button>
  );
};

export default VcardComponent;
