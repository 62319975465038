import React from "react";
import Cancel from "../../assets/icons/Cancel";
import "./dynamicCancel.css";
const DynamicCancel = ({ onClick, defaultTheme }) => {
  return (
    <>
      {" "}
      <div className="genIconCont_dyna">
        <div
          className="phone-icon-s_dyna"
          style={{
            background: defaultTheme === "" ? `#36A6A4` : `${defaultTheme}`,
          }}
        ></div>
        <div className="childIcon_dyna">
          <Cancel fill={defaultTheme === "" ? `#36A6A4` : `${defaultTheme}`} />
        </div>
      </div>
    </>
  );
};

export default DynamicCancel;
