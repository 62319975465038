import React from "react";
import "./createTeamTemp.css";
import TeamTempNavbar from "../../../genComponents/teamTempNavbar/TeamTempNavbar";

const CreateTeamTemp = () => {
  const handleSubmitForm = (e) => {
    e.preventDefault();
  };
  return (
    <div className="totalResBodyCreateTeam">
      <div className="createTeam-info-family">
        <TeamTempNavbar tempHeader="Name template" fill="#36A6A4" />

        <form>
          <div className="createtemplate_form_gen">
            <div className="createTemp_heading">Name this template</div>
            <div className="createTemp_input">
              <input placeholder="What do you want to call this template?" />
            </div>

            <button onClick={handleSubmitForm} className="createTempBtn_">
              Create this template
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateTeamTemp;
