import React, { useState, useEffect } from "react";

import { useHistory, useLocation, useParams } from "react-router-dom";
import { axiosCalls } from "../../../_api";
import { hideLoader, showLoader } from "../../../loader";
import SectionsHeader from "../../../genComponents/sectionsHeader/SectionsHeader";
import SuccessAlert from "../../screens/successAlert/SuccessAlert";
import FailureAlert from "../../screens/failureAlert/FailureAlert";
import uuid from "react-uuid";
import AddNew from "../../../assets/icons/AddNew";
import { DefaultInputPrefix } from "../../../genComponents/defaultInputPrefix/DefaultInputPrefix";
import { DefaultInput } from "../../../genComponents/defaultInput/DefaultInput";
import { DefaultMinusBtnSecond } from "../../../genComponents/defaultMinusBtn/DefaultMinusBtn";
import { DefaultMinusBtn } from "../../../genComponents/defaultMinusBtn/DefaultMinusBtn";
import EditLinkThumbNail from "../../screens/editLinks/editLinkThumbNail/EditLinkThumbNail";
import { DefaultSubmitBtn } from "../../../genComponents/defaultInput/DefaultInput";
import "./tempLinks.css";
import TeamSwitchButton from "../../../genComponents/teamSwitchButton/TeamSwitchButton";

const TempLinks = () => {
  const unique_id = uuid();

  const [thumbImg, setThumbImg] = useState(null);
  const [coverImage, setCoverImage] = useState(null);

  const [onSuccessLinkPage, setOnSuccessLinkPage] = useState(false);
  const [onErrorPage, setOnErrorPage] = useState(false);

  const [thumbModal, setThumbModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [defaultColor, setDefaultColor] = useState("");
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [inputValue, setInputValue] = useState([
    {
      title: null,
      link_image_url: null,
      url: "",
      isValid: true,
      inputStartsHttp: false,
    },
  ]);
  const [inputValueBtn, setInputValueBtn] = useState([
    {
      title: null,
      link_image_url: null,
      url: "",
      isValid: true,
      inputStartsHttp: false,
    },
  ]);

  const [url, setUrl] = useState("");
  const [isValidt, setIsValidt] = useState(true);
  const [inputStartsHttpCheck, setInputStartsHttpCheck] = useState(false);

  const handleInputChange = (e, index) => {
    const prefixTextOne = "ftp://";
    const prefixText = "https://";
    const prefixTextOld = "http://";

    const inputData = [...inputValue];
    inputData[index].url = e.target.value;
    const value = e.target.value;

    if (value?.toLowerCase()?.startsWith(prefixText)) {
      inputData[index].url = value?.slice(8);
    } else if (value?.toLowerCase()?.startsWith(prefixTextOld)) {
      inputData[index].url = value?.slice(7);
    } else if (value?.toLowerCase()?.startsWith(prefixTextOne)) {
      inputData[index].url = value?.slice(6);
    } else {
      inputData[index].url = value;
    }

    setInputValue(inputData);
  };

  const validateUrl = (value, index) => {
    const urlRegex = /^(http[s]?:\/\/|www\.|ftp:\/\/)/;

    if (urlRegex.test(value)) {
      const inputData = [...inputValue];
      inputData[index].isValid = true;
      setInputValue(inputData);
      setIsValidt(true);
    } else {
      const inputData = [...inputValue];
      inputData[index].isValid = false;
      setInputValue(inputData);
      setIsValidt(false);
    }
  };

  console.log("uuid", unique_id);
  // useEffect(() => {
  //   getLinkData();
  // }, []);

  // const getLinkData = () => {
  //   let profNavLink = localStorage.getItem("Link");
  //   const parsedArr = JSON.parse(profNavLink);
  //   setInputValue(parsedArr);
  //   setInputValueBtn(parsedArr);

  //   console.log("local storage", inputValue);
  //   console.log("parsed", parsedArr);

  //   setDefaultColor(localStorage.getItem("Default Theme"));
  // };

  // const postProfileData = async (e) => {
  //   e.preventDefault();

  //   const data = {
  //     page: "link",
  //     link: inputValue,
  //   };
  //   showLoader();
  //   const res = await axiosCalls(`admin/profile`, "POST", data);

  //   if (res) {
  //     hideLoader();
  //     console.log(res?.data);
  //     if (res?.er) {
  //       console.log(res?.data?.er);
  //       setOnErrorPage(true);
  //       setTimeout(() => {
  //         setOnErrorPage(false);
  //       }, 3000);
  //       return;
  //     }

  //     setOnSuccessLinkPage(true);

  //     setTimeout(() => {
  //       setOnSuccessLinkPage(false);
  //       window.location.href = "/";
  //     }, 2000);
  //   }
  // };

  const handleTitleChange = (e, index) => {
    const inputData = [...inputValue];
    inputData[index].title = e.target.value;
    setInputValue(inputData);
    console.log(inputValue);
  };

  const handleUrlChange = (e, index) => {
    const inputData = [...inputValue];
    inputData[index].url = e.target.value;
    setInputValue(inputData);
    console.log(inputValue);
  };

  const handleAddLinksImgAdd = () => {
    const abc = [
      ...inputValue,
      {
        title: null,
        link_image_url: null,
        url: "",
      },
    ];
    setInputValue(abc);
    setInputValueBtn(abc);
    console.log(inputValue);
  };

  const handleDeleteLinksTitleAdd = (index) => {
    const deleteVal = [...inputValue];
    deleteVal?.splice(index, 1);
    setInputValue(deleteVal);
  };

  const history = useHistory();

  const handleLinkImageChange = (selectedLinkImg, index) => {
    const inputData = [...inputValue];

    inputData[index].link_image_url = selectedLinkImg;
    setInputValue(inputData);
  };

  let coverRef;

  const handleThumbModal = () => {
    setThumbModal((prevOpen) => !prevOpen);
  };

  const prefixText = "https://";

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(inputValue);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className="totalResTempLinkBody">
      <div className="editLink-info-family">
        <SectionsHeader sectionName="Links" handleGoBack={handleGoBack} />
        <form
        // onSubmit={postProfileData}
        >
          <div className="editLink-flexxBodyGen__teamedit">
            {inputValue?.map((singleEditLink, index) => (
              <div style={{ marginBottom: "20px" }}>
                <div style={{ position: "relative" }}>
                  <DefaultInput
                    type="text"
                    placeholder=" "
                    value={singleEditLink?.title}
                    name="linksTitle"
                    onChange={(e) => handleTitleChange(e, index)}
                    spanText="Enter title (Optional)"
                    required=""
                  />

                  <DefaultMinusBtnSecond
                    onClick={() => {
                      handleDeleteLinksTitleAdd(index);
                      setIsValidt(true);
                    }}
                  />
                </div>

                <div style={{ position: "relative" }}>
                  <div className="prefix_genLink__teamedit">{prefixText}</div>
                  <DefaultInputPrefix
                    placeholder=" "
                    name="links"
                    spanText="Type/paste link"
                    required={true}
                    type="text"
                    value={singleEditLink?.url}
                    onChange={(e) => {
                      handleInputChange(e, index);
                    }}
                  />
                </div>
              </div>
            ))}

            {inputValue?.length < 10 && (
              <div className="add-links-family__teamedit">
                <div
                  onClick={handleAddLinksImgAdd}
                  className="add-phone-cont__teamedit"
                  style={{
                    color: defaultColor === "" ? `#36A6A4` : `${defaultColor}`,
                  }}
                >
                  <div className="addCon_svg__teamedit">
                    <AddNew
                      fill={defaultColor === "" ? `#36A6A4` : `${defaultColor}`}
                    />
                  </div>
                  <div className="addCon_tex__teamedit">Add new </div>
                </div>
              </div>
            )}

            <div
              style={{
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "50px",
              }}
            >
              <TeamSwitchButton lockFieldName="Lock fields?" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <DefaultSubmitBtn
                disabled={
                  inputValueBtn?.length < 1 || inputStartsHttpCheck === true
                }
                valueText="Save"
                // onClick={() => {
                //   postProfileData();
                // }}
                defaultColor={defaultColor}
              />
            </div>
          </div>
        </form>
        {thumbModal && (
          <div>
            {inputValue?.map((singleEditLink, index) => (
              <EditLinkThumbNail
                closeThumbModal={handleThumbModal}
                setThumbImg={setThumbImg}
                changeImgFunc={(setThumbImg) => {
                  handleLinkImageChange(setThumbImg, index);
                }}
                thumbImg={thumbImg}
              />
            ))}
          </div>
        )}

        {onSuccessLinkPage && <SuccessAlert alertMessage="😄 Link saved" />}
        {onErrorPage && <FailureAlert alertMessage="Oops, an error occured!" />}
        {disabled && <FailureAlert alertMessage="Oops, Invalid Link" />}
      </div>
    </div>
  );
};

export default TempLinks;
