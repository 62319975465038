import React, { useState, useEffect } from "react";
import "./tempSocialConnect.css";
import { useHistory, useLocation, useParams } from "react-router-dom";
import minus from "../../../assets/icons/minus.svg";
import selectAncorDe from "../../../assets/icons/selectAncorDe.svg";
import unSelectIcon from "../../../assets/icons/unSelectIcon.svg";
import SectionsHeader from "../../../genComponents/sectionsHeader/SectionsHeader";
import {
  DefaultHalfInputSocial,
  DefaultSubmitBtn,
} from "../../../genComponents/defaultInput/DefaultInput";
import optionAssets from "../../../genComponents/locationOptionData/optionAssets";
import { hideLoader, showLoader } from "../../../loader";
import { axiosCalls } from "../../../_api";
import uuid from "react-uuid";
import SuccessAlert from "../../screens/successAlert/SuccessAlert";
import FailureAlert from "../../screens/failureAlert/FailureAlert";
import AddNew from "../../../assets/icons/AddNew";
import TickIcon from "../../../assets/icons/TickIcon";
import DynamicCancel from "../../../genComponents/dynamicCancel/DynamicCancel";
import TeamSwitchButton from "../../../genComponents/teamSwitchButton/TeamSwitchButton";

const TempSocialConnect = () => {
  const unique_id = uuid();
  const [selectedOption, setSelectedOption] = useState([
    {
      account_type: "",
      icon_url: null,
      url: "",
      social_id: unique_id,
      isValid: true,
      inputStartsHttp: false,
    },
  ]);

  const [socialMediaText, setSocialMediaText] = useState([]);
  const [onSuccessSocialPage, setOnSuccessSocialPage] = useState(false);
  const [onErrorPage, setOnErrorPage] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const [preSelectSing, setPreSelectSing] = useState(0);
  const [selectIndex, setSelectIndex] = useState(0);
  const [saveBtnCheck, setSaveBtnCheck] = useState([
    {
      account_type: "",
      icon_url: null,
      url: "",
      social_id: unique_id,
      isValid: true,
      inputStartsHttp: false,
    },
  ]);

  const [defaultColor, setDefaultColor] = useState("");
  const [isValidt, setIsValidt] = useState(true);

  const [inputStartsHttpCheck, setInputStartsHttpCheck] = useState(false);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const isButtonEnabled = () => {
    return selectedOption.some((item) => item.icon_url === null);
  };

  useEffect(() => {
    // getProfileData();
    // const initSocial = localStorage.getItem("Social");
    // const parsedArr = JSON.parse(initSocial);
    // setSelectedOption(parsedArr);
    // setSocialMediaText(parsedArr);
    // setSaveBtnCheck(parsedArr);
    // console.log("selected option from local", parsedArr);
    // setDefaultColor(localStorage.getItem("Default Theme"));
  }, []);

  useEffect(() => {
    defaultSelected();
  }, [isOpen]);

  const defaultSelected = () => {
    if (selectedOption[selectIndex].account_type === "Facebook") {
      setPreSelectSing(1);
    } else if (selectedOption[selectIndex].account_type === "Instagram") {
      setPreSelectSing(2);
    } else if (selectedOption[selectIndex].account_type === "LinkedIn") {
      setPreSelectSing(3);
    } else if (selectedOption[selectIndex].account_type === "Messenger") {
      setPreSelectSing(4);
    } else if (selectedOption[selectIndex].account_type === "Pinterest") {
      setPreSelectSing(5);
    } else if (selectedOption[selectIndex].account_type === "Snapchat") {
      setPreSelectSing(6);
    } else if (selectedOption[selectIndex].account_type === "Tumblr") {
      setPreSelectSing(7);
    } else if (selectedOption[selectIndex].account_type === "Twitter") {
      setPreSelectSing(8);
    } else if (selectedOption[selectIndex].account_type === "Whatsapp") {
      setPreSelectSing(9);
    } else if (selectedOption[selectIndex].account_type === "Youtube") {
      setPreSelectSing(10);
    } else if (selectedOption[selectIndex].account_type === "Tiktok") {
      setPreSelectSing(11);
    } else if (selectedOption[selectIndex].account_type === "Threads") {
      setPreSelectSing(12);
    } else if (selectedOption[selectIndex].account_type === "") {
      setPreSelectSing(0);
    }
  };

  const postProfileData = async (e) => {
    e.preventDefault();

    const data = {
      page: "social",
      social: selectedOption,
    };
    showLoader();
    const res = await axiosCalls(`admin/profile`, "POST", data);

    if (res) {
      hideLoader();
      console.log(res?.data);
      if (res?.er) {
        console.log(res?.data?.er);
        setOnErrorPage(true);
        setTimeout(() => {
          setOnErrorPage(false);
        }, 3000);
        return;
      }
      window.location.href = "/";

      setOnSuccessSocialPage(true);

      setTimeout(() => {
        setOnSuccessSocialPage(false);
        window.location.href = "/";
      }, 2000);
    }
  };

  const handleSelectAdd = () => {
    setSelectedOption([
      ...selectedOption,

      {
        account_type: "",
        icon_url: null,
        url: "",
        social_id: unique_id,
        isValid: true,
        inputStartsHttp: false,
      },
    ]);

    setSaveBtnCheck([
      ...saveBtnCheck,

      {
        account_type: "",
        icon_url: null,
        url: "",
        social_id: unique_id,
        isValid: true,
        inputStartsHttp: false,
      },
    ]);

    // setSelectedOption(spreadSelect);
    console.log(selectedOption, "add new");
  };

  const handleSelectChange = (selectedItem, selectedItemImg, i) => {
    const inputData = [...selectedOption];
    inputData[i].account_type = selectedItem;
    inputData[i].icon_url = selectedItemImg;

    setSelectedOption(inputData);

    console.log(i, "index onChange");

    console.log(selectedOption);
  };

  const handletextChange = (mediaText, i) => {
    const prefixTextOne = "ftp://";
    // const prefixTextOne = "ftp://";
    const prefixText = "https://";
    const prefixTextOld = "http://";
    const whatsAppPrefix = "https://wa.me/";
    const whatsAppPrefixZero = "https://wa.me/0";
    const whatsAppPrefixOld = "http://wa.me/";
    const removeZero = "0";
    const inputData = [...selectedOption];
    const value = mediaText?.target?.value;
    inputData[i].url = mediaText?.target?.value;

    if (value?.toLowerCase()?.startsWith(whatsAppPrefixZero)) {
      inputData[i].url = value?.slice(15);
    } else if (value?.toLowerCase()?.startsWith(whatsAppPrefix)) {
      inputData[i].url = value?.slice(14);
    } else if (value?.toLowerCase()?.startsWith(whatsAppPrefixOld)) {
      inputData[i].url = value?.slice(13);
    } else if (value?.toLowerCase()?.startsWith(prefixTextOld)) {
      inputData[i].url = value?.slice(7);
    } else if (value?.toLowerCase()?.startsWith(prefixText)) {
      inputData[i].url = value?.slice(8);
    } else if (value?.toLowerCase()?.startsWith(prefixTextOne)) {
      inputData[i].url = value?.slice(6);
    } else if (value?.toLowerCase()?.startsWith(removeZero)) {
      if (inputData[i].account_type === "Whatsapp") {
        inputData[i].url = value?.slice(1);
      } else {
        inputData[i].url = value?.slice(0);
      }
    } else {
      inputData[i].url = value;
    }

    setSelectedOption(inputData);

    // if (inputData[i].url.startsWith("https://")) {
    //   //  setInputValue(value.substring("https://".length));

    //   const slicedInput = inputData[i].url.slice(8);

    //   setSelectedOption(inputData);

    //   // setSelectedOption(inputData);
    // } else {
    //   //  setInputValue(value);

    //   setSelectedOption(inputData);
    // }

    // if (
    //   inputData[i].url.startsWith(
    //     prefixText || whatsAppPrefix || prefixTextOld || whatsAppPrefixOld
    //   )
    // ) {
    //   inputData[i].inputStartsHttp = true;
    //   setInputStartsHttpCheck(true);
    // } else {
    //   inputData[i].inputStartsHttp = false;
    //   setInputStartsHttpCheck(false);
    // }
    // if (inputData[i].url.startsWith(prefixText || whatsAppPrefix)) {
    //   inputData[i].url = mediaText.target.value;
    // } else {
    //   // setInputValue(`${prefix}${value}`);

    //   // inputData[i].url = `${prefixText}${mediaText.target.value}`;

    //   inputData[i].url = `${
    //     selectedOption[i].account_type === "Whatsapp"
    //       ? whatsAppPrefix
    //       : prefixText
    //   }${mediaText.target.value}`;
    // }

    // setSelectedOption(inputData);

    // validateUrl(inputData[i].url, i);

    console.log(selectedOption);
  };

  const handleSelectDelete = (i) => {
    const deleteSelectedVal = [...selectedOption];
    deleteSelectedVal?.splice(i, 1);
    setSelectedOption(deleteSelectedVal);
  };

  const toggling = () => setIsOpen(!isOpen);
  const closeSelectToggle = () => {
    setIsOpen(false);
  };

  const handleChildClick = (event) => {
    event.stopPropagation();
  };

  const prefixText = "https://";
  const whatsAppPrefix = "https://wa.me/";

  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className="totalResSocialBody__teamSocial">
      <div className="social-info-family__teamSocial">
        <SectionsHeader
          sectionName="Social connect"
          handleGoBack={handleGoBack}
        />
        <form
        // onSubmit={postProfileData}
        >
          <div>
            <div>
              {selectedOption?.map((selectedOpt, i) => {
                return (
                  <>
                    <div
                      style={{ marginBottom: "10px" }}
                      className="flex-inputs"
                    >
                      <div
                        onClick={() => {
                          toggling();
                          console.log("indicate index", i);
                          setSelectIndex(i);
                        }}
                        className="custom-selectBtnSocial__teamSocial"
                      >
                        {selectedOpt?.account_type === "" &&
                        selectedOpt?.icon_url === null ? (
                          <div className="custom-selectBtnTextSocial__teamSocial">
                            Select
                          </div>
                        ) : (
                          <div className="custom-selectedOptionGen__teamSocial">
                            <div className="customSelected-IconGen__teamSocial">
                              <img
                                src={selectedOpt?.icon_url}
                                alt="social_icon"
                              />
                            </div>
                            <div className="custom-selectBtnTextSocialSe__teamSocial">
                              {selectedOpt?.account_type}
                            </div>
                          </div>
                        )}

                        <div className="custom-selectAncorSocial__teamSocial">
                          <img src={selectAncorDe} alt="select-icnSocial" />
                        </div>
                      </div>

                      <div
                        style={{ marginBottom: "10px" }}
                        className="social-remove-section__teamSocial"
                      >
                        <div className="prefix_genSocial__teamSocial">
                          {selectedOpt?.account_type === "Whatsapp"
                            ? whatsAppPrefix
                            : prefixText}
                        </div>
                        <DefaultHalfInputSocial
                          type="text"
                          value={selectedOpt?.url}
                          name="socialAccount"
                          onChange={(e) => {
                            handletextChange(e, i);
                            console.log("text id", i);
                          }}
                          placeholder=" "
                          required={true}
                          socialsSelect={selectedOpt?.account_type}
                        />

                        <div className="remove-social-icon__teamSocial ">
                          <img
                            src={minus}
                            alt="remove"
                            onClick={() => {
                              handleSelectDelete(i);

                              console.log("handling delete id", i);
                            }}
                          />
                        </div>
                      </div>

                      {isOpen && (
                        <div
                          className="select-modal-bodySocial__teamSocial"
                          onClick={closeSelectToggle}
                        >
                          <div
                            className="select-modalBody-contSocial__teamSocial"
                            onClick={handleChildClick}
                          >
                            <div className="select-modalHeaderSocial__teamSocial">
                              <div className="select-modalHeader-textSocial__teamSocial">
                                Select Social
                              </div>
                              <div
                                onClick={closeSelectToggle}
                                className="select-modalHeader-canBtnSocial__teamSocial "
                              >
                                <DynamicCancel defaultTheme={defaultColor} />
                              </div>
                            </div>

                            <div>
                              <div
                                onClick={() => {
                                  handleSelectChange(
                                    "Facebook",
                                    optionAssets.fbfacebook,
                                    selectIndex
                                  );

                                  setPreSelectSing(1);
                                  console.log(preSelectSing);
                                  console.log("handling", i);

                                  closeSelectToggle();
                                }}
                              >
                                <div className="select-modal-optionSocial__teamSocial">
                                  <div className="socialSelectIcon-famm__teamSocial">
                                    <div className="socialSelect-Iconn__teamSocial">
                                      <img src={optionAssets.fbfacebook} />
                                    </div>
                                    <div className="select-modal-optionTextSocial__teamSocial">
                                      Facebook
                                    </div>
                                  </div>
                                  <div className="select-modal-optionIconSocial__teamSocial">
                                    {preSelectSing === 1 ? (
                                      <TickIcon
                                        fill={
                                          defaultColor === ""
                                            ? `#36A6A4`
                                            : `${defaultColor}`
                                        }
                                      />
                                    ) : (
                                      <img src={unSelectIcon} alt="unselect" />
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div
                                onClick={() => {
                                  handleSelectChange(
                                    "Instagram",
                                    optionAssets.iconinstagram,
                                    selectIndex
                                  );

                                  setPreSelectSing(2);
                                  console.log(preSelectSing);
                                  console.log("handling two", i);
                                  closeSelectToggle();
                                }}
                              >
                                <div className="select-modal-optionSocial__teamSocial">
                                  <div className="socialSelectIcon-famm__teamSocial">
                                    <div className="socialSelect-Iconn__teamSocial">
                                      <img src={optionAssets.iconinstagram} />
                                    </div>
                                    <div className="select-modal-optionTextSocial__teamSocial">
                                      Instagram
                                    </div>
                                  </div>
                                  <div className="select-modal-optionIconSocial__teamSocial">
                                    {preSelectSing === 2 ? (
                                      <TickIcon
                                        fill={
                                          defaultColor === ""
                                            ? `#36A6A4`
                                            : `${defaultColor}`
                                        }
                                      />
                                    ) : (
                                      <img src={unSelectIcon} alt="unselect" />
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div
                                onClick={() => {
                                  handleSelectChange(
                                    "LinkedIn",
                                    optionAssets.iconlinkedin,
                                    selectIndex
                                  );

                                  setPreSelectSing(3);
                                  console.log(preSelectSing);
                                  closeSelectToggle();
                                }}
                              >
                                <div className="select-modal-optionSocial__teamSocial">
                                  <div className="socialSelectIcon-famm__teamSocial">
                                    <div className="socialSelect-Iconn__teamSocial">
                                      <img src={optionAssets.iconlinkedin} />
                                    </div>
                                    <div className="select-modal-optionTextSocial__teamSocial">
                                      LinkedIn
                                    </div>
                                  </div>
                                  <div className="select-modal-optionIconSocial__teamSocial">
                                    {preSelectSing === 3 ? (
                                      <TickIcon
                                        fill={
                                          defaultColor === ""
                                            ? `#36A6A4`
                                            : `${defaultColor}`
                                        }
                                      />
                                    ) : (
                                      <img src={unSelectIcon} alt="unselect" />
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div
                                onClick={() => {
                                  handleSelectChange(
                                    "Messenger",
                                    optionAssets.iconmessenger,
                                    selectIndex
                                  );

                                  setPreSelectSing(4);
                                  console.log(preSelectSing);
                                  closeSelectToggle();
                                }}
                              >
                                <div className="select-modal-optionSocial__teamSocial">
                                  <div className="socialSelectIcon-famm__teamSocial">
                                    <div className="socialSelect-Iconn__teamSocial">
                                      <img src={optionAssets.iconmessenger} />
                                    </div>
                                    <div className="select-modal-optionTextSocial__teamSocial">
                                      Messenger
                                    </div>
                                  </div>
                                  <div className="select-modal-optionIconSocial__teamSocial">
                                    {preSelectSing === 4 ? (
                                      <TickIcon
                                        fill={
                                          defaultColor === ""
                                            ? `#36A6A4`
                                            : `${defaultColor}`
                                        }
                                      />
                                    ) : (
                                      <img src={unSelectIcon} alt="unselect" />
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div
                                onClick={() => {
                                  handleSelectChange(
                                    "Pinterest",
                                    optionAssets.iconpinterest,
                                    selectIndex
                                  );

                                  setPreSelectSing(5);
                                  console.log(preSelectSing);
                                  closeSelectToggle();
                                }}
                              >
                                <div className="select-modal-optionSocial__teamSocial">
                                  <div className="socialSelectIcon-famm__teamSocial">
                                    <div className="socialSelect-Iconn__teamSocial">
                                      <img src={optionAssets.iconpinterest} />
                                    </div>
                                    <div className="select-modal-optionTextSocial__teamSocial">
                                      Pinterest
                                    </div>
                                  </div>
                                  <div className="select-modal-optionIconSocial__teamSocial">
                                    {preSelectSing === 5 ? (
                                      <TickIcon
                                        fill={
                                          defaultColor === ""
                                            ? `#36A6A4`
                                            : `${defaultColor}`
                                        }
                                      />
                                    ) : (
                                      <img src={unSelectIcon} alt="unselect" />
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div
                                onClick={() => {
                                  handleSelectChange(
                                    "Snapchat",
                                    optionAssets.iconsnapchat,
                                    selectIndex
                                  );

                                  setPreSelectSing(6);
                                  console.log(preSelectSing);
                                  closeSelectToggle();
                                }}
                              >
                                <div className="select-modal-optionSocial__teamSocial">
                                  <div className="socialSelectIcon-famm__teamSocial">
                                    <div className="socialSelect-Iconn__teamSocial">
                                      <img src={optionAssets.iconsnapchat} />
                                    </div>
                                    <div className="select-modal-optionTextSocial__teamSocial">
                                      Snapchat
                                    </div>
                                  </div>
                                  <div className="select-modal-optionIconSocial__teamSocial">
                                    {preSelectSing === 6 ? (
                                      <TickIcon
                                        fill={
                                          defaultColor === ""
                                            ? `#36A6A4`
                                            : `${defaultColor}`
                                        }
                                      />
                                    ) : (
                                      <img src={unSelectIcon} alt="unselect" />
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div
                                onClick={() => {
                                  handleSelectChange(
                                    "Tumblr",
                                    optionAssets.icontumblr,
                                    selectIndex
                                  );

                                  setPreSelectSing(7);
                                  console.log(preSelectSing);
                                  closeSelectToggle();
                                }}
                              >
                                <div className="select-modal-optionSocial__teamSocial">
                                  <div className="socialSelectIcon-famm__teamSocial">
                                    <div className="socialSelect-Iconn__teamSocial">
                                      <img src={optionAssets.icontumblr} />
                                    </div>
                                    <div className="select-modal-optionTextSocial__teamSocial">
                                      Tumblr
                                    </div>
                                  </div>
                                  <div className="select-modal-optionIconSocial__teamSocial">
                                    {preSelectSing === 7 ? (
                                      <TickIcon
                                        fill={
                                          defaultColor === ""
                                            ? `#36A6A4`
                                            : `${defaultColor}`
                                        }
                                      />
                                    ) : (
                                      <img src={unSelectIcon} alt="unselect" />
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div
                                onClick={() => {
                                  handleSelectChange(
                                    "Twitter",
                                    optionAssets.icontwitter,
                                    selectIndex
                                  );

                                  setPreSelectSing(8);
                                  console.log(preSelectSing);
                                  closeSelectToggle();
                                }}
                              >
                                <div className="select-modal-optionSocial__teamSocial">
                                  <div className="socialSelectIcon-famm__teamSocial">
                                    <div className="socialSelect-Iconn__teamSocial">
                                      <img src={optionAssets.icontwitter} />
                                    </div>
                                    <div className="select-modal-optionTextSocial__teamSocial">
                                      Twitter
                                    </div>
                                  </div>
                                  <div className="select-modal-optionIconSocial__teamSocial">
                                    {preSelectSing === 8 ? (
                                      <TickIcon
                                        fill={
                                          defaultColor === ""
                                            ? `#36A6A4`
                                            : `${defaultColor}`
                                        }
                                      />
                                    ) : (
                                      <img src={unSelectIcon} alt="unselect" />
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div
                                onClick={() => {
                                  handleSelectChange(
                                    "Whatsapp",
                                    optionAssets.iconwhatsapp,
                                    selectIndex
                                  );

                                  setPreSelectSing(9);
                                  console.log(preSelectSing);
                                  closeSelectToggle();
                                }}
                              >
                                <div className="select-modal-optionSocial__teamSocial">
                                  <div className="socialSelectIcon-famm__teamSocial">
                                    <div className="socialSelect-Iconn__teamSocial">
                                      <img src={optionAssets.iconwhatsapp} />
                                    </div>
                                    <div className="select-modal-optionTextSocial">
                                      Whatsapp
                                    </div>
                                  </div>
                                  <div className="select-modal-optionIconSocial__teamSocial">
                                    {preSelectSing === 9 ? (
                                      <TickIcon
                                        fill={
                                          defaultColor === ""
                                            ? `#36A6A4`
                                            : `${defaultColor}`
                                        }
                                      />
                                    ) : (
                                      <img src={unSelectIcon} alt="unselect" />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div
                                onClick={() => {
                                  handleSelectChange(
                                    "Youtube",
                                    optionAssets.youtubeIcon,
                                    selectIndex
                                  );

                                  setPreSelectSing(10);
                                  console.log(preSelectSing);
                                  closeSelectToggle();
                                }}
                              >
                                <div className="select-modal-optionSocial__teamSocial">
                                  <div className="socialSelectIcon-famm__teamSocial">
                                    <div className="socialSelect-Iconn__teamSocial">
                                      <img src={optionAssets.youtubeIcon} />
                                    </div>
                                    <div className="select-modal-optionTextSocial__teamSocial">
                                      Youtube
                                    </div>
                                  </div>
                                  <div className="select-modal-optionIconSocial__teamSocial">
                                    {preSelectSing === 10 ? (
                                      <TickIcon
                                        fill={
                                          defaultColor === ""
                                            ? `#36A6A4`
                                            : `${defaultColor}`
                                        }
                                      />
                                    ) : (
                                      <img src={unSelectIcon} alt="unselect" />
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div
                                onClick={() => {
                                  handleSelectChange(
                                    "Tiktok",
                                    optionAssets.tiktokIcon,
                                    selectIndex
                                  );

                                  setPreSelectSing(11);
                                  console.log(preSelectSing);
                                  closeSelectToggle();
                                }}
                              >
                                <div className="select-modal-optionSocial__teamSocial">
                                  <div className="socialSelectIcon-famm__teamSocial">
                                    <div className="socialSelect-Iconn__teamSocial">
                                      <img src={optionAssets.tiktokIcon} />
                                    </div>
                                    <div className="select-modal-optionTextSocial__teamSocial">
                                      Tiktok
                                    </div>
                                  </div>
                                  <div className="select-modal-optionIconSocial__teamSocial">
                                    {preSelectSing === 11 ? (
                                      <TickIcon
                                        fill={
                                          defaultColor === ""
                                            ? `#36A6A4`
                                            : `${defaultColor}`
                                        }
                                      />
                                    ) : (
                                      <img src={unSelectIcon} alt="unselect" />
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div
                                onClick={() => {
                                  handleSelectChange(
                                    "Threads",
                                    optionAssets.threads,
                                    selectIndex
                                  );

                                  setPreSelectSing(12);
                                  console.log(preSelectSing);
                                  closeSelectToggle();
                                }}
                              >
                                <div className="select-modal-optionSocial__teamSocial">
                                  <div className="socialSelectIcon-famm__teamSocial">
                                    <div className="socialSelect-Iconn__teamSocial">
                                      <img src={optionAssets.threads} />
                                    </div>
                                    <div className="select-modal-optionTextSocial__teamSocial">
                                      Threads
                                    </div>
                                  </div>
                                  <div className="select-modal-optionIconSocial__teamSocial">
                                    {preSelectSing === 12 ? (
                                      <TickIcon
                                        fill={
                                          defaultColor === ""
                                            ? `#36A6A4`
                                            : `${defaultColor}`
                                        }
                                      />
                                    ) : (
                                      <img src={unSelectIcon} alt="unselect" />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div style={{ marginBottom: "100px" }}></div>
                          </div>
                        </div>
                      )}
                    </div>
                    {selectedOpt.isValid === false ? (
                      <p className="url_validSocial__teamSocial">
                        Please enter a valid URL, prefix must start with either
                        www. or http:// or https:// or ftp://
                      </p>
                    ) : null}
                    {selectedOpt.inputStartsHttp ? (
                      <p className="url_validSocial__teamSocial">
                        Please remove prefix that starts with either http:// or
                        https:// or https://wa.me/
                      </p>
                    ) : null}
                  </>
                );
              })}
            </div>
            <div></div>
          </div>

          {selectedOption.length < 10 && (
            <div className="add-social-family__teamSocial">
              <div
                onClick={() => {
                  handleSelectAdd();
                }}
                className="add-social-cont__teamSocial"
                style={{
                  color: defaultColor === "" ? `#36A6A4` : `${defaultColor}`,
                }}
              >
                <AddNew
                  fill={defaultColor === "" ? `#36A6A4` : `${defaultColor}`}
                />
                Add new
              </div>
            </div>
          )}

          <div
            style={{
              display: "flex",
              alignItem: "center",
              justifyContent: "center",
              flexDirection: "column",

              // position: "absolute",
              // width: "100%",
              // bottom: "10px",
            }}
          >
            <div
              style={{
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "50px",
              }}
            >
              <TeamSwitchButton lockFieldName="Lock fields?" />
            </div>

            <div
              style={{
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <DefaultSubmitBtn
                onClick={() => {
                  postProfileData();
                }}
                valueText="Save"
                disabled={
                  saveBtnCheck?.length < 1 || isButtonEnabled() === true
                }
                defaultColor={defaultColor}
              />
            </div>
          </div>
        </form>

        {onSuccessSocialPage && (
          <SuccessAlert alertMessage="😄 Social links saved" />
        )}

        {onErrorPage && <FailureAlert alertMessage="Oops, an error occured!" />}
      </div>
    </div>
  );
};

export default TempSocialConnect;
