import axios from "axios";

export const baseUrl = "https://api.enterpriseinsight.co:8085/v1/deets";

// export const baseUrl =
//   "https://api.enterpriseinsight.co:8085/v1/deets/admin/profile";
export let timoutReq = 10000;

export const axiosCalls = async (path, method, data = null) => {
  const token = localStorage.getItem("token");
  try {
    let res = await axios({
      method,
      url: `${baseUrl}/${path}`,
      data,
      timeout: timoutReq,
      headers: {
        Authorization: `Bearer ${token}`,
        prefer: "params=single-object",
        // "Content-Type": "application/octet-stream",
      },
    });
    if (res) {
      return res;
    }
  } catch (error) {
    return { er: error.response };
  }
};

export const axiosCallSignup = async (path, method, data = null) => {
  // const token = localStorage.getItem("token");
  try {
    let res = await axios({
      method,
      url: `${baseUrl}/${path}`,
      data,
      timeout: timoutReq,
      headers: {
        prefer: "params=single-object",
      },
    });
    if (res) {
      return res;
    }
  } catch (error) {
    return { er: error.response };
  }
};
