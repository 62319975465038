import React, { useState, useEffect } from "react";
import "./adminSwitchComp.css";
import Switch from "react-switch";

const AdminSwitchComp = ({ checked, handleCheckedChange }) => {
  console.log(checked, "checkedddddd");
  return (
    <div onClick={handleCheckedChange} className="admin_switchBtn_parent">
      <div className="admin_switchBtn_text">
        Apply theme to profile backdrop?
      </div>
      <Switch
        onChange={handleCheckedChange}
        checked={checked}
        className="react-switcher"
        onColor="#0DBC82"
        // onHandleColor="#36a6a4"
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        activeBoxShadow="0 0 2px 3px #36a6a4"
      />
    </div>
  );
};

export default AdminSwitchComp;
