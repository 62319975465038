import React, { useState, useEffect } from "react";
import "./teamHome.css";
import Burger from "../../screens/adminPage/Burger";
import { axiosCalls } from "../../../_api";
import { hideLoader, showLoader } from "../../../loader";
import SectionNavHamburger from "../../../genComponents/sectionNavHamburger/SectionNavHamburger";
import TeamIcon from "../../../genComponents/teamIcon/TeamIcon";
import { useHistory, useLocation } from "react-router-dom";
import TeamTempCard from "../../../genComponents/teamTempCard/TeamTempCard";
import SingleTeamTemp from "../singleTeamTemp/SingleTeamTemp";
import NewModal from "../../../genComponents/newModal/NewModal";
import Cancel from "../../../assets/icons/Cancel";
import {
  DefaultInput,
  DefaultSubmitBtn,
} from "../../../genComponents/defaultInput/DefaultInput";
import {
  DefaultInputR,
  DefaultSubmitBtnR,
} from "../../../genComponents/defaultInputR/DefaultInputR";
import CloseTeam from "../../../assets/icons/CloseTeam";
import MainClose from "../../../assets/icons/MainClose";
const TeamHome = () => {
  const [emailData, setEmailData] = useState();
  const [loading, userLoading] = useState(true);
  const [fetchError, userFetchError] = useState(false);
  const [errorLoad, setErrorLoad] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [profileName, setProfileName] = useState("fionaokpara");
  const [navColor, setNavColor] = useState("");
  const [profImage, setProfImage] = useState(null);
  const [loadingImgName, setLoadingImgName] = useState(false);
  const [onErrorFeedPage, setOnErrorFeedPage] = useState(false);
  const [profileId, setProfileId] = useState();
  const [userRole, setUserRole] = useState("");
  const [teamTemplate, setTeamTemplate] = useState([
    { team_name: "Sales team" },
    { team_name: "Dev team" },
    { team_name: "Technical Support team" },
    { team_name: "Marketing team" },
  ]);

  const [openSingleTemp, setOpenSingleTemp] = useState(false);
  const [teamInfo, setTeamInfo] = useState({});

  const [teamCompanyName, setTeamCompanyName] = useState("");
  const [companyNameModal, setCompanyNameModal] = useState(false);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const closeCompanyNameModal = () => {
    setCompanyNameModal(false);
  };

  const history = useHistory();

  const handleCreateTemplate = () => {
    history.push("/team/createtemplate");
  };

  useEffect(() => {
    getProfile();
    getProfImage();
    setUserRole(localStorage.getItem("User Role"));
    if (teamCompanyName === "") {
      setCompanyNameModal(true);
    } else {
      setCompanyNameModal(false);
    }
  }, []);

  const handleChildClick = (event) => {
    // 👇️ stop event propagation (won't trigger parent's onClick)
    event.stopPropagation();

    // console.log("child clicked");
  };

  const getProfile = async () => {
    showLoader();

    const res = await axiosCalls(`admin/profile/${profileName}`, "GET");

    if (res) {
      userLoading(false);
      if (res?.er) {
        hideLoader();
        console.log(res?.er);

        // toast.error("Oops, Error Please Refresh", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        setErrorLoad(true);

        console.log("errfff");
        userFetchError(true);
        // <NoUserDisplay />;
        return;
      }

      setErrorLoad(false);
      hideLoader();
      const resData = res?.data?.profile;

      setEmailData(resData);
      setNavColor(resData?.theme_color_primary ?? "");
      setProfileId(resData?.id);
      // setUpdateTheme(resData?.theme_color_primary);
      console.log(">>>>>>>>>>Res", resData);
    }
  };

  const getProfImage = async () => {
    // const image_name = localStorage.getItem("Profile Img");

    const resImage = await axiosCalls(`admin/profile/${profileName}`, "GET");

    if (resImage) {
      if (resImage?.er) {
        return;
      }
    }

    const image_name = resImage?.data?.profile?.profile_image;

    const res = await axiosCalls(`s3/${image_name}`, "GET");

    if (res) {
      if (res?.er) {
        console.log(res?.er);

        console.log("errfff");
        return;
      }

      hideLoader();
      const resData = res.data;
      setProfImage(resData, "prooooooooooooo");

      console.log(resData, "pppppppppppppppppppp");
      console.log(profImage, "proffffffffffrrrrrr");
      setLoadingImgName(true);
    }
  };

  const handleSingleModalOpen = () => {
    setOpenSingleTemp(true);
  };

  const handleSingleModalClose = () => {
    setOpenSingleTemp(false);
  };

  const onSubmitCompanyName = (e) => {
    e.preventDefault();
    setCompanyNameModal(false);
  };

  const handleClickEditTemp = () => {
    history.push("/team/edit_teamplate_name");
  };

  const handleCompanyInput = (e) => {
    setTeamCompanyName(e.target.value);
  };
  return (
    <div className="totalResBodyTeamHome">
      <div className="teamHome-info-family">
        <SectionNavHamburger
          emailData={emailData}
          profImage={profImage}
          hamText="Teams"
          userRole={userRole}
        />

        <div className="edit_companyNameGenFam">
          <div className="companyName_headerFlex">
            <div className="company_nameHText">Company name</div>
            <div
              onClick={handleClickEditTemp}
              className="company_nameEditTextName"
            >
              Edit template name
            </div>
          </div>
          <div className="companyName___">
            {teamCompanyName === "" ? "Company Name" : teamCompanyName}
          </div>
        </div>

        <div
          className={
            teamTemplate.length < 1 ? "no_team_tempGen" : "true_team_tempGen"
          }
        >
          {teamTemplate.length < 1 ? (
            <div>
              <div className="no_teamTemp_icon">
                <img src={TeamIcon.noTeamTemplate} />
              </div>
              <div className="no_teamTemp_header">Welcome to Deet teams</div>
              <div className="no_teamTemp_sub">
                You can create more than 1 template, control important
                information and add members
              </div>
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              <div className="team_templatehead_section">Team templates</div>
              {teamTemplate.map((data, index) => (
                <div
                  onClick={() => {
                    handleSingleModalOpen();
                    setTeamInfo(data);
                  }}
                  style={{ width: "100%" }}
                >
                  <TeamTempCard team_name={data.team_name} />
                </div>
              ))}
            </div>
          )}

          <button onClick={handleCreateTemplate} className="create_team_btn">
            Create new template
          </button>
        </div>
      </div>

      {openSingleTemp && (
        <SingleTeamTemp
          handleSingleModalClose={handleSingleModalClose}
          teamInfo={teamInfo}
          handleSingleModalOpen={handleSingleModalOpen}
        />
      )}

      {companyNameModal === true ? (
        <div onClick={closeCompanyNameModal} className="Indexmodal-container">
          <div onClick={handleChildClick} className="Indexmodal-content">
            <div className="index_heading_parent">
              <div className="index_heading">Company name </div>
              <div
                onClick={closeCompanyNameModal}
                className="index_heading_close"
              >
                <MainClose fill="#36a6a4" />
              </div>
            </div>

            <div className="index_para">
              Add your company name as you want it to appear in your team
              members profiles
            </div>
            <form onSubmit={onSubmitCompanyName}>
              <div style={{ width: "100%" }}>
                <DefaultInputR
                  required={true}
                  type="text"
                  onChange={handleCompanyInput}
                  placeholder=""
                  spanText="Company name"
                />
              </div>

              <DefaultSubmitBtnR valueText="Save" />
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TeamHome;
