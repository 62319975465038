import React from "react";
import "./statsHalfCont.css";
import statsPoint from "../../assets/icons/statsPoint.svg";
import CounterIcon from "../../assets/icons/CounterIcon";

const StatsHalfCont = ({ figures, labelName, fill }) => {
  const beforeStyle = {
    content: "",
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    zIndex: "-1",
    margin: "-1px",
    borderRadius: "inherit",
    background: `linear-gradient(to bottom,  ${
      fill === "" ? `#36A6A4` : `${fill}`
    }, transparent)  100%`,
  };

  return (
    <>
      <div
        style={
          {
            // border: `1px solid ${fill === "" ? `#36A6A4` : `${fill}`}`,
          }
        }
        className="statsHalf-each-cont"
      >
        <div style={beforeStyle}></div>
        <div className="statsnumbCont_half">
          {/* <div className="statsImg">
            <CounterIcon fill={fill} />
          </div> */}
          <div className="statsHalf-number">{figures}</div>
        </div>
        <div className="statsHalf-label">{labelName}</div>
      </div>
    </>
  );
};

export default StatsHalfCont;
