import React, { useEffect, useState } from "react";
import "./editTempName.css";
import TeamTempNavbar from "../../../genComponents/teamTempNavbar/TeamTempNavbar";
import { useHistory, useLocation } from "react-router-dom";

const EditTempname = () => {
  const history = useHistory();
  const handleGoBack = (e) => {
    e.preventDefault();
    history.goBack();
  };
  const handleSubmitForm = (e) => {
    e.preventDefault();
  };

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className="totalResBodyEditTemp">
      <div className="editTemp-info-family">
        <TeamTempNavbar tempHeader="Edit template name" fill="#36A6A4" />
        <form>
          <div className="createtemplate_form_gen">
            <div className="edittTemp_heading">Name this template</div>
            <div className="createTemp_input">
              <input placeholder="Template name" />
            </div>

            <div className="temp_bottomEdit">
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "100%",
                }}
              >
                <button
                  onClick={handleSubmitForm}
                  className="editTemp_name_inviteBtn"
                >
                  Save
                </button>
              </div>
              <button
                onClick={handleGoBack}
                className="editTemp_name_deleteBtn"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditTempname;
