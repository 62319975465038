import React, { useState, useEffect } from "react";
import AuthFooter from "../AuthFooter";
import AuthHeadings from "../AuthHeadings";
import DefaultPassInput from "../DefaultPassInput";
import FormLabel from "../FormLabel";
import Header from "../Header";
import "./signUpCont.css";
import valiPass from "../../../../assets/icons/valiPass.svg";
import nonValiPass from "../../../../assets/icons/nonValiPass.svg";
import { useLocation, useHistory } from "react-router-dom";
import validator from "validator";
import PasswordStrengthMeter from "../passwordStrengthMeter/PasswordStrengthMeter";
import { showLoader, hideLoader } from "../../../../loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosCalls, axiosCallSignup } from "../../../../_api";
import SuccessAlert from "../../successAlert/SuccessAlert";

const SignUpCont = () => {
  const [signUpAuth, setSignUpAuth] = useState({
    firstName: "",
    lastName: "",
    userName: " ",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [signUpError, setSignUpError] = useState({
    firstName: "",
    lastName: "",
    userName: " ",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [confirmPassError, setConfirmPassError] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);
  const [lenghtValidated, setLenghtValidated] = useState(false);
  const [maxLenghtValidated, setMaxLenghtValidated] = useState(true);
  const [isStrong, initRobustPassword] = useState(null);
  const [onSuccessContactPage, setOnSuccessContactPage] = useState(false);
  const [resMessage, setResMessage] = useState("");

  const signupAuth = async (e) => {
    e.preventDefault();

    const data = {
      email: signUpAuth.email,
      pass: signUpAuth.password,
      first_name: signUpAuth.firstName,
      last_name: signUpAuth.lastName,
      profile_code: signUpAuth.userName,
    };
    showLoader();
    const res = await axiosCalls("user/register", "POST", data);
    if (res) {
      hideLoader();
      console.log(res, "res");
      if (res.er) {
        console.log(res?.er?.data, "error");

        toast.error(res?.er?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        return;
      }

      setResMessage(`😄 Success`);
      setOnSuccessContactPage(true);
      localStorage.setItem("token", res?.data?.jwt?.token);
      setTimeout(() => {
        setOnSuccessContactPage(false);
        window.location.href = "/";
      }, 3000);
      console.log(">>>>>>>>RES", res?.data);

      // window.location.href = "/init";
    }
  };

  const handleValidateChange = (e) => {
    const lower = new RegExp("(?=.*[a-z])");
    const upper = new RegExp("(?=.*[A-Z])");
    const number = new RegExp("(?=.*[0-9])");
    const special = new RegExp("(?=.*[!@#$%^&*])");
    const lenght = new RegExp("(?=.{6,})");
    const maxLenght = new RegExp("(?=.*.{36})");

    // Lower Case Validation

    if (lower.test(e)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }
    //    Upper Case Validation

    if (upper.test(e)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }

    //  Number Validation

    if (number.test(e)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }

    //  Special Validation

    if (special.test(e)) {
      setSpecialValidated(true);
    } else {
      setSpecialValidated(false);
    }

    //  Lenght Validation

    //    Upper Case Validation

    if (upper.test(e)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }

    //  Number Validation

    if (number.test(e)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }

    //  Lenght Validation

    if (lenght.test(e)) {
      setLenghtValidated(true);
    } else {
      setLenghtValidated(false);
    }

    //  MaxLenght Validation

    //    Upper Case Validation

    if (upper.test(e)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }

    //  Number Validation

    if (number.test(e)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }

    //  Special Validation

    if (maxLenght.test(e)) {
      setMaxLenghtValidated(false);
    } else {
      setMaxLenghtValidated(true);
    }
  };
  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Please Enter valid a Email!");
    }
  };

  const validateFirstName = (e) => {
    var firstName = e.target.value;

    if (signUpAuth.firstName === "") {
      setFirstNameError("Please enter your First name");
    } else {
      setFirstNameError("");
    }
    return;
  };

  const validateUserName = (e) => {
    var userName = e.target.value;

    if (signUpAuth.userName === "") {
      setUserNameError("Please enter your Username");
    } else {
      setUserNameError("");
    }
    return;
  };

  const validateLastName = (e) => {
    var firstName = e.target.value;

    if (signUpAuth.lastName === "") {
      setLastNameError("Please enter your Last name");
    } else {
      setLastNameError("");
    }
    return;
  };
  const validatePassword = (e) => {
    var firstName = e.target.value;

    if (signUpAuth.password === "") {
      setPasswordError("Please enter your Password");
    } else {
      setPasswordError("");
    }
    return;
  };

  const validateConfirmPassword = (e) => {
    var firstName = e.target.value;

    if (signUpAuth.confirmPassword === "") {
      setConfirmPassError("Please enter your Confirm Password");
    } else {
      setConfirmPassError("");
    }
    return;
  };

  const validateConfirmPasswordCheck = () => {
    // var firstName = e.target.value;
    if (signUpAuth.confirmPassword !== "") {
      if (signUpAuth.password !== signUpAuth.confirmPassword) {
        setConfirmPasswordError("Password and Confirm Password does not match");
      } else {
        setConfirmPasswordError("");
      }
      return;
    }

    return;
  };

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    validateConfirmPasswordCheck();
  }, [signUpAuth]);

  const inputFirstNameFunc = (e) => {
    setSignUpAuth({ ...signUpAuth, firstName: e.target.value });
  };
  const inputLastNameFunc = (e) => {
    setSignUpAuth({ ...signUpAuth, lastName: e.target.value });
  };

  const inputUserNameFunc = (e) => {
    setSignUpAuth({ ...signUpAuth, userName: e.target.value });
  };
  const inputEmailFunc = (e) => {
    setSignUpAuth({ ...signUpAuth, email: e.target.value });
  };

  const inputPasswordFunc = (e) => {
    // var passName = e.target.value;
    setSignUpAuth({ ...signUpAuth, password: e });
  };
  const inputConfirmPasswordFunc = (e) => {
    // var confirmName = e.target.value;
    setSignUpAuth({ ...signUpAuth, confirmPassword: e });
  };

  const passwordVisiblity = () => {
    setIsVisible((isVisible) => !isVisible);
  };
  const ConfirmPasswordVisiblity = () => {
    setIsConfirmVisible((isConfirmVisible) => !isConfirmVisible);
  };

  const initPwdInput = async (childData) => {
    initRobustPassword(childData);
  };

  const history = useHistory();
  const handleOnSubmit = (e) => {
    e.preventDefault();
    console.log(signUpAuth);
  };
  return (
    <div className="totalResBodySignup">
      <div className="signup-info-family">
        <ToastContainer />
        <Header />
        <AuthHeadings headText="Sign up" subHeadText="Create a Deets account" />
        <form autoComplete="new-password" onSubmit={signupAuth}>
          <FormLabel
            labelText="First name"
            inputType="text"
            placeholder="e.g John"
            name="fname"
            onChange={(e) => {
              console.log("signup details", signUpAuth);
              inputFirstNameFunc(e);
            }}
            onBlur={(e) => {
              validateFirstName(e);
            }}
            value={signUpAuth.firstName}
            emailError={firstNameError}
          />
          {signUpError.firstName && <div>{signUpError.firstName}</div>}
          <FormLabel
            labelText="Last name"
            inputType="text"
            placeholder="e.g Doe"
            name="lname"
            onChange={(e) => {
              inputLastNameFunc(e);
            }}
            onBlur={(e) => {
              validateLastName(e);
            }}
            emailError={lastNameError}
            value={signUpAuth.lastName}
          />

          <FormLabel
            labelText="Username"
            inputType="text"
            placeholder="e.g Doe"
            name="uname"
            onChange={(e) => {
              inputUserNameFunc(e);
            }}
            onBlur={(e) => {
              validateUserName(e);
            }}
            emailError={userNameError}
            value={signUpAuth.userName}
          />

          <FormLabel
            labelText="Email"
            inputType="email"
            placeholder="e.g. foo@bar.com"
            name="nemail"
            onChange={(e) => {
              inputEmailFunc(e);
              validateEmail(e);
              console.log("signup details", signUpAuth);
            }}
            // onBlur={(e) => {
            //   validateEmail(e);
            // }}
            value={signUpAuth.email}
            emailError={emailError}
          />

          <DefaultPassInput
            passType={isVisible ? "text" : "password"}
            placeholder="**************"
            name="pass"
            onChange={(e) => {
              handleValidateChange(e.target.value);
              inputPasswordFunc(e.target.value);
              console.log("signup details", signUpAuth);
            }}
            passwordVisiblity={passwordVisiblity}
            isVisible={isVisible}
            labelText="Password"
            onBlur={(e) => {
              validatePassword(e);
            }}
            value={signUpAuth.password}
            errorMessage={passwordError}
          />

          <DefaultPassInput
            passType={isConfirmVisible ? "text" : "password"}
            placeholder="**************"
            name="Cpass"
            onChange={(e) => {
              inputConfirmPasswordFunc(e.target.value);
              validateConfirmPasswordCheck();

              console.log("signup details", signUpAuth);
            }}
            passwordVisiblity={ConfirmPasswordVisiblity}
            isVisible={isConfirmVisible}
            labelText="Confirm Password"
            onBlur={(e) => {
              validateConfirmPassword(e);
            }}
            value={signUpAuth.confirmPassword}
            errorMessage={confirmPasswordError}
          />

          <PasswordStrengthMeter
            password={signUpAuth.password}
            actions={initPwdInput}
          />

          <div className="signupValidCompo">
            <div className="signUpValidGen">
              {
                <div className="signUpValiGen_img">
                  <img src={lenghtValidated ? valiPass : nonValiPass} />
                </div>
              }
              <div className="signUpValiText">At least 6 characters</div>
            </div>
            <div className="signUpValidGen">
              {
                <div className="signUpValiGen_img">
                  <img src={lowerValidated ? valiPass : nonValiPass} />
                </div>
              }
              <div className="signUpValiText">At least 1 lowecase letter </div>
            </div>
            <div className="signUpValidGen">
              {
                <div className="signUpValiGen_img">
                  <img src={upperValidated ? valiPass : nonValiPass} />
                </div>
              }
              <div className="signUpValiText">At least 1 uppercase letter</div>
            </div>

            <div className="signUpValidGen">
              {
                <div className="signUpValiGen_img">
                  <img src={numberValidated ? valiPass : nonValiPass} />
                </div>
              }
              <div className="signUpValiText">At least 1 numeric character</div>
            </div>
            <div className="signUpValidGen">
              {
                <div className="signUpValiGen_img">
                  <img src={specialValidated ? valiPass : nonValiPass} />
                </div>
              }
              <div className="signUpValiText">At least 1 special character</div>
            </div>
            <div className="signUpValidGen">
              {
                <div className="signUpValiGen_img">
                  <img src={maxLenghtValidated ? valiPass : nonValiPass} />
                </div>
              }
              <div className="signUpValiText">Maximum 36 characters</div>
            </div>
          </div>

          <div
            className="signup-save-form"
            style={{
              opacity:
                signUpAuth.firstName === "" ||
                signUpAuth.lastName === "" ||
                signUpAuth.userName === "" ||
                signUpAuth.password === "" ||
                signUpAuth.confirmPassword === "" ||
                signUpAuth.email === "" ||
                firstNameError ||
                lastNameError ||
                userNameError ||
                passwordError ||
                emailError ||
                confirmPassError ||
                confirmPasswordError ||
                !lowerValidated ||
                !upperValidated ||
                !numberValidated ||
                !specialValidated ||
                !lenghtValidated ||
                !maxLenghtValidated
                  ? "0.3"
                  : "1",
            }}
          >
            <div className="signup_btn-save">
              <input
                type="submit"
                value="Sign me up!"
                disabled={
                  signUpAuth.firstName === "" ||
                  signUpAuth.lastName === "" ||
                  signUpAuth.userName === "" ||
                  signUpAuth.password === "" ||
                  signUpAuth.confirmPassword === "" ||
                  signUpAuth.email === "" ||
                  firstNameError ||
                  lastNameError ||
                  userNameError ||
                  passwordError ||
                  emailError ||
                  confirmPassError ||
                  confirmPasswordError ||
                  !lowerValidated ||
                  !upperValidated ||
                  !numberValidated ||
                  !specialValidated ||
                  !lenghtValidated ||
                  !maxLenghtValidated
                }
                style={{
                  cursor:
                    signUpAuth.firstName === "" ||
                    signUpAuth.lastName === "" ||
                    signUpAuth.userName === "" ||
                    signUpAuth.password === "" ||
                    signUpAuth.confirmPassword === "" ||
                    signUpAuth.email === "" ||
                    firstNameError ||
                    lastNameError ||
                    userNameError ||
                    passwordError ||
                    emailError ||
                    confirmPassError ||
                    confirmPasswordError ||
                    !lowerValidated ||
                    !upperValidated ||
                    !numberValidated ||
                    !specialValidated ||
                    !lenghtValidated ||
                    !maxLenghtValidated
                      ? "not-allowed"
                      : "pointer",
                }}
                onClick={signupAuth}
              />
            </div>
          </div>
        </form>
        <AuthFooter
          linkText="Already have an account?"
          linkNameText="Log In"
          linkTo="/login"
        />
      </div>

      {onSuccessContactPage && (
        // <SuccessPage notifyText="Contact Information Saved" />

        <SuccessAlert alertMessage={resMessage} />
      )}
    </div>
  );
};

export default SignUpCont;
