import React, { useState } from "react";
import styled from "styled-components";
import LeftNav from "./LeftNav";
import JWT from "jwt-decode";

const StyledBurger = styled.div`
  width: 1.7rem;
  height: 1.7rem;
  position: absolute;

  top: ${({ open }) => (open ? "15px" : "15px")};
  left: ${({ open }) => (open ? "8%" : "8%")};
  tranform: translate(-50%, -50%);
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 2000000;
  cursor: pointer;
  div {
    width: 1.7rem;
    height: 0.11rem;
    background-color: ${({ open }) => (open ? "#ffffff" : "#ffffff")};
    border-radius: 10px;

    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }

  @media (max-width: 600px) {
    position: ${({ open }) => (open ? "fixed" : "absolute")};
  }
`;

const Burger = ({ emailData, profImage, userRole }) => {
  const [open, setOpen] = useState(false);
  const [openLogoutPage, setOpenLogoutPage] = useState(false);
  const [navImg, setNavImg] = useState(null);
  const [navFirstName, setNavFirstName] = useState("");
  const [navLastName, setNavLastName] = useState("");
  const [navEmailAdd, setNavEmailAdd] = useState();
  const [navColor, setNavColor] = useState("");
  const [checkImgUrl, setCheckImageUrl] = useState(null);
  const [backdropTheme, setBackdropTheme] = useState();
  //  useEffect(() => {
  //     window.addEventListener("scroll", listenToScroll);
  //     return () => window.removeEventListener("scroll", listenToScroll);
  //   }, []);

  //   const listenToScroll = () => {
  //     let heightToHideFrom = 200;
  //     const winScroll =
  //       document.body.scrollTop || document.documentElement.scrollTop;
  //     setHeight(winScroll);

  //     if (winScroll > heightToHideFrom) {
  //       isVisible && setIsVisible(false);
  //     } else {
  //       setIsVisible(true);
  //     }
  //   };

  // -----------------

  const getNavAssets = () => {
    let profNavImg = localStorage.getItem("Profile Image");
    let profNavFirstName = localStorage.getItem("First Name");
    let profNavLastName = localStorage.getItem("Last Name");
    let profNavEmailAdd = localStorage.getItem("User Email");

    console.log("Nav Email", navEmailAdd);
    if (profNavImg == undefined || profNavImg == null || profNavImg == "") {
      return;
    }

    setNavImg(profNavImg);
    setNavFirstName(profNavFirstName);
    setNavLastName(profNavLastName);
  };

  const getData = () => {
    setNavEmailAdd(emailData?.email);
    setNavImg(emailData?.profile_image_url);
    setNavFirstName(emailData?.first_name ?? "");
    setNavLastName(emailData?.last_name ?? "");
    setNavColor(emailData?.theme_color_primary ?? "");
    setCheckImageUrl(emailData?.profile_image ?? "");
    setBackdropTheme(emailData?.apply_theme_to_backdrop);
  };

  return (
    <>
      <StyledBurger
        style={{ display: `${openLogoutPage ? "none" : "flex"}` }}
        open={open}
        onClick={() => {
          setOpen(!open);
          // getNavAssets();
          getData();
        }}
      >
        <div />
        <div />
        <div />
      </StyledBurger>

      <LeftNav
        open={open}
        openLogoutBtn={() => setOpenLogoutPage(!openLogoutPage)}
        closeLogoutBtn={() => setOpenLogoutPage(false)}
        openLogoutPage={openLogoutPage}
        navImg={navImg}
        navFirstName={navFirstName}
        navLastName={navLastName}
        navEmailAdd={navEmailAdd}
        navColor={navColor}
        checkImgUrl={checkImgUrl}
        profImage={profImage}
        userRole={userRole}
        backdropTheme={backdropTheme}
      />
    </>
  );
};

export default Burger;
