import React from "react";
import "./formLabel.css";

const FormLabel = ({
  labelText,
  inputType,
  name,
  onChange,
  placeholder,
  emailAd,
  emailError,
  required,
}) => {
  return (
    <div className="formLabelMain_input">
      <div className="form_containGen">
        <div className="inputLabelContain">
          <span className="formLabelAs">*</span>{" "}
          <span className="formLabelText">{labelText}</span>
        </div>
        <input
          type={inputType}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          className="no-auto-zoom"
          required={required}
        />
        {emailError !== "" ? (
          <div className="email_errorCont">{emailError}</div>
        ) : null}
      </div>
    </div>
  );
};

export default FormLabel;
