import React from "react";

const PhoneIcon = ({ fill }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1337_7477)">
        <path
          d="M3.86484 0.577091C3.68437 0.141153 3.20859 -0.0908778 2.75391 0.033341L0.691406 0.595841C0.283594 0.708341 0 1.07865 0 1.50053C0 7.29897 4.70156 12.0005 10.5 12.0005C10.9219 12.0005 11.2922 11.7169 11.4047 11.3091L11.9672 9.24662C12.0914 8.79193 11.8594 8.31615 11.4234 8.13568L9.17344 7.19818C8.79141 7.03881 8.34844 7.14897 8.08828 7.47006L7.14141 8.62553C5.49141 7.84506 4.15547 6.50912 3.375 4.85912L4.53047 3.91459C4.85156 3.65209 4.96172 3.21147 4.80234 2.82943L3.86484 0.579435V0.577091Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1337_7477">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PhoneIcon;
