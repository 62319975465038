import React, { useState, useEffect } from "react";
import "./forgotPassword.css";
import { Link } from "react-router-dom";

import backArrow from "../../../assets/icons/backArrow.svg";
import deetsBlackLogo from "../../../assets/icons/deetsBlackLogo.svg";

import deetslogo from "../../../assets/icons/deetslogo.svg";
// import passwordHide from "../../../assets/icons/passwordHide.png";
// import passwordHide from "../../../assets/icons/passwordHide.png";
import hidePassword from "../../../assets/icons/hidePassword.svg";
import viewPassword from "../../../assets/icons/viewPassword.svg";
import FormLabel from "./FormLabel";
import NewAuthModal from "./newAuthModal/NewAuthModal";
import { useHistory, useLocation } from "react-router-dom";
// import NewModal from "./newModal/NewModal";

const ForgotPassword = () => {
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleCloseModalFunc = () => {
    setOpenModal(false);
  };

  const handleopenModalFunc = (e) => {
    e.preventDefault();
    setOpenModal(true);
  };

  const handleGoBack = () => {
    history.goBack(); // Go back to the previous page
  };

  const handleClick = () => {
    history.push("/forgotpassword/otp");
  };
  return (
    <div className="totalResBodyFPass">
      <div className="fPass-info-family">
        <div className="fPass-info-header">
          <div style={{ cursor: "pointer" }} onClick={handleGoBack}>
            <img src={backArrow} alt="back_arrow" />
          </div>

          <div className="fPass-info-text">
            {/* <img src={deetsBlackLogo} alt="deets_logo" /> */}
            Forgot password
          </div>
          <div></div>
        </div>

        <div className="fPass-email-text">
          Enter your registered email address here
        </div>

        <form onSubmit={handleopenModalFunc}>
          <FormLabel
            labelText="Email"
            inputType="email"
            placeholder="e.g. foo@bar.com"
            name="fpass"
            // onChange={(e) => {
            //   inputLastNameFunc(e);
            // }}
            // onBlur={(e) => {
            //   validateLastName(e);
            // }}
            // emailError={lastNameError}
            // value={signUpAuth.lastName}
            required={true}
          />
          <div className="fpass_btn-save">
            <input type="submit" value="Continue" />
          </div>
        </form>
      </div>
      {/* <NewModal>
        <div>Children</div>
      </NewModal> */}

      {openModal && (
        <NewAuthModal
          closeNewModal={handleCloseModalFunc}
          headerName="Forgot password"
        >
          <div className="forgot_passwordtextt">
            A unique 4 digit code has been sent to your registered email
            address.
          </div>

          <div onClick={handleClick} className="forgot_passwordCont">
            Continue
          </div>
          <div className="forgot_passwordBack" onClick={handleCloseModalFunc}>
            Go back
          </div>
        </NewAuthModal>
      )}
    </div>
  );
};

export default ForgotPassword;
