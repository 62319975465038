import React from "react";
import { FaTimes } from "react-icons/fa";
import "./addSocialtag.css";

const AddSocialTag = ({ closeSocialTags }) => {
  return (
    <div className="social-tag-modal">
      <div className="social-tag-modal-content">
        <div className="social-tag-close_logout_icon" onClick={closeSocialTags}>
          {" "}
          <FaTimes />
        </div>
        <div className="social-tag-modal-body">
          <div className="add-tag-header">Add tag</div>
          <div className="add-tag-content-text">
            Add tag to show differences in social <br /> media accounts
          </div>
          <div className="tags-flex">
            <div className="personal-tag-cont">Personal</div>
            <div className="business-tag-cont">Business</div>
          </div>
          <div className="inputFieldSocialTag">
            <input
              type="text"
              placeholder=" "
              //   value={singleVideoLink.phone}
              //   name="videoLink"
              //   onChange={(e) => handleVideoLinkChange(e, index)}
            />
            <span>Add personal tag</span>
          </div>

          <div className="add-tag-button">Add tag</div>
          <div className="tag-cancel-button" onClick={closeSocialTags}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSocialTag;
