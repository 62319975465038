import React, { useEffect, useState } from "react";
import "./contactOd.css";

const ContactOd = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [dropdownSets, setDropdownSets] = useState([
    {
      countries: "",
      states: "",
      cities: "",
      statesList: [],
      citiesList: [],
    },
  ]);

  const handleAddDropdownSet = () => {
    setDropdownSets([
      ...dropdownSets,
      {
        countries: "",
        states: "",
        cities: "",
        statesList: [],
        citiesList: [],
      },
    ]);
  };

  const handleRemoveDropdownSet = (index) => {
    const newDropdownSets = [...dropdownSets];
    newDropdownSets.splice(index, 1);
    setDropdownSets(newDropdownSets);
  };

  const handleCountryChange = (event, dropdownSetIndex) => {
    const newDropdownSets = [...dropdownSets];
    const selectedCountry = event.target.value;
    newDropdownSets[dropdownSetIndex].countries = selectedCountry;

    const selectedState = "";
    const selectedCity = "";
    const states =
      countries.find((country) => country.name === selectedCountry)?.states ||
      [];
    const cities = [];

    newDropdownSets[dropdownSetIndex].states = selectedState;
    newDropdownSets[dropdownSetIndex].cities = selectedCity;
    newDropdownSets[dropdownSetIndex].statesList = states;
    newDropdownSets[dropdownSetIndex].citiesList = cities;

    setDropdownSets(newDropdownSets);
  };

  const handleStateChange = (event, dropdownSetIndex) => {
    const newDropdownSets = [...dropdownSets];
    const selectedState = event.target.value;
    newDropdownSets[dropdownSetIndex].states = selectedState;

    const selectedCity = "";
    const cities =
      newDropdownSets[dropdownSetIndex].statesList.find(
        (state) => state.name === selectedState
      )?.cities || [];

    newDropdownSets[dropdownSetIndex].cities = selectedCity;
    newDropdownSets[dropdownSetIndex].citiesList = cities;

    setDropdownSets(newDropdownSets);
  };

  const handleCityChange = (event, dropdownSetIndex) => {
    const newDropdownSets = [...dropdownSets];
    const selectedCity = event.target.value;
    newDropdownSets[dropdownSetIndex].cities = selectedCity;
    setDropdownSets(newDropdownSets);
    console.log(dropdownSets, "yudooo");
  };

  const countries = [
    {
      name: "USA",
      states: [
        {
          name: "California",
          cities: ["Los Angeles", "San Francisco", "San Diego"],
        },
        {
          name: "New York",
          cities: ["New York City", "Buffalo", "Rochester"],
        },
      ],
    },
    {
      name: "Canada",
      states: [
        {
          name: "Ontario",
          cities: ["Toronto", "Ottawa", "Hamilton"],
        },
        {
          name: "Quebec",
          cities: ["Montreal", "Quebec City", "Sherbrooke"],
        },
      ],
    },
  ];

  // useEffect(() => {
  //   if (isOpen === true) {
  //     alert("hi");
  //   }
  // }, []);

  const handleOpenModal = () => {
    setIsOpenModal(true);
    // if (isOpen === true) {
    //   alert("hi");
    // }
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };
  return (
    <>
      <div>
        {dropdownSets.map((dropdownSet, index) => (
          <div key={index}>
            <label htmlFor={`country-dropdown-${index}`}>
              Select a country:
            </label>
            <select
              id={`country-dropdown-${index}`}
              value={dropdownSet.countries}
              onChange={(event) => handleCountryChange(event, index)}
            >
              <option value="">--Select a country--</option>
              {countries.map((country) => (
                <option key={country.name} value={country.name}>
                  {country.name}
                </option>
              ))}
            </select>

            <label htmlFor={`state-dropdown-${index}`}>Select a state:</label>
            <select
              id={`state-dropdown-${index}`}
              value={dropdownSet.states}
              onChange={(event) => handleStateChange(event, index)}
              disabled={!dropdownSet.countries}
            >
              <option value="">--Select a state--</option>
              {dropdownSet.statesList.map((state) => (
                <option key={state.name} value={state.name}>
                  {state.name}
                </option>
              ))}
            </select>

            <label htmlFor={`city-dropdown-${index}`}>Select a city:</label>
            <select
              id={`city-dropdown-${index}`}
              value={dropdownSet.cities}
              onChange={(event) => handleCityChange(event, index)}
              disabled={!dropdownSet.states}
            >
              <option value="">--Select a city--</option>
              {dropdownSet.citiesList.map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))}
            </select>

            <button onClick={() => handleRemoveDropdownSet(index)}>
              Remove dropdown set
            </button>
          </div>
        ))}

        <button onClick={handleAddDropdownSet}>Add dropdown set</button>
      </div>

      <div className={`modal-wrapperTes ${isOpenModal ? "open" : ""}`}>
        <button onClick={handleOpenModal}>Open Modal</button>

        {isOpenModal && (
          <div className="modal-wrapperTes">
            <div className="modal-contentTes">
              <h2>Modal Content</h2>
              <p>This is the modal content.</p>
              <button onClick={handleCloseModal}>Close</button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ContactOd;
