import React, { useEffect } from "react";
import defaultContCard from "../../../../assets/icons/defaultContCard.svg";
import deleteIconConnect from "../../../../assets/icons/deleteIconConnect.svg";

import "./connectionCard.css";
import ConnectMailIcon from "../../../../assets/icons/ConnectMailIcon";
import ConnectPhoneIcon from "../../../../assets/icons/ConnectPhoneIcon";
import optionIcons from "../../../../assets/icons/optionIcons.svg";
import { useState } from "react";
import ConnectModalMail from "../../../../assets/icons/ConnectModalMail";
import ConnectModalPhone from "../../../../assets/icons/ConnectModalPhone";
import VcardComponent from "../../../../libs/Vcard";

const ConnectionCard = ({
  connectsData,
  handleSingleConnect,
  index,
  menuId,

  active,
  onClick,
  singleConnectData,
  handleChildClick,
}) => {
  const [menuBtn, setMenuBtn] = useState(false);
  const [defaultTheme, setDefaultTheme] = useState("");
  const {
    full_name,

    phone_number,
    email,

    added_dt,
    profile_id,
  } = connectsData;

  useEffect(() => {
    setDefaultTheme(localStorage.getItem("Default Theme"));
  }, []);

  const handleMenuBtnOpen = () => {
    setMenuBtn(!menuBtn);
  };

  const handleMenuBtnClose = () => {
    if (menuBtn === true) {
      setMenuBtn(false);
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    // Remove all non-digit characters from the phone number
    const digitsOnly = phoneNumber?.replace(/\D/g, "");

    // Apply the desired format: +000 000 000 0000
    const formattedPhoneNumber = `${digitsOnly?.slice(
      0,
      3
    )} ${digitsOnly?.slice(3, 6)} ${digitsOnly?.slice(
      6,
      9
    )} ${digitsOnly?.slice(9)}`;

    return formattedPhoneNumber;
  };

  const formattedPhoneNumber = formatPhoneNumber(phone_number);

  const dateStr = added_dt;
  const date = new Date(dateStr);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = date?.toLocaleString("en-US", options);

  console.log(formattedDate, "form date");
  return (
    <div
      style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}
      onClick={handleChildClick}
    >
      <div
        // onClick={() => {
        //   handleSingleConnect(connectsData);
        //   console.log(profile_id, "container id");
        //   console.log(connectsData, "container id");
        // }}
        // onClick={handleMenuBtnClose}
        className="connectionCard_bodyGen"
        onClick={() => {
          onClick(menuId);
          handleSingleConnect(connectsData);
        }}
      >
        <div className="connectionCard_SubBody">
          {/* {/* {contactImg === null ? ( */}
          <div className="connectionCard_img">
            <img src={defaultContCard} />
          </div>
          {/* ) : ( 
          <div className="connectionCard_img">
            <img src={contactImg} />
          </div>
          )} */}
          <div className="connectionCard_Right">
            <div className="connectionCard_RightName">{full_name}</div>
            {/* {jobDesc && ( */}
            <div className="connects_detailFlex">
              <div className="connects_detailsIcon">
                <ConnectMailIcon
                  fill={defaultTheme === "" ? `#36A6A4` : `${defaultTheme}`}
                />
              </div>
              <div className="connectionCard_RightDesc">{email}</div>
            </div>
            <div className="connects_detailFlex">
              <div className="connects_detailsIcon">
                <ConnectPhoneIcon
                  fill={defaultTheme === "" ? `#36A6A4` : `${defaultTheme}`}
                />
              </div>
              <div className="connectionCard_RightDesc">
                {formattedPhoneNumber}
              </div>
            </div>
            {/* )} */}
          </div>
        </div>
        <div className="connectionCard_date">
          <div className="connections_optIcon">
            <img
              // onClick={() => {

              //   handleMenuBtnOpen();
              // }}

              onClick={() => onClick(menuId)}
              src={optionIcons}
            />
          </div>
          <div className="connections_dateOpt">{formattedDate}</div>
        </div>
        {active && (
          <div className="menu_genBody">
            <div className="connectModal_mailGen">
              <div className="connectModal_mailIcon">
                <ConnectModalMail
                  fill={defaultTheme === "" ? `#36A6A4` : `${defaultTheme}`}
                />
              </div>
              <div>
                <div className="connectModal_textHead">Email</div>

                <a
                  href={`mailto:${email}`}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <div className="connectModalMail_text">{email}</div>
                </a>
              </div>
            </div>

            <div className="conectModal_phoneGen">
              <div className="connectModalPhone_icon">
                <ConnectModalPhone
                  fill={defaultTheme === "" ? `#36A6A4` : `${defaultTheme}`}
                />
              </div>

              <div>
                <div className="connectModal_textHead">Call</div>

                <a
                  href={`tel:${phone_number}`}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <div className="connectModalPhone_text">{phone_number}</div>
                </a>
              </div>
            </div>

            <VcardComponent
              defaultTheme={defaultTheme}
              userData={singleConnectData}
            />
            {/* <button
              // style={{
              //   border: `0.3px solid ${
              //     defaultTheme === "" ? `#36A6A4` : `${defaultTheme}`
              //   }`,
              // }}
              className="deleteContact_Btn"
              onClick={console.log(index, "data delete index")}
            >
              Delete contact
            </button> */}

            {/* <div className="deleteContact_iconnn__">
              <img src={deleteIconConnect} />
            </div> */}

            {/* <div className="saveConnects_btn">Save contact</div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default ConnectionCard;
