import optionAssets from "./optionAssets";
import connectionsAssets from "./connectionsAssets";
import { useState } from "react";
import LinkIcon from "../../assets/icons/LinkIcon";
import "./locationOptionData.css";

export const stateOptionData = [
  {
    name: "Abia",
    valueId: "Abia",
  },
  {
    name: "Adamawa",
    valueId: "Adamawa",
  },
  {
    name: "Akwa - Ibom",
    valueId: "Akwa - Ibom",
  },
  {
    name: "Anambra",
    valueId: "Anambra",
  },
  {
    name: "Bauchi",
    valueId: "Bauchi",
  },
  {
    name: "Bayelsa",
    valueId: "Bayelsa",
  },
  {
    name: "Benue",
    valueId: "Benue",
  },
];

export const cityOptionData = [
  {
    name: "A",
    valueId: "A",
  },
  {
    name: "B",
    valueId: "B",
  },
  {
    name: "C",
    valueId: "C",
  },
  {
    name: "D",
    valueId: "D",
  },
  {
    name: "E",
    valueId: "E",
  },
  {
    name: "F",
    valueId: "F",
  },
  {
    name: "G",
    valueId: "G",
  },
  {
    name: "H",
    valueId: "H",
  },
];

export const socialOptionData = [
  {
    id: 1,
    account_type: "Facebook",
    icon_url: optionAssets.icontwitter,
  },
  {
    id: 2,
    account_type: "Instagram",
    icon_url: optionAssets.iconinstagram,
  },
  {
    id: 3,
    account_type: "Twitter",
    icon_url: optionAssets.icontwitter,
  },
  {
    id: 4,
    account_type: "LinkedIn",
    icon_url: optionAssets.iconlinkedin,
  },
  {
    id: 5,
    account_type: "Messenger",
    icon_url: optionAssets.iconmessenger,
  },
  {
    id: 6,
    account_type: "Pinterest",
    icon_url: optionAssets.iconpinterest,
  },
  {
    id: 7,
    account_type: "Snapchat",
    icon_url: optionAssets.iconsnapchat,
  },
  {
    id: 8,
    account_type: "Tumblr",
    icon_url: optionAssets.icontumblr,
  },

  {
    id: 9,
    account_type: "Whatsapp",
    icon_url: optionAssets.iconwhatsapp,
  },
];

export const connectionsData = [
  {
    id: 1,
    contactFirstName: "Joseph ",
    contactLastName: "Rahma-nni",
    contactImg: null,
    contactPhoneNo: "+234 (0) 467 234 5324",
    contactEmailAdd: "AdbulRahman@icloud.com",
    jobDesc: "",
    contactDate: "2 Mar, 2023",
  },
  {
    id: 2,
    contactFirstName: "Akeela ",
    contactLastName: "Bernice",
    contactImg: null,
    contactPhoneNo: "+234 (0) 467 234 5324",
    contactEmailAdd: "AdbulRahman@icloud.com",
    jobDesc:
      "Conducting surveys and utilizing various sampling techniques, Collecting and analyzing financial, political and socioeconomic data",
    contactDate: "1 Mar, 2023",
  },

  {
    id: 3,
    contactFirstName: "Obianuju ",
    contactLastName: "Amaka",
    contactImg: null,
    contactPhoneNo: "+234 (0) 467 234 5324",
    contactEmailAdd: "AdbulRahman@icloud.com",
    jobDesc: "Lead marketer, Flutterware Internet holdings",
    contactDate: "28 Feb, 2023",
  },
  {
    id: 4,
    contactFirstName: "Cally ",
    contactLastName: "Barry",
    contactImg: null,
    contactPhoneNo: "+234 (0) 467 234 5324",
    contactEmailAdd: "AdbulRahman@icloud.com",
    jobDesc: "Head Sales and customer relations, Anabel suit",
    contactDate: "23 Feb, 2023",
  },
  {
    id: 5,
    contactFirstName: "Saviour ",
    contactLastName: "Ferdinand",
    contactImg: null,
    contactPhoneNo: "+234 (0) 467 234 5324",
    contactEmailAdd: "AdbulRahman@icloud.com",
    jobDesc: "Data engineer with 20 years Experience",
    contactDate: "20 Feb, 2023",
  },
  {
    id: 6,
    contactFirstName: "Fiona ",
    contactLastName: "Okpara",
    contactImg: null,
    contactPhoneNo: "+234 (0) 467 234 5324",
    contactEmailAdd: "AdbulRahman@icloud.com",
    jobDesc: "Lead marketer, Flutterware Internet holdings",
    contactDate: "18 Feb, 2023",
  },
  {
    id: 7,
    contactFirstName: "Bucho",
    contactLastName: "Isi-barine",
    contactImg: null,
    contactPhoneNo: "+234 (0) 467 234 5324",
    contactEmailAdd: "AdbulRahman@icloud.com",
    contactDate: "10 Feb, 2023",
  },
  {
    id: 8,
    contactFirstName: "Abdul",
    contactLastName: "Rahmanni",
    contactImg: null,
    contactPhoneNo: "+234 (0) 467 234 5324",
    contactEmailAdd: "AdbulRahman@icloud.com",
    contactDate: "10 Feb, 2023",
  },
  {
    id: 9,
    contactFirstName: "Abdul",
    contactLastName: "Rahmanni",
    contactImg: null,
    contactPhoneNo: "+234 (0) 467 234 5324",
    contactEmailAdd: "AdbulRahman@icloud.com",
    contactDate: "10 Feb, 2023",
  },

  {
    id: 10,
    contactFirstName: "Abdul",
    contactLastName: "Rahmanni",
    contactImg: null,
    contactPhoneNo: "+234 (0) 467 234 5324",
    contactEmailAdd: "AdbulRahman@icloud.com",
    contactDate: "10 Feb, 2023",
  },
  {
    id: 11,
    contactFirstName: "Kenendra",
    contactLastName: "Osfold",
    contactImg: null,
    contactPhoneNo: "+234 (0) 467 234 5324",
    contactEmailAdd: "AdbulRahman@icloud.com",
    contactDate: "10 Feb, 2023",
  },
  {
    id: 12,
    contactFirstName: "Kenendra",
    contactLastName: "Osfold",
    contactImg: null,
    contactPhoneNo: "+234 (0) 467 234 5324",
    contactEmailAdd: "AdbulRahman@icloud.com",
    contactDate: "10 Feb, 2023",
  },
  {
    id: 13,
    contactFirstName: "Kenendra",
    contactLastName: "Osfold",
    contactImg: null,
    contactPhoneNo: "+234 (0) 467 234 5324",
    contactEmailAdd: "AdbulRahman@icloud.com",
    contactDate: "10 Feb, 2023",
  },
  {
    id: 14,
    contactFirstName: "Kenendra",
    contactLastName: "Osfold",
    contactImg: null,
    contactPhoneNo: "+234 (0) 467 234 5324",
    contactEmailAdd: "AdbulRahman@icloud.com",
    contactDate: "10 Feb, 2023",
  },
  {
    id: 15,
    contactFirstName: "Denendra",
    contactLastName: "Osfold",
    contactImg: null,
    contactPhoneNo: "+234 (0) 467 234 5324",
    contactEmailAdd: "AdbulRahman@icloud.com",
    contactDate: "10 Feb, 2023",
  },
];

export const socialData = [
  {
    id: 0,
    account_type: "Facebook",
    icon_url: optionAssets.iconmessenger,
    url: "https://facebook.com/111222",
  },
  {
    id: 1,
    account_type: "Facebook",
    icon_url: optionAssets.iconmessenger,
    url: "https://facebook.com/111222",
  },
  {
    id: 2,
    account_type: "Instagram",
    icon_url: optionAssets.iconinstagram,
    url: "https://insta.com/111222",
  },
];

export const addressData = [
  {
    name: "Afghanistan",
    states: [
      {
        name: "Badakhshan",
        cities: [
          "Arghanj Khwa",
          "Argo",
          "Baharak",
          "Darayim",
          "Fayzabad",
          "Ishkashim",
          "Jurm",
          "Khash",
          "Khwahan",
          "Kishim",
          "Kohistan",
          "Kuf Ab",
          "Kuran Wa Munjan",
          "Maimay ",
          "Nusay ",
          "Ragh",
          "Shahri Buzurg",
          "Shekay",
          "Shighnan",
          "Shuhada ",
          "Tagab ",
          "Tishkan ",
          "Wakhan",
          "Wurduj ",
          "Yaftali Sufla",
          "Yamgan ",
          "Yawan ",
          "Zebak",
        ],
      },
      {
        name: "Baghlan",
        cities: [
          "Andarab",
          "Baghlan ",
          "Baghlani Jadid",
          "Burka",
          "Dahana i Ghuri",
          "Dih Salah",
          "Dushi",
          "Farang wa Gharu",
          "Guzargahi Nur",
          "Khinjan",
          "Khost wa Fereng",
          "Khwaja Hijran",
          "Nahrin",
          "Puli Hisar",
          "Puli Khumri",
          "Tala Wa Barfak",
        ],
      },
      {
        name: "Kunduz",
        cities: [
          "Ali Abad",
          "Archi ",
          "Chardara",
          "Imam Sahib",
          "Khan Abad",
          "Kunduz",
          "Qalay-I-Zal",
          "Aaqtash",
          "Gul Tepa",
          "Kalbaad",
        ],
      },

      {
        name: "Takhar ",
        cities: [
          "Baharak ",
          "Bangi ",
          "Chah Ab",
          "Chal",
          "Darqad",
          "Dashti Qala",
          "Farkhar",
          "Hazar Sumuch",
          "Ishkamish",
          "Kalafgan",
          "Khwaja Bahauddin",
          "Khwaja Ghar",
          "Namak Ab",
          "Rustaq",
          "Taluqan",
          "Warsaj",
          "Yangi Qala",
        ],
      },
      {
        name: "Balkh ",
        cities: [
          "Balkh ",
          "Chahar Bolak ",
          "Chahar Kint",
          "Chimtal",
          "Dawlatabad",
          "Dihdadi",
          "Kaldar",
          "Khulmi",
          "Kishindih",
          "Marmul",
          "Mazar-e Sharif",
          "Nahri Shahi",
          "Sholgara",
          "Shortepa",
          "Zari",
        ],
      },
      {
        name: "Faryab  ",
        cities: [
          "Almar ",
          "Andkhoy ",
          "Bilchiragh",
          "Dawlat Abad",
          "Gurziwan ",
          "Khani Chahar Bagh",
          "Khwaja Sabz Posh",
          "Kohistan",
          "Maymana",
          "Pashtun Kot",
          "Qaramqol",
          "Qaysar",
          "Qurghan ",
          "Shirin Tagab",
        ],
      },
      {
        name: "Jowzjan   ",
        cities: [
          "Aqcha ",
          "Darzab ",
          "Fayzabad",
          "Khamyab",
          "Khaniqa ",
          "Khwaja Du Koh",
          "Mardyan",
          "Mingajik",
          "Qarqin",
          "Qush Tepa",
          "Shibirghan",
        ],
      },
      {
        name: "Samangan    ",
        cities: [
          "Aybak ",
          "Dara-I-Sufi Balla  ",
          "Dara-I-Sufi Payan",
          "Feroz Nakhchir",
          "Hazrati Sultan ",
          "Khuram Wa Sarbagh",
          "Ruyi Du Ab",
        ],
      },
      {
        name: "Sar-e Pol ",
        cities: [
          "Balkhab ",
          "Gosfandi",
          "Kohistanat",
          "Sangcharak",
          "Sar-e Pul",
          "Sayyad",
          "Sozma Qala",
        ],
      },
      {
        name: "Sar-e Pol ",
        cities: [
          "Balkhab ",
          "Gosfandi",
          "Kohistanat",
          "Sangcharak",
          "Sar-e Pul",
          "Sayyad",
          "Sozma Qala",
        ],
      },
      {
        name: "Bamyan ",
        cities: [
          "Bamyan ",
          "Kahmard ",
          "Panjab",
          "Sayghan ",
          "Shibar",
          "Waras",
          "Yakawlang",
        ],
      },
      {
        name: "Kabul  ",
        cities: [
          "Bagrami ",
          "Chahar Asyab ",
          "Deh Sabz",
          "Farza  ",
          "Guldara",
          "Istalif",
          "Kabul",
          "Kalakan",
          "Khaki Jabbar",
          "Mir Bacha Kot",
          "Mussahi",
          "Paghman",
          "Qarabagh",
          "Shakardara",
          "Surobi",
        ],
      },
      {
        name: "Kapisa",
        cities: [
          "Alasay ",
          "Hesa Awal Kohistan",
          "Hesa Duwum Kohistan",
          "Koh Band",
          "Mahmud Raqi",
          "Nijrab",
          "Tagab",
        ],
      },
      {
        name: "Logar ",
        cities: [
          "Azra  ",
          "Baraki Barak",
          "Charkh",
          "Kharwar ",
          "Khoshi",
          "Mohammad Agha",
          "Puli Alam",
        ],
      },
      {
        name: "Panjshir  ",
        cities: [
          "Anaba ",
          "Bazarak ",
          "Darah Abdullah khail",
          "Khenj  ",
          "Paryan ",
          "Rokha ",
          "Shotul ",
          "Darah Abshar",
        ],
      },
      {
        name: "Parwan",
        cities: [
          "Bagram",
          "Chaharikar",
          "Ghorband",
          "Jabul Saraj",
          "Kohi Safi",
          "Salang",
          "Sayed Khel",
          "Sheikh Ali",
          "Shinwari",
          "Surkhi Parsa",
        ],
      },
      {
        name: "Maidan Wardak",
        cities: [
          "Chaki",
          "Day Mirdad",
          "Hisa-I-Awali Bihsud",
          "Jaghatu ",
          "Jalrez",
          "Markazi Bihsud",
          "Maydan Shahr",
          "Nirkh",
          "Saydabad",
        ],
      },
      {
        name: "Kunar",
        cities: [
          "Asadabad",
          "Bar Kunar",
          "Chapa Dara",
          "Chawkay ",
          "Dangam",
          "Dara-I-Pech",
          "Ghaziabad ",
          "Khas Kunar",
          "Marawara",
          "Narang Wa Badil",
          "Nari",
          "Nurgal",
          "Shaigal ",
          "Shultan ",
          "Sirkanai",
          "Wata Pur ",
          "Shultan",
        ],
      },
      {
        name: "Laghman ",
        cities: [
          "Alingar",
          "Alishing",
          "Baad Pakh",
          "Dawlat Shah ",
          "Mihtarlam",
          "Qarghayi",
        ],
      },
      {
        name: "Nangarhar  ",
        cities: [
          "Achin",
          "Bati Kot",
          "Behsud ",
          "Chaparhar",
          "Dara-I-Nur",
          "Dih Bala",
          "Dur Baba",
          "Goshta",
          "Hisarak",
          "Jalalabad",
          "Kama",
          "Khogyani",
          "Kot ",
          "Kuz Kunar",
          "Lal Pur",
          "Muhmand Dara",
          "Nazyan",
          "Pachir Wa Agam",
          "Rodat",
          "Sherzad",
          "Shinwar",
          "Surkh Rod",
          "Haska Meyna",
        ],
      },
      {
        name: "Nuristan",
        cities: [
          "Bargi Matal",
          "Du Ab",
          "Kamdesh",
          "Mandol",
          "Nurgaram ",
          "Parun ",
          "Wama",
          "Waygal",
        ],
      },
      {
        name: "Badghis",
        cities: [
          "Ab Kamari",
          "Ghormach",
          "Jawand",
          "Muqur",
          "Bala Murghab ",
          "Qadis ",
          "Qala-I-Naw",
        ],
      },
      {
        name: "Farah ",
        cities: [
          "Anar Dara",
          "Bakwa",
          "Bala Buluk",
          "Farah",
          "Gulistan",
          "Khaki Safed ",
          "Lash wa Juwayn",
          "Pur Chaman",
          "Pusht Rod",
          "Qala i Kah",
          "Shib Koh",
        ],
      },
      {
        name: "Ghor",
        cities: [
          "Chaghcharan (Firozkoh)",
          "Marghab ",
          "Charsada",
          "Dawlat Yar",
          "Du Layna ",
          "Lal Wa Sarjangal",
          "Pasaband",
          "Saghar",
          "Shahrak",
          "Taywara",
          "Tulak",
        ],
      },
      {
        name: "Herat",
        cities: [
          "Adraskan",
          "Chishti Sharif",
          "Farsi",
          "Ghoryan",
          "Gulran",
          "Guzara",
          "Hirat",
          "Injil",
          "Karukh",
          "Kohsan",
          "Kushk",
          "Kushki Kuhna",
          "Obe",
          "Pashtun Zarghun",
          "Sabzwar",
          "Zinda Jan (Pooshang)",
          "Turghandi",
          "Islam Qala",
        ],
      },
      {
        name: "Ghazni",
        cities: [
          "Ab Band",
          "Ajristan",
          "Andar",
          "Dih Yak",
          "Gelan",
          "Ghazni City",
          "Giro",
          "Jaghatū District",
          "Jaghuri",
          "Khugiani ",
          "Khwaja Umari",
          "Malistan",
          "Muqur",
          "Nawa",
          "Nawur",
          "Qarabagh",
          "Rashidan ",
          "Waghaz ",
          "Zana Khan",
        ],
      },
      {
        name: "Khost",
        cities: [
          "Bak",
          "Gurbuz",
          "Jaji Maydan",
          "Khost (Matun)",
          "Mandozai",
          "Musa Khel",
          "Nadir Shah Kot",
          "Qalandar",
          "Sabari",
          "Shamal ",
          "Spera",
          "Tani",
          "Tere Zayi",
        ],
      },
      {
        name: "Paktia ",
        cities: [
          "Ahmad Aba",
          "Ahmadkhel",
          "Dand Aw Patan",
          "Gardez",
          "Janikhel",
          "Lazha Mangal",
          "Said Karam",
          "Shwak",
          "Tsamkani",
          "Zadran ",
          "Zazi",
          "Zurmat",
          "Rohani Baba",
          "Mirzaka",
          "Gerda Serai",
        ],
      },
      {
        name: "Paktika",
        cities: [
          "Barmal",
          "Dila",
          "Gayan",
          "Gomal",
          "Janikhel",
          "Khairkot",
          "Mata Khan",
          "Nika",
          "Omna",
          "Sar Hawza",
          "Surobi",
          "Sharan",
          "Terwa ",
          "Urgun",
          "Wazakhwa",
          "Wor Mamay",
          "Yahya Khel",
          "Yusufkhel ",
          "Ziruk",
        ],
      },
      {
        name: "Daykundi ",
        cities: [
          "Ishtarlay ",
          "Kajran ",
          "Khadir ",
          "Kiti ",
          "Miramor ",
          "Nili ",
          "Sangtakht ",
          "Shahristan ",
        ],
      },
      {
        name: "Helmand  ",
        cities: [
          "Baghran ",
          "Dishu ",
          "Garmsir ",
          "Grishk ",
          "Kajaki ",
          "Khanashin ",
          "Lashkargah ",
          "Majrah  ",
          "Musa Qala",
          "Nad Ali",
          "Nawa-I-Barakzayi",
          "Nawzad",
          "Sangin",
          "Washir",
        ],
      },
      {
        name: "Kandahar   ",
        cities: [
          "Arghandab ",
          "Dand ",
          "Arghistan ",
          "Daman ",
          "Ghorak ",
          "Kandahar ",
          "Khakrez ",
          "Maruf  ",
          "Maiwand",
          "Miyan Nasheen",
          "Naish ",
          "Panjwaye",
          "Reg",
          "Shah Wali Kot",
          "Shorabak",
          "Spin Boldak",
          "Zhari ",
        ],
      },
      {
        name: "Nimruz  ",
        cities: [
          "Chahar Burjak ",
          "Chakhansur ",
          "Kang ",
          "Khash Rod ",
          "Zaranj ",
        ],
      },
      {
        name: "Orūzgān",
        cities: [
          "Chora",
          "Deh Rawood ",
          "Gizab ",
          "Khas Uruzgan",
          "Shahidi Hassas ",
          "Tarinkot",
        ],
      },
      {
        name: "Zabul ",
        cities: [
          "Argahandab",
          "Atghar",
          "Daychopan ",
          "Kakar",
          "Naw Bahar",
          "Qalat ",
          "Shah Joy",
          "Shamulzayi",
          "Shinkay",
          "Tarnak Wa Jaldak",
          "Suria",
        ],
      },
    ],
  },

  {
    name: "Albania",
    states: [
      {
        name: "Berat",
        cities: ["Berat", "Corovode"],
      },
      {
        name: "Diber",
        cities: ["Burrel", "Peshkopi"],
      },
      {
        name: "Durres",
        cities: ["Durres", "Kruje", "Tirana"],
      },
      {
        name: "Elbasan",
        cities: ["Elbasan", "Gramsh", "Librazhd"],
      },
      {
        name: "Fier",
        cities: ["Fier", "Lushnje"],
      },
      {
        name: "Gjirokaster",
        cities: ["Gjirokaster", "Permet", "Tepelene"],
      },
      {
        name: "Korce",
        cities: ["Erseke", "Korce", "Pogradec"],
      },
      {
        name: "Kukes",
        cities: ["Bajram Curri	", "Kukes"],
      },
      {
        name: "Lezhe",
        cities: ["Lezhe", "Rreshen"],
      },
      {
        name: "Shkoder",
        cities: ["Puke", "Shkoder"],
      },
      {
        name: "Vlore",
        cities: ["Sarande	", "Vlore"],
      },
    ],
  },

  {
    name: "Algeria",
    states: [
      {
        name: "Adrar",
        cities: [
          "Adrar",
          "Reggane",
          "Timimoun",
          "Bordj Badji Mokhtar",
          "Aoulef",
          "Zaouiet Kounta",
          "Akabli",
          "Timokten ",
        ],
      },
      {
        name: "Chlef",
        cities: [
          "Chlef",
          "Ténès",
          "El Karimia",
          "Beni Haoua",
          "Boukadir",
          "Oued Fodda",
          "Oued Sly",
          "Abou El Hassen",
          "Taougrit",
          "Ain Merane",
        ],
      },
      {
        name: "Laghouat",
        cities: [
          "Laghouat",
          "Aflou",
          "Ksar El Hirane",
          "El Assafia",
          "Brida",
          "Oued Morra",
          "Sidi Makhlouf",
          "Gueltet Sidi Saad",
          "Ain Madhi",
          "Aougrout",
        ],
      },
      {
        name: "Oum El Bouaghi",
        cities: [
          "Oum El Bouaghi",
          "Ain Beida",
          "Aïn M'lila",
          "Ain Fakroun",
          "Ain Kercha",
          "Ain M'lila",
          "Behir Chergui",
          "Berriche",
          "Bir Chouhada",
          "Dhalaa",
          "El Amiria",
          "El Belala",
          "El Fedjoudj Boughrara",
          "Feroukhi",
          "Ksar Sbahi",
          "Meskiana",
          "Ouled Gacem",
          "R'Khams",
          "Sigus",
        ],
      },
      {
        name: "Batna",
        cities: [
          "Batna",
          "Arris",
          "Barika",
          "Merouana",
          "Boumia",
          "Biskra",
          "El Madher",

          "Timgad",
          "Aïn Touta",
          "Ras El Aioun",
          "Menaa",
          "Ouled Si Slimane",
          "Ain Djasser",
          "Chemora",
          "Seggana",
        ],
      },
      {
        name: "Béjaïa",
        cities: [
          "Béjaïa ",
          "Akbou",
          "Amizour",
          "Aokas",
          "Barbacha",
          "Chemini",
          "Darguina",
          "El Kseur",
          "Ifri-Ouzellaguene",
          "Kherrata",
          "Seddouk",
          "Sidi Aïch",
          "Souk El Tenine",
          "Tazmalt",
          "Tichy",
          "Timezrit",
          "Toudja",
          "Tizi N'Berber",
        ],
      },
      {
        name: "Biskra",
        cities: [
          "Biskra",
          "Tolga",
          "Ouled Djellal",
          "M'Lili",
          "El Kantara",
          "El Outaya",
          "Ain Zaatout",
          "Sidi Okba",
          "Besbes",
          "Zeribet El Oued",
          "Djemorah",
          "Chetma",
          "Foughala",
          "M'Chouneche",
          "Sidi Khaled",
          "Ourlal",
        ],
      },
      {
        name: "Béchar",
        cities: [
          "Béchar ",
          "Abadla",
          "Taghit",
          "Kenadsa",
          "Igli",
          "Beni Abbès",
          "Erg Ferradj",
          "Lahmar",
          "Erg Chech",
          "Kénadsa",
          "Timoudi",
          "Kerzaz",
          "Ouled Khoudir",
          "Meridja",
          "Beni Ikhlef",
        ],
      },
      {
        name: "Blida",
        cities: [
          "Boufarik",
          "Chérea",
          "Oued El Alleug",
          "Ouled Yaich",
          "Bougara",
          "Soumaa",
          "El Affroun",
          "Larbaâ",
          "Bouinan",
          "Mouzaïa",
          "Meftah",
          "Oued Djer",
          "Hammam Melouane",
          "Beni Tamou",
        ],
      },
      {
        name: "Bouira",
        cities: [
          "Lakhdaria",
          "Sour El-Ghozlane",
          "M'Chedallah",
          "Bechloul",
          "El-Asnam",
          "Aomar",
          "Aghbalou",
          "Bordj Okhriss",
          "Taguedit",
          "Saharidj",
          "Ain El Hadjar",
          "Dirah",
          "Maala",
          "El-Hachimia",
          "Lakhdaria",
          "Lakhdaria",
          "Lakhdaria",
          "Lakhdaria",
          "Lakhdaria",
          "Lakhdaria",
          "Lakhdaria",
          "Lakhdaria",
          "Lakhdaria",
        ],
      },
      {
        name: "Tamanghasset",
        cities: [
          "In Salah",
          "In Aménas",
          "Idles",
          "Abalessa",
          "Tazrouk",
          "In Ghar",
          "Foggaret Ezzoua",
          "Tin Zaouatine",
          "Tit",
          "Bordj Badji Mokhtar",
          "Illizi",
          "In Ekker",
        ],
      },
      {
        name: "Tébessa",
        cities: [
          "Bir El Ater",
          "Negrine",
          "El Aouinet",
          "El Kouif",
          "Cheria",
          "Ouenza",
          "El Ogla",
          "Morsott",
          "Oum Ali",
          "Bekkaria",
          "Bir Dheheb",
          "Boukhadra",
        ],
      },
      {
        name: "Tlemcen",
        cities: [
          "Maghnia",
          "Ain Tallout",
          "Ghazaouet",
          "Remchi",
          "Beni Snous",

          "Sabra",
          "Zenata",
          "Sidi Abdelli",
          "Bab El Assa",
          "Ain Fezza",
          "Marsa Ben M'Hidi",
          "Souani",
          "Ouled Mimoun",
          "Aïn Ghoraba",
          "El Fehoul",
        ],
      },
      {
        name: "Tiaret",
        cities: [
          "Frenda",
          "Medroussa",
          "Ksar Chellala",
          "Ain Bouchekif",
          "Ain Kermes",
          "Ain Deheb",
          "Rahouia",
          "Sidi Hosni",
          "Sidi Ali Mellal",
          "Zmalet El Emir Aek",
          "Mechraa Safa",
          "Tousnina",
          "Bougtoub",
          "Sebt",
          "Tagdemt",
          "Oued Lilli",
          "Dahmouni",
          "Aïn El Hadid",
        ],
      },
      {
        name: "Tizi Ouzou",
        cities: [
          "Draâ Ben Khedda",
          "Azeffoun",
          "Azazga",
          "Aïn El Hammam",
          "Boghni",
          "Ouadhias",
          "Iferhounène",
          "Tigzirt",
          "Aïn Zaouia",
          "Beni Douala",
          "Aït Yahia",
          "Ouacif",
          "Mekla",
          "Aït Mahmoud",
        ],
      },
      {
        name: "Algiers",
        cities: [
          "Boumerdès",
          "Tizi Ouzou",
          "Blida",
          "Tipaza",
          "Ain Defla",
          "Médéa",
          "Bordj Bou Arréridj",
          "Bouira",
          "Tébessa",
          "Skikda",
          "Bejaia",
          "Jijel",
          "Jijel",
          "Sétif",
          "Batna",
          "Annaba",
          "Constantine",
          "Oran",
          "Mostaganem",
          "Tlemcen",
        ],
      },
      {
        name: "Djelfa",
        cities: [
          "El Idrissia",
          "Messaad",
          "Ain Maabed",
          "Guernini",
          "Had Sahary",
          "Ain Oussera",
          "Hassi Bahbah",
          "Sidi Ladjel",
          "Dar Chioukh",
          "Moudjbara",
          "Birine",
          "Zaccar",
          "Faidh El Botma",
          "Sed Rahal",
          "Djilali Ben Amar",
          "Zaafrane",
          "Messaad",
        ],
      },
      {
        name: "Jijel",
        cities: [
          "El Aouana",
          "Taher",
          "Settara",
          "Kaous",
          "El Milia",
          "Ziama Mansouriah",
          "Chekfa",
          "Emir Abdelkader",
          "Boudriaa Beni Yadjis",
          "Ouled Yahia Khadrouch",
          "Djimla",
          "Sidi Abdelaziz",
          "Texenna",
          "Selma Benziada",
          "Bordj T'har",
        ],
      },
      {
        name: "Sétif",
        cities: [
          "Ain Azel",
          "El Eulma",
          "Aïn Oulmene",
          "Bougaa",
          "Béni Aziz",
          "Bazer Sakhra",
          "Hammam Guergour",
          "Beni Fouda",
          "Tizi N'Bechar",
        ],
      },
      {
        name: "Saïda",
        cities: [
          "Ain El Hadjar",
          "Ain Sekhouna",
          "Ain Soltane",
          "Djelloul",
          "Ouled Brahim",
          "Sidi Ahmed",
          "Sidi Boubekeur",
        ],
      },
      {
        name: "Skikda",
        cities: [
          "Azzaba",
          "Collo",
          "El Harrouch",
          "Jijel",
          "Raml Souk",
          "Ain Kechra",
          "Ouled Attia",
          "Beni Zid",
          "Tamalous",
        ],
      },
      {
        name: "Sidi Bel Abbès",
        cities: [
          "Télagh",
          "Buffalo",
          "Mostefa Ben Brahim",
          "Ténes",
          "Ras El Ma",
          "Sidi Lahcène",
          "Marhoum",
          "Tenira",
          "Sfisef",
          "Béthioua",
        ],
      },
      {
        name: "Annaba",
        cities: [
          "El Bouni",
          "Seraidi",
          "Ain Berda",
          "El Hadjar",
          "Chetaibi",
          "Treat",
          "Berrahal",
          "Oued El Aneb",
          "Cheurfa",
        ],
      },
      {
        name: "Guelma",
        cities: [
          "Boumahra Ahmed",
          "Oued Zenati",
          "Ain Makhlouf",
          "Aïn Hessainia",
          "Hammam N'Bails",
          "Héliopolis",
          "Khezara",
          "Roknia",
          "Bouchegouf",
        ],
      },
      {
        name: "Constantine",
        cities: [
          "El Khroub",
          "Aïn Smara",
          "Hamma Bouziane",
          "Zighoud Youcef",
          "Beni Hamiden",
          "Didouche Mourad",
          "Ibn Ziad",
          "Zouaghi Slimane",
          "Ouled Rahmoune",
        ],
      },
      {
        name: "Médéa",
        cities: [
          "Berrouaghia",
          "Ouled Deide",
          "El Omaria",
          "Ksar El Boukhari",
          "Ain Boucif",
          "Kef Lakhdar",
          "Chelalet El Adhaoura",
          "Seghouane",
          "Souaghi",
        ],
      },
      {
        name: "Mostaganem",
        cities: [
          "Ain Nouissy",
          "Mazagran",
          "Hassi Mameche",
          "Ain Tédelès",
          "Sidi Ali",
          "Kheir Eddine",
          "Bouguirat",
          "Sidi Lakhdar",
          "Achaacha",
        ],
      },
      {
        name: "M'Sila",
        cities: [
          "Bou Saada",
          "Ain El Hadjel",
          "Sidi Aissa",
          "Ouled Derradj",
          "Magra",
          "Berhoum",
          "Khettouti Sed-El-Jir",
          "Beni Ilmane",
          "Ain El Melh",
        ],
      },
      {
        name: "Mascara",
        cities: [
          "Sig",
          "Ghriss",
          "Hachem",
          "El Bordj",
          "Tighennif",
          "Froha",
          "Mohammadia",
          "Mamounia",
          "Oued Taria",
        ],
      },
      {
        name: "Ouargla",
        cities: [
          "Touggourt",
          "El Hadjira",
          "Hassi Messaoud",
          "N'goussa",
          "Rouissat",
          "Taibet",
          "Megarine",
          "Sidi Slimane",
          "Zaouia El Abidia",
        ],
      },
      {
        name: "Oran",
        cities: [
          "Bethioua",
          "Bir El Djir",
          "Es Senia",
          "Gdyel",
          "Arzew",
          "Aïn El Turk",
          "Sidi Chami",
          "Ain Franine",
          "Hassi Bounif",
        ],
      },
      {
        name: "El Bayadh",
        cities: [
          "Rogassa",
          "Boualem",
          "Ghassoul",
          "Boussemghoun",
          "El Abiodh Sidi Cheikh",
          "Sidi Ameur",
          "Arbaouat",
          "Brezina",
          "Chellala",
        ],
      },
      {
        name: "Illizi",
        cities: [
          "Djanet",
          "Bordj Omar Driss",
          "In Amenas",
          "Ain Salah",
          "Debdeb",
          "Tin Alkoum",
          "Tin Zaouatine",
          "Bordj El Haouass",
          "Foum Toub",
        ],
      },
      {
        name: "Bordj Bou Arréridj",
        cities: [
          "Medjana",
          "El Hamadia",
          "Bir Kasdali",
          "Mansourah",
          "Bir Ghbalou",
          "Djaafra",
          "El M'hir",
          "Tafreg",
          "Khelil",
        ],
      },
      {
        name: "Boumerdès",
        cities: [
          "Bordj Menaiel",
          "Isser",
          "Khemis El Khechna",
          "Corso",
          "Naciria",
          "Ouled Moussa",
          "Dellys",
          "Djinet",
          "Boudouaou",
        ],
      },
      {
        name: "El Taref",
        cities: [
          "Bouhadjar",
          "Besbes",
          "Ben M'Hidi",
          "Chihani",
          "Drean",
          "Echatt",
          "El Aioun",
          "Oued Zitoun",
          "Raml Souk",
        ],
      },
      {
        name: "Tindouf",
        cities: [
          "Oum El Assel",
          "Reggane",
          "Béchar",
          "Abadla",
          "Igli",
          "Timoudi",
          "Kénadsa",
          "Beni Abbès",
          "Taghit",
        ],
      },
      {
        name: "Tissemsilt",
        cities: [
          "Theniet El Had",
          "Bordj Emir Khaled",
          "Khemisti",
          "Lardjem",
          "Ammari",
          "Lazharia",
          "Boucaid",
          "Sidi Boutouchent",
          "Ouled Bessem",
        ],
      },
      {
        name: "El Oued",
        cities: [
          "Djamaa",
          "Taghzout",
          "Debila",
          "Guemar",
          "Magrane",
          "Still",
          "Taleb Larbi",
          "Hassani Abdelkrim",
          "Trifaoui",
        ],
      },
      {
        name: "Khenchela",
        cities: [
          "Aïn Touila",
          "Bouhmama",
          "El Mahmal",
          "Kais",
          "Khirane",
          "M'Toussa",
          "Ouled Rechache",
          "Remila",
          "Tamza",
        ],
      },
      {
        name: "Souk Ahras",
        cities: [
          "Sedrata",
          "Haddada",
          "M'daourouche",
          "Oum El Adhaim",
          "Taoura",
          "Terraguelt",
          "Bir Bouhouche",
          "Mechroha",
          "Kerkera",
        ],
      },
      {
        name: "Tipasa",
        cities: [
          "Cherchell",
          "Hadjout",
          "Fouka",
          "Douaouda",
          "Bou Ismaïl",
          "Ahmer El Ain",
          "Gouraya",
          "Gouraya",
          "Gouraya",
        ],
      },
      {
        name: "Mila",
        cities: [
          "Ferdjioua",
          "Tassadane Haddada",
          "Teleghma",
          "Grarem Gouga",
          "Ain Tine",
          "Oued Athmania",
          "Zeghaia",
          "Chelghoum Laid",
          "Timgad",
          "Bouhatem",
          "Amira Arras",
          "Tadjenanet",
          "Ain Beida Moussa",
        ],
      },
      {
        name: "Aïn Defla",
        cities: [
          "El Amra",
          "El Attaf",
          "Miliana",
          "Hammam Righa",
          "Khemis Miliana",
          "Djelida",
          "Aïn Lechiakh",
          "Aïn Soltane",
          "Bir Ould Khelifa",
          "Bordj Emir Khaled",
          "Djendel",
          "Rouina",
          "Tarik Ibn Ziad",
        ],
      },
      {
        name: "Naâma",
        cities: [
          "Mécheria",
          "Aïn Sefra",
          "Tiout",
          "Sfissifa",
          "Moghrar",
          "Assela",
          "Asla",
          "Ain Ben Khelil",
          "El Biodh",
          "El Ménéa",
          "Ksabi",
          "Moghrar Tahtani",
          "Safar",
        ],
      },
      {
        name: "Aïn Témouchent",
        cities: [
          "Béni Saf",
          "El Amria",
          "El Malah",
          "Hammam Bouhadjar",
          "Oulhassa Gheraba",
          "Chaabet El Ham",
          "Hassi El Ghella",
          "Ain Tolba",
          "Sidi Ben Adda",
          "Tamzoura",
          "Chentouf",
          "Bouzedjar",
          "Oued Berkeche",
        ],
      },
      {
        name: "Ghardaïa",
        cities: [
          "El Menia",
          "Berriane",
          "Metlili Chaamba",
          "Guerrara",
          "El Guerrara",
          "Hassi Fehal",
          "Bounoura",
          "Sebseb",
          "Zelfana",
          "El Atteuf",
          "M'zab",
          "Bérianne",
          "Barouda",
        ],
      },
      {
        name: "Relizane",
        cities: [
          "Mazouna",
          "Yellel",
          "Oued Rhiou",
          "Ain Tarek",
          "Ain Tarek",
          "Bendaoud",
          "Sidi Khettab",
          "Djidiouia",
          "Sidi M'hamed Ben Ali",
          "Zemmoura",
          "Kalaa",
          "El Matmar",
          "Ammi Moussa",
          "Ouarizane",
        ],
      },
    ],
  },
  {
    name: "Andorra",
    states: [
      {
        name: "Andorra la Vella",
        cities: [
          "Barri Antic",
          "La Comella",
          "Andorra la Vella Sud-Oest",
          "Andorra la Vella Sud-Est",
          "Andorra la Vella Nord",
        ],
      },
      {
        name: "Canillo",
        cities: [
          "Canillo",
          "Bordes d'Envalira",
          "Soldeu",
          "Incles",
          "El Tarter",
          "Prats",
          "Ransol",
        ],
      },
      {
        name: "Encamp",
        cities: [
          "Encamp",
          "Les Bons",
          "Vila",
          "Els Cortals d'Encamp",
          "Pas de la Casa",
        ],
      },
      {
        name: "Escaldes-Engordany",
        cities: ["Escaldes-Engordany"],
      },
      {
        name: "La Massana",
        cities: [
          "Anyós",
          "Arinsal",
          "Erts",
          "La Massana",
          "Ordino",
          "Pal",
          "Sispony",
        ],
      },
      {
        name: "Ordino",
        cities: ["Ordino", "Sornàs", "Ansalonga", "La Cortinada", "Llorts"],
      },
      {
        name: "Sant Julià de Lòria",
        cities: ["Sant Julià de Lòria"],
      },
    ],
  },
  {
    name: "Angola",
    states: [
      {
        name: "Bengo",
        cities: [
          "Caxito",
          "Ambriz",
          "Dande",
          "Mabubas",
          "Bula Atumba",
          "Demba Chio",
          "Kicabo",
        ],
      },
      {
        name: "Benguela",
        cities: [
          "Benguela",
          "Lobito",
          "Cubal",
          "Baía Farta",
          "Bocoio",
          "Balombo",
          "Chongorói",
          "Caimbambo",
          "Ganda",
          "Catumbela",
          "Chongolo",
          "Cavongue",
          "Chindumbo",
          "Dombe Grande",
          "Bela Vista",
        ],
      },

      {
        name: "Bié",
        cities: [
          "Kuito",
          "Chitembo",
          "Andulo",
          "Cunhinga",
          "Camacupa",
          "Catabola",
          "Cuemba",
          "Chinguar",
          "Nharêa",
          "Nharea",
          "Belmonte",
          "Cunje",
          "Katabola",
          "Cunje",
          "Cuemba",
          "Chinguar",
        ],
      },

      {
        name: "Cabinda",
        cities: [
          "Cabinda",
          "Belize",
          "Buco-Zau",
          "Cacongo",
          "Landana",
          "Lândana",
          "Necuto",
          "Chinge",
          "Dinge",
          "Malembo",
          "Massabi",
          "Tando-Zinze",
          "Chibodo",
          "Miconje",
        ],
      },
      {
        name: "Cuando Cubango",
        cities: [
          "Menongue",
          "Cuito Cuanavale",
          "Cuchi",
          "Calai",
          "Dirico",
          "Nancova",
          "Mavinga",
          "Rivungo",
          "Bunjei",
          "Chinga",
          "Dumbo",
        ],
      },
      {
        name: "Cuanza Norte",
        cities: [
          "N'dalatando",
          "Camabatela",
          "Golungo Alto",
          "Samba Lucala",
          "Banga",
          "Lucala",
          "Quiculungo",
          "Ambaca",
          "Bolongongo",
          "Gonguembo",
          "Zenza do Itombe",
          "Pango Aluquém",
          "Cazengo",
          "Cambambe",
        ],
      },

      {
        name: "Cuanza Sul",
        cities: [
          "Sumbe",
          "Waku Kungo",
          "Quibala",
          "Cassongue",
          "Conda",
          "Mussende",
          "Seles",
          "Ebo",
          "Amboiva",
          "Quilenda",
          "Libolo",
          "Gabela",
          "Chingo",
          "Katchiungo",
          "Gungo",
          "Calulo",
          "Porto Amboim",
          "Pambangala",
        ],
      },
      {
        name: "Cunene",
        cities: [
          "Ondjiva",
          "Xangongo",
          "Cahama",
          "Namakunde",
          "Curoca",
          "Môngua",
          "Kuvelai",
          "Chitado",
        ],
      },

      {
        name: "Huambo",
        cities: [
          "Huambo",
          "Caála",
          "Londuimbali",
          "Mungo",
          "Chicala-Cholohanga",
          "Cachiungo",
          "Ecunha",
          "Tchindjenje",
          "Longonjo",
          "Ukuma",
          "Bailundo",
          "Catchiungo",
        ],
      },

      {
        name: "Huíla",
        cities: [
          "Lubango",
          "Humpata",
          "Chibia",
          "Quipungo",
          "Caconda",
          "Matala",
          "Cacula",
          "Caluquembe",
          "Jau",
          "Tchivinguiro",
          "Chipindo",
          "Gambos",
          "Chicomba",
          "Quilengues",
          "Kuvango",
        ],
      },
      {
        name: "Luanda",
        cities: [
          "Luanda",
          "Viana",
          "Cacuaco",
          "Cazenga",
          "Belas",
          "Talatona",
          "Kilamba Kiaxi",
          "Sambizanga",
          "Rangel",
          "Ramiros",
          "Ingombota",
          "São Paulo",
          "Cassequel",
          "Camama",
          "Palanca",
          "Maculusso",
          "Coqueiros",
          "Alvalade",
          "Bairro Azul",
          "Miramar",
          "Ilha do Cabo",
          "Samba",
          "Prenda",
          "Marçal",
          "Gamek",
          "Boavista",
          "Nova Vida",
          "Zango",
          "Zona Verde",
          "Zona Industrial",
          "Benfica",
          "Vila Alice",
          "Patriota",
          "Zango II",
          "Cazadores",
          "Bita",
          "Kifica",
          "Aeroporto",
          "Golfe II",
          "Kifica II",
          "Bom Jesus",
          "Panguila",
          "Ramiros II",
          "Quifica",
          "Viana II",
          "Kilamba Kiaxi II",
          "São Pedro da Barra",
          "Sequele",
          "Cabiri",
          "Benfica II",
          "Kapalanga",
          "Calemba II",
          "Boa Vista II",
          "Golfe I",
          "Kilamba Kiaxi III",
          "Cacuaco II",
          "Kilamba Kiaxi IV",
          "Morro Bento",
          "Vila Flor",
          "Kilamba Kiaxi V",
          "Kilamba Kiaxi VI",
          "Golfe III",
        ],
      },

      {
        name: "Lunda Norte",
        cities: [
          "Dundo",
          "Chitato",
          "Xá-Muteba",
          "Cuango",
          "Capenda-Camulemba",
          "Lubalo",
          "Cambulo",
          "Lucapa",
          "Alto Chicapa",
          "Caumbo",
          "Cassanje",
          "Cacolo",
          "Caxita",
          "Cassange Tchikaia",
          "Cuilo Futa",
          "Luremo",
          "Quango",
          "Quimbango",
          "Sanzala",
          "Xinge",
          "Xiyenge",
          "Zulo",
        ],
      },

      {
        name: "Lunda Sul",
        cities: [
          "Saurimo",
          "Dala",
          "Muconda",
          "Cacolo",
          "Luma Cassai",
          "Alto Chicapa",
          "Mwene Vunongue",
          "Nangweshi",
          "Cambulo",
          "Cassai Sul",
          "Cassai Norte",
          "Luachimo",
          "Luó",
          "Luiana",
          "Cassanje",
          "Cacolo",
          "Xassengue",
          "Tshilumbo",
        ],
      },

      {
        name: "Malanje",
        cities: [
          "Malanje",
          "Cacuso",
          "Calandula",
          "Cangandala",
          "Canbundi-Catembo",
          "Caombo",
          "Cuaba Nzoji",
          "Cunda-Dia-Baze",
          "Kalandula",
          "Kiwaba-Nzoji",
          "Luquembo",
          "Mucari",
          "Quela",
          "Quirima",
          "Safu",
          "Xandel",
        ],
      },

      {
        name: "Moxico",
        cities: [
          "Luena",
          "Alto Zambeze",
          "Bundas",
          "Camanongue",
          "Léua",
          "Luchazes",
          "Luau",
          "Lumbala Nguimbo",
          "Moxico",
          "Lutuai",
          "Xá-Muteba",
          "Lépi",
          "Cazombo",
        ],
      },
      {
        name: "Namibe",
        cities: [
          "Namibe",
          "Tômbwa",
          "Bentiaba",
          "Bibala",
          "Virei",
          "Camacuio",
          "Mucuio",
          "Tchindjenje",
          "Vicombo",
          "Xangongo",
        ],
      },
      {
        name: "Uíge",
        cities: [
          "Uíge",
          "Maquela do Zombo",
          "Negage",
          "Mucaba",
          "Quimbele",
          "Sanza Pombo",
          "Ambuila",
          "Bungo",
          "Puri",
          "Damba",
          "Alto Cauale",
          "Songo",
          "Bembe",
          "Milunga",
          "Quitexe",
          "Mucaba Grande",
          "Quipedro",
          "Camabatela",
          "Zombo",
        ],
      },
      {
        name: "Zaire",
        cities: [
          "Mbanza Congo",
          "Soyo",
          "N'zeto",
          "Tomboco",
          "Cuimba",
          "Nzeto",
          "Quinzau",
          "M'pozo",
          "Sede",
          "Kinzau",
          "Caxito Gombe",
          "Kizenga",
          "Kuimba-Kiaxi",
          "Luvu",
          "Nsosso",
        ],
      },
    ],
  },

  {
    name: "Antigua and Barbuda",
    states: [
      {
        name: "Saint George",
        cities: [
          "Piggotts",
          "New Winthorpes",
          "Barnes Hill",
          "Potters Village",
        ],
      },
      {
        name: "Saint John",
        cities: [
          "St. John's",
          "Bolands",
          "Carlisle",
          "Cedar Grove",
          "Christian Hill",
          "Claremont",
          "Crosbies",
          "Ebenezer",
          "Five Islands Village",
          "Fort James",
          "Freemans Village",
          "Gray Hill",
          "Green Bay",
          "Grays Farm",
          "Herberts",
          "Hodges Bay",
          "Jennings",
          "McKinnons",
          "New Winthorpes",
          "Osborne",
          "Piggotts",
          "Potters Village",
          "Sea View Farm",
          "St. George",
          "Upper Bigglesworth",
          "Urlings",
          "Woods",
        ],
      },
      {
        name: "Saint Mary",
        cities: ["Bolands", "Parham", "Freetown", "Greenbay"],
      },
      {
        name: "Saint Paul",
        cities: [
          "Falmouth",
          "English Harbour Town",
          "Cobbs Cross",
          "Picadilly",
        ],
      },
      {
        name: "Saint Peter",
        cities: ["Parham", "Barbuda", "Codrington", "Cedar Grove"],
      },
      {
        name: "Saint Philip",
        cities: ["Freemans Village", "Clare Hall", "Willikies", "Seatons"],
      },
    ],
  },

  {
    name: "Argentina",
    states: [
      {
        name: "Buenos Aires",
        cities: [
          "Buenos Aires",
          "La Plata",
          "Mar del Plata",
          "Bahía Blanca",
          "Tandil",
          "Olavarría",
          "Azul",
          "Chascomús",
          "Luján",
        ],
      },
      {
        name: "Catamarca",
        cities: [
          "San Fernando del Valle de Catamarca",
          "Andalgalá",
          "Belén",
          "Santa María",
          "Recreo",
          "Tinogasta",
        ],
      },
      {
        name: "Chaco",
        cities: [
          "Resistencia",
          "Barranqueras",
          "Fontana",
          "Villa Ángela",
          "Presidencia Roque Sáenz Peña",
          "Charata",
        ],
      },
      {
        name: "Chubut",
        cities: [
          "Rawson",
          "Comodoro Rivadavia",
          "Puerto Madryn",
          "Trelew",
          "Esquel",
          "Gaiman",
          "Dolavon",
        ],
      },
      {
        name: "Córdoba",
        cities: [
          "Córdoba",
          "Villa Carlos Paz",
          "Río Cuarto",
          "San Francisco",
          "Alta Gracia",
          "Bell Ville",
          "Villa María",
        ],
      },
      {
        name: "Corrientes",
        cities: [
          "Corrientes",
          "Goya",
          "Esquina",
          "Curuzú Cuatiá",
          "Paso de los Libres",
          "Mercedes",
        ],
      },
      {
        name: "Entre Ríos",
        cities: [
          "Paraná",
          "Concepción del Uruguay",
          "Gualeguaychú",
          "Colón",
          "Victoria",
          "La Paz",
        ],
      },
      {
        name: "Formosa",
        cities: [
          "Formosa",
          "Clorinda",
          "Pirané",
          "Las Lomitas",
          "Misión Laishí",
          "Ibarreta",
        ],
      },
      {
        name: "Jujuy",
        cities: [
          "San Salvador de Jujuy",
          "Palpalá",
          "San Pedro de Jujuy",
          "Humahuaca",
          "Libertador General San Martín",
          "La Quiaca",
        ],
      },
      {
        name: "La Pampa",
        cities: [
          "Santa Rosa",
          "General Pico",
          "Toay",
          "Realicó",
          "General Acha",
          "Eduardo Castex",
        ],
      },
      {
        name: "La Rioja",
        cities: [
          "La Rioja",
          "Chamical",
          "Aimogasta",
          "Chepes",
          "Villa Unión",
          "Olta",
        ],
      },
      {
        name: "Mendoza",
        cities: [
          "Mendoza",
          "San Rafael",
          "Godoy Cruz",
          "Las Heras",
          "Luján de Cuyo",
          "Maipú",
        ],
      },
      {
        name: "Misiones",
        cities: [
          "Posadas",
          "Eldorado",
          "Oberá",
          "Puerto Rico",
          "Apóstoles",
          "San Vicente",
        ],
      },

      {
        name: "Neuquén",
        cities: [
          "Neuquén",
          "Cutral-Có",
          "Plaza Huincul",
          "Zapala",
          "San Martín de los Andes",
          "Junín de los Andes",
          "Chos Malal",
        ],
      },
      {
        name: "Río Negro",
        cities: [
          "Viedma",
          "San Carlos de Bariloche",
          "General Roca",
          "Cipolletti",
          "Allen",
          "El Bolsón",
          "Choele Choel",
        ],
      },
      {
        name: "Salta",
        cities: [
          "Salta",
          "San Ramón de la Nueva Orán",
          "Tartagal",
          "Cafayate",
          "Metán",
          "General Güemes",
          "Rosario de la Frontera",
        ],
      },
      {
        name: "San Juan",
        cities: [
          "San Juan",
          "Rawson",
          "Rivadavia",
          "Chimbas",
          "Santa Lucía",
          "Pocito",
          "Caucete",
        ],
      },
      {
        name: "San Luis",
        cities: [
          "San Luis",
          "Villa Mercedes",
          "Merlo",
          "Santa Rosa del Conlara",
          "Justo Daract",
          "La Toma",
          "Naschel",
        ],
      },
      {
        name: "Santa Cruz",
        cities: [
          "Río Gallegos",
          "Puerto Deseado",
          "El Calafate",
          "Caleta Olivia",
          "Pico Truncado",
          "Perito Moreno",
          "Gobernador Gregores",
        ],
      },
      {
        name: "Santa Fe",
        cities: [
          "Santa Fe",
          "Rosario",
          "Venado Tuerto",
          "Rafaela",
          "Santo Tomé",
          "Esperanza",
          "Villa Constitución",
        ],
      },
      {
        name: "Santiago del Estero",
        cities: [
          "Santiago del Estero",
          "La Banda",
          "Termas de Río Hondo",
          "Fernández",
          "Añatuya",
          "Frías",
          "Loreto",
        ],
      },
      {
        name: "Tierra del Fuego, Antártida e Islas del Atlántico Sur",
        cities: ["Ushuaia", "Río Grande", "Tolhuin"],
      },
      {
        name: "Tucumán",
        cities: [
          "San Miguel de Tucumán",
          "Yerba Buena",
          "Tafí Viejo",
          "Banda del Río Salí",
          "Aguilares",
          "Monteros",
          "Concepción",
        ],
      },
    ],
  },

  {
    name: "Armenia",
    states: [
      {
        name: "Ararat",
        cities: ["Artashat", "Ararat", "Vedi", "Masis"],
      },
      {
        name: "Artsakh",
        cities: ["Stepanakert", "Askeran", "Hadrut", "Martakert"],
      },
      {
        name: "Gegharkunik",
        cities: ["Gavar", "Sevan", "Martuni", "Vardenis"],
      },
      {
        name: "Kotayk",
        cities: ["Hrazdan", "Abovyan", "Tsakhkadzor", "Charentsavan"],
      },
      {
        name: "Lori",
        cities: ["Vanadzor", "Alaverdi", "Stepanavan", "Spitak"],
      },
      {
        name: "Shirak",
        cities: ["Gyumri", "Artik", "Maralik", "Akhourian"],
      },
      {
        name: "Syunik",
        cities: ["Kapan", "Goris", "Meghri", "Sisian"],
      },
      {
        name: "Tavush",
        cities: ["Ijevan", "Dilijan", "Berd", "Noyemberyan"],
      },
      {
        name: "Vayots Dzor",
        cities: ["Yeghegnadzor", "Jermuk", "Vayk", "Arpi"],
      },
      {
        name: "Yerevan",
        cities: ["Yerevan"],
      },
    ],
  },

  {
    name: "Australia",
    states: [
      {
        name: "Australian Capital Territory",
        cities: ["Canberra"],
      },
      {
        name: "New South Wales",
        cities: [
          "Sydney",
          "Newcastle",
          "Wollongong",
          "Albury",
          "Orange",
          "Tamworth",
          "Broken Hill",
        ],
      },
      {
        name: "Northern Territory",
        cities: ["Darwin", "Alice Springs", "Katherine", "Tennant Creek"],
      },
      {
        name: "Queensland",
        cities: [
          "Brisbane",
          "Gold Coast",
          "Cairns",
          "Townsville",
          "Toowoomba",
          "Mackay",
          "Rockhampton",
        ],
      },
      {
        name: "South Australia",
        cities: ["Adelaide", "Mount Gambier", "Whyalla", "Port Augusta"],
      },
      {
        name: "Tasmania",
        cities: ["Hobart", "Launceston", "Devonport"],
      },
      {
        name: "Victoria",
        cities: [
          "Melbourne",
          "Geelong",
          "Ballarat",
          "Bendigo",
          "Shepparton",
          "Wodonga",
        ],
      },
      {
        name: "Western Australia",
        cities: [
          "Perth",
          "Albany",
          "Broome",
          "Kalgoorlie",
          "Geraldton",
          "Bunbury",
          "Mandurah",
        ],
      },
    ],
  },

  {
    name: "Austria",
    states: [
      {
        name: "Burgenland",
        cities: ["Eisenstadt", "Rust", "Oberwart", "Jennersdorf", "Güssing"],
      },
      {
        name: "Carinthia",
        cities: [
          "Klagenfurt",
          "Villach",
          "Wolfsberg",
          "Spittal an der Drau",
          "Feldkirchen",
        ],
      },
      {
        name: "Lower Austria",
        cities: [
          "St. Pölten",
          "Wiener Neustadt",
          "Krems an der Donau",
          "Amstetten",
          "Lilienfeld",
        ],
      },
      {
        name: "Upper Austria",
        cities: ["Linz", "Wels", "Steyr", "Leonding", "Braunau am Inn"],
      },
      {
        name: "Salzburg",
        cities: [
          "Salzburg",
          "Hallein",
          "Saalfelden am Steinernen Meer",
          "Zell am See",
          "St. Johann im Pongau",
        ],
      },
      {
        name: "Styria",
        cities: [
          "Graz",
          "Leoben",
          "Bruck an der Mur",
          "Kapfenberg",
          "Knittelfeld",
        ],
      },
      {
        name: "Tyrol",
        cities: ["Innsbruck", "Kufstein", "Lienz", "Imst", "Wörgl"],
      },
      {
        name: "Vorarlberg",
        cities: ["Bregenz", "Dornbirn", "Feldkirch", "Bludenz", "Hohenems"],
      },
      {
        name: "Vienna",
        cities: ["Vienna"],
      },
    ],
  },

  {
    name: "Azerbaijan",
    states: [
      {
        name: "Absheron",
        cities: ["Baku", "Khirdalan", "Shuvalan", "Zabrat"],
      },
      {
        name: "Ganja-Gazakh",
        cities: ["Ganja", "Nizami", "Gazakh", "Aghstafa"],
      },
      {
        name: "Guba-Khachmaz",
        cities: ["Guba", "Khachmaz", "Gusar", "Khudat"],
      },
      {
        name: "Lankaran",
        cities: ["Lankaran", "Astara", "Masalli", "Lerik"],
      },
      {
        name: "Mingachevir",
        cities: ["Mingachevir", "Agdash", "Samkir", "Shamakhi"],
      },
      {
        name: "Shaki-Zaqatala",
        cities: ["Shaki", "Balakan", "Zaqatala", "Sheki"],
      },
      {
        name: "Aras",
        cities: ["Naxcivan", "Julfa", "Sharur", "Ordubad"],
      },
      {
        name: "Yevlakh",
        cities: ["Yevlakh", "Goychay", "Zardab", "Mingecevir"],
      },
      {
        name: "Kur-Araz",
        cities: ["Beylagan", "Bilasuvar", "Imishli", "Neftchala"],
      },
      {
        name: "Quba",
        cities: ["Quba", "Qusar", "Baku", "Khachmaz"],
      },
      {
        name: "Shirvan",
        cities: ["Shirvan", "Salyan", "Bilasuvar", "Beylagan"],
      },
      {
        name: "Nakhchivan",
        cities: ["Naxcivan", "Julfa", "Sharur", "Ordubad"],
      },
    ],
  },

  {
    name: "Bahamas",
    states: [
      {
        name: "Acklins",
        cities: [
          "Mason's Bay",
          "Delectable Bay",
          "Lovely Bay",
          "Snug Corner",
          "Salina Point",
        ],
      },
      {
        name: "Berry Islands",
        cities: [
          "Great Harbour Cay",
          "Bullock's Harbour",
          "Chub Cay",
          "Little Harbour",
          "Mamma Rhoda",
        ],
      },
      {
        name: "Bimini",
        cities: [
          "Alice Town",
          "Bailey Town",
          "Porgy Bay",
          "Radio Beach",
          "Cat Cay",
        ],
      },
      {
        name: "Black Point",
        cities: [
          "Black Point",
          "Fowlers",
          "The Ferry",
          "Mount Thompson",
          "Munnings",
        ],
      },
      {
        name: "Cat Island",
        cities: [
          "New Bight",
          "Arthur's Town",
          "Old Bight",
          "Port Howe",
          "Hawk's Nest",
        ],
      },
      {
        name: "Central Abaco",
        cities: [
          "Marsh Harbour",
          "Dundas Town",
          "Murphy Town",
          "Spring City",
          "Casuarina Point",
        ],
      },
      {
        name: "Central Andros",
        cities: [
          "Mastic Point",
          "Andros Town",
          "Mangrove Cay",
          "Nicholls Town",
          "Congo Town",
        ],
      },
      {
        name: "Central Eleuthera",
        cities: [
          "Governor's Harbour",
          "James Cistern",
          "Tarpum Bay",
          "Rock Sound",
          "Hatchet Bay",
        ],
      },
      {
        name: "City of Freeport",
        cities: ["Freeport", "West End"],
      },
      {
        name: "Crooked Island and Long Cay",
        cities: [
          "Colonel Hill",
          "Cabbage Hill",
          "Pitts Town",
          "Bight",
          "Crooked Island",
        ],
      },
      {
        name: "East Grand Bahama",
        cities: [
          "High Rock",
          "McLean's Town",
          "Pelican Point",
          "Sweeting's Cay",
          "Water Cay",
        ],
      },
      {
        name: "Exuma",
        cities: [
          "George Town",
          "Barrett's",
          "Harts",
          "William's Town",
          "Black Point",
        ],
      },
      {
        name: "Grand Cay",
        cities: ["Grand Cay"],
      },
      {
        name: "Harbour Island",
        cities: ["Dunmore Town"],
      },
      {
        name: "Hope Town",
        cities: ["Hope Town", "Great Guana Cay", "Man O' War Cay"],
      },
      {
        name: "Inagua",
        cities: [
          "Matthew Town",
          "Pittstown Point",
          "Betsy Bay",
          "Morton Salt",
          "Farrington",
        ],
      },
      {
        name: "Long Island",
        cities: [
          "Clarence Town",
          "Stella Maris",
          "Simms",
          "Bunches",
          "Deadman's Cay",
        ],
      },
      {
        name: "Mangrove Cay",
        cities: ["Mangrove Cay"],
      },
      {
        name: "Mayaguana",
        cities: [
          "Abraham's Bay",
          "Pirates Well",
          "Betsy Bay",
          "Fowlers",
          "Betsy Bay",
        ],
      },

      {
        name: "Mayaguana",
        cities: ["Abraham's Bay", "Betsy Bay", "Pirates Well"],
      },
      {
        name: "New Providence",
        cities: ["Nassau"],
      },
      {
        name: "North Abaco",
        cities: ["Cooper's Town", "Fox Town", "Mount Hope", "Wood Cay"],
      },
      {
        name: "North Andros",
        cities: ["Mastic Point", "Morgan's Bluff", "Nicholls Town"],
      },
      {
        name: "North Eleuthera",
        cities: ["Current", "Gregory Town", "Lower Bogue", "Upper Bogue"],
      },
      {
        name: "Ragged Island",
        cities: ["Duncan Town"],
      },
      {
        name: "Rum Cay",
        cities: ["Port Nelson"],
      },
      {
        name: "San Salvador and Rum Cay",
        cities: ["Cockburn Town"],
      },
      {
        name: "South Abaco",
        cities: ["Cherokee Sound", "Crossing Rocks", "Sandy Point"],
      },
      {
        name: "South Andros",
        cities: ["Mangrove Cay", "Mars Bay"],
      },
      {
        name: "South Eleuthera",
        cities: ["Governor's Harbour", "Rock Sound"],
      },
    ],
  },

  {
    name: "Bahrain",
    states: [
      {
        name: "Capital Governorate",
        cities: [
          "Manama",
          "Muharraq",
          "Isa Town",
          "Juffair",
          "Adliya",
          "Seef",
          "Hidd",
          "Riffa",
          "Sanabis",
          "Tubli",
          "Zinj",
          "Sitra",
          "Budaiya",
          "Jidhafs",
          "Diplomatic Area",
          "Gudaibiya",
          "Umm Al Hassam",
          "Bani Jamra",
        ],
      },
      {
        name: "Muharraq Governorate",
        cities: [
          "Muharraq",
          "Amwaj Islands",
          "Busaiteen",
          "Hidd",
          "Isa Town",
          "Juffair",
          "Sitra",
        ],
      },
      {
        name: "Southern Governorate",
        cities: [
          "Riffa",
          "Awali",
          "Isa Town",
          "Jaww",
          "Zallaq",
          "Hawar Islands",
          "Jidda Island",
          "Al Jasra",
          "Al Kharijiyah",
          "Durrat Al Bahrain",
          "Zallaq Springs",
        ],
      },
      {
        name: "Northern Governorate",
        cities: [
          "Hamad Town",
          "Budaiya",
          "Diraz",
          "Al-Malikiyah",
          "Al-Musalla",
          "Barbar",
          "Bani Jamra",
          "Jannusan",
          "Karzakan",
          "Maqaba",
          "Saar",
          "Shakhura",
          "Tubli",
          "Jidhafs",
          "Sanabis",
          "Abu Saiba",
          "Janabiyah",
          "Malkiya",
          "Sadad",
          "Salmabad",
          "Sar",
        ],
      },
    ],
  },

  {
    name: "Bangladesh",
    states: [
      {
        name: "Barisal",
        cities: ["Barisal", "Bhola", "Jhalokati", "Patuakhali", "Pirojpur"],
      },
      {
        name: "Chittagong",
        cities: [
          "Chittagong",
          "Brahmanbaria",
          "Chandpur",
          "Comilla",
          "Cox's Bazar",
          "Feni",
          "Khagrachhari",
          "Lakshmipur",
          "Noakhali",
          "Rangamati",
        ],
      },
      {
        name: "Dhaka",
        cities: [
          "Dhaka",
          "Faridpur",
          "Gazipur",
          "Gopalganj",
          "Jamalpur",
          "Kishoreganj",
          "Madaripur",
          "Manikganj",
          "Munshiganj",
          "Mymensingh",
          "Narayanganj",
          "Narsingdi",
          "Netrokona",
          "Rajbari",
          "Shariatpur",
          "Sherpur",
          "Tangail",
        ],
      },
      {
        name: "Khulna",
        cities: [
          "Khulna",
          "Bagerhat",
          "Chuadanga",
          "Jessore",
          "Jhenaidah",
          "Kushtia",
          "Magura",
          "Meherpur",
          "Narail",
          "Satkhira",
        ],
      },
      {
        name: "Mymensingh",
        cities: ["Mymensingh", "Jamalpur", "Netrokona", "Sherpur"],
      },
      {
        name: "Rajshahi",
        cities: [
          "Rajshahi",
          "Bogra",
          "Joypurhat",
          "Naogaon",
          "Natore",
          "Chapainawabganj",
          "Pabna",
          "Sirajganj",
        ],
      },
      {
        name: "Rangpur",
        cities: [
          "Rangpur",
          "Dinajpur",
          "Gaibandha",
          "Kurigram",
          "Lalmonirhat",
          "Nilphamari",
          "Panchagarh",
          "Thakurgaon",
        ],
      },
      {
        name: "Sylhet",
        cities: ["Sylhet", "Habiganj", "Moulvibazar", "Sunamganj"],
      },
    ],
  },

  {
    name: "Barbados",
    states: [
      {
        name: "Christ Church",
        cities: ["Oistins", "Silver Sands", "Bridgetown", "Balls"],
      },
      {
        name: "Saint Andrew",
        cities: ["Belleplaine", "Bathsheba", "Chimborazo"],
      },
      {
        name: "Saint George",
        cities: ["The Glebe", "The Pine", "Redman Village"],
      },
      {
        name: "Saint James",
        cities: ["Holetown", "Fitts Village", "Weston"],
      },
      {
        name: "Saint John",
        cities: ["Four Roads", "Horse Hill", "Grazettes"],
      },
      {
        name: "Saint Joseph",
        cities: ["Bathsheba", "Benn Hill", "Blackmans"],
      },
      {
        name: "Saint Lucy",
        cities: ["Allmans", "Bromefield", "Half Moon Fort"],
      },
      {
        name: "Saint Michael",
        cities: ["Bridgetown", "Garrison", "The Pine"],
      },
      {
        name: "Saint Peter",
        cities: ["Speightstown", "Boscobel", "Mullins"],
      },
      {
        name: "Saint Philip",
        cities: ["Crab Hill", "Long Bay", "Bayfield"],
      },
      {
        name: "Saint Thomas",
        cities: ["Welchman Hall", "Clifton Hill", "Cave Hill"],
      },
    ],
  },
  {
    name: "Belarus",
    states: [
      {
        name: "Brest",
        cities: [
          "Brest",
          "Baranovichi",
          "Pinsk",
          "Kobrin",
          "Luninets",
          "Kamenets",
        ],
      },
      {
        name: "Gomel",
        cities: [
          "Gomel",
          "Mozhaysk",
          "Svetlogorsk",
          "Rechitsa",
          "Kalinkovichi",
          "Zhytkavichy",
        ],
      },
      {
        name: "Grodno",
        cities: ["Grodno", "Lida", "Volkovysk", "Slonim", "Mosty", "Shchuchin"],
      },
      {
        name: "Minsk",
        cities: [
          "Minsk",
          "Zhodino",
          "Slutsk",
          "Borisov",
          "Salihorsk",
          "Fanipol",
        ],
      },
      {
        name: "Mogilev",
        cities: [
          "Mogilev",
          "Bobruisk",
          "Asipovichy",
          "Gorki",
          "Osipovichi",
          "Krichev",
        ],
      },
      {
        name: "Vitebsk",
        cities: [
          "Vitebsk",
          "Orsha",
          "Novopolotsk",
          "Polotsk",
          "Lepel",
          "Gorodok",
        ],
      },
    ],
  },

  {
    name: "Belgium",
    states: [
      { name: "Brussels-Capital Region", cities: ["Brussels"] },
      {
        name: "Antwerp",
        cities: ["Antwerp", "Mechelen", "Turnhout"],
      },
      {
        name: "East Flanders",
        cities: ["Ghent", "Aalst", "Sint-Niklaas"],
      },
      {
        name: "Flemish Brabant",
        cities: ["Leuven", "Vilvoorde", "Halle"],
      },
      {
        name: "Limburg",
        cities: ["Hasselt", "Genk", "Tongeren"],
      },
      {
        name: "West Flanders",
        cities: ["Bruges", "Kortrijk", "Ostend"],
      },
      {
        name: "Brabant Wallon",
        cities: ["Nivelles", "Wavre", "Waterloo"],
      },
      {
        name: "Hainaut",
        cities: ["Mons", "Charleroi", "Tournai"],
      },
      {
        name: "Liège",
        cities: ["Liège", "Verviers", "Huy"],
      },
      {
        name: "Luxembourg",
        cities: ["Arlon", "Bastogne", "Marche-en-Famenne"],
      },
      {
        name: "Namur",
        cities: ["Namur", "Andenne", "Dinant"],
      },
    ],
  },

  {
    name: "Belize",
    states: [
      {
        name: "Belize",
        cities: [
          "Belize City",
          "San Pedro",
          "Belmopan",
          "Ladyville",
          "Hattieville",
          "Dangriga",
          "San Ignacio",
          "Benque Viejo del Carmen",
          "Orange Walk Town",
          "Corozal Town",
        ],
      },
      {
        name: "Cayo",
        cities: [
          "San Ignacio",
          "Benque Viejo del Carmen",
          "Belmopan",
          "Santa Elena",
          "Camalote",
          "Teakettle",
          "Ontario",
          "Unitedville",
          "San Antonio",
          "Roaring Creek",
        ],
      },
      {
        name: "Corozal",
        cities: [
          "Corozal Town",
          "San Pedro",
          "Calderitas",
          "Chunox",
          "Concepción",
          "Copper Bank",
          "Little Belize",
          "Progresso",
          "San Joaquín",
          "Sarteneja",
          "Xaibe",
        ],
      },
      {
        name: "Orange Walk",
        cities: [
          "Orange Walk Town",
          "Trial Farm",
          "Yo Creek",
          "August Pine Ridge",
          "San Estevan",
          "San Jose",
          "San Felipe",
          "Carmelita",
          "Douglas",
          "Indian Church",
        ],
      },
      {
        name: "Stann Creek",
        cities: [
          "Dangriga",
          "Hopkins",
          "Placencia",
          "Independence",
          "Seine Bight",
          "Santa Rosa",
          "Maya Beach",
          "Pomona",
          "San Roman",
        ],
      },
      {
        name: "Toledo",
        cities: [
          "Punta Gorda",
          "San Antonio",
          "San Felipe",
          "Silver Creek",
          "Barranco",
          "Crique Jute",
          "Blue Creek",
          "San Marcos",
          "San Pedro Columbia",
        ],
      },
    ],
  },

  {
    name: "Benin",
    states: [
      {
        name: "Alibori",
        cities: ["Kandi", "Banikoara", "Gogounou", "Segbana"],
      },
      {
        name: "Atakora",
        cities: ["Natitingou", "Tanguiéta", "Kérou", "Boukoumbé"],
      },
      {
        name: "Atlantique",
        cities: ["Abomey-Calavi", "Ouidah", "Allada", "Cotonou"],
      },
      {
        name: "Borgou",
        cities: ["Parakou", "Djougou", "Nikki", "Kandi"],
      },
      {
        name: "Collines",
        cities: ["Savé", "Bantè", "Dassa-Zoumé", "Glazoué"],
      },
      {
        name: "Donga",
        cities: ["Djougou", "Copargo", "Ouake", "Bassila"],
      },
      {
        name: "Kouffo",
        cities: ["Lokossa", "Djakotomey", "Dogbo", "Aplahoué"],
      },
      {
        name: "Littoral",
        cities: ["Cotonou", "Sèmè-Kpodji", "Godomey", "Ouidah"],
      },
      {
        name: "Mono",
        cities: ["Grand-Popo", "Bopa", "Houéyogbé", "Athiémé"],
      },
      {
        name: "Ouémé",
        cities: ["Porto-Novo", "Sakété", "Adjohoun", "Bonou"],
      },
      {
        name: "Plateau",
        cities: ["Pobé", "Kétou", "Ifangni", "Adja-Ouèrè"],
      },
      {
        name: "Zou",
        cities: ["Abomey", "Bohicon", "Zogbodomey", "Covè"],
      },
    ],
  },

  {
    name: "Bhutan",
    states: [
      {
        name: "Bumthang",
        cities: ["Jakar", "Chumey", "Tang", "Ura"],
      },
      {
        name: "Chhukha",
        cities: [
          "Phuentsholing",
          "Gedu",
          "Sipsu",
          "Chhukha",
          "Chapcha",
          "Dala",
        ],
      },
      {
        name: "Dagana",
        cities: ["Dagapela", "Tashidingkha", "Drujegang"],
      },
      {
        name: "Gasa",
        cities: ["Gasa", "Laya"],
      },
      {
        name: "Haa",
        cities: ["Haa"],
      },
      {
        name: "Lhuntse",
        cities: ["Lhuntse", "Mongar"],
      },
      {
        name: "Mongar",
        cities: ["Mongar", "Bathpalathang"],
      },
      {
        name: "Paro",
        cities: ["Paro", "Thimphu"],
      },
      {
        name: "Pemagatshel",
        cities: ["Pemagatshel", "Nganglam", "Dungmaed"],
      },
      {
        name: "Punakha",
        cities: ["Punakha", "Wangdue Phodrang"],
      },
      {
        name: "Samdrup Jongkhar",
        cities: ["Samdrup Jongkhar", "Dewathang", "Tashigang"],
      },
      {
        name: "Samtse",
        cities: ["Samtse", "Phuentshogling"],
      },
      {
        name: "Sarpang",
        cities: ["Gelephu", "Sarpang"],
      },
      {
        name: "Thimphu",
        cities: ["Thimphu"],
      },
      {
        name: "Trashigang",
        cities: ["Trashigang", "Radhi", "Yonphula"],
      },
      {
        name: "Trashiyangtse",
        cities: ["Trashiyangtse", "Gomphu", "Bumdeling"],
      },
      {
        name: "Trongsa",
        cities: ["Trongsa", "Zhemgang"],
      },
      {
        name: "Tsirang",
        cities: ["Damphu", "Tsirang"],
      },
      {
        name: "Wangdue Phodrang",
        cities: ["Wangdue Phodrang"],
      },
      {
        name: "Zhemgang",
        cities: ["Zhemgang", "Tingtibi", "Nangkor"],
      },
    ],
  },

  {
    name: "Bolivia",
    states: [
      {
        name: "La Paz",
        cities: [
          "La Paz",
          "El Alto",
          "Viacha",
          "Coroico",
          "Copacabana",
          "Achacachi",
        ],
      },
      {
        name: "Cochabamba",
        cities: [
          "Cochabamba",
          "Sacaba",
          "Quillacollo",
          "Colcapirhua",
          "Vinto",
          "Tiquipaya",
        ],
      },
      {
        name: "Santa Cruz",
        cities: [
          "Santa Cruz de la Sierra",
          "Montero",
          "Warnes",
          "Cotoca",
          "Camiri",
          "Puerto Quijarro",
        ],
      },
      {
        name: "Oruro",
        cities: [
          "Oruro",
          "Huanuni",
          "Caracollo",
          "Machacamarca",
          "Totora",
          "Santuario de Quillacas",
        ],
      },
      {
        name: "Potosí",
        cities: ["Potosí", "Llallagua", "Uyuni", "Villazón", "Tupiza", "Puna"],
      },
      {
        name: "Chuquisaca",
        cities: [
          "Sucre",
          "Camargo",
          "Monteagudo",
          "Villa Abecia",
          "Padilla",
          "Serrano",
        ],
      },
      {
        name: "Tarija",
        cities: [
          "Tarija",
          "Bermejo",
          "Yacuiba",
          "Villamontes",
          "Entre Ríos",
          "Padcaya",
        ],
      },
      {
        name: "Beni",
        cities: [
          "Trinidad",
          "Riberalta",
          "Guayaramerín",
          "Rurrenabaque",
          "San Ignacio de Moxos",
          "Santa Ana del Yacuma",
        ],
      },
      {
        name: "Pando",
        cities: [
          "Cobija",
          "Porvenir",
          "Riberalta",
          "Puerto Gonzalo Moreno",
          "Filadelfia",
          "Bolpebra",
        ],
      },
    ],
  },

  {
    name: "Bosnia and Herzegovina",
    states: [
      {
        name: "Federation of Bosnia and Herzegovina",
        cities: [
          "Sarajevo",
          "Zenica",
          "Tuzla",
          "Mostar",
          "Bihac",
          "Bugojno",
          "Livno",
          "Kakanj",
          "Travnik",
          "Visoko",
          "Konjic",
          "Jajce",
          "Gorazde",
          "Cazin",
          "Grude",
          "Donji Vakuf",
          "Zavidovici",
          "Vitez",
          "Kiseljak",
          "Tomislavgrad",
          "Capljina",
          "Ljubuski",
          "Stolac",
          "Posusje",
          "Bosanska Krupa",
          "Kresevo",
          "Novi Travnik",
          "Fojnica",
          "Bosanski Petrovac",
          "Kupres",
        ],
      },
      {
        name: "Republika Srpska",
        cities: [
          "Banja Luka",
          "Prijedor",
          "Doboj",
          "Trebinje",
          "Bijeljina",
          "Foča",
          "Vlasenica",
          "Zvornik",
          "Laktasi",
          "Bileca",
          "Mrkonjic Grad",
          "Nevesinje",
          "Gacko",
          "Pale",
          "Kostajnica",
          "Kotor Varos",
          "Srbac",
          "Modrica",
          "Brcko",
          "Gracanica",
          "Kalinovik",
          "Ljubinje",
          "Milići",
          "Osmaci",
          "Pelagićevo",
          "Rudo",
          "Srebrenica",
          "Sokolac",
          "Teslic",
          "Vukosavlje",
          "Zepce",
        ],
      },
      {
        name: "Brčko District",
        cities: ["Brčko"],
      },
    ],
  },

  {
    name: "Botswana",
    states: [
      {
        name: "Central",
        cities: ["Serowe", "Palapye", "Mahalapye", "Lobatse", "Mochudi"],
      },
      {
        name: "Chobe",
        cities: ["Kasane", "Kazungula"],
      },
      {
        name: "Ghanzi",
        cities: ["Ghanzi", "Maunatlala"],
      },
      {
        name: "Kgatleng",
        cities: ["Mochudi", "Ramonaka"],
      },
      {
        name: "Kgalagadi",
        cities: ["Tsabong", "Hukuntsi"],
      },
      {
        name: "Kweneng",
        cities: ["Molepolole", "Lobatse", "Letlhakeng"],
      },
      {
        name: "North-East",
        cities: ["Francistown", "Selebi-Phikwe"],
      },
      {
        name: "North-West",
        cities: ["Maun", "Shakawe"],
      },
      {
        name: "South-East",
        cities: ["Gaborone", "Lobatse"],
      },
    ],
  },

  {
    name: "Brazil",
    states: [
      {
        name: "Acre",
        cities: [
          "Rio Branco",
          "Cruzeiro do Sul",
          "Sena Madureira",
          "Tarauacá",
          "Feijó",
          "Manoel Urbano",
          "Brasiléia",
          "Mâncio Lima",
          "Plácido de Castro",
          "Xapuri",
        ],
      },
      {
        name: "Alagoas",
        cities: [
          "Maceió",
          "Arapiraca",
          "Rio Largo",
          "Palmeira dos Índios",
          "União dos Palmares",
          "Penedo",
          "São Miguel dos Campos",
          "Campo Alegre",
          "Delmiro Gouveia",
          "São Luís do Quitunde",
        ],
      },
      {
        name: "Amapá",
        cities: [
          "Macapá",
          "Santana",
          "Laranjal do Jari",
          "Oiapoque",
          "Porto Grande",
          "Mazagão",
          "Tartarugalzinho",
          "Pedra Branca do Amapari",
          "Amapá",
          "Vitória do Jari",
        ],
      },
      {
        name: "Amazonas",
        cities: [
          "Manaus",
          "Parintins",
          "Itacoatiara",
          "Manacapuru",
          "Coari",
          "Tefé",
          "Tabatinga",
          "São Gabriel da Cachoeira",
          "Maués",
          "Humaitá",
        ],
      },
      {
        name: "Bahia",
        cities: [
          "Salvador",
          "Feira de Santana",
          "Vitória da Conquista",
          "Camaçari",
          "Itabuna",
          "Juazeiro",
          "Lauro de Freitas",
          "Ilhéus",
          "Jequié",
          "Alagoinhas",
        ],
      },
      {
        name: "Ceará",
        cities: [
          "Fortaleza",
          "Caucaia",
          "Juazeiro do Norte",
          "Maracanaú",
          "Sobral",
          "Crato",
          "Itapipoca",
          "Maranguape",
          "Iguatu",
          "Quixadá",
        ],
      },
      {
        name: "Distrito Federal",
        cities: ["Brasília"],
      },
      {
        name: "Espírito Santo",
        cities: [
          "Vitória",
          "Serra",
          "Vila Velha",
          "Cariacica",
          "Linhares",
          "Cachoeiro de Itapemirim",
          "Colatina",
          "Guarapari",
          "Aracruz",
          "Nova Venécia",
        ],
      },
      {
        name: "Goiás",
        cities: [
          "Goiânia",
          "Aparecida de Goiânia",
          "Anápolis",
          "Rio Verde",
          "Luziânia",
          "Águas Lindas de Goiás",
          "Valparaíso de Goiás",
          "Trindade",
          "Formosa",
          "Novo Gama",
        ],
      },
      {
        name: "Maranhão",
        cities: [
          "São Luís",
          "Imperatriz",
          "Timon",
          "Caxias",
          "Codó",
          "Paço do Lumiar",
          "Açailândia",
          "Bacabal",
          "Balsas",
          "Santa Inês",
        ],
      },
      {
        name: "Mato Grosso",
        cities: [
          "Cuiabá",
          "Várzea Grande",
          "Rondonópolis",
          "Sinop",
          "Tangará da Serra",
          "Cáceres",
          "Primavera do Leste",
          "Barra do Garças",
          "Alta Floresta",
          "Nova Mutum",
        ],
      },
      {
        name: "Mato Grosso do Sul",
        cities: [
          "Campo Grande",
          "Dourados",
          "Três Lagoas",
          "Corumbá",
          "Ponta Porã",
          "Naviraí",
          "Nova Andradina",
          "Aquidauana",
          "Maracaju",
          "Sidrolândia",
        ],
      },
      {
        name: "Minas Gerais",
        cities: [
          "Belo Horizonte",
          "Uberlândia",
          "Contagem",
          "Juiz de Fora",
          "Betim",
          "Montes Claros",
          "Ribeirão das Neves",
          "Uberaba",
          "Governador Valadares",
          "Ipatinga",
        ],
      },
      {
        name: "Pará",
        cities: [
          "Belém",
          "Ananindeua",
          "Santarém",
          "Marabá",
          "Parauapebas",
          "Castanhal",
          "Abaiara",
          "Abaetetuba",
          "Barcarena",
          "Cametá",
        ],
      },
      {
        name: "Paraíba",
        cities: [
          "João Pessoa",
          "Campina Grande",
          "Santa Rita",
          "Patos",
          "Bayeux",
          "Cabedelo",
          "Sousa",
          "Cajazeiras",
          "Guarabira",
          "Mamanguape",
        ],
      },
      {
        name: "Paraná",
        cities: [
          "Curitiba",
          "Londrina",
          "Maringá",
          "Ponta Grossa",
          "Cascavel",
          "São José dos Pinhais",
          "Foz do Iguaçu",
          "Colombo",
          "Guarapuava",
          "Paranaguá",
        ],
      },
      {
        name: "Pernambuco",
        cities: [
          "Recife",
          "Jaboatão dos Guararapes",
          "Olinda",
          "Caruaru",
          "Petrolina",
          "Paulista",
          "Cabo de Santo Agostinho",
          "Camaragibe",
          "Garanhuns",
          "Vitória de Santo Antão",
        ],
      },
      {
        name: "Piauí",
        cities: [
          "Teresina",
          "Parnaíba",
          "Picos",
          "Floriano",
          "Piripiri",
          "Campo Maior",
          "Altos",
          "José de Freitas",
          "Barras",
          "Pedro II",
        ],
      },
      {
        name: "Rio de Janeiro",
        cities: [
          "Rio de Janeiro",
          "São Gonçalo",
          "Duque de Caxias",
          "Nova Iguaçu",
          "Niterói",
          "Belford Roxo",
          "Campos dos Goytacazes",
          "São João de Meriti",
          "Petrópolis",
          "Volta Redonda",
        ],
      },
      {
        name: "Rio Grande do Norte",
        cities: [
          "Natal",
          "Mossoró",
          "Parnamirim",
          "São Gonçalo do Amarante",
          "Ceará-Mirim",
          "Macau",
          "Caicó",
          "Currais Novos",
          "Santa Cruz",
          "Apodi",
        ],
      },
      {
        name: "Rio Grande do Sul",
        cities: [
          "Porto Alegre",
          "Caxias do Sul",
          "Pelotas",
          "Santa Maria",
          "Rio Grande",
          "Novo Hamburgo",
          "Passo Fundo",
        ],
      },
      {
        name: "Rondônia",
        cities: [
          "Porto Velho",
          "Ji-Paraná",
          "Ariquemes",
          "Vilhena",
          "Cacoal",
          "Rolim de Moura",
          "Guajará-Mirim",
        ],
      },
      {
        name: "Roraima",
        cities: [
          "Boa Vista",
          "Caracaraí",
          "Mucajaí",
          "São Luiz",
          "Pacaraima",
          "Alto Alegre",
          "Amajari",
        ],
      },
      {
        name: "Santa Catarina",
        cities: [
          "Florianópolis",
          "Joinville",
          "Blumenau",
          "Chapecó",
          "Criciúma",
          "Itajaí",
          "Jaraguá do Sul",
        ],
      },
      {
        name: "São Paulo",
        cities: [
          "São Paulo",
          "Guarulhos",
          "Campinas",
          "São Bernardo do Campo",
          "Santo André",
          "São José dos Campos",
          "Osasco",
        ],
      },
      {
        name: "Sergipe",
        cities: [
          "Aracaju",
          "Nossa Senhora do Socorro",
          "Lagarto",
          "Itabaiana",
          "Estância",
          "Tobias Barreto",
          "Simão Dias",
        ],
      },
      {
        name: "Tocantins",
        cities: [
          "Palmas",
          "Araguaína",
          "Gurupi",
          "Porto Nacional",
          "Paraíso do Tocantins",
          "Colinas do Tocantins",
          "Guaraí",
        ],
      },
    ],
  },

  {
    name: "Brunei",
    states: [
      {
        name: "Belait",
        cities: ["Kuala Belait", "Seria", "Kuala Balai"],
      },
      {
        name: "Brunei-Muara",
        cities: ["Bandar Seri Begawan", "Tutong", "Jerudong", "Kilanas"],
      },
      {
        name: "Temburong",
        cities: ["Bangar"],
      },
      {
        name: "Tutong",
        cities: ["Pekan Tutong"],
      },
    ],
  },

  {
    name: "Bulgaria",
    states: [
      {
        name: "Blagoevgrad",
        cities: [
          "Blagoevgrad",
          "Bansko",
          "Petrich",
          "Sandanski",
          "Gotse Delchev",
          "Razlog",
          "Simitli",
        ],
      },
      {
        name: "Burgas",
        cities: [
          "Burgas",
          "Aytos",
          "Karnobat",
          "Sungurlare",
          "Sredets",
          "Kameno",
          "Sozopol",
        ],
      },
      {
        name: "Dobrich",
        cities: [
          "Dobrich",
          "General Toshevo",
          "Balchik",
          "Kavarna",
          "Shabla",
          "Tervel",
          "Dryanovo",
        ],
      },
      {
        name: "Gabrovo",
        cities: [
          "Gabrovo",
          "Sevlievo",
          "Tryavna",
          "Dryanovo",
          "Apriltsi",
          "Teteven",
          "Plachkovtsi",
        ],
      },
      {
        name: "Haskovo",
        cities: [
          "Haskovo",
          "Dimitrovgrad",
          "Harmanli",
          "Svilengrad",
          "Topolovgrad",
          "Lyubimets",
          "Simeonovgrad",
        ],
      },
      {
        name: "Kardzhali",
        cities: [
          "Kardzhali",
          "Momchilgrad",
          "Krumovgrad",
          "Ardino",
          "Dzhebel",
          "Kirkovo",
          "Makresh",
        ],
      },
      {
        name: "Kyustendil",
        cities: [
          "Kyustendil",
          "Dupnitsa",
          "Kocherinovo",
          "Rila",
          "Boboshevo",
          "Sapareva Banya",
          "Blagoevgrad",
        ],
      },
      {
        name: "Lovech",
        cities: [
          "Lovech",
          "Lukovit",
          "Troyan",
          "Apriltsi",
          "Letnitsa",
          "Teteven",
          "Yablanitsa",
        ],
      },
      {
        name: "Montana",
        cities: [
          "Montana",
          "Lom",
          "Berkovitsa",
          "Valchedram",
          "Boychinovtsi",
          "Medkovets",
          "Chiprovtsi",
        ],
      },
      {
        name: "Pazardzhik",
        cities: [
          "Pazardzhik",
          "Velingrad",
          "Septemvri",
          "Peshtera",
          "Rakitovo",
          "Batak",
          "Bratsigovo",
        ],
      },
      {
        name: "Pernik",
        cities: [
          "Pernik",
          "Radomir",
          "Zemen",
          "Kovachevtsi",
          "Breznik",
          "Tran",
          "Bobov Dol",
        ],
      },
      {
        name: "Pleven",
        cities: [
          "Pleven",
          "Gulyantsi",
          "Nikopol",
          "Iskar",
          "Pordim",
          "Dolni Dabnik",
          "Levski",
        ],
      },
      {
        name: "Plovdiv",
        cities: [
          "Plovdiv",
          "Asenovgrad",
          "Parvomay",
          "Sadovo",
          "Rakovski",
          "Karlovo",
          "Hisarya",
        ],
      },
      {
        name: "Razgrad",
        cities: [
          "Razgrad",
          "Isperih",
          "Loznitsa",
          "Samuil",
          "Tsar Kaloyan",
          "Zavet",
        ],
      },
      {
        name: "Ruse",
        cities: [
          "Ruse",
          "Borovo",
          "Byala",
          "Dve mogili",
          "Ivanovo",
          "Kubrat",
          "Slivo pole",
          "Tsenovo",
        ],
      },
      {
        name: "Shumen",
        cities: [
          "Shumen",
          "Kaolinovo",
          "Novi Pazar",
          "Veliki Preslav",
          "Kaspichan",
          "Smyadovo",
        ],
      },
      {
        name: "Silistra",
        cities: [
          "Silistra",
          "Dulovo",
          "Glavinitsa",
          "Alfatar",
          "Sitovo",
          "Tutrakan",
        ],
      },
      {
        name: "Sliven",
        cities: ["Sliven", "Kotel", "Nova Zagora", "Tvarditsa"],
      },
      {
        name: "Smolyan",
        cities: [
          "Smolyan",
          "Banite",
          "Chepelare",
          "Devin",
          "Dospat",
          "Madan",
          "Nedelino",
          "Rudozem",
          "Zlatograd",
        ],
      },
      {
        name: "Sofia",
        cities: [
          "Sofia",
          "Bankya",
          "Botevgrad",
          "Dragoman",
          "Kostenets",
          "Mirkovo",
          "Pirdop",
          "Pravets",
          "Samokov",
          "Slivnitsa",
          "Svoge",
          "Elin Pelin",
          "Godech",
          "Ihtiman",
          "Koprivshtitsa",
          "Kostenets",
          "Pernik",
          "Radomir",
        ],
      },
      {
        name: "Stara Zagora",
        cities: [
          "Stara Zagora",
          "Kazanlak",
          "Chirpan",
          "Galabovo",
          "Gurkovo",
          "Radnevo",
          "Shipka",
          "Karlovo",
          "Maglizh",
          "Nova Zagora",
          "Opan",
          "Panagyurishte",
          "Parvomay",
          "Sadovo",
          "Strelcha",
          "Chelopech",
        ],
      },
      {
        name: "Targovishte",
        cities: [
          "Targovishte",
          "Omurtag",
          "Antonovo",
          "Opaka",
          "Popovo",
          "Smyadovo",
        ],
      },
      {
        name: "Varna",
        cities: [
          "Varna",
          "Aksakovo",
          "Beloslav",
          "Provadia",
          "Dalgopol",
          "Devnya",
          "Valchi Dol",
        ],
      },

      {
        name: "Veliko Tarnovo",
        cities: [
          "Veliko Tarnovo",
          "Gorna Oryahovitsa",
          "Lyaskovets",
          "Svishtov",
        ],
      },
      {
        name: "Vidin",
        cities: ["Vidin", "Belogradchik", "Kula", "Dimovo"],
      },
      {
        name: "Vratsa",
        cities: ["Vratsa", "Mezdra", "Borovan", "Kozloduy"],
      },
    ],
  },

  {
    name: "Burkina Faso",
    states: [
      {
        name: "Boucle du Mouhoun",
        cities: [
          "Dédougou",
          "Kongoussi",
          "Yako",
          "Solenzo",
          "Boromo",
          "Nouna",
          "Tougan",
        ],
      },
      {
        name: "Cascades",
        cities: [
          "Banfora",
          "Orodara",
          "Dano",
          "Mangodara",
          "Niou",
          "Sidéradougou",
          "Toussiana",
        ],
      },
      {
        name: "Centre",
        cities: [
          "Ouagadougou",
          "Koudougou",
          "Ouahigouya",
          "Kaya",
          "Ziniaré",
          "Zorgho",
          "Sapouy",
        ],
      },
      {
        name: "Centre-Est",
        cities: [
          "Tenkodogo",
          "Koupéla",
          "Garango",
          "Boussouma",
          "Bittou",
          "Zabré",
          "Yargo",
        ],
      },
      {
        name: "Centre-Nord",
        cities: [
          "Kaya",
          "Kongoussi",
          "Ziniaré",
          "Pissila",
          "Boulsa",
          "Zitenga",
          "Korsimoro",
        ],
      },
      {
        name: "Centre-Ouest",
        cities: [
          "Koudougou",
          "Réo",
          "Houndé",
          "Boromo",
          "Nouna",
          "Léo",
          "Solenzo",
        ],
      },
      {
        name: "Centre-Sud",
        cities: ["Manga", "Pô", "Zabré", "Dano", "Kombissiri", "Naze", "Béré"],
      },
      {
        name: "Est",
        cities: [
          "Fada N'gourma",
          "Tenkodogo",
          "Diapaga",
          "Bogandé",
          "Pama",
          "Gayéri",
          "Kantchari",
        ],
      },
      {
        name: "Hauts-Bassins",
        cities: [
          "Bobo-Dioulasso",
          "Dédougou",
          "Houndé",
          "Banfora",
          "Nouna",
          "Dano",
          "Orodara",
        ],
      },
      {
        name: "Nord",
        cities: [
          "Ouahigouya",
          "Kaya",
          "Yako",
          "Titao",
          "Kongoussi",
          "Tougan",
          "Ziniaré",
        ],
      },
      {
        name: "Plateau-Central",
        cities: [
          "Ziniaré",
          "Koudougou",
          "Ouagadougou",
          "Koupéla",
          "Zorgho",
          "Boussé",
          "Bousse",
        ],
      },
      {
        name: "Sahel",
        cities: [
          "Dori",
          "Gorom-Gorom",
          "Sebba",
          "Djibo",
          "Ouahigouya",
          "Markoye",
          "Titao",
        ],
      },
      {
        name: "Sud-Ouest",
        cities: [
          "Gaoua",
          "Batié",
          "Diébougou",
          "Banfora",
          "Nouna",
          "Orodara",
          "Mangodara",
        ],
      },
    ],
  },

  {
    name: "Burundi",
    states: [
      {
        name: "Bubanza",
        cities: ["Bubanza", "Murwi", "Musigati", "Rugazi", "Ryansoro"],
      },
      {
        name: "Bujumbura Mairie",
        cities: [
          "Bujumbura",
          "Jabe",
          "Kanyosha",
          "Kinindo",
          "Musaga",
          "Muha",
          "Mutakura",
          "Rohero",
        ],
      },
      {
        name: "Bujumbura Rural",
        cities: [
          "Bugarama",
          "Bukinanyana",
          "Isale",
          "Kabezi",
          "Mubimbi",
          "Mugongomanga",
          "Muhuta",
          "Nyabiraba",
        ],
      },
      {
        name: "Bururi",
        cities: ["Bururi", "Matana", "Gihofi", "Rutovu", "Songa"],
      },
      {
        name: "Cankuzo",
        cities: ["Cankuzo", "Cendajuru", "Gisagara", "Kigamba", "Mishiha"],
      },
      {
        name: "Cibitoke",
        cities: ["Cibitoke", "Buganda", "Gihanga", "Murwi", "Rugombo"],
      },
      {
        name: "Gitega",
        cities: ["Gitega", "Buhiga", "Busiga", "Itaba", "Makebuko"],
      },
      {
        name: "Karuzi",
        cities: [
          "Karuzi",
          "Bugenyuzi",
          "Butaganzwa",
          "Gitaramuka",
          "Mugongo-Manga",
        ],
      },
      {
        name: "Kayanza",
        cities: ["Kayanza", "Buhinyuza", "Matongo", "Muruta", "Rango"],
      },
      {
        name: "Kirundo",
        cities: ["Kirundo", "Bugabira", "Bwambarangwe", "Gitobe", "Vumbi"],
      },
      {
        name: "Makamba",
        cities: ["Makamba", "Kibago", "Nyanza-Lac", "Vugizo", "Mabanda"],
      },
      {
        name: "Muyinga",
        cities: ["Muyinga", "Bweru", "Giteranyi", "Kiremba", "Muhanga"],
      },
      {
        name: "Mwaro",
        cities: ["Mwaro", "Bukeye", "Gisozi", "Kiganda", "Nyabihanga"],
      },
      {
        name: "Ngozi",
        cities: ["Ngozi", "Buhiga", "Gashikanwa", "Kiremba", "Marangara"],
      },
      {
        name: "Rumonge",
        cities: ["Rumonge", "Burambi", "Buyengero", "Muhuta", "Murwi"],
      },
      {
        name: "Rutana",
        cities: ["Rutana", "Bukemba", "Giharo", "Mpinga-Kayove", "Shanga"],
      },
    ],
  },

  {
    name: "Cabo Verde",
    states: [
      { name: "Boa Vista", cities: ["Sal Rei", "Rabil"] },
      {
        name: "Brava",
        cities: ["Nova Sintra"],
      },
      {
        name: "Fogo",
        cities: ["São Filipe", "Mosteiros"],
      },
      {
        name: "Maio",
        cities: ["Vila do Maio"],
      },
      {
        name: "Sal",
        cities: ["Espargos", "Santa Maria"],
      },
      {
        name: "Santiago",
        cities: [
          "Praia",
          "Assomada",
          "Tarrafal",
          "Pedra Badejo",
          "Calheta de São Miguel",
        ],
      },
      {
        name: "São Nicolau",
        cities: ["Ribeira Brava", "Tarrafal de São Nicolau"],
      },
      {
        name: "São Vicente",
        cities: ["Mindelo"],
      },
      {
        name: "Boa Vista",
        cities: ["Sal Rei", "Rabil"],
      },
    ],
  },

  {
    name: "Cambodia",
    states: [
      {
        name: "Banteay Meanchey",
        cities: ["Serei Saophoan", "Poipet", "Sisophon", "Mongkol Borei"],
      },
      {
        name: "Battambang",
        cities: ["Battambang", "Pailin", "Sisophon"],
      },
      {
        name: "Kampong Cham",
        cities: ["Kampong Cham", "Cheung Prey", "Prey Chhor"],
      },
      {
        name: "Kampong Chhnang",
        cities: ["Kampong Chhnang", "Chol Kiri", "Baribour", "Rolea B'ier"],
      },
      {
        name: "Kampong Speu",
        cities: ["Kampong Speu", "Odongk", "Chbar Mon"],
      },
      {
        name: "Kampot",
        cities: ["Kampot", "Banteay Meas", "Angkor Chey"],
      },
      {
        name: "Kandal",
        cities: ["Ta Khmau", "Kandal Stueng", "Lvea Aem"],
      },
      {
        name: "Kep",
        cities: ["Kep", "Dara Sakor"],
      },
      {
        name: "Koh Kong",
        cities: ["Koh Kong", "Sre Ambel", "Mondul Seima"],
      },
      {
        name: "Kratié",
        cities: ["Kratié", "Snuol"],
      },
      {
        name: "Mondulkiri",
        cities: ["Senmonorom", "Keo Seima", "Kaoh Nheaek"],
      },
      {
        name: "Phnom Penh",
        cities: ["Phnom Penh"],
      },
      {
        name: "Preah Vihear",
        cities: ["Preah Vihear", "Choam Khsant"],
      },
      {
        name: "Prey Veng",
        cities: ["Prey Veng", "Ba Phnom", "Peam Ro"],
      },
      {
        name: "Pursat",
        cities: ["Pursat", "Kandieng"],
      },
      {
        name: "Ratanakiri",
        cities: ["Banlung", "Andoung Meas"],
      },
      {
        name: "Siem Reap",
        cities: ["Siem Reap", "Srey Snom"],
      },
      {
        name: "Sihanoukville",
        cities: ["Sihanoukville"],
      },
      {
        name: "Stung Treng",
        cities: ["Stung Treng"],
      },
      {
        name: "Svay Rieng",
        cities: ["Svay Rieng", "Bavet"],
      },
      {
        name: "Takeo",
        cities: ["Takeo", "Tram Kak"],
      },
      {
        name: "Tboung Khmum",
        cities: ["Suong", "Memot"],
      },
    ],
  },
  {
    name: "Cameroon",
    states: [
      {
        name: "Adamaoua",
        cities: [
          "Ngaoundéré",
          "Tignère",
          "Meiganga",
          "Banyo",
          "Yola",
          "Gashiga",
        ],
      },
      {
        name: "Centre",
        cities: [
          "Yaoundé",
          "Mbalmayo",
          "Obala",
          "Monatélé",
          "Bafia",
          "Akonolinga",
        ],
      },
      {
        name: "East",
        cities: [
          "Bertoua",
          "Batouri",
          "Abong-Mbang",
          "Doumé",
          "Lomie",
          "Nguelebok",
        ],
      },
      {
        name: "Far North",
        cities: ["Maroua", "Mokolo", "Yagoua", "Mora", "Kousseri", "Kaélé"],
      },
      {
        name: "Littoral",
        cities: ["Douala", "Kribi", "Edéa", "Nkongsamba", "Mbanga", "Manjo"],
      },
      {
        name: "North",
        cities: ["Garoua", "Guider", "Lagdo", "Rey Bouba", "Poli", "Tcholliré"],
      },
      {
        name: "Northwest",
        cities: ["Bamenda", "Mamfe", "Kumbo", "Nkambe", "Ndop", "Bafut"],
      },
      {
        name: "South",
        cities: [
          "Ebolowa",
          "Kribi",
          "Meyomessala",
          "Sangmélima",
          "Ambam",
          "Emana",
        ],
      },
      {
        name: "Southwest",
        cities: ["Buea", "Limbe", "Kumba", "Tiko", "Mutengene", "Victoria"],
      },
      {
        name: "West",
        cities: [
          "Bafoussam",
          "Foumban",
          "Dschang",
          "Mbouda",
          "Bafang",
          "Bangangté",
        ],
      },
    ],
  },
  {
    name: "Central African Republic (CAR)",
    states: [
      {
        name: "Bamingui-Bangoran",
        cities: ["Ndélé", "Kabo", "Goundam", "Poukandja"],
      },
      {
        name: "Basse-Kotto",
        cities: ["Mobaye", "Zémio", "Rafai", "Goz Beida"],
      },
      {
        name: "Haute-Kotto",
        cities: ["Ouadda", "Birao", "Bria", "Ndele"],
      },
      {
        name: "Haut-Mbomou",
        cities: ["Obo", "Zémio", "Bambouti", "Rafaï"],
      },
      {
        name: "Kémo",
        cities: ["Sibut", "Boda", "Mbrès", "Dékoa"],
      },
      {
        name: "Lobaye",
        cities: ["Mbaiki", "Boda", "Boganangone", "Mongoumba"],
      },
      {
        name: "Mambéré-Kadéï",
        cities: ["Berbérati", "Bouar", "Carnot", "Gamboula"],
      },
      {
        name: "Mbomou",
        cities: ["Bangassou", "Obo", "Rafaï", "Zémio"],
      },
      {
        name: "Nana-Grébizi",
        cities: ["Kaga-Bandoro", "Birao", "Batangafo", "Ndele"],
      },
      {
        name: "Nana-Mambéré",
        cities: ["Bouar", "Boda", "Bocaranga", "Mara"],
      },
      {
        name: "Ombella-M'Poko",
        cities: ["Bimbo", "Bossembélé", "Boali", "Yaloké"],
      },
      {
        name: "Ouaka",
        cities: ["Bambari", "Ippy", "Bria", "Grimari"],
      },
      {
        name: "Ouham",
        cities: ["Bossangoa", "Bozoum", "Bocaranga", "Koui"],
      },
      {
        name: "Ouham-Pendé",
        cities: ["Bocaranga", "Bozoum", "Ngaoundaye", "Paoua"],
      },
      {
        name: "Sangha-Mbaéré",
        cities: ["Mbaïki", "Boda", "Bayanga", "Gamboula"],
      },
      {
        name: "Vakaga",
        cities: ["Birao", "Ndélé", "Ouanda Djallé", "Sam Ouandja"],
      },
    ],
  },
  {
    name: "Chad",
    states: [
      {
        name: "Batha",
        cities: [
          "Ati",
          "Oum Hadjer",
          "Am Timan",
          "Biltine",
          "Moussoro",
          "Massaguet",
          "Mangalmé",
          "Koumra",
          "Goz Beida",
          "Djourf Al Ahmar",
        ],
      },
      {
        name: "Borkou",
        cities: ["Faya-Largeau", "Bardai", "Zouar", "Kouba Olanga", "Birak"],
      },
      {
        name: "Chari-Baguirmi",
        cities: [
          "Massenya",
          "Kyabé",
          "Moundou",
          "Mongo",
          "Bousso",
          "N'Djamena",
          "Guelendeng",
          "Bitkine",
          "Béro",
        ],
      },
      {
        name: "Ennedi-Est",
        cities: ["Fada", "Adré", "Am Dam", "Mourdi", "Bahaï", "Adé"],
      },
      {
        name: "Ennedi-Ouest",
        cities: ["Faya-Largeau", "Bardai", "Zouar", "Kouba Olanga", "Birak"],
      },
      {
        name: "Guéra",
        cities: [
          "Mongo",
          "Moussoro",
          "Massaguet",
          "Mangalmé",
          "Koumra",
          "Goz Beida",
          "Djourf Al Ahmar",
          "Bitkine",
          "Béré",
        ],
      },
      {
        name: "Hadjer-Lamis",
        cities: ["Massakory", "Doba", "Bongor", "Fianga", "Léré", "Goz-Beïda"],
      },
      {
        name: "Kanem",
        cities: [
          "Mao",
          "Moussoro",
          "Massaguet",
          "Mangalmé",
          "Koumra",
          "Goz Beida",
          "Djourf Al Ahmar",
          "Am Timan",
        ],
      },
      {
        name: "Lac",
        cities: ["Bol", "Baga Sola", "Léré", "Massakory", "N'Djamena"],
      },
      {
        name: "Logone Occidental",
        cities: ["Moundou", "Doba", "Kyabé", "Massenya", "Bousso"],
      },
      {
        name: "Logone Oriental",
        cities: ["Doba", "Moundou", "Kyabé", "Bébédjia"],
      },
      {
        name: "Mandoul",
        cities: [
          "Koumra",
          "Kyabé",
          "Moundou",
          "Doba",
          "Guelendeng",
          "Bitkine",
          "Béré",
        ],
      },
      {
        name: "Mayo-Kebbi Est",
        cities: [
          "Pala",
          "Bongor",
          "Moundou",
          "Guelendeng",
          "Léré",
          "Goz-Beïda",
        ],
      },
      {
        name: "Mayo-Kebbi Ouest",
        cities: [
          "Pala",
          "Bongor",
          "Moundou",
          "Guelendeng",
          "Léré",
          "Goz-Beïda",
        ],
      },
      {
        name: "Mayo-Kebbi Est",
        cities: [
          "Bébédjia",
          "Béro",
          "Fianga",
          "Léré",
          "Bousso",
          "Koum",
          "Bédjondo",
          "Mangueigne",
        ],
      },
      {
        name: "Mondoul",
        cities: ["Laï", "Bousso", "Bédjondo", "Mangueigne"],
      },
      {
        name: "Ouaddaï",
        cities: [
          "Abéché",
          "Adré",
          "Fada",
          "Biltine",
          "Am-Djarass",
          "Mangalmé",
          "Bardaï",
          "Zouar",
          "Oum Hadjer",
          "Amdjereme",
          "Kolonghé",
          "Kouba Olanga",
          "Goz Beida",
          "Mourdi",
          "Marena",
          "Adoum Timo",
          "Kouno",
          "Rigueik",
          "Am Dam",
          "Diguila",
          "Goré",
          "Bédaya",
          "Adé",
          "Koumogo",
          "Dourbali",
          "Mao",
          "Goundi",
          "Madjéra",
          "Bourkou-Ennedi-Tibesti",
          "Tissi",
          "Mangueigne",
        ],
      },
      {
        name: "Salamat",
        cities: ["Am Timan", "Moussoro", "Béré", "Moundou", "Mangueigne"],
      },
      {
        name: "Sila",
        cities: [
          "Doba",
          "Kélo",
          "Moussoro",
          "Bongor",
          "Koumra",
          "Gounou Gaya",
          "Guelendeng",
          "Bénoye",
        ],
      },
      {
        name: "Tandjilé",
        cities: ["Laï", "Bousso", "Bédjondo", "Mangueigne"],
      },
      {
        name: "Tibesti",
        cities: ["Faya-Largeau", "Mangueigne"],
      },
      {
        name: "Wadi Fira",
        cities: ["Massakory", "Mangueigne"],
      },
    ],
  },
  {
    name: "Chile",
    states: [
      {
        name: "Arica",
        cities: ["Arica"],
      },
      {
        name: "Parinacota",
        cities: ["Putre"],
      },
      {
        name: "Iquique",
        cities: ["Iquique", "Alto Hospicio"],
      },
      {
        name: "Tamarugal",
        cities: ["Pozo Almonte", "Camiña", "Colchane", "Huara", "Pica"],
      },
      {
        name: "Antofagasta",
        cities: ["Antofagasta", "Mejillones", "Sierra Gorda", "Taltal"],
      },
      {
        name: "El Loa",
        cities: ["Calama", "Ollagüe", "San Pedro de Atacama"],
      },
      {
        name: "Tocopilla",
        cities: ["Tocopilla", "María Elena"],
      },
      {
        name: "Copiapó",
        cities: ["Copiapó", "Caldera", "Tierra Amarilla"],
      },
      {
        name: "Chañaral",
        cities: ["Chañaral", "Diego de Almagro"],
      },
      {
        name: "Huasco",
        cities: ["Vallenar", "Alto del Carmen", "Freirina", "Huasco"],
      },
      {
        name: "Elqui",
        cities: [
          "La Serena",
          "Coquimbo",
          "Andacollo",
          "La Higuera",
          "Paiguano",
          "Vicuña",
        ],
      },
      {
        name: "Choapa",
        cities: ["Illapel", "Canela", "Los Vilos", "Salamanca"],
      },
      {
        name: "Limarí",
        cities: [
          "Ovalle",
          "Combarbalá",
          "Monte Patria",
          "Punitaqui",
          "Río Hurtado",
        ],
      },
      {
        name: "Valparaíso",
        cities: [
          "Valparaíso",
          "Casablanca",
          "Concón",
          "Juan Fernández",
          "Puchuncaví",
          "Quintero",
          "Viña del Mar",
        ],
      },
      {
        name: "Isla de Pascua",
        cities: ["Hanga Roa"],
      },
      {
        name: "Los Andes",
        cities: ["Los Andes", "Calle Larga", "Rinconada", "San Esteban"],
      },
    ],
  },
  {
    name: "China",
    states: [
      {
        name: "Anhui",
        cities: [
          "Hefei",
          "Wuhu",
          "Bengbu",
          "Huainan",
          "Ma'anshan",
          "Huaibei",
          "Tongling",
          "Anqing",
          "Huangshan",
          "Chuzhou",
          "Fuyang",
          "Suzhou",
          "Lu'an",
          "Bozhou",
          "Chizhou",
          "Xuancheng",
        ],
      },
      {
        name: "Beijing",
        cities: [
          "Dongcheng",
          "Xicheng",
          "Chaoyang",
          "Fengtai",
          "Shijingshan",
          "Haidian",
          "Mentougou",
          "Fangshan",
          "Tongzhou",
          "Shunyi",
          "Changping",
          "Daxing",
          "Pinggu",
          "Huairou",
          "Miyun",
          "Yanqing",
        ],
      },
      {
        name: "Chongqing",
        cities: [
          "Yuzhong",
          "Jiangbei",
          "Shapingba",
          "Jiulongpo",
          "Nan'an",
          "Banan",
          "Yubei",
          "Beibei",
          "Dadukou",
          "Fuling",
          "Wanzhou",
          "Qianjiang",
          "Hechuan",
          "Changshou",
        ],
      },
      {
        name: "Fujian",
        cities: [
          "Fuzhou",
          "Xiamen",
          "Quanzhou",
          "Zhangzhou",
          "Putian",
          "Longyan",
          "Sanming",
          "Nanping",
          "Ningde",
        ],
      },
      {
        name: "Gansu",
        cities: [
          "Lanzhou",
          "Jinchang",
          "Baiyin",
          "Tianshui",
          "Wuwei",
          "Pingliang",
          "Jiayuguan",
          "Qingyang",
          "Dingxi",
          "Longnan",
          "Gannan Tibetan Autonomous Prefecture",
          "Linxia Hui Autonomous Prefecture",
        ],
      },
      {
        name: "Guangdong",
        cities: [
          "Guangzhou",
          "Shenzhen",
          "Dongguan",
          "Foshan",
          "Zhongshan",
          "Huizhou",
          "Jiangmen",
          "Zhuhai",
          "Heyuan",
          "Maoming",
          "Shantou",
          "Chaozhou",
          "Jieyang",
          "Meizhou",
          "Shaoguan",
          "Yangjiang",
          "Zhanjiang",
        ],
      },
      {
        name: "Guangxi",
        cities: [
          "Nanning",
          "Guilin",
          "Liuzhou",
          "Wuzhou",
          "Beihai",
          "Yulin",
          "Baise",
          "Qinzhou",
          "Hezhou",
          "Fangchenggang",
          "Guigang",
          "Laibin",
          "Chongzuo",
        ],
      },
      {
        name: "Guizhou",
        cities: [
          "Guiyang",
          "Zunyi",
          "Anshun",
          "Liupanshui",
          "Kaili",
          "Duyun",
          "Tongren",
          "Xingyi",
          "Bijie",
        ],
      },
      {
        name: "Hainan",
        cities: [
          "Haikou",
          "Sanya",
          "Qionghai",
          "Wuzhishan",
          "Danzhou",
          "Dongfang",
          "Wenchang",
          "Qiongzhong",
          "Ding'an",
          "Tunchang",
          "Changjiang",
          "Lingao",
          "Baisha",
          "Baoting",
          "Ledong",
          "Lingshui",
          "Yinggehai",
        ],
      },
      {
        name: "Hebei",
        cities: [
          "Shijiazhuang",
          "Tangshan",
          "Handan",
          "Xingtai",
          "Baoding",
          "Zhangjiakou",
          "Chengde",
          "Cangzhou",
          "Langfang",
          "Hengshui",
        ],
      },
      {
        name: "Heilongjiang",
        cities: [
          "Harbin",
          "Qiqihar",
          "Jiamusi",
          "Mudanjiang",
          "Daqing",
          "Yichun",
          "Jixi",
          "Hegang",
          "Shuangyashan",
          "Qitaihe",
          "Jiamusi",
          "Heihe",
          "Suihua",
        ],
      },
      {
        name: "Henan",
        cities: [
          "Zhengzhou",
          "Luoyang",
          "Kaifeng",
          "Pingdingshan",
          "Anyang",
          "Hebi",
          "Xinxiang",
          "Jiaozuo",
          "Puyang",
          "Xuchang",
          "Zhumadian",
          "Sanmenxia",
          "Luohe",
          "Shangqiu",
          "Nanyang",
          "Xinyang",
        ],
      },
      {
        name: "Hong Kong",
        cities: [
          "Central and Western",
          "Eastern",
          "Southern",
          "Wan Chai",
          "Sham Shui Po",
          "Kowloon City",
          "Kwun Tong",
          "Wong Tai Sin",
          "Yau Tsim Mong",
          "Islands",
          "Kwai Tsing",
          "North",
          "Sai Kung",
          "Sha Tin",
          "Tai Po",
          "Tsuen Wan",
          "Tuen Mun",
          "Yuen Long",
        ],
      },
      {
        name: "Hubei",
        cities: [
          "Wuhan",
          "Huangshi",
          "Shiyan",
          "Yichang",
          "Xiangyang",
          "Ezhou",
          "Jingmen",
          "Jingzhou",
          "Huanggang",
          "Xiaogan",
          "Suizhou",
          "Enshi Tujia and Miao Autonomous Prefecture",
        ],
      },
      {
        name: "Hunan",
        cities: [
          "Changsha",
          "Xiangtan",
          "Zhuzhou",
          "Hengyang",
          "Shaoyang",
          "Yueyang",
          "Chenzhou",
          "Yiyang",
          "Changde",
          "Zhangjiajie",
          "Loudi",
          "Yongzhou",
          "Huaihua",
          "Xiangxi",
        ],
      },
      {
        name: "Inner Mongolia",
        cities: [
          "Hohhot",
          "Baotou",
          "Chifeng",
          "Tongliao",
          "Hulunbuir",
          "Ordos",
          "Erenhot",
          "Manzhouli",
          "Xilinhot",
          "Wuhai",
          "Bayannur",
          "Ulanqab",
          "Alxa Left Banner",
          "Arxan",
          "Zhalantun",
          "Linhe",
          "Wuyuan",
        ],
      },
      {
        name: "Jiangsu",
        cities: [
          "Nanjing",
          "Suzhou",
          "Wuxi",
          "Changzhou",
          "Yangzhou",
          "Nantong",
          "Lianyungang",
          "Zhenjiang",
          "Taizhou",
          "Huai'an",
          "Yancheng",
          "Xuzhou",
          "Suqian",
        ],
      },
      {
        name: "Jiangxi",
        cities: [
          "Nanchang",
          "Jingdezhen",
          "Pingxiang",
          "Jiujiang",
          "Xinyu",
          "Yingtan",
          "Ganzhou",
          "Ji'an",
          "Yichun",
          "Fuzhou",
          "Shangrao",
        ],
      },
      {
        name: "Jilin",
        cities: [
          "Changchun",
          "Jilin",
          "Siping",
          "Liaoyuan",
          "Tonghua",
          "Baishan",
          "Songyuan",
          "Baicheng",
          "Yanji",
          "Jiaohe",
          "Huadian",
          "Shulan",
          "Panshi",
          "Gongzhuling",
          "Meihekou",
          "Hunchun",
          "Longjing",
          "Dunhua",
        ],
      },
      {
        name: "Liaoning",
        cities: [
          "Shenyang",
          "Dalian",
          "Anshan",
          "Fushun",
          "Benxi",
          "Dandong",
          "Jinzhou",
          "Yingkou",
          "Fuxin",
          "Liaoyang",
          "Panjin",
          "Tieling",
          "Chaoyang",
          "Huludao",
        ],
      },
      {
        name: "Macau",
        cities: ["Macau Peninsula", "Taipa", "Coloane"],
      },
      {
        name: "Ningxia Hui ",
        cities: ["Yinchuan", "Shizuishan", "Wuzhong", "Guyuan", "Zhongwei"],
      },
      {
        name: "Qinghai",
        cities: [
          "Xining",
          "Haidong",
          "Hainan",
          "Haibei",
          "Huangnan",
          "Golog",
          "Yushu",
          "Haixi",
        ],
      },
      {
        name: "Shaanxi",
        cities: [
          "Xi'an",
          "Baoji",
          "Xianyang",
          "Tongchuan",
          "Weinan",
          "Yan'an",
          "Yulin",
          "Hanzhong",
          "Ankang",
          "Shangluo",
        ],
      },
      {
        name: "Shandong",
        cities: [
          "Jinan",
          "Qingdao",
          "Zibo",
          "Zaozhuang",
          "Dongying",
          "Yantai",
          "Weifang",
          "Jining",
          "Tai'an",
          "Weihai",
          "Rizhao",
          "Linyi",
          "Dezhou",
          "Binzhou",
          "Heze",
        ],
      },
      {
        name: "Shanghai",
        cities: [
          "Huangpu",
          "Xuhui",
          "Changning",
          "Jing'an",
          "Putuo",
          "Hongkou",
          "Yangpu",
          "Minhang",
          "Baoshan",
          "Jiading",
          "Pudong",
          "Jinshan",
          "Songjiang",
          "Qingpu",
          "Fengxian",
          "Chongming",
        ],
      },
      {
        name: "Shanxi",
        cities: [
          "Taiyuan",
          "Datong",
          "Changzhi",
          "Yangquan",
          "Jincheng",
          "Shuozhou",
          "Yuncheng",
          "Xinzhou",
          "Linfen",
          "Jinzhong",
          "Lüliang",
        ],
      },
      {
        name: "Sichuan",
        cities: [
          "Chengdu",
          "Mianyang",
          "Luzhou",
          "Deyang",
          "Yibin",
          "Nanchong",
          "Leshan",
          "Neijiang",
          "Zigong",
          "Panzhihua",
          "Suining",
          "Guangyuan",
          "Dazhou",
          "Meishan",
          "Ya'an",
          "Ziyang",
          "Abazhou",
          "Ganzi",
          "Liangshan",
        ],
      },
      {
        name: "Tianjin",
        cities: [
          "Tianjin",
          "Baodi",
          "Beichen",
          "Binhaixin",
          "Dongli",
          "Hebei",
          "Hedong",
          "Heping",
          "Hongqiao",
          "Jianguomenwai",
          "Jinnan",
          "Nankai",
          "Ninghe",
          "Shuanggang",
          "Wuqing",
          "Xiqing",
        ],
      },
      {
        name: "Tibet",
        cities: [
          "Lhasa",
          "Shigatse",
          "Qamdo",
          "Nagqu",
          "Nyingchi",
          "Gyangze",
          "Rutog",
          "Zhangmu",
        ],
      },
      {
        name: "Xinjiang",
        cities: [
          "Ürümqi",
          "Karamay",
          "Kashgar",
          "Hotan",
          "Aksu",
          "Yining",
          "Turpan",
          "Hami",
          "Changji",
          "Shihezi",
          "Tacheng",
          "Altay",
          "Korla",
          "Atushi",
          "Bole",
        ],
      },
      {
        name: "Yunnan",
        cities: [
          "Kunming",
          "Qujing",
          "Yuxi",
          "Baoshan",
          "Zhaotong",
          "Lijiang",
          "Pu'er",
          "Lincang",
          "Chuxiong",
          "Honghe",
          "Wenshan",
          "Xishuangbanna",
          "Dali",
          "Dehong",
          "Nujiang",
          "Diqing",
        ],
      },
      {
        name: "Zhejiang",
        cities: [
          "Hangzhou",
          "Ningbo",
          "Wenzhou",
          "Jiaxing",
          "Huzhou",
          "Shaoxing",
          "Jinhua",
          "Quzhou",
          "Zhoushan",
          "Taizhou",
          "Lishui",
        ],
      },
      {
        name: "Taiwan",
        cities: [
          "Taipei",
          "New Taipei",
          "Kaohsiung",
          "Taichung",
          "Tainan",
          "Hsinchu",
          "Taoyuan",
          "Keelung",
          "Chiayi",
          "Changhua",
          "Pingtung",
          "Hualien",
          "Yilan",
          "Miaoli",
          "Nantou",
          "Penghu",
          "Taitung",
          "Kinmen",
          "Lienchiang",
        ],
      },
    ],
  },
  {
    name: "Colombia",
    states: [
      {
        name: "Amazonas",
        cities: ["Leticia", "Puerto Nariño"],
      },
      {
        name: "Antioquia",
        cities: ["Medellín", "Bello", "Itagüí", "Envigado", "Sabaneta"],
      },
      {
        name: "Arauca",
        cities: ["Arauca", "Saravena", "Tame"],
      },
      {
        name: "Atlántico",
        cities: [
          "Barranquilla",
          "Soledad",
          "Malambo",
          "Puerto Colombia",
          "Galapa",
        ],
      },
      {
        name: "Bolívar",
        cities: [
          "Cartagena",
          "Magangué",
          "Turbaco",
          "Carmen de Bolívar",
          "Arjona",
        ],
      },
      {
        name: "Boyacá",
        cities: [
          "Tunja",
          "Duitama",
          "Sogamoso",
          "Chiquinquirá",
          "Puerto Boyacá",
        ],
      },
      {
        name: "Caldas",
        cities: ["Manizales", "La Dorada", "Chinchiná", "Riosucio", "Aguadas"],
      },
      {
        name: "Caquetá",
        cities: [
          "Florencia",
          "Puerto Rico",
          "San Vicente del Caguán",
          "Valparaíso",
          "Solano",
        ],
      },
      {
        name: "Casanare",
        cities: ["Yopal", "Aguazul", "Villanueva", "Monterrey", "Tauramena"],
      },
      {
        name: "Cauca",
        cities: [
          "Popayán",
          "Santander de Quilichao",
          "Cajibío",
          "Puerto Tejada",
          "El Bordo",
        ],
      },
      {
        name: "Cesar",
        cities: [
          "Valledupar",
          "Aguachica",
          "Bosconia",
          "La Jagua de Ibirico",
          "San Alberto",
        ],
      },
      {
        name: "Chocó",
        cities: ["Quibdó", "Bajo Baudó", "Condoto", "Riosucio", "Istmina"],
      },
      {
        name: "Córdoba",
        cities: ["Montería", "Planeta Rica", "Cereté", "Lorica", "Sahagún"],
      },
      {
        name: "Cundinamarca",
        cities: ["Bogotá", "Soacha", "Facatativá", "Zipaquirá", "Girardot"],
      },
      {
        name: "Guainía",
        cities: ["Puerto Inírida", "Morichal", "San Felipe"],
      },
      {
        name: "Guaviare",
        cities: [
          "San José del Guaviare",
          "Calamar",
          "Miraflores",
          "El Retorno",
        ],
      },
      {
        name: "Huila",
        cities: [
          "Acevedo",
          "Agrado",
          "Aipe",
          "Algeciras",
          "Altamira",
          "Baraya",
          "Campoalegre",
          "Colombia",
          "Elias",
          "Garzón",
          "Gigante",
          "Guadalupe",
          "Hobo",
          "Iquira",
          "Isnos",
          "La Argentina",
          "La Plata",
          "Nátaga",
          "Neiva",
          "Oporapa",
          "Paicol",
          "Palermo",
          "Palestina",
          "Pital",
          "Pitalito",
          "Rivera",
          "Saladoblanco",
          "San Agustin",
          "Santa Maria",
          "Suaza",
          "Tarqui",
          "Tello",
          "Teruel",
          "Tesalia",
          "Timana",
          "Villavieja",
          "Yaguará",
        ],
      },
      {
        name: "La Guajira",
        cities: [
          "Albania",
          "Barrancas",
          "Dibulla",
          "Distracción",
          "El Molino",
          "Fonseca",
          "Hatonuevo",
          "La Jagua del Pilar",
          "Maicao",
          "Manaure Balcón del Cesar",
          "Riohacha",
          "San Juan del Cesar",
          "Uribia",
          "Urumita",
          "Villanueva",
        ],
      },
      {
        name: "Magdalena",
        cities: [
          "Algarrobo",
          "Aracataca",
          "Ariguaní",
          "Cerro de San Antonio",
          "Chibolo",
          "Ciénaga",
          "Concordia",
          "El Banco",
          "El Piñón",
          "El Retén",
          "Fundación",
          "Guamal",
          "Nueva Granada",
          "Pedraza",
          "Pijiño del Carmen",
          "Pivijay",
          "Plato",
          "Puebloviejo",
          "Remolino",
          "Sabanas de San Angel",
          "Salamina",
          "San Sebastián de Buenavista",
          "San Zenón",
          "Santa Ana",
          "Santa Bárbara de Pinto",
          "Santa Marta",
          "Sitionuevo",
          "Tenerife",
          "Zapayán",
          "Zona Bananera",
        ],
      },
      {
        name: "Meta",
        cities: [
          "Acacias",
          "Barranca de Upía",
          "Cabuyaro",
          "Castilla la Nueva",
          "Cubarral",
          "Cumaral",
          "El Calvario",
          "El Castillo",
          "El Dorado",
          "Fuente de Oro",
          "Granada",
          "Guamal",
          "La Macarena",
          "La Uribe",
          "Lejanías",
          "Mapiripán",
          "Mesetas",
          "Puerto Concordia",
          "Puerto Gaitán",
          "Puerto Lleras",
          "Puerto López",
          "Puerto Rico",
          "Restrepo",
          "San Carlos de Guaroa",
          "San Juan de Arama",
          "San Juanito",
          "San Martín",
          "Villavicencio",
          "Vistahermosa",
        ],
      },
      {
        name: "Nariño",
        cities: [
          "Aldana",
          "Ancuyá",
          "Arboleda",
          "Barbacoas",
          "Belén",
          "Buesaco",
          "Chachagüí",
          "Colón",
          "Consaca",
          "Contadero",
          "Córdoba",
          "Cuaspud",
          "Cumbal",
          "Cumbitara",
          "El Charco",
          "El Peñol",
          "El Rosario",
          "El Tablón de Gómez",
          "El Tambo",
          "Francisco Pizarro",
          "Funes",
          "Guachucal",
          "Guaitarilla",
          "Gualmatán",
          "Iles",
          "Imués",
          "Ipiales",
          "La Cruz",
          "La Florida",
          "La Llanada",
          "La Tola",
          "La Unión",
          "Leiva",
          "Linares",
          "Los Andes",
          "Magüi Payán",
          "Mallama",
          "Mosquera",
          "Nariño",
          "Olaya Herrera",
          "Ospina",
          "Pasto",
          "Policarpa",
          "Potosí",
          "Providencia",
          "Puerres",
          "Pupiales",
          "Ricaurte",
          "Roberto Payán",
          "Samaniego",
          "San Bernardo",
          "San Lorenzo",
          "San Pablo",
          "San Pedro de Cartago",
          "Sandoná",
          "Santa Bárbara",
          "Santacruz",
          "Sapuyes",
          "Taminango",
          "Tangua",
          "Tumaco",
          "Túquerres",
          "Yacuanquer",
        ],
      },
      {
        name: "Norte de Santander",
        cities: [
          "Abrego",
          "Arboledas",
          "Bochalema",
          "Bucarasica",
          "Cáchira",
          "Cácota",
          "Chinácota",
          "Chitagá",
          "Convención",
          "Cúcuta",
          "Cucutilla",
          "Duranía",
          "El Carmen",
          "El Tarra",
          "El Zulia",
          "Gramalote",
          "Hacarí",
          "Herrán",
          "La Esperanza",
          "La Playa",
          "Labateca",
          "Los Patios",
          "Lourdes",
          "Mutiscua",
          "Ocaña",
          "Pamplona",
          "Pamplonita",
          "Puerto Santander",
          "Ragonvalia",
          "Salazar",
          "San Calixto",
          "San Cayetano",
          "Santiago",
          "Santo Domingo de Silos",
          "Sardinata",
        ],
      },
      {
        name: "Putumayo",
        cities: [
          "Mocoa",
          "Orito",
          "Puerto Asís",
          "Puerto Caicedo",
          "Puerto Guzmán",
          "Leguízamo",
          "Sibundoy",
          "San Francisco",
          "San Miguel",
          "Villa Garzón",
        ],
      },
      {
        name: "Quindío",
        cities: [
          "Armenia",
          "Calarcá",
          "Circasia",
          "Córdoba",
          "Filandia",
          "Génova",
          "La Tebaida",
          "Montenegro",
          "Pijao",
          "Quimbaya",
          "Salento",
        ],
      },
      {
        name: "Risaralda",
        cities: [
          "Pereira",
          "Dosquebradas",
          "La Virginia",
          "Marsella",
          "Mistrató",
          "Pueblo Rico",
          "Quinchía",
          "Santa Rosa de Cabal",
          "Santuario",
        ],
      },
      {
        name: "San Andrés y Providencia",
        cities: ["San Andrés", "Providencia", "Santa Catalina"],
      },
      {
        name: "Santander",
        cities: [
          "Bucaramanga",
          "Aguada",
          "Albania",
          "Aratoca",
          "Barbosa",
          "Barichara",
          "Barrancabermeja",
          "Betulia",
          "Bolívar",
          "Cabrera",
          "California",
          "Capitanejo",
          "Carcasí",
          "Cepitá",
          "Cerrito",
          "Charalá",
          "Charta",
          "Chima",
          "Chipatá",
          "Cimitarra",
          "Concepción",
          "Confines",
          "Contratación",
          "Coromoro",
          "Curití",
          "El Carmen de Chucurí",
          "El Guacamayo",
          "El Peñón",
          "El Playón",
          "Encino",
          "Enciso",
          "Florián",
          "Floridablanca",
          "Galán",
          "Gámbita",
          "Girón",
          "Guaca",
          "Guadalupe",
          "Guapotá",
          "Guavatá",
          "Güepsa",
          "Hato",
          "Jesús María",
          "Jordán",
          "La Belleza",
          "Landázuri",
          "La Paz",
          "Lebrija",
          "Los Santos",
          "Macaravita",
          "Málaga",
          "Matanza",
          "Mogotes",
          "Molagavita",
          "Ocamonte",
          "Oiba",
          "Onzaga",
          "Palmar",
          "Palmas del Socorro",
          "Páramo",
          "Piedecuesta",
          "Pinchote",
          "Puente Nacional",
          "Puerto Parra",
          "Puerto Wilches",
          "Rionegro",
          "Sabana de Torres",
          "San Andrés",
          "San Benito",
          "San Gil",
          "San Joaquín",
          "San José de Miranda",
          "San Miguel",
          "San Vicente de Chucurí",
          "Santa Bárbara",
          "Santa Helena del Opón",
          "Simacota",
          "Socorro",
          "Suaita",
          "Sucre",
          "Suratá",
          "Tona",
          "Valle de San José",
          "Vélez",
          "Vetas",
          "Villanueva",
          "Zapatoca",
        ],
      },
      {
        name: "Sucre",
        cities: [
          "Sincelejo",
          "Corozal",
          "Tolú",
          "San Onofre",
          "Sincé",
          "Morroa",
          "Los Palmitos",
        ],
      },
      {
        name: "Tolima",
        cities: [
          "Ibagué",
          "Espinal",
          "Mariquita",
          "Chaparral",
          "Honda",
          "Fresno",
          "Líbano",
        ],
      },
      {
        name: "Valle del Cauca",
        cities: [
          "Cali",
          "Buenaventura",
          "Palmira",
          "Tuluá",
          "Yumbo",
          "Candelaria",
          "Cartago",
        ],
      },
      { name: "Vaupés", cities: ["Carurú", "Mitú", "Taraira"] },
      {
        name: "Vichada",
        cities: ["Cumaribo", "La Primavera", "Puerto Carreño", "Santa Rosalía"],
      },
    ],
  },
  {
    name: "Comoros",
    states: [
      {
        name: "Grande Comore",
        cities: [
          "Moroni",
          "Mutsamudu",
          "Fomboni",
          "Domoni",
          "Sima",
          "Ouani",
          "Itsandra",
          "Bambao",
          "Mvangani",
          "Hahaya",
          "Milembeni",
          "Wanani",
          "Mkiriwadjumoi",
          "Ntsoudjini",
          "Kangani",
          "Mramani",
          "Mvouni",
          "Koki",
          "Dziani",
          "Barakani",
          "Dzaha",
        ],
      },
      {
        name: "Mohéli",
        cities: [
          "Fomboni",
          "Nioumachoua",
          "Miringoni",
          "Hoani",
          "Mitsamiouli",
          "Ouallah",
          "Hamavouna",
          "Mbaleni",
          "Mremani",
          "Kyo",
        ],
      },
      {
        name: "Anjouan",
        cities: [
          "Moutsamoudou",
          "Domoni",
          "Simani",
          "Ouani",
          "Moya",
          "Bambao",
          "Mirontsi",
          "Sada",
          "Salimani",
          "Chindini",
          "Chirongui",
          "Mkiriwadjumoi",
        ],
      },
    ],
  },
  {
    name: "Democratic Republic of the Congo",
    states: [
      {
        name: "Bas-Uele",
        cities: ["Buta", "Aketi", "Poko", "Titule", "Bondo", "Bambesa"],
      },
      {
        name: "Équateur",
        cities: [
          "Mbandaka",
          "Bikoro",
          "Basankusu",
          "Bolomba",
          "Bumba",
          "Gemena",
        ],
      },
      {
        name: "Haut-Katanga",
        cities: [
          "Lubumbashi",
          "Likasi",
          "Kolwezi",
          "Kasumbalesa",
          "Kambove",
          "Kipushi",
        ],
      },
      {
        name: "Haut-Lomami",
        cities: [
          "Kamina",
          "Kabongo",
          "Kaniama",
          "Malemba Nkulu",
          "Mwene-Ditu",
          "Kabalo",
        ],
      },
      {
        name: "Haut-Uele",
        cities: ["Isiro", "Dungu", "Faradje", "Nioka", "Rungu", "Bondo"],
      },
      {
        name: "Ituri",
        cities: ["Bunia", "Aru", "Mahagi", "Djugu", "Irumu", "Mambasa"],
      },
      {
        name: "Kasaï",
        cities: ["Tshikapa", "Ilebo", "Mweka", "Bena Leka", "Luebo", "Kananga"],
      },
      {
        name: "Kasaï-Central",
        cities: [
          "Kananga",
          "Kamuesha",
          "Lusambo",
          "Dibaya",
          "Bulungu",
          "Kazumba",
        ],
      },
      {
        name: "Kasaï-Oriental",
        cities: [
          "Mbuji-Mayi",
          "Tshilenge",
          "Kabinda",
          "Lodja",
          "Kanowa",
          "Kambove",
        ],
      },
      {
        name: "Kinshasa",
        cities: [
          "Kinshasa",
          "Kikwit",
          "Matadi",
          "Mbandaka",
          "Kasangulu",
          "Mwene-Ditu",
        ],
      },
      {
        name: "Kongo-Central",
        cities: ["Matadi", "Boma", "Tshela", "Muanda", "Kasangulu", "Kimpese"],
      },
      {
        name: "Kwango",
        cities: [
          "Kenge",
          "Kasongo-Lunda",
          "Popokabaka",
          "Feshi",
          "Kamako",
          "Kakenge",
        ],
      },

      {
        name: "Kwilu",
        cities: [
          "Bandundu-Djoko-Punda",
          "Bulungu",
          "Gungu",
          "Idiofa",
          "Kikwit",
          "Kilembwe",
          "Mangai",
          "Masimanimba",
          "Mataki",
          "Mukedi",
          "Mushie",
          "Vanga Kasaï",
        ],
      },
      {
        name: "Kwango",
        cities: [
          "Bagata",
          "Bulungu",
          "Feshi",
          "Kasongo-Lunda",
          "Kenge",
          "Mangalaboyi",
          "Masi-Manimba",
          "Popokabaka",
        ],
      },
      {
        name: "Kwilu-Ngongo",
        cities: [
          "Bolobo",
          "Bongandanga",
          "Djombo",
          "Lukolela",
          "Mokala",
          "Mondombe",
          "Ngungu",
          "Pimu",
        ],
      },
      {
        name: "Kwilu-Ntumba",
        cities: [
          "Banza-Lukenga",
          "Bolobo",
          "Bongandanga",
          "Inongo",
          "Kikwit",
          "Masi-Manimba",
          "Mondombe",
          "Vanga Kasaï",
        ],
      },
      {
        name: "Lomami",
        cities: [
          "Bena Leka",
          "Dekese",
          "Kabinda",
          "Kasaji",
          "Katako-Kombe",
          "Kole",
          "Lubao",
          "Lubefu",
          "Lusambo",
          "Mwene-Ditu",
          "Samba",
          "Tshilenge",
        ],
      },
      {
        name: "Lualaba",
        cities: [
          "Dilolo",
          "Kamina",
          "Kasaji",
          "Kolwezi",
          "Likasi",
          "Lubumbashi",
          "Mwene-Ditu",
        ],
      },
      {
        name: "Mai-Ndombe",
        cities: ["Bolobo", "Inongo", "Kiri", "Kwamouth", "Makanza", "Watsa"],
      },
      {
        name: "Maniema",
        cities: [
          "Kabambare",
          "Kailo",
          "Kasongo",
          "Kibombo",
          "Kindu",
          "Komanda",
          "Lubutu",
          "Mikelenge",
          "Pangi",
          "Sakania",
          "Upemba",
        ],
      },
      {
        name: "Mongala",
        cities: ["Bongandanga", "Bumba", "Lisala", "Makanza", "Watsa"],
      },
      {
        name: "Nord-Kivu",
        cities: [
          "Beni",
          "Butembo",
          "Goma",
          "Kasindi",
          "Lubero",
          "Masisi",
          "Rutshuru",
        ],
      },
      {
        name: "Nord-Ubangi",
        cities: [
          "Businga",
          "Businga-Kenge",
          "Businga-Lokwa",
          "Gbadolite",
          "Gemena",
          "Karawa",
          "Lisala",
          "Ngbundu-Tshiloangu",
        ],
      },
      {
        name: "Nord-Ubangi",
        cities: [
          "Businga",
          "Gbadolite",
          "Gemena",
          "Kungu",
          "Lisala",
          "Mobayi-Mbongo",
          "Yakoma",
        ],
      },
      {
        name: "Sankuru",
        cities: [
          "Katako-Kombe",
          "Lodja",
          "Lomela",
          "Lubefu",
          "Mweka",
          "Ndekesha",
          "PaniaMutombo",
          "Sankuru",
          "Sensa",
          "Tshilenge",
          "Yembe",
        ],
      },
      {
        name: "South Kivu",
        cities: [
          "Baraka",
          "Bukavu",
          "Fizi",
          "Idjwi",
          "Kabare",
          "Kalehe",
          "Kamituga",
          "Kavumu",
          "Kiliba",
          "Misisi",
          "Sange",
          "Shabunda",
          "Uvira",
        ],
      },
      {
        name: "Sud-Ubangi",
        cities: [
          "Businga",
          "Bosobolo",
          "Gemena",
          "Kungu",
          "Libenge",
          "Ntokou",
          "Watsa",
          "Yakoma",
          "Zongo",
        ],
      },
      {
        name: "Tanganyika",
        cities: [
          "Kabalo",
          "Kalemie",
          "Kampene",
          "Kasenga",
          "Kongolo",
          "Manono",
          "Moba",
          "Mulongo",
          "Nyemba",
          "Nyunzu",
          "Pweto",
          "Sandoa",
        ],
      },
      {
        name: "Tshopo",
        cities: [
          "Bafwasende",
          "Isiro",
          "Kisangani",
          "Makiso",
          "Mampoko",
          "Opala",
          "Ubundu",
        ],
      },
      {
        name: "Upper Lomami",
        cities: [
          "Bukama",
          "Dilolo",
          "Kamina",
          "Kanyama",
          "Kapanga",
          "Kasaji",
          "Kaniama",
          "Malemba-Nkulu",
          "Mukubu",
          "Mwene-Ditu",
          "Tshilundu",
        ],
      },
      {
        name: "Upper Katanga",
        cities: [
          "Kambove",
          "Kasumbalesa",
          "Kipushi",
          "Likasi",
          "Lubumbashi",
          "Pweto",
        ],
      },
      {
        name: "Upper Uele",
        cities: [
          "Ango",
          "Dungu",
          "Faradje",
          "Garamba",
          "Niangara",
          "Rungu",
          "Uvira",
          "Watsa",
          "Zobia",
        ],
      },
      {
        name: "Ituri",
        cities: [
          "Aru",
          "Djugu",
          "Irumu",
          "Mahagi",
          "Mambasa",
          "Mungbere",
          "Nia-Nia",
          "Nyankunde",
          "Tchomia",
        ],
      },
    ],
  },
  {
    name: "Republic of the Congo",
    states: [
      { name: "Bouenza", cities: ["Madingou", "Loudima", "Nkayi", "Yandza"] },
      {
        name: "Brazzaville",
        cities: ["Brazzaville"],
      },
      {
        name: "Cuvette",
        cities: ["Owando", "Makoua", "Okoyo", "Mampouya"],
      },
      {
        name: "Cuvette-Ouest",
        cities: ["Ewo", "Kelle", "Madingring"],
      },
      {
        name: "Kouilou",
        cities: ["Pointe-Noire", "Hinda", "Loango", "Madingo-Kayes"],
      },
      {
        name: "Lékoumou",
        cities: ["Sibiti", "Gamboma", "Komono"],
      },
      {
        name: "Likouala",
        cities: ["Impfondo", "Epena", "Bétou", "Dongou"],
      },
      {
        name: "Niari",
        cities: ["Dolisie", "Mossendjo", "Loubomo", "Mayoko"],
      },
      {
        name: "Plateaux",
        cities: ["Djambala", "Lekana", "Seketi", "Makotimpoko"],
      },
      {
        name: "Pool",
        cities: ["Kinkala", "Goma Tse-Tse", "Vindza", "Mbandza-Ndounga"],
      },
      {
        name: "Sangha",
        cities: ["Ouésso", "Makoua", "Sembé", "Moloundou"],
      },
      {
        name: "Loutété",
        cities: ["Loutété"],
      },
    ],
  },
  {
    name: "Costa Rica",
    states: [
      {
        name: "San José",
        cities: [
          "San José",
          "Escazú",
          "Desamparados",
          "Curridabat",
          "San Francisco",
          "San Antonio",
        ],
      },
      {
        name: "Alajuela",
        cities: [
          "Alajuela",
          "San Ramón",
          "Grecia",
          "Atenas",
          "Naranjo",
          "Zarcero",
        ],
      },
      {
        name: "Cartago",
        cities: [
          "Cartago",
          "Paraíso",
          "La Unión",
          "Turrialba",
          "Jiménez",
          "Oreamuno",
        ],
      },
      {
        name: "Heredia",
        cities: [
          "Heredia",
          "Barva",
          "Santo Domingo",
          "San Rafael",
          "Santa Bárbara",
          "Sarapiquí",
        ],
      },
      {
        name: "Guanacaste",
        cities: [
          "Liberia",
          "Nicoya",
          "Santa Cruz",
          "Bagaces",
          "Carrillo",
          "Cañas",
        ],
      },
      {
        name: "Puntarenas",
        cities: [
          "Puntarenas",
          "Esparza",
          "Buenos Aires",
          "Montes de Oro",
          "Osa",
          "Quepos",
        ],
      },
      {
        name: "Limón",
        cities: [
          "Limón",
          "Pococí",
          "Siquirres",
          "Matina",
          "Talamanca",
          "Guácimo",
        ],
      },
    ],
  },
  {
    name: "Cote d'Ivoire",
    states: [
      {
        name: "Agnéby-Tiassa",
        cities: [
          "Agboville",
          "Akoupé",
          "Rubino",
          "Anyama",
          "Adzopé",
          "Yakassé-Mé",
        ],
      },
      {
        name: "Bafing",
        cities: ["Touba", "Ouaninou", "Koro", "Sikasso"],
      },
      {
        name: "Bas-Sassandra",
        cities: [
          "San Pedro",
          "Sassandra",
          "Soubré",
          "Tabou",
          "Gagnoa",
          "Guéyo",
        ],
      },
      {
        name: "Denguélé",
        cities: ["Odienné", "Kaniasso", "Madinani"],
      },
      {
        name: "Dix-Huit Montagnes",
        cities: ["Man", "Bangolo", "Danane"],
      },
      {
        name: "Fromager",
        cities: ["Gagnoa", "Divo", "Sinfra"],
      },
      {
        name: "Haut-Sassandra",
        cities: ["Daloa", "Vavoua", "Issia"],
      },
      {
        name: "Lacs",
        cities: ["Yamoussoukro", "Tiébissou", "Bongouanou"],
      },
      {
        name: "Lagunes",
        cities: ["Abidjan", "Grand-Bassam", "Dabou", "Jacqueville"],
      },
      {
        name: "Marahoué",
        cities: ["Bouaflé", "Daloa", "Vavoua"],
      },
      {
        name: "Moyen-Cavally",
        cities: ["Guiglo", "Taï", "Soubré"],
      },
      {
        name: "Moyen-Comoé",
        cities: ["Abengourou", "Bongouanou", "M'Bahiakro"],
      },
      {
        name: "N'zi-Comoé",
        cities: ["Dimbokro", "Bocanda", "Daoukro"],
      },
      {
        name: "Savanes",
        cities: ["Korhogo", "Ferkessédougou", "Boundiali"],
      },
      {
        name: "Sud-Bandama",
        cities: ["San-Pédro", "Sassandra", "Divo"],
      },
      {
        name: "Sud-Comoé",
        cities: ["Aboisso", "Grand-Bassam", "Adiaké"],
      },
      {
        name: "Vallée du Bandama",
        cities: ["Bouaké", "Béoumi", "Botro"],
      },
      {
        name: "Woroba",
        cities: ["Séguéla", "Mankono", "Vavoua"],
      },
      {
        name: "Zanzan",
        cities: ["Bondoukou", "Bouna", "Tanda"],
      },
    ],
  },
  {
    name: "Croatia",
    states: [
      {
        name: "City of Zagreb",
        cities: ["Zagreb"],
      },
      {
        name: "Krapina-Zagorje County",
        cities: ["Krapina", "Pregrada", "Zlatar", "Donja Stubica", "Zabok"],
      },
      {
        name: "Sisak-Moslavina County",
        cities: ["Sisak", "Kutina", "Glina", "Novska", "Petrinja"],
      },
      {
        name: "Karlovac County",
        cities: ["Karlovac", "Ogulin", "Slunj", "Duga Resa", "Vojnic"],
      },
      {
        name: "Varaždin County",
        cities: ["Varaždin", "Ludbreg", "Lepoglava", "Ivanec", "Novi Marof"],
      },
      {
        name: "Koprivnica-Križevci County",
        cities: [
          "Koprivnica",
          "Križevci",
          "Đurđevac",
          "Virje",
          "Novigrad Podravski",
        ],
      },
      {
        name: "Bjelovar-Bilogora County",
        cities: ["Bjelovar", "Čazma", "Garešnica", "Grubišno Polje", "Daruvar"],
      },
      {
        name: "Primorje-Gorski Kotar County",
        cities: ["Rijeka", "Crikvenica", "Kraljevica", "Opatija", "Bakar"],
      },
      {
        name: "Lika-Senj County",
        cities: ["Gospić", "Otočac", "Senj", "Novalja", "Donji Lapac"],
      },
      {
        name: "Virovitica-Podravina County",
        cities: ["Virovitica", "Slatina", "Pitomača", "Orahovica", "Čačinci"],
      },
      {
        name: "Požega-Slavonia County",
        cities: ["Požega", "Pakrac", "Pleternica", "Lipik", "Kutjevo"],
      },
      {
        name: "Brod-Posavina County",
        cities: [
          "Slavonski Brod",
          "Nova Gradiška",
          "Pleternica",
          "Slavonski Šamac",
          "Donji Andrijevci",
        ],
      },
      {
        name: "Zadar County",
        cities: ["Zadar", "Benkovac", "Pag", "Biograd na Moru", "Obrovac"],
      },
      {
        name: "Osijek-Baranja County",
        cities: ["Osijek", "Đakovo", "Valpovo", "Beli Manastir", "Našice"],
      },
      {
        name: "Šibenik-Knin County",
        cities: ["Šibenik", "Knin", "Drniš", "Skradin", "Vodice"],
      },
      {
        name: "Šibenik-Knin County",
        cities: [
          "Drniš",
          "Knin",
          "Pirovac",
          "Skradin",
          "Tisno",
          "Vodice",
          "Šibenik",
        ],
      },
      {
        name: "Sisak-Moslavina County",
        cities: [
          "Glina",
          "Hrvatska Kostajnica",
          "Kutina",
          "Novska",
          "Petrinja",
          "Sisak",
        ],
      },
      {
        name: "Slavonski Brod-Posavina County",
        cities: ["Nova Gradiška", "Slavonski Brod"],
      },
      {
        name: "Split-Dalmatia County",
        cities: [
          "Hvar",
          "Kaštela",
          "Makarska",
          "Omiš",
          "Sinj",
          "Split",
          "Stari Grad",
          "Supetar",
          "Trogir",
          "Vis",
        ],
      },
      {
        name: "Varaždin County",
        cities: ["Lepoglava", "Varaždin", "Varaždinske Toplice"],
      },
      {
        name: "Virovitica-Podravina County",
        cities: ["Orahovica", "Slatina"],
      },
      {
        name: "Vukovar-Syrmia County",
        cities: ["Ilok", "Otok", "Vinkovci", "Vukovar"],
      },
      {
        name: "Zadar County",
        cities: [
          "Benkovac",
          "Biograd na Moru",
          "Nin",
          "Obrovac",
          "Pag",
          "Zadar",
        ],
      },
      {
        name: "Zagreb",
        cities: ["Zagreb"],
      },
      {
        name: "Zagreb County",
        cities: [
          "Dugo Selo",
          "Ivanić-Grad",
          "Jastrebarsko",
          "Samobor",
          "Sveta Nedelja",
          "Velika Gorica",
          "Vrbovec",
          "Zaprešić",
        ],
      },
    ],
  },
  {
    name: "Cuba",
    states: [
      {
        name: "Artemisa",
        cities: [
          "Artemisa",
          "Bahía Honda",
          "San Antonio de los Baños",
          "Caimito",
          "Guanajay",
          "Bauta",
          "Alquízar",
          "Candelaria",
          "Mariel",
          "Güira de Melena",
        ],
      },

      {
        name: "Cienfuegos",
        cities: [
          "Cienfuegos",
          "Palmira",
          "Rodas",
          "Cruces",
          "Lajas",
          "Abreus",
          "Cumanayagua",
        ],
      },

      {
        name: "Ciego de Ávila",
        cities: [
          "Ciego de Ávila",
          "Morón",
          "Chambas",
          "Primero de Enero",
          "Venezuela",
          "Baraguá",
          "Florencia",
        ],
      },
      {
        name: "Camagüey",
        cities: [
          "Camagüey",
          "Florida",
          "Guáimaro",
          "Nuevitas",
          "Santa Cruz del Sur",
          "Vertientes",
        ],
      },
      {
        name: "Ciego de Ávila",
        cities: [
          "Ciego de Ávila",
          "Morón",
          "Chambas",
          "Majagua",
          "Primero de Enero",
          "Venezuela",
        ],
      },
      {
        name: "Cienfuegos",
        cities: [
          "Cienfuegos",
          "Palmira",
          "Abreus",
          "Cruces",
          "Cumanayagua",
          "Rodas",
        ],
      },
      {
        name: "Granma",
        cities: [
          "Bayamo",
          "Manzanillo",
          "Bartolomé Masó",
          "Buey Arriba",
          "Campechuela",
          "Cauto Cristo",
          "Guisa",
          "Jiguaní",
          "Media Luna",
          "Niquero",
          "Pilón",
          "Río Cauto",
          "Yara",
        ],
      },
      {
        name: "Guantánamo",
        cities: [
          "Guantánamo",
          "Baracoa",
          "El Salvador",
          "Imías",
          "Maisí",
          "Manuel Tames",
          "Niceto Pérez",
          "San Antonio del Sur",
          "Yateras",
        ],
      },
      {
        name: "Havana",
        cities: [
          "Havana",
          "Arroyo Naranjo",
          "Boyeros",
          "Centro Habana",
          "Cerro",
          "Cotorro",
          "Diez de Octubre",
          "Guanabacoa",
          "La Habana del Este",
          "La Lisa",
          "Marianao",
          "Playa",
          "Plaza",
          "Regla",
          "San Miguel del Padrón",
        ],
      },
      {
        name: "Holguín",
        cities: [
          "Holguín",
          "Moá",
          "Báguanos",
          "Cacocum",
          "Calixto García",
          "Cueto",
          "Frank País",
          "Gibara",
          "Mayarí",
          "Sagua de Tánamo",
          "Urbano Noris",
        ],
      },
      {
        name: "Isla de la Juventud",
        cities: ["Nueva Gerona"],
      },
      {
        name: "Las Tunas",
        cities: [
          "Las Tunas",
          "Puerto Padre",
          "Amancio",
          "Colombia",
          "Jesús Menéndez",
          "Jobabo",
          "Majibacoa",
          "Manatí",
          "Palma Soriano",
          "San Germán",
        ],
      },
      {
        name: "Matanzas",
        cities: [
          "Matanzas",
          "Cárdenas",
          "Calimete",
          "Jagüey Grande",
          "Jovellanos",
          "Limonar",
          "Los Arabos",
          "Martí",
          "Pedro Betancourt",
          "Perico",
          "Unión de Reyes",
        ],
      },
      {
        name: "Mayabeque",
        cities: [
          "Bejucal",
          "Jaruco",
          "Madruga",
          "Melena del Sur",
          "Nueva Paz",
          "Quivicán",
          "San José de las Lajas",
          "San Nicolás de Bari",
        ],
      },
      {
        name: "Matanzas",
        cities: [
          "Cárdenas",
          "Colón",
          "Jagüey Grande",
          "Jovellanos",
          "Limonar",
          "Matanzas",
          "Perico",
        ],
      },
      {
        name: "Las Tunas",
        cities: [
          "Amancio",
          "Colombia",
          "Jobabo",
          "Las Tunas",
          "Majibacoa",
          "Manatí",
          "Puerto Padre",
        ],
      },
      {
        name: "Pinar del Río",
        cities: [
          "Consolación del Sur",
          "Guane",
          "La Palma",
          "Los Palacios",
          "Mantua",
          "Minas de Matahambre",
          "Pinar del Río",
          "San Luis",
          "Sandino",
          "Viñales",
        ],
      },
      {
        name: "Sancti Spíritus",
        cities: [
          "Cabaiguán",
          "Fomento",
          "Jatibonico",
          "La Sierpe",
          "Sancti Spíritus",
          "Taguasco",
          "Trinidad",
          "Yaguajay",
        ],
      },
      {
        name: "Santiago de Cuba",
        cities: [
          "Contramaestre",
          "Guamá",
          "Palma Soriano",
          "Santiago de Cuba",
          "Songo-La Maya",
        ],
      },
      {
        name: "Villa Clara",
        cities: [
          "Caibarién",
          "Camajuaní",
          "Cifuentes",
          "Corralillo",
          "Manicaragua",
          "Placetas",
          "Quemado de Güines",
          "Ranchuelo",
          "Remedios",
          "Sagüa la Grande",
          "Santa Clara",
        ],
      },
    ],
  },
  {
    name: "Cyprus",
    states: [
      {
        name: "Famagusta",
        cities: [
          "Paralimni",
          "Deryneia",
          "Sotira",
          "Agia Napa",
          "Xylofagou",
          "Liopetri",
          "Protaras",
        ],
      },
      {
        name: "Kyrenia",
        cities: [
          "Kyrenia",
          "Girne",
          "Alsancak",
          "Karaoglanoglu",
          "Karakum",
          "Lapta",
        ],
      },
      {
        name: "Larnaca",
        cities: [
          "Larnaca",
          "Aradippou",
          "Livadia",
          "Dromolaxia",
          "Oroklini",
          "Pervolia",
          "Xylotymbou",
        ],
      },
      {
        name: "Limassol",
        cities: [
          "Limassol",
          "Agios Athanasios",
          "Germasogeia",
          "Kato Polemidia",
          "Mesa Geitonia",
          "Ypsonas",
          "Pissouri",
        ],
      },
      {
        name: "Nicosia",
        cities: [
          "Nicosia",
          "Strovolos",
          "Aglandjia",
          "Lakatamia",
          "Pera Chorio",
          "Geriatrics",
        ],
      },
      {
        name: "Paphos",
        cities: [
          "Paphos",
          "Chlorakas",
          "Peyia",
          "Polis",
          "Kissonerga",
          "Empa",
          "Geroskipou",
        ],
      },
    ],
  },
  {
    name: "Czech Republic",
    states: [
      { name: "Prague", cities: ["Prague"] },
      {
        name: "Central Bohemia",
        cities: ["Kladno", "Mladá Boleslav", "Kolín", "Nymburk", "Poděbrady"],
      },
      {
        name: "South Bohemia",
        cities: ["České Budějovice", "Třeboň", "Jindřichův Hradec"],
      },
      {
        name: "Plzeň",
        cities: ["Plzeň", "Klatovy", "Tachov"],
      },
      {
        name: "Karlovy Vary",
        cities: ["Karlovy Vary", "Sokolov"],
      },
      {
        name: "Ústí nad Labem",
        cities: ["Ústí nad Labem", "Děčín", "Chomutov", "Litoměřice"],
      },
      {
        name: "Liberec",
        cities: ["Liberec", "Česká Lípa", "Jablonec nad Nisou"],
      },
      {
        name: "Hradec Králové",
        cities: ["Hradec Králové", "Pardubice", "Chrudim"],
      },
      {
        name: "Pardubice",
        cities: ["Pardubice", "Chrudim", "Svitavy"],
      },
      {
        name: "Vysočina",
        cities: ["Jihlava", "Třebíč", "Havlíčkův Brod", "Pelhřimov"],
      },
      {
        name: "South Moravian",
        cities: ["Brno", "Blansko", "Znojmo", "Hodonín"],
      },
      {
        name: "Olomouc",
        cities: ["Olomouc", "Přerov", "Prostějov"],
      },
      {
        name: "Moravian-Silesian",
        cities: ["Ostrava", "Frýdek-Místek", "Opava", "Karviná"],
      },
      {
        name: "Zlín",
        cities: ["Zlín", "Uherské Hradiště", "Vsetín"],
      },
    ],
  },
  {
    name: "Denmark",
    states: [
      {
        name: "Capital Region of Denmark",
        cities: [
          "Copenhagen",
          "Frederiksberg",
          "Ballerup",
          "Gentofte",
          "Gladsaxe",
          "Herlev",
          "Hvidovre",
          "Lyngby-Taarbæk",
          "Rødovre",
          "Tårnby",
        ],
      },
      {
        name: "Central Denmark Region",
        cities: [
          "Aarhus",
          "Silkeborg",
          "Herning",
          "Randers",
          "Viborg",
          "Skive",
          "Horsens",
          "Holstebro",
          "Ikast-Brande",
          "Odder",
        ],
      },
      {
        name: "Region of Southern Denmark",
        cities: [
          "Odense",
          "Esbjerg",
          "Kolding",
          "Vejen",
          "Middelfart",
          "Fredericia",
          "Sønderborg",
          "Nyborg",
          "Assens",
          "Varde",
        ],
      },
      {
        name: "North Denmark Region",
        cities: [
          "Aalborg",
          "Hjørring",
          "Frederikshavn",
          "Thisted",
          "Brønderslev",
          "Hobro",
          "Sæby",
          "Pandrup",
          "Aars",
          "Skagen",
        ],
      },
      {
        name: "Zealand Region",
        cities: [
          "Roskilde",
          "Køge",
          "Næstved",
          "Slagelse",
          "Holbæk",
          "Ringsted",
          "Sorø",
          "Kalundborg",
          "Greve",
          "Høje-Taastrup",
        ],
      },
    ],
  },
  {
    name: "Djibouti",
    states: [
      { name: "Arta", cities: ["Arta", "Holhol"] },
      {
        name: "Dikhil",
        cities: ["Dikhil", "Ali Sabieh"],
      },
      {
        name: "Djibouti",
        cities: ["Djibouti", "Tadjourah", "Obock"],
      },
      {
        name: "Obock",
        cities: ["Obock", "Khor Angar"],
      },
      {
        name: "Tadjourah",
        cities: ["Tadjourah", "Balho", "Dorra", "Galafi", "Loyada"],
      },
    ],
  },

  {
    name: "Dominica",
    states: [
      {
        name: "Saint Andrew Parish",
        cities: ["Marigot", "Wesley", "Woodford Hill", "Calibishie"],
      },
      {
        name: "Saint David Parish",
        cities: [
          "Castle Bruce",
          "Laudat",
          "Loubiere",
          "Morne Jaune",
          "Rosalie",
        ],
      },
      {
        name: "Saint George Parish",
        cities: ["Roseau", "Canefield", "Massacre", "Morne Daniel", "Tarreau"],
      },
      {
        name: "Saint John Parish",
        cities: ["Portsmouth", "Bense", "Coulibistrie", "Salisbury"],
      },
      {
        name: "Saint Joseph Parish",
        cities: ["Mero", "St. Joseph", "Bells", "Giraudel", "Morne Prosper"],
      },
      {
        name: "Saint Luke Parish",
        cities: ["Pointe Michel", "Soufrière", "Scotts Head"],
      },
      {
        name: "Saint Mark Parish",
        cities: ["Grand Bay", "Bagatelle", "La Plaine", "Rivière Cyrique"],
      },
      {
        name: "Saint Patrick Parish",
        cities: [
          "La Plaine",
          "Bioche",
          "Boetica",
          "Coulibistrie",
          "Dublanc",
          "Grand Bay",
          "Morne Raquette",
          "Paix Bouche",
          "Petite Soufrière",
          "Penville",
          "Salybia",
        ],
      },
    ],
  },
  {
    name: "Dominican Republic",
    states: [
      {
        name: "Azua",
        cities: [
          "Azua",
          "Estebanía",
          "Guayabal",
          "Las Charcas",
          "Las Yayas de Viajama",
          "Padre Las Casas",
          "Peralta",
          "Sabana Yegua",
          "Tabara Arriba",
        ],
      },
      {
        name: "Bahoruco",
        cities: ["Neyba", "Galván", "Los Ríos", "Tamayo"],
      },
      {
        name: "Barahona",
        cities: [
          "Barahona",
          "Cabral",
          "El Peñón",
          "Enriquillo",
          "Fundación",
          "Jaquimeyes",
          "La Ciénaga",
          "Las Salinas",
          "Paraíso",
          "Polo",
          "Vicente Noble",
        ],
      },
      {
        name: "Dajabón",
        cities: [
          "Dajabón",
          "El Pino",
          "Loma de Cabrera",
          "Partido",
          "Restauración",
        ],
      },
      {
        name: "Distrito Nacional",
        cities: ["Santo Domingo"],
      },
      {
        name: "Duarte",
        cities: [
          "San Francisco de Macorís",
          "Arenoso",
          "Castillo",
          "Eugenio María de Hostos",
          "Las Guáranas",
          "Pimentel",
          "Villa Riva",
        ],
      },
      {
        name: "Elías Piña",
        cities: [
          "Comendador",
          "Bánica",
          "El Llano",
          "Hondo Valle",
          "Pedro Santana",
        ],
      },
      {
        name: "El Seibo",
        cities: ["Santa Cruz de El Seibo", "Miches"],
      },
      {
        name: "Espaillat",
        cities: [
          "Moca",
          "Cayetano Germosén",
          "Gaspar Hernández",
          "Jamao al Norte",
        ],
      },
      {
        name: "Hato Mayor",
        cities: ["Hato Mayor del Rey", "El Valle", "Sabana de la Mar"],
      },
      {
        name: "Hermanas Mirabal",
        cities: ["Salcedo", "Tenares", "Villa Tapia"],
      },
      {
        name: "Independencia",
        cities: [
          "Jimaní",
          "Cristóbal",
          "Duvergé",
          "La Descubierta",
          "Mella",
          "Postrer Río",
        ],
      },
      {
        name: "La Altagracia",
        cities: ["Higüey", "Bávaro", "Bayahibe", "San Rafael del Yuma"],
      },
      {
        name: "La Romana",
        cities: ["La Romana", "Guaymate", "Villa Hermosa"],
      },
      {
        name: "La Vega",
        cities: [
          "La Vega",
          "Concepción de La Vega",
          "Jarabacoa",
          "Jima Abajo",
          "Moca",
          "Río Verde Arriba",
        ],
      },
      {
        name: "María Trinidad Sánchez",
        cities: ["Nagua", "Cabrera", "El Factor", "Río San Juan"],
      },
      {
        name: "Monseñor Nouel",
        cities: ["Bonao", "Maimón", "Piedra Blanca"],
      },
      {
        name: "Monte Cristi",
        cities: [
          "Castanuelas",
          "Guayubín",
          "Las Matas de Santa Cruz",
          "Monte Cristi",
          "Pepillo Salcedo",
          "Villa Vásquez",
        ],
      },
      {
        name: "Monte Plata",
        cities: [
          "Bayaguana",
          "Don Juan",
          "Monte Plata",
          "Peralvillo",
          "Sabana Grande de Boyá",
          "Yamasá",
        ],
      },
      {
        name: "Pedernales",
        cities: ["Oviedo", "Pedernales"],
      },
      {
        name: "Peravia",
        cities: ["Baní", "Nizao", "Sabana Buey", "Sabana Grande de Palenque"],
      },
      {
        name: "Puerto Plata",
        cities: [
          "Altamira",
          "Guananico",
          "Imbert",
          "Los Hidalgos",
          "Luperón",
          "Sosúa",
          "Villa Isabela",
        ],
      },
      {
        name: "Samana",
        cities: ["Las Terrenas", "Sánchez", "Samana"],
      },
      {
        name: "San Cristobal",
        cities: [
          "Bajos de Haina",
          "Cambita Garabitos",
          "Los Cacaos",
          "Sabana Grande de Palenque",
          "San Cristóbal",
          "Villa Altagracia",
        ],
      },
      {
        name: "San Jose de Ocoa",
        cities: ["Ocoa"],
      },
      {
        name: "San Juan",
        cities: [
          "Bohechío",
          "El Cercado",
          "Juan de Herrera",
          "Las Matas de Farfán",
          "San Juan",
          "Vallejuelo",
        ],
      },
      {
        name: "San Pedro de Macorís",
        cities: [
          "Consuelo",
          "Guayacanes",
          "Quisqueya",
          "Ramón Santana",
          "San José de los Llanos",
          "San Pedro de Macorís",
        ],
      },
      {
        name: "Sánchez Ramírez",
        cities: ["Cevicos", "Cotuí", "Fantino"],
      },
      {
        name: "Santiago",
        cities: [
          "Bisonó",
          "Jánico",
          "Licey al Medio",
          "Puñal",
          "Santiago de los Caballeros",
          "Tamboril",
          "Villa Bisonó",
        ],
      },
      {
        name: "Santiago Rodríguez",
        cities: ["Los Almácigos", "San Ignacio de Sabaneta"],
      },
      {
        name: "Santo Domingo",
        cities: [
          "Boca Chica",
          "Los Alcarrizos",
          "Pedro Brand",
          "San Antonio de Guerra",
          "Santo Domingo Este",
          "Santo Domingo Norte",
          "Santo Domingo Oeste",
        ],
      },
      {
        name: "Valverde",
        cities: ["Mao", "Esperanza", "Laguna Salada", "Villa Elisa"],
      },
    ],
  },
  {
    name: "Ecuador",
    states: [
      {
        name: "Azuay",
        cities: ["Cuenca", "Girón", "Gualaceo", "Paute", "Sígsig"],
      },
      {
        name: "Bolívar",
        cities: ["Guaranda", "Chillanes", "Echeandía", "San Miguel", "Caluma"],
      },
      {
        name: "Cañar",
        cities: ["Azogues", "Biblían", "Cañar", "La Troncal", "El Tambo"],
      },
      {
        name: "Carchi",
        cities: ["Tulcán", "Bolívar", "Espejo", "Mira", "Montúfar"],
      },
      {
        name: "Chimborazo",
        cities: ["Riobamba", "Alausí", "Guano", "Chambo", "Chunchi"],
      },
      {
        name: "Cotopaxi",
        cities: ["Latacunga", "La Maná", "Pangua", "Pujilí", "Saquisilí"],
      },
      {
        name: "El Oro",
        cities: ["Machala", "Pasaje", "Arenillas", "Santa Rosa", "Huaquillas"],
      },
      {
        name: "Esmeraldas",
        cities: ["Esmeraldas", "Atacames", "Eloy Alfaro", "Muisne", "Quinindé"],
      },
      {
        name: "Galápagos",
        cities: [
          "Puerto Baquerizo Moreno",
          "Puerto Ayora",
          "Santa Cruz",
          "Isabela",
          "San Cristóbal",
        ],
      },
      {
        name: "Guayas",
        cities: ["Guayaquil", "Daule", "Durán", "Milagro", "Samborondón"],
      },
      {
        name: "Imbabura",
        cities: [
          "Ibarra",
          "Otavalo",
          "Cotacachi",
          "Pimampiro",
          "San Miguel de Urcuquí",
        ],
      },
      {
        name: "Loja",
        cities: ["Loja", "Cariamanga", "Catamayo", "Zapotillo", "Pindal"],
      },
      {
        name: "Los Ríos",
        cities: ["Babahoyo", "Quevedo", "Baba", "Montalvo", "Urdaneta"],
      },
      {
        name: "Manabí",
        cities: ["Portoviejo", "Chone", "Jipijapa", "Manta", "Rocafuerte"],
      },
      {
        name: "Morona Santiago",
        cities: ["Macas", "Gualaquiza", "Morona", "Sucúa", "Huamboya"],
      },
      {
        name: "Napo",
        cities: [
          "Tena",
          "Archidona",
          "El Chaco",
          "Quijos",
          "Carlos Julio Arosemena Tola",
        ],
      },
      {
        name: "Orellana",
        cities: [
          "Aguarico",
          "La Belleza",
          "Lago San Pedro",
          "Puerto Francisco de Orellana",
          "Tiputini",
        ],
      },
      {
        name: "Pastaza",
        cities: ["Mera", "Puyo", "Santa Clara", "Arajuno"],
      },
      {
        name: "Pichincha",
        cities: [
          "Cayambe",
          "Mejía",
          "Pedro Moncayo",
          "Pedro Vicente Maldonado",
          "Puerto Quito",
          "Quito",
          "Rumiñahui",
        ],
      },
      {
        name: "Santa Elena",
        cities: ["La Libertad", "Salinas", "Santa Elena"],
      },
      {
        name: "Santo Domingo de los Tsáchilas",
        cities: ["Santo Domingo"],
      },
      {
        name: "Sucumbíos",
        cities: [
          "Cascales",
          "Cuyabeno",
          "Gonzalo Pizarro",
          "Lago Agrio",
          "Putumayo",
          "Shushufindi",
          "Sucumbíos",
        ],
      },
      {
        name: "Tungurahua",
        cities: [
          "Ambato",
          "Baños de Agua Santa",
          "Cevallos",
          "Mocha",
          "Patate",
          "Pelileo",
          "Pillaro",
          "Quero",
        ],
      },
      {
        name: "Zamora Chinchipe",
        cities: [
          "Chinchipe",
          "El Pangui",
          "Nangaritza",
          "Palanda",
          "Paquisha",
          "Yacuambi",
          "Yantzaza",
          "Zamora",
        ],
      },
    ],
  },

  {
    name: "Egypt",
    states: [
      {
        name: "Alexandria",
        cities: ["Alexandria", "Borg El Arab", "Abu Qir", "Rosetta"],
      },
      {
        name: "Aswan",
        cities: ["Aswan", "Kom Ombo", "Edfu", "Abu Simbel"],
      },
      {
        name: "Asyut",
        cities: ["Asyut", "El Badari", "Abnub", "Manfalut"],
      },
      {
        name: "Beheira",
        cities: ["Damanhur", "Kafr El Dawwar", "Rashid", "Edko"],
      },
      {
        name: "Beni Suef",
        cities: ["Beni Suef", "Nasser", "New Beni Suef", "El Wasta"],
      },
      {
        name: "Cairo",
        cities: ["Cairo", "Giza", "Shubra El Kheima", "6th of October City"],
      },
      {
        name: "Dakahlia",
        cities: ["Mansoura", "Agda", "Matareya", "Mit Ghamr"],
      },
      {
        name: "Damietta",
        cities: ["Damietta", "Kafr Saad", "Faraskur", "El Zarqa"],
      },
      {
        name: "Faiyum",
        cities: ["Faiyum", "Ibsheway", "Sinnuris", "Yusuf El Sediaq"],
      },
      {
        name: "Gharbia",
        cities: ["Tanta", "Kafr El Zayat", "Zefta", "Basyoun"],
      },
      {
        name: "Giza",
        cities: ["Giza", "Abu Ghalib", "Al Omraneyah", "Pyramids"],
      },
      {
        name: "Ismailia",
        cities: ["Ismailia", "Fayed", "Qantara Sharq", "El Tal El Kabir"],
      },
      {
        name: "Kafr El Sheikh",
        cities: ["Kafr El Sheikh", "Desouk", "Fouh", "Qellin"],
      },
      {
        name: "Luxor",
        cities: ["Luxor", "Karnak", "West Bank", "Valley of the Kings"],
      },
      {
        name: "Matruh",
        cities: ["Marsa Matruh", "El Hamam", "Sidi Barrani", "Alamein"],
      },
      {
        name: "Minya",
        cities: ["Minya", "Maghagha", "Bani Mazar", "Samalut"],
      },
      {
        name: "Monufia",
        cities: ["Shibin El Kom", "Ashmoun", "Menouf", "Sers El Lyan"],
      },
      {
        name: "New Valley",
        cities: ["Kharga", "Dakhla", "Farafra", "Baris Oasis"],
      },
      {
        name: "North Sinai",
        cities: ["El Arish", "Rafah", "Sheikh Zuweid", "Nakhl"],
      },
      {
        name: "Port Said",
        cities: ["Port Said", "Port Fuad", "El-Arish"],
      },
      {
        name: "Qalyubia",
        cities: [
          "Banha",
          "Qalyub",
          "Shubra El Kheima",
          "Al Qanater Al Khayriyah",
          "Khanka",
          "Toukh",
          "Qaha",
          "Kafr Shukr",
          "Obour City",
          "Khosous",
          "Shibin El Qanater",
          "El Ubour",
        ],
      },
      {
        name: "Qena",
        cities: [
          "Qena",
          "Nag Hammadi",
          "Dishna",
          "Farshut",
          "Qift",
          "Luxor",
          "Armant",
          "Esna",
          "Alwaqf",
          "Gurna",
          "Karnak",
        ],
      },
      {
        name: "Red Sea",
        cities: ["Hurghada", "Marsa Alam", "Safaga", "El Qoseir", "Ras Ghareb"],
      },
      {
        name: "Sharqia",
        cities: [
          "Zagazig",
          "Al Husseiniya",
          "Al Qanayat",
          "Belbeis",
          "Abu Hammad",
          "Abu Kebir",
          "Al Ibrahimiyah",
          "Kafr Saqr",
          "Mashtul as Suq",
          "Minya al Qamh",
          "Tall al Kabir",
        ],
      },
      {
        name: "Sohag",
        cities: [
          "Sohag",
          "Akhmim",
          "Gerga",
          "Tahta",
          "Tima",
          "Girga",
          "Dar El Salam",
          "Juhaynah",
          "Saqulta",
          "El Maragha",
        ],
      },
      {
        name: "South Sinai",
        cities: [
          "Sharm El Sheikh",
          "Dahab",
          "Nuweiba",
          "Taba",
          "Saint Catherine",
        ],
      },
      {
        name: "Suez",
        cities: [
          "Suez",
          "Ataqah",
          "Arbaeen",
          "Faisal",
          "Ismailia",
          "Port Said",
        ],
      },
    ],
  },

  {
    name: "El Salvador",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },

  {
    name: "Equatorial Guinea",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Eritrea",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Estonia",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Eswatini",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Ethiopia",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Fiji",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Finland",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "France",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Gabon",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Gambia",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Georgia",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Germany",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Ghana",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Greece",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Grenada",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Guinea",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Guinea-Bissau",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Guyana",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Haiti",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Honduras",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Hungary",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Iceland",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "India",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Indonesia",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Iran",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Iraq",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Ireland",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Israel",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Italy",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Jamaica",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Japan",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Jordan",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Kazakhstan",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Kenya",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Kiribati",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Kosovo",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Kuwait",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Kyrgyzstan",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Laos",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Latvia",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Lebanon",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Lesotho",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Liberia",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Libya",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Liechtenstein",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Lithuania",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Luxembourg",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Madagascar",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Malawi",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Malaysia",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Maldives",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Mali",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Malta",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Marshall Islands",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Mauritania",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Mauritius",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Mexico",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Micronesia",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Moldova",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Monaco",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Mongolia",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Montenegro",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Morocco",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Mozambique",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Myanmar",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Namibia",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Nauru",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Nepal",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Netherlands",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "New Zealand",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Nicaragua",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Niger",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Nigeria",
    states: [
      {
        name: "Abia",
        cities: [
          "Aba North",
          "Aba South",
          "Arochukwu",
          "Bende",
          "Ikwuano",
          "Isiala Ngwa North",
          "Isiala Ngwa South",
          "Isuikwuato",
          "Obi Ngwa",
          "Ohafia",
          "Osisioma",
          "Ugwunagbo",
          "Ukwa East",
          "Ukwa West",
          "Umuahia North",
          "muahia South",
          "Umu Nneochi",
        ],
      },
      {
        name: "Adamawa",
        cities: [
          "Demsa",
          "Fufure",
          "Ganye",
          "Gayuk",
          "Gombi",
          "Grie",
          "Hong",
          "Jada",
          "Larmurde",
          "Madagali",
          "Maiha",
          "Mayo Belwa",
          "Michika",
          "Mubi North",
          "Mubi South",
          "Numan",
          "Shelleng",
          "Song",
          "Toungo",
          "Yola North",
          "Yola South",
        ],
      },
      {
        name: "AkwaIbom",
        cities: [
          "Abak",
          "Eastern Obolo",
          "Eket",
          "Esit Eket",
          "Essien Udim",
          "Etim Ekpo",
          "Etinan",
          "Ibeno",
          "Ibesikpo Asutan",
          "Ibiono-Ibom",
          "Ika",
          "Ikono",
          "Ikot Abasi",
          "Ikot Ekpene",
          "Ini",
          "Itu",
          "Mbo",
          "Mkpat-Enin",
          "Nsit-Atai",
          "Nsit-Ibom",
          "Nsit-Ubium",
          "Obot Akara",
          "Okobo",
          "Onna",
          "Oron",
          "Oruk Anam",
          "Udung-Uko",
          "Ukanafun",
          "Uruan",
          "Urue-Offong Oruko",
          "Uyo",
        ],
      },

      {
        name: "Anambra ",
        cities: [
          "Aguata",
          "Anambra East",
          "Anambra West",
          "Anaocha",
          "Awka North",
          "Awka South",
          "Ayamelum",
          "Dunukofia",
          "Ekwusigo",
          "Idemili North",
          "Idemili South",
          "Ihiala",
          "Njikoka",
          "Nnewi North",
          "Nnewi South",
          "Ogbaru",
          "Onitsha North",
          "Onitsha South",
          "Orumba North",
          "Orumba South",
          "Oyi",
        ],
      },
      {
        name: "Bauchi ",
        cities: [
          "Alkaleri",
          "Bauchi",
          "Bogoro",
          "Damban",
          "Darazo",
          "Dass",
          "Gamawa",
          "Ganjuwa",
          "Giade",
          "Itas-Gadau",
          "Jama are",
          "Katagum",
          "Kirfi",
          "Misau",
          "Ningi",
          "Shira",
          "Tafawa Balewa",
          " Toro",
          " Warji",
          " Zaki",
        ],
      },
      {
        name: "Bayelsa",
        cities: [
          "Brass",
          "Ekeremor",
          "Kolokuma Opokuma",
          "Nembe",
          "Ogbia",
          "Sagbama",
          "Southern Ijaw",
          "Yenagoa",
        ],
      },
      {
        name: "Benue",
        cities: [
          "Agatu",
          "Apa",
          "Ado",
          "Buruku",
          "Gboko",
          "Guma",
          "Gwer East",
          "Gwer West",
          "Katsina-Ala",
          "Konshisha",
          "Kwande",
          "Logo",
          "Makurdi",
          "Obi",
          "Ogbadibo",
          "Ohimini",
          "Oju",
          "Okpokwu",
          "Oturkpo",
          "Tarka",
          "Ukum",
          "Ushongo",
          "Vandeikya",
        ],
      },
      {
        name: "Borno",
        cities: [
          "Abadam",
          "Askira-Uba",
          "Bama",
          "Bayo",
          "Biu",
          "Chibok",
          "Damboa",
          "Dikwa",
          "Gubio",
          "Guzamala",
          "Gwoza",
          "Hawul",
          "Jere",
          "Kaga",
          "Kala-Balge",
          "Konduga",
          "Kukawa",
          "Kwaya Kusar",
          "Mafa",
          "Magumeri",
          "Maiduguri",
          "Marte",
          "Mobbar",
          "Monguno",
          "Ngala",
          "Nganzai",
          "Shani",
        ],
      },
      {
        name: "Cross River",
        cities: [
          "Abi",
          "Akamkpa",
          "Akpabuyo",
          "Bakassi",
          "Bekwarra",
          "Biase",
          "Boki",
          "Calabar Municipal",
          "Calabar South",
          "Etung",
          "Ikom",
          "Obanliku",
          "Obubra",
          "Obudu",
          "Odukpani",
          "Ogoja",
          "Yakuur",
          "Yala",
        ],
      },
      {
        name: "Delta ",
        cities: [
          "Aniocha North",
          "Aniocha South",
          "Bomadi",
          "Burutu",
          "Ethiope East",
          "Ethiope West",
          "Ika North East",
          "Ika South",
          "Isoko North",
          "Isoko South",
          "Ndokwa East",
          "Ndokwa West",
          "Okpe",
          "Oshimili North",
          "Oshimili South",
          "Patani",
          "Sapele",
          "Udu",
          "Ughelli North",
          "Ughelli South",
          "Ukwuani",
          "Uvwie",
          "Warri North",
          "Warri South",
          "Warri South West",
        ],
      },
      {
        name: "Ebonyi ",
        cities: [
          "Abakaliki",
          "Afikpo North",
          "Afikpo South",
          "Ebonyi",
          "Ezza North",
          "Ezza South",
          "Ikwo",
          "Ishielu",
          "Ivo",
          "Izzi",
          "Ohaozara",
          "Ohaukwu",
          "Onicha",
        ],
      },
      {
        name: "Edo",
        cities: [
          "Akoko-Edo",
          "Egor",
          "Esan Central",
          "Esan North-East",
          "Esan South-East",
          "Esan West",
          "Etsako Central",
          "Etsako East",
          "Etsako West",
          "Igueben",
          "Ikpoba Okha",
          "Orhionmwon",
          "Oredo",
          "Ovia North-East",
          "Ovia South-West",
          "Owan East",
          "Owan West",
          "Uhunmwonde",
        ],
      },
      {
        name: "Ekiti",
        cities: [
          "Ado Ekiti",
          "Efon",
          "Ekiti East",
          "Ekiti South-West",
          "Ekiti West",
          "Emure",
          "Gbonyin",
          "Ido Osi",
          "Ijero",
          "Ikere",
          "Ikole",
          "Ilejemeje",
          "Irepodun-Ifelodun",
          "Ise-Orun",
          "Moba",
          "Oye",
        ],
      },
      {
        name: "Rivers ",
        cities: [
          "Port Harcourt",
          "Obio-Akpor",
          "Okrika",
          "Ogu–Bolo",
          "Eleme",
          "Tai",
          "Gokana",
          "Khana",
          "Oyigbo",
          "Opobo–Nkoro",
          "Andoni",
          "Bonny",
          "Degema",
          "Asari-Toru",
          "Akuku-Toru",
          "Abua–Odual",
          "Ahoada West",
          "Ahoada East",
          "Ogba–Egbema–Ndoni",
          "Emohua",
          "Ikwerre",
          "Etche",
          "Omuma",
        ],
      },
      {
        name: "Enugu  ",
        cities: [
          "Aninri",
          "Awgu",
          "Enugu East",
          "Enugu North",
          "Enugu South",
          "Ezeagu",
          "Igbo Etiti",
          "Igbo Eze North",
          "Igbo Eze South",
          "Isi Uzo",
          "Nkanu East",
          "Nkanu West",
          "Nsukka",
          "Oji River",
          "Udenu",
          "Udi",
          "Uzo Uwani",
        ],
      },
      {
        name: "FCT",
        cities: [
          "Abaji",
          "Bwari",
          "Gwagwalada",
          "Kuje",
          "Kwali",
          "Municipal Area Council",
        ],
      },
      {
        name: "Gombe",
        cities: [
          "Akko",
          "Balanga",
          "Billiri",
          "Dukku",
          "Funakaye",
          "Gombe",
          "Kaltungo",
          "Kwami",
          "Nafada",
          "Shongom",
          "Yamaltu-Deba",
        ],
      },
      {
        name: "Imo",
        cities: [
          "Aboh Mbaise",
          "Ahiazu Mbaise",
          "Ehime Mbano",
          "Ezinihitte",
          "Ideato North",
          "Ideato South",
          "Ihitte-Uboma",
          "Ikeduru",
          "Isiala Mbano",
          "Isu",
          "Mbaitoli",
          "Ngor Okpala",
          "Njaba",
          "Nkwerre",
          "Nwangele",
          "Obowo",
          "Oguta",
          "Ohaji-Egbema",
          "Okigwe",
          "Orlu",
          "Orsu",
          "Oru East",
          "Oru West",
          "Owerri Municipal",
          "Owerri North",
          "Owerri West",
          "Unuimo",
        ],
      },
      {
        name: "Jigawa ",
        cities: [
          "Auyo",
          "Babura",
          "Biriniwa",
          "Birnin Kudu",
          "Buji",
          "Dutse",
          "Gagarawa",
          "Garki",
          "Gumel",
          "Guri",
          "Gwaram",
          "Gwiwa",
          "Hadejia",
          "Jahun",
          "Kafin Hausa",
          "Kazaure",
          "Kiri Kasama",
          "Kiyawa",
          "Kaugama",
          "Maigatari",
          "Malam Madori",
          "Miga",
          "Ringim",
          "Roni",
          "Sule Tankarkar",
          "Taura",
          "Yankwashi",
        ],
      },
      {
        name: "Kaduna",
        cities: [
          "Birnin Gwari",
          "Chikun",
          "Giwa",
          "Igabi",
          "Ikara",
          "Jaba",
          "Jema a",
          "Kachia",
          "Kaduna North",
          "Kaduna South",
          "Kagarko",
          "Kajuru",
          "Kaura",
          "Kauru",
          "Kubau",
          "Kudan",
          "Lere",
          "Makarfi",
          "Sabon Gari",
          "Sanga",
          "Soba",
          "Zangon Kataf",
          "Zaria",
        ],
      },
      {
        name: "Kano",
        cities: [
          "Ajingi",
          "Albasu",
          "Bagwai",
          "Bebeji",
          "Bichi",
          "Bunkure",
          "Dala",
          "Dambatta",
          "Dawakin Kudu",
          "Dawakin Tofa",
          "Doguwa",
          "Fagge",
          "Gabasawa",
          "Garko",
          "Garun Mallam",
          "Gaya",
          "Gezawa",
          "Gwale",
          "Gwarzo",
          "Kabo",
          "Kano Municipal",
          "Karaye",
          "Kibiya",
          "Kiru",
          "Kumbotso",
          "Kunchi",
          "Kura",
          "Madobi",
          "Makoda",
          "Minjibir",
          "Nasarawa",
          "Rano",
          "Rimin Gado",
          "Rogo",
          "Shanono",
          "Sumaila",
          "Takai",
          "Tarauni",
          "Tofa",
          "Tsanyawa",
          "Tudun Wada",
          "Ungogo",
          "Warawa",
          "Wudil",
        ],
      },
      {
        name: "Katsina",
        cities: [
          "Bakori",
          "Batagarawa",
          "Batsari",
          "Baure",
          "Bindawa",
          "Charanchi",
          "Dandume",
          "Danja",
          "Dan Musa",
          "Daura",
          "Dutsi",
          "Dutsin Ma",
          "Faskari",
          "Funtua",
          "Ingawa",
          "Jibia",
          "Kafur",
          "Kaita",
          "Kankara",
          "Kankia",
          "Katsina",
          "Kurfi",
          "Kusada",
          "Mai Adua",
          "Malumfashi",
          "Mani",
          "Mashi",
          "Matazu",
          "Musawa",
          "Rimi",
          "Sabuwa",
          "Safana",
          "Sandamu",
          "Zango",
        ],
      },
      {
        name: "Kebbi",
        cities: [
          "Aleiro",
          "Arewa Dandi",
          "Argungu",
          "Augie",
          "Bagudo",
          "Birnin Kebbi",
          "Bunza",
          "Dandi",
          "Fakai",
          "Gwandu",
          "Jega",
          "Kalgo",
          "Koko Besse",
          "Maiyama",
          "Ngaski",
          "Sakaba",
          "Shanga",
          "Suru",
          "Wasagu Danko",
          "Yauri",
          "Zuru",
        ],
      },
      {
        name: "Kogi",
        cities: [
          "Adavi",
          "Ajaokuta",
          "Ankpa",
          "Bassa",
          "Dekina",
          "Ibaji",
          "Idah",
          "Igalamela Odolu",
          "Ijumu",
          "Kabba Bunu",
          "Kogi",
          "Lokoja",
          "Mopa Muro",
          "Ofu",
          "Ogori Magongo",
          "Okehi",
          "Okene",
          "Olamaboro",
          "Omala",
          "Yagba East",
          "Yagba West",
        ],
      },
      {
        name: "Kwara",
        cities: [
          "Asa",
          "Baruten",
          "Edu",
          "Ekiti",
          "Ifelodun",
          "Ilorin East",
          "Ilorin South",
          "Ilorin West",
          "Irepodun",
          "Isin",
          "Kaiama",
          "Moro",
          "Offa",
          "Oke Ero",
          "Oyun",
          "Pategi",
        ],
      },
      {
        name: "Lagos",
        cities: [
          "Agege",
          "Ajeromi-Ifelodun",
          "Alimosho",
          "Amuwo-Odofin",
          "Apapa",
          "Badagry",
          "Epe",
          "Eti Osa",
          "Ibeju-Lekki",
          "Ifako-Ijaiye",
          "Ikeja",
          "Ikorodu",
          "Kosofe",
          "Lagos Island",
          "Lagos Mainland",
          "Mushin",
          "Ojo",
          "Oshodi-Isolo",
          "Shomolu",
          "Surulere",
        ],
      },
      {
        name: "Nasarawa",
        cities: [
          "Akwanga",
          "Awe",
          "Doma",
          "Karu",
          "Keana",
          "Keffi",
          "Kokona",
          "Lafia",
          "Nasarawa",
          "Nasarawa Egon",
          "Obi",
          "Toto",
          "Wamba",
        ],
      },
      {
        name: "Niger ",
        cities: [
          "Agaie",
          "Agwara",
          "Bida",
          "Borgu",
          "Bosso",
          "Chanchaga",
          "Edati",
          "Gbako",
          "Gurara",
          "Katcha",
          "Kontagora",
          "Lapai",
          "Lavun",
          "Magama",
          "Mariga",
          "Mashegu",
          "Mokwa",
          "Moya",
          "Paikoro",
          "Rafi",
          "Rijau",
          "Shiroro",
          "Suleja",
          "Tafa",
          "Wushishi",
        ],
      },
      {
        name: "Ogun",
        cities: [
          "Abeokuta North",
          "Abeokuta South",
          "Ado-Odo Ota",
          "Egbado North",
          "Egbado South",
          "Ewekoro",
          "Ifo",
          "Ijebu East",
          "Ijebu North",
          "Ijebu North East",
          "Ijebu Ode",
          "Ikenne",
          "Imeko Afon",
          "Ipokia",
          "Obafemi Owode",
          "Odeda",
          "Odogbolu",
          "Ogun Waterside",
          "Remo North",
          "Shagamu",
        ],
      },
      {
        name: "Ondo",
        cities: [
          "Akoko North-East",
          "Akoko North-West",
          "Akoko South-West",
          "Akoko South-East",
          "Akure North",
          "Akure South",
          "Ese Odo",
          "Idanre",
          "Ifedore",
          "Ilaje",
          "Ile Oluji-Okeigbo",
          "Irele",
          "Odigbo",
          "Okitipupa",
          "Ondo East",
          "Ondo West",
          "Ose",
          "Owo",
        ],
      },
      {
        name: "Osun",
        cities: [
          "Atakunmosa East",
          "Atakunmosa West",
          "Aiyedaade",
          "Aiyedire",
          "Boluwaduro",
          "Boripe",
          "Ede North",
          "Ede South",
          "Ife Central",
          "Ife East",
          "Ife North",
          "Ife South",
          "Egbedore",
          "Ejigbo",
          "Ifedayo",
          "Ifelodun",
          "Ila",
          "Ilesa East",
          "Ilesa West",
          "Irepodun",
          "Irewole",
          "Isokan",
          "Iwo",
          "Obokun",
          "Odo Otin",
          "Ola Oluwa",
          "Olorunda",
          "Oriade",
          "Orolu",
          "Osogbo",
        ],
      },
      {
        name: "Oyo",
        cities: [
          "Afijio",
          "Akinyele",
          "Atiba",
          "Atisbo",
          "Egbeda",
          "Ibadan North",
          "Ibadan North-East",
          "Ibadan North-West",
          "Ibadan South-East",
          "Ibadan South-West",
          "Ibarapa Central",
          "Ibarapa East",
          "Ibarapa North",
          "Ido",
          "Irepo",
          "Iseyin",
          "Itesiwaju",
          "Iwajowa",
          "Kajola",
          "Lagelu",
          "Ogbomosho North",
          "Ogbomosho South",
          "Ogo Oluwa",
          "Olorunsogo",
          "Oluyole",
          "Ona Ara",
          "Orelope",
          "Ori Ire",
          "Oyo",
          "Oyo East",
          "Saki East",
          "Saki West",
          "Surulere",
        ],
      },
      {
        name: "Plateau",
        cities: [
          "Bokkos",
          "Barkin Ladi",
          "Bassa",
          "Jos East",
          "Jos North",
          "Jos South",
          "Kanam",
          "Kanke",
          "Langtang South",
          "Langtang North",
          "Mangu",
          "Mikang",
          "Pankshin",
          "Qua an Pan",
          "Riyom",
          "Shendam",
          "Wase",
        ],
      },
      {
        name: "Sokoto",
        cities: [
          "Binji",
          "Bodinga",
          "Dange Shuni",
          "Gada",
          "Goronyo",
          "Gudu",
          "Gwadabawa",
          "Illela",
          "Isa",
          "Kebbe",
          "Kware",
          "Rabah",
          "Sabon Birni",
          "Shagari",
          "Silame",
          "Sokoto North",
          "Sokoto South",
          "Tambuwal",
          "Tangaza",
          "Tureta",
          "Wamako",
          "Wurno",
          "Yabo",
        ],
      },
      {
        name: "Taraba ",
        cities: [
          "Ardo Kola",
          "Bali",
          "Donga",
          "Gashaka",
          "Gassol",
          "Ibi",
          "Jalingo",
          "Karim Lamido",
          "Kumi",
          "Lau",
          "Sardauna",
          "Takum",
          "Ussa",
          "Wukari",
          "Yorro",
          "Zing",
        ],
      },
      {
        name: "Yobe ",
        cities: [
          "Bade",
          "Bursari",
          "Damaturu",
          "Fika",
          "Fune",
          "Geidam",
          "Gujba",
          "Gulani",
          "Jakusko",
          "Karasuwa",
          "Machina",
          "Nangere",
          "Nguru",
          "Potiskum",
          "Tarmuwa",
          "Yunusari",
          "Yusufari",
        ],
      },
      {
        name: "Zamfara ",
        cities: [
          "Anka",
          "Bakura",
          "Birnin Magaji Kiyaw",
          "Bukkuyum",
          "Bungudu",
          "Gummi",
          "Gusau",
          "Kaura Namoda",
          "Maradun",
          "Maru",
          "Shinkafi",
          "Talata Mafara",
          "Chafe",
          "Zurmi",
        ],
      },
    ],
  },
  {
    name: "North Korea",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "North Macedonia",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Norway",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Oman",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Pakistan",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Palau",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Palestine",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Panama",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Papua New Guinea",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Paraguay",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Peru",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Philippines",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },

  {
    name: "Poland",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Portugal",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Qatar",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Romania",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Russia",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Rwanda",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Saint Kitts and Nevis",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Saint Lucia",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Saint Vincent and the Grenadines",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Samoa",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "San Marino",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Sao Tome and Principe",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Saudi Arabia",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },

  {
    name: "Senegal",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Serbia",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Seychelles",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Sierra Leone",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Singapore",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Slovakia",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Slovenia",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Solomon Islands",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Somalia",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },

  {
    name: "South Africa",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "South Korea",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "South Sudan",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Spain",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Sri Lanka",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Sudan",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Suriname",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Sweden",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Switzerland",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Syria",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Taiwan",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },

  {
    name: "Tajikistan",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Tanzania",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Thailand",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Timor-Leste",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Togo",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Tonga",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Trinidad and Tobago",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Tunisia",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Turkey",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Turkmenistan",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Tuvalu",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Uganda",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Ukraine",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "United Arab Emirates (UAE)",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "United Kingdom (UK)",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "United States of America (USA)",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Uruguay",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Uzbekistan",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Vanuatu",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Vatican City (Holy See)",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Venezuela",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
  {
    name: "Vietnam",
    states: [
      {
        name: "Ontario",
        cities: ["Toronto", "Ottawa", "Hamilton"],
      },
      {
        name: "Quebec",
        cities: ["Montreal", "Quebec City", "Sherbrooke"],
      },
    ],
  },
];

export const countriesList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Democratic Republic of the",
  "Congo, Republic of the",
  "Costa Rica",
  "Cote d'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia (formerly Macedonia)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City (Holy See)",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export default function LinkParamImg() {
  const [defaultTheme, setDefaultTheme] = useState("#36A6A4");
  return (
    <div className="new_linkgenIconCont">
      <div
        className="new_linkphone-icon-s"
        style={{
          background: `${defaultTheme}`,
        }}
      ></div>
      <div className="new_linkchildIcon">
        <LinkIcon fill={defaultTheme} />
      </div>
    </div>
  );
}
