import React, { useState, useEffect } from "react";
import "./defaultInputR.css";

export const DefaultInputR = ({
  value,
  name,
  onChange,
  placeholder,
  spanText,
  type,
  required,
}) => {
  return (
    <div className="inputFieldDefaultR">
      <input
        type={type}
        value={value}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
      />
      <span> {spanText}</span>
    </div>
  );
};

export const DefaultSubmitBtnR = ({
  disabled,
  valueText,
  styleList,
  isValidUrl,
  defaultColor,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div className="default-save-formR" style={styleList}>
      <input
        type="submit"
        disabled={disabled}
        // disabled={disable}
        value={valueText}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        style={{
          transition: "0.5s ease-in-out 0s;",
          // background: hover ? "#248381" : "",
          opacity: disabled ? "0.3" : "1",
          cursor: disabled ? "not-allowed" : "pointer",
          background: defaultColor === "" ? `#36A6A4` : `${defaultColor}`,
        }}
      />
    </div>
  );
};
