import React, { useState } from "react";
import "./cropper.css";

import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";

import { generateDownload } from "../../utils/cropImage";
import { getCroppedImg } from "../../utils/cropImage";
import { dataURLtoFile } from "../../utils/dataURLtoFile";
import { BiX } from "react-icons/bi";
import { motion } from "framer-motion";
import FailureAlert from "../screens/failureAlert/FailureAlert";

export const RenderCropper = ({ handleCropper, setAvatar, changeImgFunc }) => {
  const inputRef = React.useRef();

  const triggerFileSelectPopup = () => inputRef.current.click();

  const [image, setImage] = React.useState(null);
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [onErrorProfileImage, setOnErrorProfileImage] = useState(false);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      const fileSize = file.size / 1024 / 1024; // Convert to MB
      if (fileSize > 2) {
        setOnErrorProfileImage(true);

        setTimeout(() => {
          setOnErrorProfileImage(false);
        }, 4000);
      } else {
        // Upload the file
        const reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]);
        reader.addEventListener("load", () => {
          setImage(reader.result);
        });
      }

      // setAvatar({
      //   blob: file,
      //   previewUrl: URL.createObjectURL(file),
      // });
    }
  };

  const onDownload = () => {
    generateDownload(image, croppedArea);
  };

  const onUpload = async () => {
    // const canvas = await getCroppedImg(image, croppedArea);
    // const canvasDataUrl = canvas.toDataURL("image/jpeg");
    // const convertedUrlToFile = dataURLtoFile(canvasDataUrl, "cropped-img.jpeg");
    // if (!crop || !imageSrc) {
    //   return;
    // }

    const canvas = await getCroppedImg(image, croppedArea);

    canvas.toBlob(
      (blob) => {
        console.log(blob?.name, "blobbbb");
        const previewUrl = window.URL.createObjectURL(blob);
        console.log(previewUrl, "----");

        //  const anchor = document.createElement("a");
        //  anchor.download = "image.jpeg";
        //  anchor.href = URL.createObjectURL(blob);
        //  anchor.click();

        //  window.URL.revokeObjectURL(previewUrl);
        // setAvatar(blob);

        setAvatar({
          blob,
          previewUrl,
        });
        // changeImgFunc(previewUrl);
      },
      "image/jpeg",
      0.66
    );
  };

  return (
    <div className="backgroundGenFamCropper">
      <div
        className="container"
        // initial={{ x: "-70%", opacity: 0, scale: 1 }}
        // animate={{ x: 0, opacity: 1, scale: 1 }}
        // transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        <Button
          onClick={handleCropper}
          style={{
            fontSize: "40px",
            marginLeft: "auto",
            marginRight: "auto",
            color: "#f2f2f2",
          }}
        >
          <BiX />
        </Button>

        <div className="container-cropper">
          {image ? (
            <>
              <div className="cropper">
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={1.0}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                  zoomWithScroll={true}
                  showGrid={true}
                  zoomSpeed={4}
                  maxZoom={3}
                />
              </div>

              <div className="slider">
                <Slider
                  min={1}
                  max={3}
                  step={0.1}
                  value={zoom}
                  onChange={(e, zoom) => setZoom(zoom)}
                />
              </div>
            </>
          ) : null}
        </div>

        <div className="container-buttons">
          <input
            type="file"
            accept="image/*"
            ref={inputRef}
            onChange={onSelectFile}
            style={{ display: "none" }}
          />
          {/* <Button
          onClick={() => setImage(null)}
          variant="contained"
          color="primary"
          style={{ marginRight: "10px" }}
        >
          Clear
        </Button> */}
          <Button
            variant="contained"
            color="primary"
            onClick={triggerFileSelectPopup}
            style={{
              backgroundColor: "#36a6a4",
              color: "#ffffff",
              marginRight: "10px",
              borderRadius: "10px",
              textTransform: "capitalize",
              width: "fitContent",
              height: "40px",
              fontSize: "12px",
            }}
          >
            Select Image
          </Button>

          {image === null ? (
            ""
          ) : (
            <Button
              variant="contained"
              style={{
                backgroundColor: "#36a6a4",
                color: "#ffffff",
                marginRight: "10px",
                borderRadius: "10px",
                textTransform: "capitalize",
                width: "fitContent",
                height: "40px",
                fontSize: "12px",
              }}
              onClick={() => {
                onUpload();
                handleCropper();
              }}
            >
              Use Image
            </Button>
          )}
          <Button
            variant="contained"
            color="secondary"
            style={{
              marginRight: "10px",
              backgroundColor: "#3c485e",
              color: "#ffffff",
              borderRadius: "10px",
              textTransform: "capitalize",
              width: "fitContent",
              height: "40px",
              fontSize: "12px",
            }}
            onClick={handleCropper}
          >
            Cancel
          </Button>
        </div>
      </div>

      {onErrorProfileImage && (
        <FailureAlert alertMessage="File size must be less than 2MB" />
      )}
    </div>
  );
};
