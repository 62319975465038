import React from "react";
import "./login.css";
import deetsBlackLogo from "../../../assets/icons/deetsBlackLogo.svg";

const Header = () => {
  return (
    <div className="logout-info-header">
      <div className="logout-info-text">
        <img src={deetsBlackLogo} alt="deets_logo" />
      </div>
    </div>
  );
};

export default Header;
