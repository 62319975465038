import React from "react";

const LogoutIcon = ({ fill }) => {
  return (
    <>
      <svg
        width="20"
        height="17"
        viewBox="0 0 20 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.54401 6.03165C4.83763 5.73949 4.83881 5.26462 4.54665 4.97099C4.25449 4.67737 3.77962 4.67619 3.48599 4.96835L4.54401 6.03165ZM0.470994 7.96835C0.177372 8.26051 0.176188 8.73538 0.468349 9.02901C0.760511 9.32263 1.23538 9.32381 1.52901 9.03165L0.470994 7.96835ZM1.52901 7.96835C1.23538 7.67619 0.760511 7.67737 0.468349 7.97099C0.176188 8.26462 0.177372 8.73949 0.470994 9.03165L1.52901 7.96835ZM3.48599 12.0317C3.77962 12.3238 4.25449 12.3226 4.54665 12.029C4.83881 11.7354 4.83763 11.2605 4.54401 10.9683L3.48599 12.0317ZM1 7.75C0.585787 7.75 0.25 8.08579 0.25 8.5C0.25 8.91421 0.585787 9.25 1 9.25V7.75ZM15 9.25C15.4142 9.25 15.75 8.91421 15.75 8.5C15.75 8.08579 15.4142 7.75 15 7.75V9.25ZM3.48599 4.96835L0.470994 7.96835L1.52901 9.03165L4.54401 6.03165L3.48599 4.96835ZM0.470994 9.03165L3.48599 12.0317L4.54401 10.9683L1.52901 7.96835L0.470994 9.03165ZM1 9.25L15 9.25V7.75L1 7.75V9.25Z"
          fill={fill}
        />
        <path
          d="M7 11.5C7 13.7091 8.79086 15.5 11 15.5H15C17.2091 15.5 19 13.7091 19 11.5V5.5C19 3.29086 17.2091 1.5 15 1.5H11C8.79086 1.5 7 3.29086 7 5.5"
          stroke={fill}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default LogoutIcon;
