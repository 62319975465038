import React from "react";
import "./failureAlert.css";

const FailureAlert = ({ alertMessage, alertIcon }) => {
  return (
    <div className="totalResBodyErrorAlert">
      <div className="errorBodyGen">
        <div>{alertMessage}</div>
      </div>
    </div>
  );
};

export default FailureAlert;
