import React from "react";
import "./statsCont.css";
import statsPoint from "../../assets/icons/statsPoint.svg";
import CounterIcon from "../../assets/icons/CounterIcon";

const StatsCont = ({ figures, labelName, fill }) => {
  const beforeStyle = {
    content: "",
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    zIndex: "-1",
    margin: "-1px",
    borderRadius: "inherit",
    background: `linear-gradient(to bottom,  ${
      fill === "" ? `#36A6A4` : `${fill}`
    }, transparent)  100%`,
  };
  return (
    <>
      <div
        style={
          {
            // border: `1px solid ${fill === "" ? `#36A6A4` : `${fill}`}`,
            // border-image: linear-gradient(to bottom, red, rgba(0, 0, 0, 0)) 1 100%;
            // borderImage: `linear-gradient(to bottom,  ${
            //   fill === "" ? `#36A6A4` : `${fill}`
            // }, rgba(0, 0, 0, 0)) 1 100%`,
            //  background: linear-gradient(to bottom, red, transparent);
            // background: `linear-gradient(to bottom,  ${
            //   fill === "" ? `#36A6A4` : `${fill}`
            // }, transparent)  100%`,
          }
        }
        className="stats-each-cont"
      >
        <div style={beforeStyle}></div>
        <div className="statsnumbCont">
          {/* <div className="statsImg">
            <CounterIcon fill={fill} />
          </div> */}
          <div className="stats-number">{figures}</div>
        </div>
        <div className="stats-label">{labelName}</div>
      </div>
    </>
  );
};

export default StatsCont;
