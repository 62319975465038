import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import ProfileInfoTemp from "./components/teamScreen/selectTeamTemplateScreens/profileInfoTemp/ProfileInfoTemp";
import TeamCardsPersonalInfo from "./components/teamScreen/teamCardsPersonalInfo/TeamCardsPersonalInfo";
import {
  LoginPage,
  ForgotPasswordPage,
  HomePage,
  BackdropPage,
  PersonalInfoPage,
  ContactInfoPage,
  SocialConnectPage,
  VideoLinkPage,
  EditLinkPage,
  ViewConnectPage,
  ShareLinkPage,
  SupportPage,
  ChangePasswordPage,
  TeamAdminHomePage,
  TeamChangePasswordPage,
  TeamPersonalPage,
  TeamContactPage,
  TeamSocialPage,
  TeamVideoPage,
  TeamLinksPage,
  TeamSupportPage,
  ActiveTemplatePage,
  CreateTemplatePage,
  ProfileInfoTempPage,
  ContactInfoTempPage,
  SocialConnectTempPage,
  VideoLinkTempPage,
  LinksTempPage,
  TeamCardsMembersPage,
  TeamCardsPersonalInfoPage,
  TeamCardsViewPersonalPage,
  MembersAdminPage,
  MembersLinksPage,
  MembersPersonalPage,
  MembersSocialPage,
  MembersContactPage,
  MembersVideoPage,
  TeamConnectionsPage,
  AddManualConnectionPage,
  MembersClickedInfoPage,
  MembersSendInvitePage,
  ProfileImgPage,
  FeedBackScreenPage,
  SignUpContPage,
  PreAccountPageCont,
  ContactOdPage,
  ForgotPassOtpPage,
  InputPassPage,
  TeamHomePage,
  createTeamTempPage,
  SingleTeamTempPage,
  EditTempNamePage,
  TempCompanyInfoPage,
  TempCustomizePage,
  TempSocialConnectPage,
  TempVideoLinkPage,
  TempLinksPage,
  InviteMemberPage,
} from "./pages";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/signup" component={SignUpContPage} />
        <Route exact path="/forgot_password" component={ForgotPasswordPage} />
        <Route exact path="/" component={HomePage} />
        <Route exact path="/profile_img" component={ProfileImgPage} />
        <Route exact path="/feedback" component={FeedBackScreenPage} />

        <Route exact path="/backdrop" component={BackdropPage} />
        <Route exact path="/personal_info" component={PersonalInfoPage} />
        <Route exact path="/contact_info" component={ContactInfoPage} />
        <Route exact path="/contact_infod" component={ContactOdPage} />

        <Route exact path="/social_info" component={SocialConnectPage} />
        <Route exact path="/video_link" component={VideoLinkPage} />
        <Route exact path="/link" component={EditLinkPage} />
        <Route exact path="/view_connect" component={ViewConnectPage} />
        <Route exact path="/share_link" component={ShareLinkPage} />
        <Route exact path="/support" component={SupportPage} />
        <Route exact path="/change_password" component={ChangePasswordPage} />

        <Route exact path="/forgotpassword/otp" component={ForgotPassOtpPage} />
        <Route exact path="/inputpassword" component={InputPassPage} />
        <Route exact path="/team" component={TeamHomePage} />
        <Route
          exact
          path="/team/createtemplate"
          component={createTeamTempPage}
        />
        <Route
          exact
          path="/team/teamplate_page"
          component={SingleTeamTempPage}
        />

        <Route
          exact
          path="/team/edit_teamplate_name"
          component={EditTempNamePage}
        />

        <Route
          exact
          path="/team/temp_companyinfo"
          component={TempCompanyInfoPage}
        />

        <Route
          exact
          path="/team/temp_customize"
          component={TempCustomizePage}
        />

        <Route
          exact
          path="/team/temp_socialpage"
          component={TempSocialConnectPage}
        />

        <Route
          exact
          path="/team/temp_videolink"
          component={TempVideoLinkPage}
        />

        <Route exact path="/team/temp_links" component={TempLinksPage} />

        <Route exact path="/team/invite_member" component={InviteMemberPage} />

        {/* <Route exact path="/team_admin" component={TeamAdminHomePage} />
        <Route
          exact
          path="/team_change_password"
          component={TeamChangePasswordPage}
        />
        <Route exact path="/team_personal" component={TeamPersonalPage} />
        <Route exact path="/team_contact" component={TeamContactPage} />
        <Route exact path="/team_social" component={TeamSocialPage} />
        <Route exact path="/team_video" component={TeamVideoPage} />
        <Route exact path="/team_links" component={TeamLinksPage} />
        <Route exact path="/team_support" component={TeamSupportPage} />
        <Route exact path="/active_template" component={ActiveTemplatePage} />
        <Route exact path="/create_template" component={CreateTemplatePage} />
        <Route exact path="/profile_template" component={ProfileInfoTempPage} />
        <Route exact path="/contact_template" component={ContactInfoTempPage} />
        <Route
          exact
          path="/social_template"
          component={SocialConnectTempPage}
        />
        <Route
          exact
          path="/video_link_template"
          component={VideoLinkTempPage}
        />
        <Route exact path="/links_template" component={LinksTempPage} />
        <Route
          exact
          path="/team_cards_members"
          component={TeamCardsMembersPage}
        />

        <Route
          exact
          path="/team_cards_personal"
          component={TeamCardsPersonalInfoPage}
        />
        <Route
          exact
          path="/team_cards_view"
          component={TeamCardsViewPersonalPage}
        />
        <Route exact path="/team_connections" component={TeamConnectionsPage} />
        <Route exact path="/members_admin" component={MembersAdminPage} />
        <Route exact path="/members_links" component={MembersLinksPage} />
        <Route exact path="/members_personal" component={MembersPersonalPage} />
        <Route exact path="/members_social" component={MembersSocialPage} />

        <Route exact path="/members_contact" component={MembersContactPage} />
        <Route exact path="/members_video" component={MembersVideoPage} />

        <Route
          exact
          path="/addmanual_connections"
          component={AddManualConnectionPage}
        />

        <Route
          exact
          path="/members_clickedInfo"
          component={MembersClickedInfoPage}
        />

        <Route
          exact
          path="/members_sendInvite"
          component={MembersSendInvitePage}
        /> */}

        <Route exact path="/init" component={PreAccountPageCont} />
      </Switch>
    </Router>
  );
}
