import React from "react";
import "./sectionsHeader.css";
import backArrow from "../../assets/icons/backArrow.svg";
import { useHistory } from "react-router-dom";

const SectionsHeader = ({ sectionName, handleGoBack }) => {
  const history = useHistory();

  return (
    <>
      <div className="sectional-info-header">
        <div className="arrowImg-cont" onClick={handleGoBack}>
          <img src={backArrow} alt="back_arrow" />
        </div>

        <div className="sectional-info-text"> {sectionName}</div>
        <div></div>
      </div>
    </>
  );
};

export default SectionsHeader;
