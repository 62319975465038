import React, { useState, useEffect } from "react";
import "./editLinkThumbNail.css";
import cameraIcon from "../../../../assets/icons/cameraIcon.svg";
import cancelProfImg from "../../../../assets/icons/cancelProfImg.svg";
import { RenderCropper } from "../../../cropper/cropper";
import SectionHeaderCancel from "../../../../genComponents/sectionHeaderCancel/SectionHeaderCancel";
import WordsBullet from "../../../../genComponents/wordsBullet/WordsBullet";
import SuccessPage from "../../successScreen/SuccessPage";

const EditLinkThumbNail = ({
  closeThumbModal,
  thumbImg,
  setThumbImg,
  changeImgFunc,
}) => {
  const [open, setOpen] = React.useState(false);

  const [showCropper, setShowCropper] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleCropper = () => setShowCropper((prevValue) => !prevValue);

  let profileRef;

  const handleProfImgCancel = () => {
    setThumbImg(null);
  };

  const saveToStorage = () => {
    let thumbAssets = {
      image: thumbImg,
    };
    console.log(thumbAssets);
    let stf = JSON.stringify(thumbAssets);
    console.log(stf);
    localStorage.setItem("thumbAssets", stf);
  };

  return (
    <div className="thumbLinkModal-genBody">
      <div className="thumbLinkModal-Content">
        <div className="thumbNailHeader-CanFam">
          <div className="thumbNailHeader-CanFam-btn" onClick={closeThumbModal}>
            Cancel
          </div>

          <div></div>
        </div>

        <div className="thumb-imgFrame">
          <div className="thumb-uploadedImgText">Image preview</div>

          {thumbImg !== null && (
            <div className="thumb-uploadedImgPre">
              <img src={thumbImg} alt="profile_img" />
            </div>
          )}
          {thumbImg === null ? (
            <div
              className="thumb-imgUploadBtn"
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? "composition-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(event) => {
                handleCropper();
                handleClose(event);
              }}
            >
              <img src={cameraIcon} alt="up_icon" />
              {/* <input
                type="file"
                name="profileImage"
                ref={(refParam) => (profileRef = refParam)}
                onChange={(e) => {
                  setProImg(URL.createObjectURL(e.target.files[0]));
                }}
              /> */}
            </div>
          ) : (
            <div
              className="cancelthumb_profImgSec"
              onClick={handleProfImgCancel}
            >
              <img src={cancelProfImg} alt="cancel_Img" />
            </div>
          )}
        </div>
        <div className="thumbImg-btnFamGen">
          {thumbImg === null ? (
            <div
              className="selectProfThumb-ImgBtn"
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? "composition-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(event) => {
                handleCropper();
                handleClose(event);
              }}
            >
              Select image
              {/* <input
                type="file"
                name="profileImage"
                ref={(refParam) => (profileRef = refParam)}
                onChange={(e) => {
                  setProImg(URL.createObjectURL(e.target.files[0]));
                }}
              /> */}
            </div>
          ) : (
            <div className="uploadThumbNail-BtnFam">
              <div
                className="changeThumbNail-btn"
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(event) => {
                  handleCropper();
                  handleClose(event);
                }}
              >
                Change photo{" "}
                {/* <input
                  type="file"
                  name="profileImage"
                  ref={(refParam) => (profileRef = refParam)}
                  onChange={(e) => {
                    setProImg(URL.createObjectURL(e.target.files[0]));
                  }}
                /> */}
              </div>
              <div
                onClick={() => {
                  //   saveToStorage();
                  closeThumbModal();
                }}
                className="uploadThumbNaill-mainBtn"
              >
                Use this photo
              </div>
            </div>
          )}
        </div>

        <div style={{ marginLeft: "30px", marginTop: "150px" }}>
          <WordsBullet bulletText="You can select and upload an image" />

          <WordsBullet bulletText="You can remove the image and it returns to default initials" />

          <WordsBullet bulletText="You can always change an image to another " />
        </div>

        {showCropper && (
          <RenderCropper
            handleCropper={handleCropper}
            setAvatar={setThumbImg}
            changeImgFunc={changeImgFunc}
          />
        )}
      </div>
    </div>
  );
};

export default EditLinkThumbNail;
