import React from "react";

const FeedBackBullet = ({ fill }) => {
  return (
    <>
      <svg
        width="13"
        height="15"
        viewBox="0 0 13 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.70193 6.84178L5.51079 4.58521L4.31886 6.84178C4.23452 7.0068 4.11261 7.14971 3.96293 7.25899C3.81234 7.36884 3.63833 7.44226 3.45457 7.47349L1 7.95278L2.71364 9.99563C2.82924 10.1218 2.91633 10.2713 2.969 10.4341C3.02125 10.5965 3.0376 10.7683 3.01693 10.9377L2.69086 13.5714L4.95136 12.4824C5.12604 12.399 5.3172 12.3558 5.51079 12.3559C5.69255 12.3558 5.87171 12.3991 6.03329 12.4824L8.34486 13.5038L8.018 10.9C7.99733 10.7306 8.01368 10.5588 8.06593 10.3963C8.1186 10.2336 8.20569 10.084 8.32129 9.95792L10.0208 7.94963L7.56621 7.47035C7.38246 7.43912 7.20844 7.36569 7.05786 7.25585C6.90856 7.14746 6.78667 7.00566 6.70193 6.84178Z"
          fill={fill}
          stroke={fill}
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.9674 1.8792C12.2022 1.64533 12.2029 1.26543 11.969 1.03068C11.7352 0.795923 11.3553 0.795212 11.1205 1.02909L11.9674 1.8792ZM8.38701 3.75237C8.15226 3.98625 8.15154 4.36615 8.38542 4.6009C8.6193 4.83565 8.99919 4.83636 9.23395 4.60249L8.38701 3.75237ZM12.4232 4.14842C12.6581 3.91461 12.6589 3.53471 12.4251 3.29989C12.1913 3.06507 11.8114 3.06425 11.5765 3.29806L12.4232 4.14842ZM9.75369 5.11306C9.51887 5.34687 9.51805 5.72677 9.75186 5.96159C9.98567 6.19641 10.3656 6.19723 10.6004 5.96342L9.75369 5.11306ZM9.68937 1.42518C9.92419 1.19137 9.92501 0.811473 9.6912 0.576653C9.4574 0.341833 9.0775 0.341013 8.84268 0.574821L9.68937 1.42518ZM7.01904 2.39061C6.78422 2.62441 6.7834 3.00431 7.0172 3.23913C7.25101 3.47395 7.63091 3.47477 7.86573 3.24096L7.01904 2.39061ZM11.1205 1.02909L8.38701 3.75237L9.23395 4.60249L11.9674 1.8792L11.1205 1.02909ZM11.5765 3.29806L9.75369 5.11306L10.6004 5.96342L12.4232 4.14842L11.5765 3.29806ZM8.84268 0.574821L7.01904 2.39061L7.86573 3.24096L9.68937 1.42518L8.84268 0.574821Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default FeedBackBullet;
