import React, { useState } from "react";
import "./personalInfo.css";
import backArrow from "../../../assets/icons/backArrow.png";
import cancelSelect from "../../../assets/icons/cancelSelect.svg";
import unSelectIcon from "../../../assets/icons/unSelectIcon.svg";
import selectAncor from "../../../assets/icons/selectAncor.svg";
import anchorDefault from "../../../assets/icons/anchorDefault.svg";

import selectIcon from "../../../assets/icons/selectIcon.svg";

import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import SuccessPage from "../successScreen/SuccessPage";
import SectionsHeader from "../../../genComponents/sectionsHeader/SectionsHeader";
import {
  DefaultHalfInput,
  DefaultInput,
  DefaultSubmitBtn,
} from "../../../genComponents/defaultInput/DefaultInput";
import DefaultTextArea from "../../../genComponents/defaultTextArea/DefaultTextArea";
import { Link, useLocation, useParams } from "react-router-dom";
import { axiosCalls } from "../../../_api";
import { showLoader, hideLoader } from "../../../loader";
import SuccessAlert from "../successAlert/SuccessAlert";
import FailureAlert from "../failureAlert/FailureAlert";
import DynamicCancel from "../../../genComponents/dynamicCancel/DynamicCancel";
import TickIcon from "../../../assets/icons/TickIcon";

const PersonalInfo = () => {
  const [user, setUser] = useState();

  const [profileName, setProfileName] = useState("fionaokpara");

  const [loading, userLoading] = useState(true);
  const [fetchError, userFetchError] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [preSelect, setPreSelect] = useState(0);

  const [disable, setDisable] = React.useState(false);
  const [onSuccessPersonalPage, setOnSuccessPersonalPage] = useState(false);
  const [updateAboutPersonalInfos, setUpdateAboutPersonalInfos] = useState("");
  const [onErrorPage, setOnErrorPage] = useState(false);
  const [defaultColor, setDefaultColor] = useState("");

  useEffect(() => {
    // setID(localStorage.getItem("ID"));

    setInputValue({
      firstName: localStorage.getItem("First Name"),
      middleName: localStorage.getItem("Middle Name"),
      about: localStorage.getItem("About"),
      surName: localStorage.getItem("Last Name"),
      companyName: localStorage.getItem("Company Name"),
      positionName: localStorage.getItem("Job Title"),
      deetProfLink: localStorage.getItem("Profile Code"),
      suffix: localStorage.getItem("Suffix Name"),
    });

    console.log(typeof setInputValue?.companyName, "type of");

    setSelectedOption(localStorage.getItem("Industry"));
    setDefaultColor(localStorage.getItem("Default Theme"));
  }, []);

  useEffect(() => {
    getDefaultOption();
  }, [selectedOption]);

  const [inputValue, setInputValue] = useState({
    firstName: "",
    middleName: "",
    about: "",
    surName: "",
    suffix: "",
    companyName: "",
    positionName: "",
    deetProfLink: "",
  });
  const {
    firstName,
    middleName,
    about,
    surName,
    suffix,
    companyName,
    positionName,
    deetProfLink,
  } = inputValue;

  const history = useHistory();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const getDefaultOption = () => {
    console.log(selectedOption, "rrrrrrr");
    console.log(preSelect, "dddddd");
    if (selectedOption === "") {
      setPreSelect(1);
    } else if (selectedOption === "Accounting") {
      setPreSelect(2);
    } else if (selectedOption === "Agriculture") {
      setPreSelect(3);
    } else if (selectedOption === "Arts") {
      setPreSelect(4);
    } else if (selectedOption === "Auto") {
      setPreSelect(5);
    } else if (selectedOption === "B2B Services") {
      setPreSelect(6);
    } else if (selectedOption === "Construction") {
      setPreSelect(7);
    } else if (selectedOption === "Healthcare") {
      setPreSelect(8);
    } else if (selectedOption === "Home Services") {
      setPreSelect(9);
    } else if (selectedOption === "Hospitality") {
      setPreSelect(10);
    } else if (selectedOption === "Energy") {
      setPreSelect(11);
    } else if (selectedOption === "Oil and Gas") {
      setPreSelect(12);
    } else if (selectedOption === "Legal") {
      setPreSelect(13);
    }
  };

  const postProfileData = async (e) => {
    e.preventDefault();

    const data = {
      page: "about",
      first_name: firstName,
      middle_name: middleName,
      about: about,
      last_name: surName,
      name_suffix: suffix,
      company_name: companyName,
      job_title: positionName,
      industry: selectedOption,
      profile_code: deetProfLink,
    };
    showLoader();
    const res = await axiosCalls(`admin/profile`, "POST", data);

    if (res) {
      hideLoader();
      console.log(res?.data);
      if (res?.er) {
        console.log(res?.data?.er);
        setOnErrorPage(true);
        setTimeout(() => {
          setOnErrorPage(false);
        }, 3000);
        return;
      }
      // window.location.href = "/";

      setOnSuccessPersonalPage(true);

      setTimeout(() => {
        setOnSuccessPersonalPage(false);
        window.location.href = "/";
      }, 2000);
    }
  };

  const toggling = () => setIsOpen(!isOpen);
  const closeSelectToggle = () => {
    setIsOpen(false);
  };

  const handleChildClick = (event) => {
    // 👇️ stop event propagation (won't trigger parent's onClick)
    event.stopPropagation();

    // console.log("child clicked");
  };

  const onSelectedClicked = () => {
    console.log(selectedOption);
  };

  const onSelectedClickedOne = (e) => {
    // preSelect === 1
    //   ? setSelectedOption("Accounting")
    //   : preSelect === 2
    //   ? setSelectedOption("Agriculture")
    //   : null;

    // event.stopPropagation();

    // setSelectedOption("Accounting");

    console.log("selectedOption");
    // if (preSelect === 1) {
    //   return setSelectedOption("Accounting");
    // } else if (preSelect === 2) {
    //   return setSelectedOption("Agriculture");
    // } else if (preSelect === 3) {
    //   return setSelectedOption("Arts");
    // } else if (preSelect === 4) {
    //   return setSelectedOption("Auto");
    // } else if (preSelect === 5) {
    //   return setSelectedOption("B2B Services");
    // } else if (preSelect === 6) {
    //   return setSelectedOption("Construction");
    // } else if (preSelect === 7) {
    //   return setSelectedOption("Healthcare");
    // } else if (preSelect === 8) {
    //   return setSelectedOption("Home Services");
    // } else if (preSelect === 9) {
    //   return setSelectedOption("Hospitality");
    // } else {
    //   return null;
    // }

    // e.persist();

    // setSelectedOption("Accounting");
  };

  const onSelectedClickedTwo = (e) => {
    setSelectedOption("Agricuture");
    console.log(selectedOption);
  };

  // const onOptionClicked = (value) => {
  //   setSelectedOption(value);
  //   setIsOpen(false);
  //   console.log(selectedOption);
  // };

  // const [about, setAbout] = useState("");
  // const [firstName, setFirstName] = useState("");
  // const [surName, setSurName] = useState("");

  // const [user, setUser] = useState("");

  // const [updatePersonalInfos, setUpdatePersonalInfos] = useState([
  //   {
  //     id: 1,
  //     firstName: "",
  //     middleName: "",
  //     jobTitle: "",
  //     industry: "",
  //     about: "",
  //     linkSuffix: "",
  //   },
  // ]);

  // const addAbout = (updatePersonalInfo) => {
  //   const id = Math.floor(Math.random() * 10000) + 1;
  //   const newAbout = { id, ...updatePersonalInfo };
  //   setUpdatePersonalInfos([newAbout]);
  // };

  // const saveToStorage = () => {
  //   console.log(updateAboutPersonalInfos);

  //   let assets = {
  //     about,
  //   };
  //   console.log(assets);
  //   let st = JSON.stringify(assets);
  //   console.log(st);
  //   localStorage.setItem("assets", st);
  // };

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   saveToStorage();
  //   setOnSuccessPersonalPage(true);
  // };

  // const handleChange = (e) => {
  //   const { firstName, value } = e.target;
  //   setInputValue((prev) => ({
  //     ...prev,
  //     [firstName]: value,
  //   }));
  //   console.log(inputValue);

  // };

  // useEffect(() => {
  //   submitStatus();
  // }, []);

  // const submitStatus = () => {
  //   firstName || surName === "" ? setDisable(true) : setDisable(false);
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log(inputValue);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className="totalResPersonalBody">
      <div className="personal-info-family">
        <SectionsHeader
          sectionName="Personal info"
          handleGoBack={handleGoBack}
        />
        <form className="personal-form-cont" onSubmit={postProfileData}>
          <DefaultInput
            type="text"
            value={firstName}
            name="firstName"
            onChange={handleChange}
            placeholder=" "
            spanText="Enter your first name"
          />

          <DefaultInput
            type="text"
            value={middleName}
            name="middleName"
            onChange={handleChange}
            placeholder=" "
            spanText="Middle Name (initials)"
          />

          <DefaultInput
            type="text"
            value={surName}
            name="surName"
            onChange={handleChange}
            placeholder=" "
            spanText="Surname"
          />
          <DefaultInput
            type="text"
            value={suffix}
            name="suffix"
            onChange={handleChange}
            placeholder=" "
            spanText="Suffix (e.g PhD, MD)"
          />
          <DefaultInput
            type="text"
            value={companyName ?? ""}
            name="companyName"
            onChange={handleChange}
            placeholder=" "
            spanText="Company Name"
          />

          <DefaultInput
            type="text"
            value={positionName}
            name="positionName"
            onChange={handleChange}
            placeholder=" "
            spanText="Job title/position"
          />

          <div>
            {/* <div onClick={toggling}>{selectedOption || "Mangoes"}</div> */}

            <div onClick={toggling} className="custom-selectBtn">
              {selectedOption === "" ? null : (
                <div className="personal-span-placeHolder">
                  {" "}
                  Industry/category of work
                </div>
              )}

              {selectedOption === "" ? (
                <div className="custom-selectBtnText">
                  Industry/category of work{" "}
                </div>
              ) : (
                <div
                  className="custom-selectBtnText"
                  style={{ marginTop: selectedOption !== "" ? "15px" : "" }}
                >
                  {selectedOption}
                </div>
              )}
              <div
                className="custom-selectAncor"
                style={{ marginTop: selectedOption !== "" ? "15px" : "" }}
              >
                <img src={anchorDefault} alt="select-icn" />
              </div>
            </div>

            {isOpen && (
              <div className="select-modal-body" onClick={closeSelectToggle}>
                {/* <div>
                  {options.map((option) => (
                    <div
                      onClick={onOptionClicked(option)}
                      key={Math.random()}
                    >
                      {option}
                    </div>
                  ))}
                </div> */}
                <div
                  // className="select-modalBody-cont "
                  className={`select-modalBody-cont  ${
                    isOpen ? "open_selectModalBody" : "close_selectModalBody"
                  }`}
                  onClick={handleChildClick}
                >
                  <div className="select-modalHeader">
                    <div className="select-modalHeader-text">
                      Industry/category of work
                    </div>
                    <div
                      onClick={closeSelectToggle}
                      className="select-modalHeader-canBtn"
                    >
                      <DynamicCancel
                        defaultTheme={defaultColor}
                        // onClick={handleChildClick}
                      />

                      {/* <img src={cancelSelect} alt="cancel" /> */}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      // onSelectedClicked();
                      // onSelectedClickedOne();
                      setSelectedOption("");
                      setPreSelect(1);
                      closeSelectToggle();
                      console.log(selectedOption);
                      console.log(preSelect);
                    }}
                    className="select-modal-option"
                  >
                    <div className="select-modal-optionText"> None</div>
                    <div className="select-modal-optionIcon">
                      {preSelect === 1 ? (
                        // <TickIcon fill={defaultColor === "" ? `#36A6A4` : `${defaultColor}`}/>
                        <TickIcon
                          fill={
                            defaultColor === "" ? `#36A6A4` : `${defaultColor}`
                          }
                        />
                      ) : (
                        <img src={unSelectIcon} alt="unselect" />
                      )}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      // onSelectedClicked();
                      // onSelectedClickedOne();
                      setSelectedOption("Accounting");
                      setPreSelect(2);
                      closeSelectToggle();
                      console.log(selectedOption);
                      console.log(preSelect);
                    }}
                    className="select-modal-option"
                  >
                    <div className="select-modal-optionText"> Accounting</div>
                    <div className="select-modal-optionIcon">
                      {preSelect === 2 ? (
                        <TickIcon
                          fill={
                            defaultColor === "" ? `#36A6A4` : `${defaultColor}`
                          }
                        />
                      ) : (
                        <img src={unSelectIcon} alt="unselect" />
                      )}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setSelectedOption("Agriculture");
                      setPreSelect(3);
                      closeSelectToggle();
                    }}
                    className="select-modal-option"
                  >
                    <div className="select-modal-optionText"> Agriculture</div>
                    <div className="select-modal-optionIcon">
                      {preSelect === 3 ? (
                        <TickIcon
                          fill={
                            defaultColor === "" ? `#36A6A4` : `${defaultColor}`
                          }
                        />
                      ) : (
                        <img src={unSelectIcon} alt="unselect" />
                      )}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setSelectedOption("Arts");
                      setPreSelect(4);
                      closeSelectToggle();
                    }}
                    className="select-modal-option"
                  >
                    <div className="select-modal-optionText"> Arts</div>
                    <div className="select-modal-optionIcon">
                      {preSelect === 4 ? (
                        <TickIcon
                          fill={
                            defaultColor === "" ? `#36A6A4` : `${defaultColor}`
                          }
                        />
                      ) : (
                        <img src={unSelectIcon} alt="unselect" />
                      )}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setSelectedOption("Auto");
                      setPreSelect(5);
                      closeSelectToggle();
                    }}
                    className="select-modal-option"
                  >
                    <div className="select-modal-optionText"> Auto</div>
                    <div className="select-modal-optionIcon">
                      {preSelect === 5 ? (
                        <TickIcon
                          fill={
                            defaultColor === "" ? `#36A6A4` : `${defaultColor}`
                          }
                        />
                      ) : (
                        <img src={unSelectIcon} alt="unselect" />
                      )}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setSelectedOption("B2B Services");
                      setPreSelect(6);
                      closeSelectToggle();
                    }}
                    className="select-modal-option"
                  >
                    <div className="select-modal-optionText"> B2B Services</div>
                    <div className="select-modal-optionIcon">
                      {preSelect === 6 ? (
                        <TickIcon
                          fill={
                            defaultColor === "" ? `#36A6A4` : `${defaultColor}`
                          }
                        />
                      ) : (
                        <img src={unSelectIcon} alt="unselect" />
                      )}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setSelectedOption("Construction");
                      setPreSelect(7);
                      closeSelectToggle();
                    }}
                    className="select-modal-option"
                  >
                    <div className="select-modal-optionText"> Construction</div>
                    <div className="select-modal-optionIcon">
                      {preSelect === 7 ? (
                        <TickIcon
                          fill={
                            defaultColor === "" ? `#36A6A4` : `${defaultColor}`
                          }
                        />
                      ) : (
                        <img src={unSelectIcon} alt="unselect" />
                      )}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setSelectedOption("Healthcare");
                      setPreSelect(8);
                      closeSelectToggle();
                    }}
                    className="select-modal-option"
                  >
                    <div className="select-modal-optionText"> Healthcare</div>
                    <div className="select-modal-optionIcon">
                      {preSelect === 8 ? (
                        <TickIcon
                          fill={
                            defaultColor === "" ? `#36A6A4` : `${defaultColor}`
                          }
                        />
                      ) : (
                        <img src={unSelectIcon} alt="unselect" />
                      )}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setSelectedOption("Home Services");
                      setPreSelect(9);
                      closeSelectToggle();
                    }}
                    className="select-modal-option"
                  >
                    <div className="select-modal-optionText">
                      {" "}
                      Home Services
                    </div>
                    <div className="select-modal-optionIcon">
                      {preSelect === 9 ? (
                        <TickIcon
                          fill={
                            defaultColor === "" ? `#36A6A4` : `${defaultColor}`
                          }
                        />
                      ) : (
                        <img src={unSelectIcon} alt="unselect" />
                      )}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setSelectedOption("Hospitality");
                      setPreSelect(10);
                      closeSelectToggle();
                    }}
                    className="select-modal-option"
                  >
                    <div className="select-modal-optionText"> Hospitality</div>
                    <div className="select-modal-optionIcon">
                      {preSelect === 10 ? (
                        <TickIcon
                          fill={
                            defaultColor === "" ? `#36A6A4` : `${defaultColor}`
                          }
                        />
                      ) : (
                        <img src={unSelectIcon} alt="unselect" />
                      )}
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      setSelectedOption("Energy");
                      setPreSelect(11);
                      closeSelectToggle();
                    }}
                    className="select-modal-option"
                  >
                    <div className="select-modal-optionText"> Energy</div>
                    <div className="select-modal-optionIcon">
                      {preSelect === 11 ? (
                        <TickIcon
                          fill={
                            defaultColor === "" ? `#36A6A4` : `${defaultColor}`
                          }
                        />
                      ) : (
                        <img src={unSelectIcon} alt="unselect" />
                      )}
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      setSelectedOption("Oil and Gas");
                      setPreSelect(12);
                      closeSelectToggle();
                    }}
                    className="select-modal-option"
                  >
                    <div className="select-modal-optionText"> Oil and Gas</div>
                    <div className="select-modal-optionIcon">
                      {preSelect === 12 ? (
                        <TickIcon
                          fill={
                            defaultColor === "" ? `#36A6A4` : `${defaultColor}`
                          }
                        />
                      ) : (
                        <img src={unSelectIcon} alt="unselect" />
                      )}
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      setSelectedOption("Legal");
                      setPreSelect(13);
                      closeSelectToggle();
                    }}
                    className="select-modal-option"
                  >
                    <div className="select-modal-optionText"> Legal</div>
                    <div className="select-modal-optionIcon">
                      {preSelect === 13 ? (
                        <TickIcon
                          fill={
                            defaultColor === "" ? `#36A6A4` : `${defaultColor}`
                          }
                        />
                      ) : (
                        <img src={unSelectIcon} alt="unselect" />
                      )}
                    </div>
                  </div>
                  {/* <div style={{ marginBottom: "100px" }}></div>
                  <div className="select-doneBtn" onClick={closeSelectToggle}>
                    Done
                  </div> */}
                </div>
              </div>
            )}
          </div>
          {/* 
          <div className="inputFieldPersonal">
            <select>
              <option>Industry/ category of work</option>
              <option value="">Accounting</option>
              <option value="">Agriculture</option>
              <option value="">Arts</option>
              <option value="">Auto</option>
              <option value="">B2B Services</option>
              <option value="">Construction</option>
              <option value="">Healthcare</option>
              <option value="">Home Services</option>
              <option value="">Hospitality</option>
              <option value="">Insurance</option>
              <option value="">Law</option>
              <option value="">Manufacturing</option>
              <option value="">Marketing</option>
              <option value="">Media</option>
              <option value="">Consulting</option>
              <option value="">Cosmetics</option>
              <option value="">E-Commerce</option>
              <option value="">Education</option>
              <option value="">Entertainment</option>
              <option value="">Finance</option>
              <option value="">Food</option>
              <option value="">Mining</option>
              <option value="">Real Estate</option>
              <option value="">Retail</option>
              <option value="">Science</option>
              <option value="">Utilities</option>
              <option value="">Wholesale Trade</option>
              <option value="">Tech</option>
            </select>
          </div> */}

          <DefaultTextArea
            value={about}
            name="about"
            onChange={handleChange}
            placeholder="About yourself"
          />

          <div className="profile-link-header">
            <div style={{ marginLeft: "5%" }}>Deets profile link</div>
          </div>
          <div className="edit-link-container">
            <div className="link-prefix">https://me.deets.digital</div>
            <div className="link-prefix-input">
              {/* <input type="text" placeholder="Enter nick/fullname" /> */}

              <DefaultHalfInput
                value={deetProfLink}
                name="deetProfLink"
                onChange={handleChange}
                placeholder=" "
                spanText="Enter nick/fullname"
              />
            </div>
          </div>

          {/* <div
            className="save-personal-form"
            style={{
              opacity: !firstName || !surName ? "0.3" : "1",

            }}
          >
            <input
              type="submit"
              disabled={!firstName || !surName}
          
              value="Save"
            />
          </div> */}
          <div
            style={{
              width: "90%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <DefaultSubmitBtn
              styleList={{
                opacity: !firstName || !surName ? "0.3" : "1",
              }}
              disabled={!firstName || !surName}
              valueText="Save"
              onClick={postProfileData}
              defaultColor={defaultColor}
            />
          </div>
        </form>
        {onSuccessPersonalPage && (
          // <SuccessPage notifyText="Personal Information Saved" />
          <SuccessAlert alertMessage="😄 Your personal info saved" />
        )}

        {onErrorPage && <FailureAlert alertMessage="Oops, an error occured!" />}
      </div>
    </div>
  );
};

export default PersonalInfo;
