import React from "react";
import "./login.css";
import { Link } from "react-router-dom";

const AuthFooter = ({ linkText, linkTo, linkNameText }) => {
  return (
    <div>
      <div className="signUpFam_line">
        <div className="signUp_init">{linkText}</div>
        <Link
          style={{ textDecoration: "none" }}
          to={linkTo}
          className="signUp_link"
        >
          {linkNameText}
        </Link>
      </div>
    </div>
  );
};

export default AuthFooter;
