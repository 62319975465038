import React from "react";

const WebsiteIcon = ({ fill }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1337_7505)">
        <path
          d="M5.58398 9.99995C5.5871 10.9235 5.70277 11.8433 5.92843 12.7388H9.52843V7.47217H5.87843C5.68638 8.30115 5.58762 9.14901 5.58398 9.99995Z"
          fill={fill}
        />
        <path
          d="M10.4727 7.47217V12.7388H14.0727C14.3077 11.8444 14.4327 10.9247 14.4449 9.99995C14.4431 9.14922 14.3462 8.30137 14.156 7.47217H10.4727Z"
          fill={fill}
        />
        <path
          d="M10.0002 1.11108C8.24217 1.11108 6.52359 1.63241 5.06182 2.60913C3.60005 3.58586 2.46074 4.97411 1.78796 6.59834C1.11518 8.22257 0.93915 10.0098 1.28213 11.7341C1.62511 13.4584 2.47169 15.0422 3.71483 16.2854C4.95796 17.5285 6.54181 18.3751 8.26609 18.7181C9.99036 19.061 11.7776 18.885 13.4019 18.2122C15.0261 17.5395 16.4143 16.4001 17.3911 14.9384C18.3678 13.4766 18.8891 11.758 18.8891 9.99997C18.8891 7.64249 17.9526 5.38157 16.2856 3.71458C14.6186 2.04759 12.3577 1.11108 10.0002 1.11108ZM16.7891 13.7278H14.778C14.3977 14.9182 13.8355 16.0426 13.1113 17.0611C12.5769 17.294 12.0178 17.4655 11.4447 17.5722C12.471 16.4569 13.261 15.1454 13.7669 13.7166H10.478V17.6889H9.53911V13.7278H6.23355C6.73878 15.1585 7.52876 16.472 8.55578 17.5889C7.99447 17.4833 7.44666 17.3156 6.92244 17.0889C6.19568 16.0619 5.63338 14.9279 5.25578 13.7278H3.21133C3.03386 13.404 2.87976 13.0679 2.75022 12.7222H4.97244C4.76422 11.8296 4.65614 10.9165 4.65022 9.99997C4.65053 9.15038 4.73991 8.30315 4.91689 7.4722H2.64467C2.7615 7.12747 2.90259 6.79144 3.06689 6.46664H5.18355C5.58072 5.14389 6.19665 3.897 7.00578 2.77775C7.51887 2.56515 8.05346 2.40868 8.60022 2.31108C7.49504 3.49722 6.66096 4.90947 6.15578 6.44997H9.53911V2.2222H10.478V6.46664H13.8613C13.3571 4.9286 12.5249 3.51836 11.4224 2.33331C11.9683 2.42386 12.5029 2.57286 13.0169 2.77775C13.8213 3.88892 14.4352 5.12618 14.8336 6.43886H16.928C17.0923 6.76367 17.2334 7.09969 17.3502 7.44442H15.0947C15.2755 8.28426 15.3667 9.14089 15.3669 9.99997C15.364 10.9219 15.2578 11.8406 15.0502 12.7389H17.2724C17.135 13.0795 16.9735 13.41 16.7891 13.7278Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1337_7505">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WebsiteIcon;
