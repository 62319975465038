import React from "react";

const CloseTeam = ({ fill }) => {
  return (
    <div>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_b_2322_10096)">
          <rect width="36" height="36" rx="18" fill={fill} fill-opacity="0.1" />
          <rect
            x="1"
            y="1"
            width="34"
            height="34"
            rx="17"
            stroke="#EDF0F0"
            stroke-width="2"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.2929 12.2929C12.6834 11.9024 13.3166 11.9024 13.7071 12.2929L18 16.5858L22.2929 12.2929C22.6834 11.9024 23.3166 11.9024 23.7071 12.2929C24.0976 12.6834 24.0976 13.3166 23.7071 13.7071L19.4142 18L23.7071 22.2929C24.0976 22.6834 24.0976 23.3166 23.7071 23.7071C23.3166 24.0976 22.6834 24.0976 22.2929 23.7071L18 19.4142L13.7071 23.7071C13.3166 24.0976 12.6834 24.0976 12.2929 23.7071C11.9024 23.3166 11.9024 22.6834 12.2929 22.2929L16.5858 18L12.2929 13.7071C11.9024 13.3166 11.9024 12.6834 12.2929 12.2929Z"
          fill="#9A9A9A"
        />
        <defs>
          <filter
            id="filter0_b_2322_10096"
            x="-3"
            y="-3"
            width="42"
            height="42"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.5" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_2322_10096"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_2322_10096"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default CloseTeam;
