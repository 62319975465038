import React from "react";
import "./shareTabCom.css";
import defaultForwardIcon from "../../assets/icons/defaultForwardIcon.svg";

const ShareTabCom = ({
  onClick,
  icon,
  shareName,
  shareDesc,
  borderColor,
  backgroundColor,
  iconBackground,
  altName,
}) => {
  return (
    <div
      className="default-BodyGen"
      onClick={onClick}
      style={{
        backgroundColor: `${backgroundColor}`,
        border: `2px solid ${borderColor}`,
      }}
    >
      <div
        className="default-share-icon"
        style={{ backgroundColor: `${iconBackground}` }}
      >
        <img src={icon} alt={altName} />
      </div>

      <div className="default-shareForward">
        <div className="default-shareName">{shareName}</div>
        <div className="default-forIcon">
          <img src={defaultForwardIcon} alt="default_forward_icon" />
        </div>
      </div>

      <div className="defaultDes-gen">{shareDesc}</div>
    </div>
  );
};

export default ShareTabCom;
