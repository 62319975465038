import React, { useState, useEffect } from "react";
import "./tempCompanyInfo.css";
import { useHistory, useLocation } from "react-router-dom";
import SectionsHeader from "../../../genComponents/sectionsHeader/SectionsHeader";
import {
  DefaultInput,
  DefaultSubmitBtn,
} from "../../../genComponents/defaultInput/DefaultInput";
import { DefaultMinusBtnInput } from "../../../genComponents/defaultMinusBtn/DefaultMinusBtn";
import AddNew from "../../../assets/icons/AddNew";
import minus from "../../../assets/icons/minus.svg";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { countriesList } from "../../../genComponents/locationOptionData/LocationOptionData";
import TeamSwitchButton from "../../../genComponents/teamSwitchButton/TeamSwitchButton";
import { DefaultTeamSubmitBtn } from "../../../genComponents/OptionInput/DefaultInput";

const TempCompanyInfo = () => {
  const [emailAdd, setEmailAdd] = useState([""]);
  const [defaultColor, setDefaultColor] = useState("");
  const [onSuccessContactPage, setOnSuccessContactPage] = useState(false);
  const [onErrorPage, setOnErrorPage] = useState(false);
  const [locationAdd, setLocationAdd] = useState([]);
  const [websiteAdd, setWebsiteAdd] = useState([""]);

  const [phoneNumber, setPhoneNumber] = useState([{ phone: "" }]);
  const [newPhoneNum, setNewPhoneNum] = useState([""]);

  const [fields, setFields] = useState([
    {
      address_id: 0,
      country: "",
      street: "",
      state: "",
      city: "",

      postal_code: "",
    },
  ]);

  const [emailAddBtn, setEmailAddBtn] = useState([""]);
  const [websiteAddBtn, setWebsiteAddBtn] = useState([""]);
  const [newPhoneNumBtn, setNewPhoneNumBtn] = useState([""]);
  const [fieldsBtn, setFieldsBtn] = useState([
    {
      address_id: 0,
      country: "",
      street: "",
      state: "",
      city: "",

      postal_code: "",
    },
  ]);

  const [selectedValue, setSelectedValue] = useState("option3");

  const [dropdownSets, setDropdownSets] = useState([
    {
      address_id: 0,
      country: "",
      state: "",
      city: "",

      street: "",
      postal_code: "",
    },
  ]);

  const history = useHistory();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleChangeEmailAdd = (onChangeValue, i) => {
    const inputData = [...emailAdd];
    inputData[i] = onChangeValue.target.value;
    setEmailAdd(inputData);
  };

  const handleAddEmailAdd = () => {
    const abc = [...emailAdd, []];
    setEmailAdd(abc);
    setEmailAddBtn(abc);
  };

  const handleCountryChange = (event, index) => {
    const newDropdownSets = [...dropdownSets];

    newDropdownSets[index].country = event.target.value;

    setDropdownSets(newDropdownSets);
  };

  const handleDeleteEmailAdd = (i) => {
    const deleteVal = [...emailAdd];
    deleteVal.splice(i, 1);
    setEmailAdd(deleteVal);
  };

  const handleNewPhoneChange = (onChangeValue, i) => {
    const inputData = [...newPhoneNum];
    inputData[i] = onChangeValue;
    setNewPhoneNum(inputData);
  };

  const handleNewPhoneDelete = (value) => {
    setNewPhoneNum((oldValues) => {
      return oldValues.filter((data) => data !== value);
    });
  };

  const handleNewPhoneAdd = () => {
    const abc = [...newPhoneNum, ""];
    setNewPhoneNum(abc);
    setNewPhoneNumBtn(abc);
  };

  const handleRemoveDropdownSet = (index) => {
    const newDropdownSets = [...dropdownSets];
    newDropdownSets.splice(index, 1);
    setDropdownSets(newDropdownSets);
  };

  const handleChangeStreet = (index, e) => {
    const newFields = [...dropdownSets];
    newFields[index].street = e;
    setDropdownSets(newFields);
    console.log(dropdownSets);
  };

  const handleCityChange = (index, e) => {
    const newDropdownSets = [...dropdownSets];

    newDropdownSets[index].city = e;

    setDropdownSets(newDropdownSets);
  };

  const handleStateChange = (index, e) => {
    const newDropdownSets = [...dropdownSets];

    newDropdownSets[index].state = e;

    setDropdownSets(newDropdownSets);
  };

  const handleChangePostalCode = (index, e) => {
    // setSelectedState(e);
    const newFields = [...dropdownSets];
    newFields[index].postal_code = e;
    setDropdownSets(newFields);
    console.log(dropdownSets);
  };

  const handleAddDropdownSet = () => {
    const newFields = [
      ...dropdownSets,
      {
        address_id: 0,
        country: "",
        state: "",
        city: "",

        street: "",
        postal_code: "",
      },
    ];
    setDropdownSets(newFields);
    setFieldsBtn(newFields);
  };

  const handleGoBack = () => {
    history.goBack();
  };
  return (
    <div className="totalResBodyTeamCompInfo">
      <div className="teamCompinfo-info-family ">
        <SectionsHeader
          sectionName="Company info"
          handleGoBack={handleGoBack}
        />

        <div>
          {emailAdd.map((data, i) => (
            <div className="contact-email-family">
              <DefaultInput
                type="email"
                value={data}
                name="email"
                onChange={(e) => handleChangeEmailAdd(e, i)}
                placeholder=" "
                spanText="Company email"
              />
              {/* {emailAdd.length > 1 && ( */}
              <DefaultMinusBtnInput onClick={() => handleDeleteEmailAdd(i)} />
              {/* )} */}
            </div>
          ))}
          {emailAdd.length < 3 && (
            <div className="add-phone-familyTempCompany">
              <div
                onClick={handleAddEmailAdd}
                className="add-phone-contTempCompan"
                style={{
                  color: defaultColor === "" ? `#36A6A4` : `${defaultColor}`,
                }}
              >
                <div className="addCon_svgTempCompan">
                  <AddNew
                    fill={defaultColor === "" ? `#36A6A4` : `${defaultColor}`}
                  />
                </div>
                <div className="addCon_tex">Add new email</div>
              </div>
            </div>
          )}
        </div>

        <div>
          {newPhoneNum.map((data, index) => {
            return (
              <div>
                <div className="phone-number-inputTempCompany">
                  <PhoneInput
                    international
                    defaultCountry="NG"
                    placeholder="Enter phone number"
                    value={data}
                    onChange={(e) => {
                      handleNewPhoneChange(e, index);
                    }}
                    rules={{ required: true }}
                  />

                  {/* {newPhoneNum.length > 1 && ( */}
                  <div className="remove-iconTempCompany">
                    <img
                      src={minus}
                      alt="remove"
                      onClick={() => handleNewPhoneDelete(data)}
                    />
                  </div>
                  {/* )} */}
                </div>
              </div>
            );
          })}

          {newPhoneNum.length < 4 && (
            <div className="add-phone-familyTempCompany">
              <div
                onClick={() => handleNewPhoneAdd()}
                className="add-phone-contTempCompan"
                style={{
                  color: defaultColor === "" ? `#36A6A4` : `${defaultColor}`,
                }}
              >
                <div className="addCon_svgTempCompan">
                  <AddNew
                    fill={defaultColor === "" ? `#36A6A4` : `${defaultColor}`}
                  />
                </div>
                <div className="addCon_tex">Add new phone number</div>
              </div>
            </div>
          )}
        </div>

        <div className="location-family-formTempCompa">
          {dropdownSets.map((dropdownSet, index) => (
            <div key={index} className="contact-location-familyTempCompa">
              {/* <label htmlFor={`country-dropdown-${index}`}>
                  Select a country:
                </label> */}
              <div className="location-family-entireTempCompa">
                <DefaultInput
                  type="text"
                  value={dropdownSet?.street}
                  name="address"
                  onChange={(e) => handleChangeStreet(index, e.target.value)}
                  placeholder=" "
                  spanText="Enter your address"
                  required=""
                />

                <DefaultMinusBtnInput
                  onClick={() => handleRemoveDropdownSet(index)}
                />
              </div>

              <div className="inputFieldContact-seleTempCompa">
                <select
                  id={`country-dropdown-${index}`}
                  value={dropdownSet.country}
                  onChange={(event) => handleCountryChange(event, index)}
                  placeholder="Country"
                  required={true}
                >
                  <option value="">Select your country</option>
                  {countriesList.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>

              {/* <label htmlFor={`state-dropdown-${index}`}>
                  Select a state:
                </label> */}

              <DefaultInput
                type="text"
                value={dropdownSet?.city}
                name="city"
                onChange={(e) => handleCityChange(index, e.target.value)}
                placeholder=" "
                spanText="City"
                required=""
              />

              <DefaultInput
                type="text"
                value={dropdownSet?.state}
                name="state"
                onChange={(e) => handleStateChange(index, e.target.value)}
                placeholder=" "
                spanText="State"
                required={true}
              />

              {/* <label htmlFor={`city-dropdown-${index}`}>Select a city:</label> */}
            </div>
          ))}

          {dropdownSets.length < 3 && (
            <div className="add-phone-familyTempCompany">
              <div
                onClick={handleAddDropdownSet}
                className="add-phone-contTempCompan"
                style={{
                  color: defaultColor === "" ? `#36A6A4` : `${defaultColor}`,
                }}
              >
                <div className="addCon_svgTempCompan">
                  <AddNew
                    fill={defaultColor === "" ? `#36A6A4` : `${defaultColor}`}
                  />
                </div>
                <div className="addCon_texTempCompa">Add new address</div>
              </div>
            </div>
          )}
        </div>
        <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
          <TeamSwitchButton lockFieldName="Lock fields?" />
        </div>
        <div
          style={{
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
          }}
        >
          <DefaultTeamSubmitBtn
            // disabled={
            //   emailAddBtn.length < 1 &&
            //   websiteAddBtn.length < 1 &&
            //   newPhoneNumBtn.length < 1 &&
            //   fieldsBtn.length < 1
            // }
            // onClick={postProfileData}
            valueText="Save"
            defaultColor={defaultColor}
          />
        </div>
      </div>
    </div>
  );
};

export default TempCompanyInfo;
