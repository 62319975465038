import React from "react";
import "./teamTempNavbar.css";
import CloseTeam from "../../assets/icons/CloseTeam";
import { useHistory } from "react-router-dom";

const TeamTempNavbar = ({ fill, tempHeader }) => {
  const history = useHistory();
  return (
    <div className="teamTempNavbar_Gen">
      <div className="team_leftvvvvv">
        <div className=" teamLeftDiv"></div>
        <div className="teamTextTemp">{tempHeader}</div>
        <div className="teamIconTemp" onClick={history.goBack}>
          <CloseTeam fill={fill} />
        </div>
      </div>
    </div>
  );
};

export default TeamTempNavbar;
