import React, { useEffect } from "react";
import "./logoutPage.css";
import { useHistory } from "react-router-dom";
// import FaTimes from './react-icons'
import { FaTimes } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cancelSelect from "../../../assets/icons/cancelSelect.svg";
import ProfImgCancel from "../../../assets/icons/ProfImgCancel";

const LogoutPage = ({ closeLogoutPage, navColor }) => {
  const history = useHistory();
  const logoutFunc = () => {
    localStorage.clear();
    history.push("/login");
    // window.location.href = "/login";
  };

  useEffect(() => {
    console.log(typeof navColor, "typeof");
    console.log(navColor, "navcolorbb");
  }, []);

  const handleChildClick = (event) => {
    // 👇️ stop event propagation (won't trigger parent's onClick)
    event.stopPropagation();

    // console.log("child clicked");
  };

  return (
    <div onClick={closeLogoutPage} className="logoutGen-modal">
      <div className="totalResBodyLog">
        <div onClick={handleChildClick} className="logoutModalGen-content">
          <div className="logoutModal-cancelFlex">
            <div className="logout-modal-btnText ">Log out?</div>

            <div className="close_logout_btn" onClick={closeLogoutPage}>
              {/* <img src={cancelSelect} /> */}

              <ProfImgCancel
                fill={navColor === "" ? `#36A6A4` : `${navColor}`}
              />
            </div>
          </div>

          <div className="logout-preTextF">This isn’t goodbye</div>

          <div className="logout-Mtext">
            You can always log in to edit your details
          </div>
          <div className="logout-btnsFlex">
            <div
              style={{
                background: navColor === "" ? `#36A6A4` : `${navColor}`,
              }}
              className="logout-Admincontinue-btn"
              onClick={logoutFunc}
            >
              Log out
            </div>
            <div className="logout-Admincancel-btn" onClick={closeLogoutPage}>
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutPage;
