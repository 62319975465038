import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import backArrow from "../../../assets/icons/backArrow.svg";
import searchIcon from "../../../assets/icons/searchIcon.svg";
import contactArrowDown from "../../../assets/icons/contactArrowDown.svg";
import contactArrowUp from "../../../assets/icons/contactArrowUp.svg";
import callPath from "../../../assets/icons/callPath.svg";
import mailicon from "../../../assets/icons/mailicon.svg";
import connectSearchIcon from "../../../assets/icons/connectSearchIcon.svg";
import defaultConnectImg from "../../../assets/icons/defaultConnectImg.svg";
import noConnect from "../../../assets/icons/noConnect.svg";
import noConnectList from "../../../assets/icons/noConnectList.svg";
import defaultContCard from "../../../assets/icons/defaultContCard.svg";

import "./viewConnect.css";
import SectionsHeader from "../../../genComponents/sectionsHeader/SectionsHeader";
import { connectionsData } from "../../../genComponents/locationOptionData/LocationOptionData";
import NoSearchResult from "../../../genComponents/noSearchResult/NoSearchResult";
import NoConnectPage from "../../../genComponents/noConnectPage/NoConnectPage";
import SingleConnectInfo from "./singleConnectInfo/SingleConnectInfo";
import ConnectionCard from "./connectionCard/ConnectionCard";
import { axiosCalls } from "../../../_api";
import { hideLoader, showLoader } from "../../../loader";

const ViewConnect = () => {
  const [connectionList, setConnectionList] = useState(null);
  const [search, setSearch] = useState("");
  const [error, setError] = useState("");
  const [openSingleConnect, setOpenSingleConnect] = useState(false);
  const [singleConnectData, setSingleConnectData] = useState();
  const [storeConnection, setStoreConnection] = useState([]);
  const [profileName, setProfileName] = useState("fionaokpara");
  const [loading, userLoading] = useState(true);
  const [fetchError, userFetchError] = useState(false);
  const [menuBtnH, setMenuBtnH] = useState(false);

  const [activeMenu, setActiveMenu] = useState(null);
  const history = useHistory();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleGoBack = () => {
    history.goBack();
  };

  const handleMenuClick = (menuId) => {
    if (activeMenu === menuId) {
      setActiveMenu(null); // Close menu if it's already open
    } else {
      setActiveMenu(menuId); // Open the clicked menu
    }
  };
  useEffect(() => {
    // getAssets();
    getProfile();

    console.log(singleConnectData, "djdjfjfffnnjjjjj");
  }, []);

  const handleMenuBtnToggle = () => {
    setMenuBtnH(!menuBtnH);
  };

  const handleMenuBtnClose = () => {
    setMenuBtnH(false);
  };
  const getProfile = async () => {
    showLoader();

    const res = await axiosCalls(`admin/profile/${profileName}`, "GET");

    if (res) {
      userLoading(false);
      if (res?.er) {
        hideLoader();
        userFetchError(true);
        console.log(res?.er);

        return;
      }

      const resData = res?.data?.profile;
      const parseData = resData?.connection;
      console.log(parseData, "parseeeeeeeew");
      // const sortedData = parseData.sort(
      //   (a, b) => new Date(b.added_dt) - new Date(a.added_dt)
      // );

      const sortedData = parseData.reverse();

      setConnectionList(sortedData);
      setStoreConnection(sortedData);
      hideLoader();
    }
  };

  // const getAssets = () => {
  //   let assets = localStorage.getItem("Connection List");
  //   if (assets == undefined || assets == null || assets == "") {
  //     return;
  //   }
  //   let parseData = JSON.parse(assets);
  //   console.log(">>>>>>>>>>vvvVVV", parseData);

  //   const sortedData = parseData.sort(
  //     (a, b) => new Date(b.added_dt) - new Date(a.added_dt)
  //   );

  //   setConnectionList(sortedData);
  // };

  //   const sortedData = connectionsData.sort((a, b) =>
  //     a.contactFirstName.localeCompare(b.contactFirstName)
  //   );

  // function getConnectionList() {

  //   console.log(connectionsData);

  //   setConnectionList(connectionsData);

  //   console.log("connection list arranged", connectionList);
  // }
  // useEffect(() => {
  //   getConnectionList();
  // }, []);

  const handleSearch = (value) => {
    // if (!value.length) {
    //   setConnectionList(sortedData);
    // } else {
    //   setError("No Items Were Found");
    // }

    // let assets = localStorage.getItem("Connection List");
    // if (assets == undefined || assets == null || assets == "") {
    //   return;
    // }
    // let parseData = JSON.parse(assets);
    // console.log(">>>search", parseData);

    // const sortedData = parseData.sort(
    //   (a, b) => new Date(b.date) - new Date(a.date)
    // );

    if (value.length < 1) return setConnectionList(storeConnection);

    // const filteredData = connectionsData.filter((item) =>
    //   item.contactFirstName.toLowerCase().includes(value.toLowerCase())
    // );

    const filteredData = storeConnection.filter((item) => {
      return Object.values(item)
        .join(" ")
        .toLowerCase()
        .includes(value.toLowerCase());
    });

    if (filteredData.length) {
      setConnectionList(filteredData);
      setError("");
    } else {
      // setConnectionList([]);
      setError("No Contacts Were Found");
    }
  };

  const handleSingleConnect = (connectsDataB) => {
    // setOpenSingleConnect(!openSingleConnect);
    setSingleConnectData(connectsDataB);
    console.log(connectsDataB, "dujdjji");
  };

  const handleChildClick = (event) => {
    event.stopPropagation();
    console.log("Child clicked");
  };
  // if (connectionList.length < 1) {
  //   return (
  //     <>
  //       {loading == true ? null : fetchError == true ? <NoConnectPage /> : null}
  //     </>
  //   );
  // }
  if (loading === false) {
    return (
      <div
        onClick={() => {
          setActiveMenu(null);
        }}
        className="totalResBodyConnect"
      >
        <div>
          <div className="connect-blurBackground">
            <SectionsHeader
              sectionName="View connections"
              handleGoBack={handleGoBack}
            />
            <div className="connect-searchWrapper">
              <div className="connect-searchIcon">
                <img src={connectSearchIcon} />
              </div>
              <input
                type="text"
                placeholder="Search Contact"
                // value={search}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
              />
            </div>
          </div>
          <div style={{ marginTop: "150px" }}>
            {/* {loading === false ? (
            <> */}
            {connectionList.length > 0 ? (
              <div style={{ paddingTop: "20px" }}>
                {error !== "" ? (
                  <NoSearchResult errorResult={error} />
                ) : (
                  <div
                    onClick={() => {
                      setActiveMenu(null);
                    }}
                    // onClick={handleChildClick}
                    className="sectiinSearch"
                  >
                    {connectionList.map((connectsData, index) => (
                      <ConnectionCard
                        handleSingleConnect={handleSingleConnect}
                        handleMenuBtnToggle={handleMenuBtnToggle}
                        menuBtnH={menuBtnH}
                        handleMenuBtnClose={handleMenuBtnClose}
                        connectsData={connectsData}
                        key={connectsData?.profile_id}
                        index={index}
                        menuId={index}
                        active={activeMenu === index}
                        onClick={handleMenuClick}
                        singleConnectData={singleConnectData}
                        handleChildClick={handleChildClick}
                      />
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <NoConnectPage />
            )}
            {/* </>
          ) : null} */}
          </div>
        </div>

        {openSingleConnect ? (
          <SingleConnectInfo
            handleSingleConnect={handleSingleConnect}
            singleConnectData={singleConnectData}
          />
        ) : null}
      </div>
    );
  }

  // else if (fetchError === true) {
  //   return <NoConnectPage />;
  // }
};

export default ViewConnect;
