import React from "react";
import "./teamTempCard.css";
const TeamTempCard = ({ team_name }) => {
  return (
    <div className="teamTemp_cardGen">
      <div className="teamTemp_cardName">{team_name}</div>
      <div className="teamTemp_cardHandle">Click in to edit</div>
    </div>
  );
};

export default TeamTempCard;
