import React from "react";

const ConnectModalMail = ({ fill }) => {
  return (
    <>
      <svg
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.6004 3.16139V8.15029C11.6004 8.61442 11.416 9.05954 11.0878 9.38773C10.7596 9.71592 10.3145 9.90029 9.85039 9.90029H2.15039C1.68626 9.90029 1.24114 9.71592 0.912954 9.38773C0.584765 9.05954 0.400391 8.61442 0.400391 8.15029V3.16139L5.82259 6.35199C5.87646 6.38376 5.93785 6.40052 6.00039 6.40052C6.06293 6.40052 6.12432 6.38376 6.17819 6.35199L11.6004 3.16139ZM9.85039 0.800293C10.281 0.800225 10.6965 0.958918 11.0174 1.24601C11.3384 1.5331 11.5422 1.92844 11.5899 2.35639L6.00039 5.64429L0.410891 2.35639C0.458601 1.92844 0.662417 1.5331 0.983347 1.24601C1.30428 0.958918 1.71979 0.800225 2.15039 0.800293H9.85039Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default ConnectModalMail;
