import React, { useEffect, useState } from "react";
import "./tempVideoLink.css";
import { useHistory, useLocation } from "react-router-dom";
import {
  DefaultInput,
  DefaultSubmitBtn,
} from "../../../genComponents/defaultInput/DefaultInput";
import SectionsHeader from "../../../genComponents/sectionsHeader/SectionsHeader";
import { DefaultMinusBtn } from "../../../genComponents/defaultMinusBtn/DefaultMinusBtn";
import { DefaultMinusBtnInput } from "../../../genComponents/defaultMinusBtn/DefaultMinusBtn";
import { axiosCalls } from "../../../_api";
import { hideLoader, showLoader } from "../../../loader";
import SuccessAlert from "../../screens/successAlert/SuccessAlert";
import FailureAlert from "../../screens/failureAlert/FailureAlert";
import AddNew from "../../../assets/icons/AddNew";
import TeamSwitchButton from "../../../genComponents/teamSwitchButton/TeamSwitchButton";

const TempVideoLink = () => {
  const [videoLinkEdit, setVideoLinkEdit] = useState([{ video_url: "" }]);
  const [onSuccessVideoPage, setOnSuccessVideoPage] = useState(false);
  const [onErrorPage, setOnErrorPage] = useState(false);
  const [videoLinkBtn, setVideoLinkBtn] = useState([{ video_url: "" }]);
  const [defaultColor, setDefaultColor] = useState("");

  const history = useHistory();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  // useEffect(() => {
  //   getVideoData();
  // }, []);

  // const getVideoData = () => {
  //   let profVideoLink = localStorage.getItem("Video");
  //   const parsedArr = JSON.parse(profVideoLink);
  //   setVideoLinkEdit(parsedArr);
  //   setVideoLinkBtn(parsedArr);
  //   setDefaultColor(localStorage.getItem("Default Theme"));
  // };

  // const postProfileData = async (e) => {
  //   e.preventDefault();

  //   const data = {
  //     page: "video",
  //     video: videoLinkEdit,
  //   };
  //   showLoader();
  //   const res = await axiosCalls(`admin/profile`, "POST", data);

  //   if (res) {
  //     hideLoader();
  //     console.log(res?.data);
  //     if (res?.er) {
  //       console.log(res?.data?.er);
  //       setOnErrorPage(true);
  //       setTimeout(() => {
  //         setOnErrorPage(false);
  //       }, 3000);
  //       return;
  //     }
  //     // window.location.href = "/";

  //     setOnSuccessVideoPage(true);

  //     setTimeout(() => {
  //       setOnSuccessVideoPage(false);
  //       window.location.href = "/";
  //     }, 2000);
  //   }
  // };

  const handleVideoLinkAdd = () => {
    const abc = [...videoLinkEdit, { video_url: "" }];

    setVideoLinkEdit(abc);
    setVideoLinkBtn(abc);
  };

  const handleVideoLinkRemove = (index) => {
    const deletVal = [...videoLinkEdit];
    deletVal.splice(index, 1);
    setVideoLinkEdit(deletVal);
  };

  const handleVideoLinkChange = (e, index) => {
    const inputData = [...videoLinkEdit];
    inputData[index].video_url = e.target.value;
    setVideoLinkEdit(inputData);
    console.log(videoLinkEdit);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    console.log(videoLinkEdit);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className="totalResTeamVideoBody">
      <div className="video-Teaminfo-family">
        {" "}
        <SectionsHeader sectionName="Video Link" handleGoBack={handleGoBack} />
        <form

        // onSubmit={postProfileData}
        >
          <div>
            {videoLinkEdit.map((singleVideoLink, index) => {
              return (
                <div className="video-link-input-family">
                  <DefaultInput
                    type="url"
                    placeholder=" "
                    value={singleVideoLink.video_url}
                    name="videoLink"
                    onChange={(e) => handleVideoLinkChange(e, index)}
                    spanText="Type/paste video link"
                  />

                  <DefaultMinusBtnInput
                    onClick={() => handleVideoLinkRemove(index)}
                  />
                </div>
              );
            })}
            {videoLinkEdit.length < 2 && (
              <div className="add-Teamvideo-family">
                <div
                  onClick={handleVideoLinkAdd}
                  className="add-Teamphone-cont"
                  style={{
                    color: defaultColor === "" ? `#36A6A4` : `${defaultColor}`,
                  }}
                >
                  <div className="TeamaddCon_svg">
                    <AddNew
                      fill={defaultColor === "" ? `#36A6A4` : `${defaultColor}`}
                    />
                  </div>
                  <div className="TeamaddCon_tex">Add new video</div>
                </div>
              </div>
            )}

            <div
              style={{
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "50px",
              }}
            >
              <TeamSwitchButton lockFieldName="Lock fields?" />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <DefaultSubmitBtn
                disabled={videoLinkBtn.length < 1}
                // onClick={postProfileData}
                valueText="Save"
                defaultColor={defaultColor}
              />
            </div>
          </div>
        </form>
        {onSuccessVideoPage && (
          <SuccessAlert alertMessage="😄 Video link saved" />
        )}
        {onErrorPage && <FailureAlert alertMessage="Oops, an error occured!" />}
      </div>
    </div>
  );
};

export default TempVideoLink;
