import React from "react";
import "./sectionHeaderCancel.css";
import { useHistory } from "react-router-dom";

const SectionHeaderCancel = ({ saveUpdate, defaultTheme, defaultThemeBoo }) => {
  const history = useHistory();

  return (
    <>
      <div
        style={{
          background: defaultThemeBoo ? defaultTheme || "#171923" : "#171923",
        }}
        className="sectionHeader-CanFam"
      >
        <div className="sectionHeader-CanFam-btn" onClick={history.goBack}>
          Cancel
        </div>

        {/* <div
          onClick={() => {
            saveUpdate();
          }}
          className="sectionHeader-CanFam-btn"
        >
          Save
        </div> */}
      </div>
    </>
  );
};

export default SectionHeaderCancel;
