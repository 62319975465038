import React from "react";

const ConnectPhoneIcon = ({ fill }) => {
  return (
    <>
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.57656 0.384727C2.45625 0.0941023 2.13906 -0.0605852 1.83594 0.0222273L0.460938 0.397227C0.189063 0.472227 0 0.719102 0 1.00035C0 4.86598 3.13438 8.00035 7 8.00035C7.28125 8.00035 7.52813 7.81129 7.60313 7.53942L7.97813 6.16442C8.06094 5.86129 7.90625 5.5441 7.61562 5.42379L6.11562 4.79879C5.86094 4.69254 5.56563 4.76598 5.39219 4.98004L4.76094 5.75035C3.66094 5.23004 2.77031 4.33942 2.25 3.23942L3.02031 2.60973C3.23438 2.43473 3.30781 2.14098 3.20156 1.88629L2.57656 0.38629V0.384727Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default ConnectPhoneIcon;
