import React from "react";
import "./noSearchResult.css";
import noConnectionIcon from "../../assets/icons/noConnectionIcon.svg";

const NoSearchResult = ({ errorResult }) => {
  return (
    <div className="errorFamGen">
      <div className="errorFamGen-Img">
        <img src={noConnectionIcon} />
      </div>
      <div className="errorFamGen-text">{errorResult}</div>
    </div>
  );
};

export default NoSearchResult;
