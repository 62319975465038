import React from "react";
import "./newAuthModal.css";
// import cancelSelect from "../../assets/icons/cancelSelect.svg";
import cancelSelect from "../../../../assets/icons/cancelSelect.svg";
// import DynamicCancel from "../dynamicCancel/DynamicCancel";

const NewAuthModal = ({
  closeNewModal,
  children,
  headerName,
  btnName,
  cancelBtn,
  defaultTheme,
  headerColor,
}) => {
  const handleChildClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div className="newModal-genBody_auth" onClick={closeNewModal}>
      <div onClick={handleChildClick} className="newModal-Content_auth">
        <div className="newModalContent-header_auth">
          <div
            style={{ color: headerColor }}
            className="newModalContent-headerText_auth"
          >
            {headerName}
          </div>
          <div
            className="newModalContent-headerImg_auth"
            onClick={closeNewModal}
          >
            {/* <img src={cancelSelect} alt="new_modal-cancel" /> */}
            {/* <DynamicCancel
              // defaultTheme={defaultTheme === "" ? `#36A6A4` : `${defaultTheme}`}
              defaultTheme="#36A6A4"
            /> */}

            <img src={cancelSelect} />
          </div>
        </div>

        {children}

        <div style={{ marginBottom: "50px" }}></div>
        {!btnName ? null : (
          <div className="newModal-Btn_auth" onClick={closeNewModal}>
            {btnName}
          </div>
        )}
      </div>
    </div>
  );
};

export default NewAuthModal;
