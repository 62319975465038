import React, { useState } from "react";
import "./defaultInput.css";
import { useEffect } from "react";

export const DefaultInput = ({
  value,
  name,
  onChange,
  placeholder,
  spanText,
  type,
  required,
}) => {
  return (
    <div className="inputFieldPersonalize">
      <input
        type={type}
        value={value}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        className="no-auto-zoom"
      />
      <span> {spanText}</span>
    </div>
  );
};

export const DefaultHalfInput = ({
  value,
  name,
  onChange,
  placeholder,
  spanText,
}) => {
  return (
    <div className="inputFieldPersonalize-half" style={{ opacity: "0.5" }}>
      <input
        type="text"
        value={value}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        disabled
      />
      <span> {spanText}</span>
    </div>
  );
};

export const DefaultHalfInputSocial = ({
  value,
  name,
  onChange,
  placeholder,
  spanText,
  type,
  required,
  socialsSelect,
}) => {
  return (
    <div className="inputFieldPersonalize-halfSocial">
      <input
        type={type}
        value={value}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        style={{
          padding:
            socialsSelect === "Whatsapp" ? "13px 50px 11px 115px" : "4px 60px",
        }}
      />

      <span>
        {socialsSelect === "Whatsapp"
          ? "Enter WhatsApp number"
          : "Type or paste Link"}
      </span>
    </div>
  );
};

export const DefaultHalfInputLink = ({
  value,
  name,
  onChange,
  placeholder,
  spanText,
}) => {
  return (
    <div className="inputFieldPersonalize-halfLink">
      <input
        type="text"
        value={value}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
      />
      <span> {spanText}</span>
    </div>
  );
};

export const DefaultSubmitBtn = ({
  disabled,
  valueText,
  styleList,
  isValidUrl,
  defaultColor,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div className="default-save-form" style={styleList}>
      <input
        type="submit"
        disabled={disabled}
        // disabled={disable}
        value={valueText}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        style={{
          transition: "0.5s ease-in-out 0s;",
          // background: hover ? "#248381" : "",
          opacity: disabled ? "0.3" : "1",
          cursor: disabled ? "not-allowed" : "pointer",
          background: defaultColor === "" ? `#36A6A4` : `${defaultColor}`,
        }}
      />
    </div>
  );
};
