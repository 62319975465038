import React from "react";

const FeedbackBtnBullet = ({ fill }) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.20193 6.84178L6.01079 4.58521L4.81886 6.84178C4.73452 7.0068 4.61261 7.14971 4.46293 7.25899C4.31234 7.36884 4.13833 7.44226 3.95457 7.47349L1.5 7.95278L3.21364 9.99563C3.32924 10.1218 3.41633 10.2713 3.469 10.4341C3.52125 10.5965 3.5376 10.7683 3.51693 10.9377L3.19086 13.5714L5.45136 12.4824C5.62604 12.399 5.8172 12.3558 6.01079 12.3559C6.19255 12.3558 6.37171 12.3991 6.53329 12.4824L8.84486 13.5038L8.518 10.9C8.49733 10.7306 8.51368 10.5588 8.56593 10.3963C8.6186 10.2336 8.70569 10.084 8.82129 9.95792L10.5208 7.94963L8.06621 7.47035C7.88246 7.43912 7.70844 7.36569 7.55786 7.25585C7.40856 7.14746 7.28667 7.00566 7.20193 6.84178Z"
        fill={fill}
        stroke={fill}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.4674 1.8792C12.7022 1.64533 12.7029 1.26543 12.469 1.03068C12.2352 0.795923 11.8553 0.795212 11.6205 1.02909L12.4674 1.8792ZM8.88701 3.75237C8.65226 3.98625 8.65154 4.36615 8.88542 4.6009C9.1193 4.83565 9.49919 4.83636 9.73395 4.60249L8.88701 3.75237ZM12.9232 4.14842C13.1581 3.91461 13.1589 3.53471 12.9251 3.29989C12.6913 3.06507 12.3114 3.06425 12.0765 3.29806L12.9232 4.14842ZM10.2537 5.11306C10.0189 5.34687 10.018 5.72677 10.2519 5.96159C10.4857 6.19641 10.8656 6.19723 11.1004 5.96342L10.2537 5.11306ZM10.1894 1.42518C10.4242 1.19137 10.425 0.811473 10.1912 0.576653C9.9574 0.341833 9.5775 0.341013 9.34268 0.574821L10.1894 1.42518ZM7.51904 2.39061C7.28422 2.62441 7.2834 3.00431 7.5172 3.23913C7.75101 3.47395 8.13091 3.47477 8.36573 3.24096L7.51904 2.39061ZM11.6205 1.02909L8.88701 3.75237L9.73395 4.60249L12.4674 1.8792L11.6205 1.02909ZM12.0765 3.29806L10.2537 5.11306L11.1004 5.96342L12.9232 4.14842L12.0765 3.29806ZM9.34268 0.574821L7.51904 2.39061L8.36573 3.24096L10.1894 1.42518L9.34268 0.574821Z"
        fill={fill}
      />
    </svg>
  );
};

export default FeedbackBtnBullet;
