import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import backArrow from "../../../assets/icons/backArrow.svg";
import whatsAppShare from "../../../assets/icons/whatsAppShare.svg";
import mailShare from "../../../assets/icons/mailShare.svg";
import "./support.css";
import Burger from "../adminPage/Burger";
import SectionNavHamburger from "../../../genComponents/sectionNavHamburger/SectionNavHamburger";
import ShareTabCom from "../../../genComponents/shareTabCom/ShareTabCom";
import { useState, useEffect } from "react";
import { axiosCalls } from "../../../_api";
import { hideLoader, showLoader } from "../../../loader";
import SuccessAlert from "../successAlert/SuccessAlert";
import FailureAlert from "../failureAlert/FailureAlert";
import { DefaultInput } from "../../../genComponents/defaultInput/DefaultInput";
import DefaultTextArea from "../../../genComponents/defaultTextArea/DefaultTextArea";

const Support = () => {
  const [whatsappSupport, setWhatsappSupport] = useState("");
  const [mailSupport, setMailSupport] = useState("");

  const [emailData, setEmailData] = useState();
  const [loading, userLoading] = useState(true);
  const [fetchError, userFetchError] = useState(false);
  const [errorLoad, setErrorLoad] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [profileName, setProfileName] = useState("fionaokpara");
  const [navColor, setNavColor] = useState("");
  const [profImage, setProfImage] = useState(null);
  const [loadingImgName, setLoadingImgName] = useState(false);
  const [supportTitle, setSupportTitle] = useState("");
  const [supportBody, setSupportBody] = useState("");
  const [onSuccessFeedPage, setOnSuccessFeedPage] = useState(false);
  const [onErrorFeedPage, setOnErrorFeedPage] = useState(false);
  const [profileId, setProfileId] = useState();
  const [userRole, setUserRole] = useState("");
  const [backdropTheme, setBackdropTheme] = useState();

  useEffect(() => {
    getProfile();
    getProfImage();
    setUserRole(localStorage.getItem("User Role"));
  }, []);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const getProfile = async () => {
    showLoader();

    const res = await axiosCalls(`admin/profile/${profileName}`, "GET");

    if (res) {
      userLoading(false);
      if (res?.er) {
        hideLoader();
        console.log(res?.er);

        // toast.error("Oops, Error Please Refresh", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        setErrorLoad(true);

        console.log("errfff");
        userFetchError(true);
        // <NoUserDisplay />;
        return;
      }

      setErrorLoad(false);
      hideLoader();
      const resData = res?.data?.profile;

      setEmailData(resData);
      setNavColor(resData?.theme_color_primary ?? "");
      setProfileId(resData?.id);
      setBackdropTheme(resData?.apply_theme_to_backdrop);
      // setUpdateTheme(resData?.theme_color_primary);
      console.log(">>>>>>>>>>Res", resData);
    }
  };

  const getProfImage = async () => {
    // const image_name = localStorage.getItem("Profile Img");

    const resImage = await axiosCalls(`admin/profile/${profileName}`, "GET");

    if (resImage) {
      if (resImage?.er) {
        return;
      }
    }

    const image_name = resImage?.data?.profile?.profile_image;

    const res = await axiosCalls(`s3/${image_name}`, "GET");

    if (res) {
      if (res?.er) {
        console.log(res?.er);

        console.log("errfff");
        return;
      }

      hideLoader();
      const resData = res.data;
      setProfImage(resData, "prooooooooooooo");

      console.log(resData, "pppppppppppppppppppp");
      console.log(profImage, "proffffffffffrrrrrr");
      setLoadingImgName(true);
    }
  };

  const postFeedBackData = async (e) => {
    e.preventDefault();

    const data = {
      type: "support",
      support_title: supportTitle,
      support_body: supportBody,
      profile_id: profileId,
    };
    showLoader();
    const res = await axiosCalls(`public/save`, "POST", data);

    if (res) {
      hideLoader();
      console.log(res?.data);
      if (res?.er) {
        console.log(res?.data?.er);
        setOnErrorFeedPage(true);
        setTimeout(() => {
          setOnErrorFeedPage(false);
        }, 3000);
        return;
      }
      // window.location.href = "/";

      setOnSuccessFeedPage(true);

      setTimeout(() => {
        setOnSuccessFeedPage(false);
        //  window.location.href = "/";
      }, 3000);

      setSupportBody("");
      setSupportTitle("");
    }
  };

  const handleSupportTitle = (e) => {
    setSupportTitle(e.target.value);
  };

  const handleSupportBody = (e) => {
    setSupportBody(e.target.value);
  };

  const history = useHistory();
  return (
    <div className="totalResBodySupport">
      <div className="support-family">
        <SectionNavHamburger
          emailData={emailData}
          profImage={profImage}
          hamText="Contact support"
          userRole={userRole}
        />

        {/* <div className="share-flex-cont">
          <ShareTabCom
            onClick={() => setWhatsappSupport("Hi")}
            icon={whatsAppShare}
            shareName="Whatsapp"
            shareDesc="You can chat directly with 
us here on whatsapp"
            borderColor="#36A6A4"
            backgroundColor="#D5FFFE"
            iconBackground="#36A6A4"
            altName="whatsApp_icon"
          />

          <ShareTabCom
            onClick={() => setMailSupport("Hi")}
            icon={mailShare}
            shareName="Email"
            shareDesc="Shoot us an email if you 
need to make an inquiry/
complaint"
            borderColor="#171923"
            backgroundColor="#F2F2F2"
            iconBackground="#171923"
            altName="mail_icon"
          />
        </div> */}

        <form onSubmit={postFeedBackData} className="support-form-cont ">
          <div className="support_form_header">Drop us a line</div>
          <DefaultInput
            name="contentTitle"
            spanText="Type title here"
            type="text"
            value={supportTitle}
            onChange={(e) => {
              handleSupportTitle(e);
            }}
            placeholder=" "
          />

          <DefaultTextArea
            value={supportBody}
            name="contentBody"
            onChange={(e) => {
              handleSupportBody(e);
            }}
            placeholder="Type body here"
          />

          {/* <div
            style={{ background: navColor === "" ? `#36A6A4` : `${navColor}` }}
            className="support-submitBtn"
            // onClick={postSupportData}
          >
            Send
          </div> */}

          <div
            className="support-save-form"
            style={{
              opacity: !supportTitle || !supportBody ? "0.3" : "1",
            }}
          >
            <div className="support_btn-save">
              <input
                type="submit"
                value="Send"
                disabled={!supportTitle || !supportBody}
                style={{
                  background: navColor === "" ? `#36A6A4` : `${navColor}`,
                }}
              />
            </div>
          </div>
        </form>
      </div>
      {onSuccessFeedPage && (
        // <SuccessPage notifyText="Personal Information Saved" />
        <SuccessAlert alertMessage="😄 Thank you for reaching out." />
      )}

      {onErrorFeedPage && (
        <FailureAlert alertMessage="Oops, an error occured!" />
      )}
    </div>
  );
};

export default Support;
