import React from "react";
import "./defaultMinusBtn.css";
import minus from "../../assets/icons/minus.svg";

export const DefaultMinusBtn = ({ onClick }) => {
  return (
    <div className="remove-default-icon ">
      <img src={minus} alt="remove" onClick={onClick} />
    </div>
  );
};

export const DefaultMinusBtnInput = ({ onClick }) => {
  return (
    <div className="remove-defaultInput-icon ">
      <img src={minus} alt="remove" onClick={onClick} />
    </div>
  );
};

export const DefaultMinusBtnSecond = ({ onClick }) => {
  return (
    <div className="remove-defaultSecond-icon ">
      <img src={minus} alt="remove" onClick={onClick} />
    </div>
  );
};
