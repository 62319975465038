import React from "react";

const AddNew = ({ fill }) => {
  return (
    <>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="14"
          cy="14"
          r="13.5"
          fill="white"
          stroke={fill}
          stroke-dasharray="3 3"
        />
        <path
          d="M13.2159 18.1051V9.66761H14.6477V18.1051H13.2159ZM9.71307 14.6023V13.1705H18.1506V14.6023H9.71307Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default AddNew;
