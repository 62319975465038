import React, { useState, useEffect } from "react";
import "./tempCustomize.css";
import SectionsHeader from "../../../genComponents/sectionsHeader/SectionsHeader";
import { useHistory, useLocation } from "react-router-dom";
import addlogoIcon from "../../../assets/icons/addlogoIcon.svg";
import { SketchPicker } from "react-color";
import { ToastContainer, toast } from "react-toastify";
import SuccessAlert from "../../screens/successAlert/SuccessAlert";
import FailureAlert from "../../screens/failureAlert/FailureAlert";
import { axiosCalls } from "../../../_api";
import { showLoader, hideLoader } from "../../../loader";
import Cancel from "../../../assets/icons/Cancel";
import NewModal from "../../../genComponents/newModal/NewModal";
import CameraIcon from "../../../assets/icons/CameraIcon";
import TeamSwitchButton from "../../../genComponents/teamSwitchButton/TeamSwitchButton";

const TempCustomize = () => {
  const [customColor, setCustomColor] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [color, setColor] = useState("");
  const [coverImage, setCoverImage] = useState(null);
  const [defaultImage, setDefaultImage] = useState(null);

  const [onSuccessBackdropPage, setOnSuccessBackdropPage] = useState(false);

  const [onSuccessThemeColor, setOnSuccessThemeColor] = useState(false);
  const [onErrorThemeColor, setOnErrorThemeColor] = useState(false);

  const [tab, setTab] = useState(1);
  const [backdropSlider, setBackdropSlider] = useState(0);
  const [onSuccessProfileLogo, setOnSuccessProfileLogo] = useState(false);
  const [onErrorProfileLogo, setOnErrorProfileLogo] = useState(false);
  const [loadingDefaultLogo, setLoadingDefaultLogo] = useState(false);
  const [deleteConfirmLogo, setDeleteConfirmLogo] = useState(false);
  const [defaultTheme, setDefaultTheme] = useState("");
  const [onErrorProfileLogoDel, setOnErrorProfileLogoDel] = useState(false);
  const [onSuccessProfileLogoDel, setOnSuccessProfileLogoDel] = useState(false);
  const [firstProfImage, setfirstProfImage] = useState(null);

  const handleCloseDelete = () => {
    setDeleteConfirmLogo(false);
  };

  const handleOpenDelete = () => {
    setDeleteConfirmLogo(true);
  };

  const handleFirstDelete = () => {
    setCoverImage(null);
  };
  //   useEffect(() => {
  //     setColor(localStorage.getItem("Default Theme"));
  //     setfirstProfImage(localStorage.getItem("Profile Logo Name"));
  //     console.log(color, "cooooollll");
  //   }, []);

  const handleChangeComplete = (color) => {
    setColor(color.hex);
  };

  const handleTab1 = () => {
    setTab(1);
  };
  const callSliderRight = () => {
    setBackdropSlider(1);
  };
  const callSliderLeft = () => {
    setBackdropSlider(2);
  };

  const handleTab2 = () => {
    setTab(2);
  };

  //   const postProfileTheme = async (e) => {

  //     const data = {
  //       page: "theme_color_primary",
  //       theme_color_primary: color,
  //     };
  //     showLoader();
  //     const res = await axiosCalls(`admin/profile`, "POST", data);

  //     if (res) {
  //       hideLoader();
  //       console.log(res?.data);
  //       if (res?.er) {
  //         console.log(res?.data?.er);
  //         setOnErrorThemeColor(true);
  //         setTimeout(() => {
  //           setOnErrorThemeColor(false);
  //         }, 3000);
  //         return;
  //       }

  //       setOnSuccessThemeColor(true);

  //       setTimeout(() => {
  //         setOnSuccessThemeColor(false);
  //         window.location.href = "/";
  //       }, 2000);
  //     }
  //   };

  //   const postProfileLogo = async (e) => {

  //     showLoader();
  //     const userID = localStorage.getItem("ID");

  //     const id = `profile_${userID}_${new Date().getTime()}.${
  //       coverImage?.blob.type.split("/")[1]
  //     }`; //replace userID

  //     console.log(id, "User Id");
  //     console.log(coverImage, "prof");
  //     await axiosCalls(`s3/upload?filename=${id}`, "POST", coverImage?.blob);

  //     const data = {
  //       page: "profile_logo",
  //       profile_logo: id,
  //     };

  //     const res = await axiosCalls(`admin/profile`, "POST", data);

  //     if (res) {
  //       hideLoader();
  //       console.log(res?.data);
  //       if (res?.er) {
  //         console.log(res?.data?.er);
  //         setOnErrorThemeColor(true);
  //         setTimeout(() => {
  //           setOnErrorProfileLogo(false);
  //         }, 3000);
  //         return;
  //       }

  //       setOnSuccessProfileLogo(true);

  //       setTimeout(() => {
  //         setOnSuccessProfileLogo(false);
  //         window.location.href = "/";
  //       }, 2000);
  //     }
  //   };

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  //   useEffect(() => {
  //     Aos.init({ duration: 500 });
  //     getProfLogo();
  //   }, []);
  //   const postEmptyImage = async (e) => {

  //     showLoader();
  //     const userID = localStorage.getItem("ID");
  //     setCoverImage(null);
  //     const id = null;

  //     console.log(id, "User Id");

  //     await axiosCalls(`s3/upload?filename=${id}`, "POST", coverImage);

  //     const data = {
  //       page: "profile_logo",
  //       profile_logo: id,
  //     };

  //     const res = await axiosCalls(`admin/profile`, "POST", data);

  //     if (res) {
  //       hideLoader();
  //       console.log(res?.data, "ooooooooooooooooooooo");
  //       if (res?.er) {
  //         console.log(res?.data?.er);
  //         setOnErrorProfileLogoDel(true);
  //         setTimeout(() => {
  //           setOnErrorProfileLogoDel(false);
  //         }, 3000);
  //         return;
  //       }

  //       setOnSuccessProfileLogoDel(true);
  //       setTimeout(() => {
  //         setOnSuccessProfileLogoDel(false);
  //       }, 3000);
  //       setDeleteConfirmLogo(false);
  //     }
  //   };
  const getProfLogo = async () => {
    const image_name = localStorage.getItem("Profile Logo Name");

    if (image_name == undefined || image_name == null || image_name == "") {
      return;
    }

    const res = await axiosCalls(`s3/${image_name}`, "GET");

    if (res) {
      if (res?.er) {
        console.log(res?.er);

        console.log("errfff");
        return;
      }

      hideLoader();
      const resData = res.data;
      setCoverImage({ previewUrl: resData });
      setLoadingDefaultLogo(true);

      console.log(resData, "ghghghgh");
    }
  };

  let coverRef;

  const pickerStyles = {
    default: {
      picker: {
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "10px",
        width: "328px",
        // maxWidth: "500px",
        background: "#fbfbfb",
        boxShadow: "none",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "600",
      },
    },
  };

  const saveToStorage = () => {
    console.log(coverImage);
    console.log(color);
    let assets = {
      image: coverImage,
      color,
    };
    console.log(assets);
    let st = JSON.stringify(assets);
    console.log(st);
    localStorage.setItem("assets", st);
  };

  const removeBackdropImg = () => {
    setCoverImage(null);
  };

  const handleGoBack = () => {
    history.goBack();
  };
  return (
    <div className="totalResBodyTeamCustomInfo">
      <div className="teamCustominfo-info-family ">
        <SectionsHeader sectionName="Customise" handleGoBack={handleGoBack} />

        <div className="backDrop-switchFam_tempCust">
          <div className="backdrop-btnGen_tempCust">
            <div
              className="switcher-animation_tempCust"
              style={{ transform: tab === 2 ? "translateX(158px)" : "" }}
            >
              {tab === 1 ? "  Theme" : "Add Logo"}
            </div>
            <div
              // style={{
              //   background: tab === 1 ? "#fff" : "",
              //   color: tab === 1 ? "#171923" : "#000",

              // }}
              className={`useImageSwitch_tempCust 
              
           
              
              
              `}
              onClick={() => {
                handleTab1();
                callSliderRight();
              }}
            >
              Theme
            </div>
            <div
              // style={{
              //   background: tab === 2 ? "#fff" : null,
              //   color: tab === 2 ? "#b42930" : "#000",
              // }}
              className={`useColorSwitch_tempCust 
              
              
           
              
              
              `}
              onClick={() => {
                handleTab2();
                callSliderLeft();
              }}
            >
              Add Logo
            </div>
          </div>

          <div className="dfdfdfdf_tempCust">
            {tab === 1 && (
              <div>
                <SketchPicker
                  styles={pickerStyles}
                  color={color}
                  onChange={(updatedColor) => {
                    setColor(updatedColor.hex);
                    // setSelectedColor(null);
                    // setCoverImage(null);
                  }}
                />
                <div
                  style={{
                    width: "328px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <TeamSwitchButton lockFieldName="Lock theme?" />

                  <div
                    // onClick={() => {

                    //   postProfileTheme();
                    // }}
                    className="backdrop-button___tempCust center-upload-iiibtn___tempCust"
                    style={{
                      // marginTop: "100px",
                      background: color === "" ? `#36A6A4` : `${color}`,
                    }}
                  >
                    Save Theme
                  </div>
                </div>
                <div>
                  <div className="backdrop-Btn_color_tempCust">
                    <div
                      className="backdrop-BackBtn_color_tempCust"
                      onClick={history.goBack}
                    >
                      Go back
                    </div>
                  </div>
                </div>

                {/* <div style={{ marginTop: "50px" }}>
                  <WordsBullet bulletText="Select any of the colors below and have it replace the original" />
                </div> */}
              </div>
            )}

            {tab === 2 && (
              <div>
                <div
                  style={{
                    border: `2px dashed ${
                      color === "" ? `#36A6A4` : `${color}`
                    }`,
                    // background: color === "" ? `#36A6A4` : `${color}`,
                    // opacity: "0.2",
                  }}
                  className="backdrop-img-preview_tempCust"
                >
                  {coverImage === null ? (
                    <div className="backdrop-preview-text_tempCust">
                      <div className="backdrop__textGen_tempCust">
                        <img src={addlogoIcon} />
                        <div className="backdropText__e_tempCust">
                          Click to upload a .png format of your logo
                        </div>
                        <div className="backdropText___tempCust">
                          Max sixe 30mb
                        </div>
                      </div>
                      <div className="backdrop-uploadImgF_tempCust cover-image-bg_tempCust">
                        <div className="genIconCont_back_tempCust">
                          <div
                            className="phone-icon-s_back_tempCust"
                            style={{
                              background: color === "" ? `#36A6A4` : `${color}`,
                            }}
                          ></div>
                          <div className="childIcon_came_tempCust">
                            <CameraIcon
                              fill={color === "" ? `#36A6A4` : `${color}`}
                            />
                          </div>
                        </div>

                        <input
                          type="file"
                          name="coverImage"
                          ref={(refParam) => (coverRef = refParam)}
                          onChange={(e) => {
                            setCoverImage({
                              blob: e.target.files[0],
                              previewUrl: URL.createObjectURL(
                                e.target.files[0]
                              ),
                            });

                            // setColor("");
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <img
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                      src={coverImage?.previewUrl}
                      alt="backdrop image"
                    />
                  )}

                  {coverImage !== null && (
                    <div
                      className="close-backdrop-img_tempCust"
                      onClick={
                        firstProfImage ? handleOpenDelete : handleFirstDelete
                      }
                    >
                      <div className="genIconCont_back_tempCust">
                        <div
                          className="phone-icon-s_back_tempCust"
                          style={{
                            background: color === "" ? `#36A6A4` : `${color}`,
                          }}
                        ></div>
                        <div className="childIcon_back_tempCust">
                          <Cancel
                            fill={color === "" ? `#36A6A4` : `${color}`}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="backdrop-BtnContainerGen_tempCust">
                  <TeamSwitchButton lockFieldName="Lock theme?" />
                  <div
                    className="backdrop-BackBtn_tempCust"
                    onClick={history.goBack}
                  >
                    Go back
                  </div>
                  <div style={{ cursor: "pointer" }}>
                    {coverImage === null ? (
                      <div className="backdrop-upload-btn-center_tempCust">
                        <div
                          className="backdrop-button_tempCust cover-image-bg_tempCust"
                          style={{
                            background: color === "" ? `#36A6A4` : `${color}`,
                          }}
                        >
                          <div>Upload Image </div>
                          <input
                            style={{
                              cursor: "pointer",
                              zIndex: "1000",
                              margin: "0px",
                              padding: "0px",
                            }}
                            type="file"
                            name="coverImage"
                            ref={(refParam) => (coverRef = refParam)}
                            onChange={(e) => {
                              setCoverImage(
                                URL.createObjectURL(e.target.files[0])
                              );
                              // setColor("");
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        // onClick={() => {

                        //   postProfileLogo();

                        //   console.log(coverImage, "cccccccc");
                        // }}
                        className="backdrop-button_tempCust center-upload-iii_tempCust"
                        style={{
                          background: color === "" ? `#36A6A4` : `${color}`,
                        }}
                      >
                        Save image{" "}
                      </div>
                    )}{" "}
                  </div>
                </div>
                {/* <div style={{ marginTop: "50px" }}>
                  <WordsBullet
                    fill={color === "" ? `#36A6A4` : `${color}`}
                    bulletText="Images can always be changed after preview"
                  />
                  <WordsBullet
                    fill={color === "" ? `#36A6A4` : `${color}`}
                    bulletText="Save image and return to your admin"
                  />
                  <WordsBullet
                    fill={color === "" ? `#36A6A4` : `${color}`}
                    bulletText="Go back returns to the previous state"
                  />
                </div> */}
              </div>
            )}
          </div>
        </div>

        {onSuccessThemeColor && (
          // <SuccessPage notifyText="Personal Information Saved" />
          <SuccessAlert alertMessage="😄 Your theme color saved" />
        )}
        {onSuccessProfileLogoDel && (
          // <SuccessPage notifyText="Personal Information Saved" />
          <SuccessAlert alertMessage="😄 Profile Picture Deleted" />
        )}
        {onErrorThemeColor && (
          <FailureAlert alertMessage="Oops, an error occured!" />
        )}
        {onErrorProfileLogoDel && (
          <FailureAlert alertMessage="An error occured! Please try again" />
        )}
        {onSuccessProfileLogo && (
          // <SuccessPage notifyText="Personal Information Saved" />
          <SuccessAlert alertMessage="😄 Your profile logo saved" />
        )}

        {onErrorProfileLogo && (
          <FailureAlert alertMessage="Oops, an error occured!" />
        )}

        {deleteConfirmLogo && (
          <NewModal
            closeNewModal={handleCloseDelete}
            headerName="Delete Logo?"
            // btnName="Got it"
            defaultTheme={color}
            headerColor="#FF0000"
          >
            <div className="delete_logoGen_tempCust">
              Removing this logo will completely take out the logo. Do you want
              to continue?
            </div>
            <div
              //   onClick={() => {
              //     postEmptyImage();
              //   }}
              className="delete_logoBtn_actv_tempCust"
            >
              Yes
            </div>
            <div onClick={handleCloseDelete} className="delete_logoBtn_dis">
              No
            </div>
          </NewModal>
        )}
      </div>
    </div>
  );
};

export default TempCustomize;
