import React from "react";
import noConnectList from "../../assets/icons/noConnectList.svg";
import "./noConnectPage.css";
import { Link } from "react-router-dom";

const NoConnectPage = () => {
  return (
    <>
      <Link
        to="/"
        style={{ textDecoration: "none" }}
        className="no_connectionGen-Fam"
      >
        <div className="no_connectionGen-FamImg">
          <img src={noConnectList} alt="no_connection" />
        </div>

        <div className="no_connectionGenText">You have no connections yet</div>
        <div className="no_connectionTextFam">
          Share your profile with more people and have them connect with you
          directly
        </div>
        <div className="noConnectionBtn-home">Home</div>
      </Link>
    </>
  );
};

export default NoConnectPage;
