import React from "react";
import "./preAccountBtn.css";

const PreAccountBtn = ({ btnFunc, btnText }) => {
  return (
    <div onClick={btnFunc} className="preAcct_getCard">
      {btnText}
    </div>
  );
};

export default PreAccountBtn;
