import React from "react";
import bluepen from "../../assets/icons/bluepen.svg";
import "./penButton.css";
import { Link } from "react-router-dom";

const PenButton = ({ linkPath }) => {
  return (
    <Link to={linkPath}>
      <div className="edit-bluepenicon">
        <img src={bluepen} />
      </div>
    </Link>
  );
};

export default PenButton;
