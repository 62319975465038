import React from "react";
import "./wordsBullet.css";
import defaultBullet from "../../assets/icons/defaultBullet.svg";
import DefaultBullet from "../../assets/icons/DefaultBullet";

const WordsBullet = ({ bulletText, fill }) => {
  return (
    <div className="flexWordsBullet">
      <div className="backdropBullet">
        {/* <img src={defaultBullet} alt="default_bullet" /> */}

        <DefaultBullet fill={fill} />
      </div>
      <div className="backdropBullet-text">{bulletText}</div>
    </div>
  );
};

export default WordsBullet;
