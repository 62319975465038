import React, { useState, useEffect } from "react";
import "./deleteModal.css";
import { useHistory } from "react-router-dom";
import ProfImgCancel from "../../assets/icons/ProfImgCancel";

const DeleteModal = ({
  closeLogoutPage,
  navColor,
  deleteTextHeading,
  deleteTextSubHeading,
  deleteParagraphText,
  firstButtonText,
  secondButtonText,
}) => {
  const history = useHistory();
  const logoutFunc = () => {
    localStorage.clear();
    history.push("/login");
    // window.location.href = "/login";
  };

  useEffect(() => {
    console.log(typeof navColor, "typeof");
    console.log(navColor, "navcolorbb");
  }, []);

  const handleChildClick = (event) => {
    // 👇️ stop event propagation (won't trigger parent's onClick)
    event.stopPropagation();

    // console.log("child clicked");
  };

  return (
    <div onClick={closeLogoutPage} className="logoutGen-modalGenDelete">
      <div className="totalResBodyLog_modalGenDelete">
        <div
          onClick={handleChildClick}
          className="logoutModalGen-content_modalGenDelete"
        >
          <div className="logoutModal-cancelFlex_modalGenDelete">
            <div className="logout-modal-btnText_modalGenDelete ">
              {deleteTextHeading}
            </div>

            <div
              className="close_logout_btn_modalGenDelete"
              onClick={closeLogoutPage}
            >
              {/* <img src={cancelSelect} /> */}

              <ProfImgCancel
                fill={navColor === "" ? `#36A6A4` : `${navColor}`}
              />
            </div>
          </div>

          <div className="logout-preTextF_modalGenDelete">
            {deleteTextSubHeading}
          </div>

          <div className="logout-Mtext_modalGenDelete">
            {deleteParagraphText}
          </div>
          <div className="logout-btnsFlex_modalGenDelete">
            <div
              style={{
                background: navColor === "" ? `#36A6A4` : `${navColor}`,
              }}
              className="logout-Admincontinue-btn_modalGenDelete"
              onClick={logoutFunc}
            >
              {firstButtonText}
            </div>
            <div
              className="logout-Admincancel-btn_modalGenDelete"
              onClick={closeLogoutPage}
            >
              {secondButtonText}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
