import React from "react";
import "./successAlert.css";

const SuccessAlert = ({ alertMessage, alertIcon }) => {
  return (
    <div className="totalResBodySuccessAlert">
      <div className="successBodyGen">
        <div>{alertMessage}</div>
      </div>
    </div>
  );
};

export default SuccessAlert;
