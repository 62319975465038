import React from "react";
import "./newModal.css";
import cancelSelect from "../../assets/icons/cancelSelect.svg";
import DynamicCancel from "../dynamicCancel/DynamicCancel";

const NewModal = ({
  closeNewModal,
  children,
  headerName,
  btnName,
  cancelBtn,
  defaultTheme,
  headerColor,
}) => {
  const handleChildClick = (event) => {
    // 👇️ stop event propagation (won't trigger parent's onClick)
    event.stopPropagation();

    // console.log("child clicked");
  };

  return (
    <div className="newModal-genBody" onClick={closeNewModal}>
      <div onClick={handleChildClick} className="newModal-Content">
        <div className="newModalContent-header">
          <div
            style={{ color: headerColor }}
            className="newModalContent-headerText"
          >
            {headerName}
          </div>
          <div className="newModalContent-headerImg" onClick={closeNewModal}>
            {/* <img src={cancelSelect} alt="new_modal-cancel" /> */}
            <DynamicCancel
              defaultTheme={defaultTheme === "" ? `#36A6A4` : `${defaultTheme}`}
            />
          </div>
        </div>

        {children}

        <div style={{ marginBottom: "50px" }}></div>
        {!btnName ? null : (
          <div className="newModal-Btn" onClick={closeNewModal}>
            {btnName}
          </div>
        )}
      </div>
    </div>
  );
};

export default NewModal;
