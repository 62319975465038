import React from "react";

const EmailIcon = ({ fill }) => {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2008 3.6358V10.0501C15.2008 10.6468 14.9637 11.2191 14.5418 11.6411C14.1198 12.063 13.5475 12.3001 12.9508 12.3001H3.05078C2.45404 12.3001 1.88175 12.063 1.45979 11.6411C1.03783 11.2191 0.800781 10.6468 0.800781 10.0501V3.6358L7.77218 7.738C7.84144 7.77884 7.92038 7.80039 8.00078 7.80039C8.08119 7.80039 8.16012 7.77884 8.22938 7.738L15.2008 3.6358ZM12.9508 0.600098C13.5044 0.600011 14.0386 0.804044 14.4513 1.17316C14.8639 1.54228 15.1259 2.05057 15.1873 2.6008L8.00078 6.8281L0.814281 2.6008C0.875624 2.05057 1.13767 1.54228 1.5503 1.17316C1.96292 0.804044 2.49715 0.600011 3.05078 0.600098H12.9508Z"
        fill={fill}
      />
    </svg>
  );
};

export default EmailIcon;
