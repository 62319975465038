import React from "react";
import "./adminErrorPage.css";
import noUserNew from "../../assets/icons/noUserNew.svg";

const AdminErrorPage = () => {
  const refresh = () => window.location.reload(true);
  return (
    <div className="adminError-genBody">
      <div className="adminError-Content">
        <div className="adminErrorCont-Gen">
          <div className="adminErrorCont-Img">
            <img src={noUserNew} />
          </div>
          <div className="adminErrorheadText">Connection Lost</div>
          <div className="adminErrorParaText">
            We were unable to attend to your request, please try again
          </div>
          <div className="adminError_btn" onClick={refresh}>
            Try again
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminErrorPage;
