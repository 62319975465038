import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import backArrow from "../../../assets/icons/backArrow.svg";
import "./changePassword.css";
import hidePassword from "../../../assets/icons/hidePassword.svg";
import valiPass from "../../../assets/icons/valiPass.svg";
import nonValiPass from "../../../assets/icons/nonValiPass.svg";
import viewPassword from "../../../assets/icons/viewPassword.svg";
import passwordLockIcon from "../../../assets/icons/passwordLockIcon.svg";

import SuccessPage from "../successScreen/SuccessPage";
import Burger from "../adminPage/Burger";
import { DefaultSubmitBtn } from "../../../genComponents/defaultInput/DefaultInput";
import { axiosCalls } from "../../../_api";
import { hideLoader, showLoader } from "../../../loader";
import { DefaultIconInput } from "./defaultIconInput/DefaultIconInput";
import SuccessAlert from "../successAlert/SuccessAlert";
import FailureAlert from "../failureAlert/FailureAlert";
import ValiPass from "../../../assets/icons/ValiPass";

const ChangePassword = () => {
  const [inputValue, setInputValue] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const { oldPassword, newPassword, confirmNewPassword } = inputValue;
  const [isVisibleOne, setIsVisibleOne] = useState(false);
  const [isVisibleTwo, setIsVisibleTwo] = useState(false);
  const [isVisibleThree, setIsVisibleThree] = useState(false);
  const [onSuccessPasswordChange, setOnSuccessPasswordChange] = useState(false);
  const [emailData, setEmailData] = useState();
  const [loading, userLoading] = useState(true);
  const [fetchError, userFetchError] = useState(false);
  const [errorLoad, setErrorLoad] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [profileName, setProfileName] = useState("fionaokpara");
  const [onSuccessPass, setOnSuccessPass] = useState(false);
  const [onErrorPage, setOnErrorPage] = useState(false);
  const [userId, setUserId] = useState("");
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);
  const [lenghtValidated, setLenghtValidated] = useState(false);
  const [maxLenghtValidated, setMaxLenghtValidated] = useState(true);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [defaultColor, setDefaultColor] = useState("");
  const [profImage, setProfImage] = useState(null);
  const [loadingImgName, setLoadingImgName] = useState(false);
  const [userRole, setUserRole] = useState("");

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    getProfile();
    getProfImage();
    setUserId(localStorage.getItem("ID"));
    setUserRole(localStorage.getItem("User Role"));

    console.log(userId, "ddddddddddddddddddd");
  }, []);
  const handleValidateChange = (e) => {
    const lower = new RegExp("(?=.*[a-z])");
    const upper = new RegExp("(?=.*[A-Z])");
    const number = new RegExp("(?=.*[0-9])");
    const special = new RegExp("(?=.*[!@#$%^&*])");
    const lenght = new RegExp("(?=.{6,})");
    const maxLenght = new RegExp("(?=.*.{36})");

    // Lower Case Validation

    if (lower.test(e)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }
    //    Upper Case Validation

    if (upper.test(e)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }

    //  Number Validation

    if (number.test(e)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }

    //  Special Validation

    if (special.test(e)) {
      setSpecialValidated(true);
    } else {
      setSpecialValidated(false);
    }

    //  Lenght Validation

    //    Upper Case Validation

    if (upper.test(e)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }

    //  Number Validation

    if (number.test(e)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }

    //  Lenght Validation

    if (lenght.test(e)) {
      setLenghtValidated(true);
    } else {
      setLenghtValidated(false);
    }

    //  MaxLenght Validation

    //    Upper Case Validation

    if (upper.test(e)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }

    //  Number Validation

    if (number.test(e)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }

    //  Special Validation

    if (maxLenght.test(e)) {
      setMaxLenghtValidated(false);
    } else {
      setMaxLenghtValidated(true);
    }
  };

  const validateConfirmPasswordCheck = () => {
    // var firstName = e.target.value;
    if (confirmNewPassword !== "") {
      if (newPassword !== confirmNewPassword) {
        setConfirmPasswordError(true);
      } else {
        setConfirmPasswordError(false);
      }
      return;
    }

    return;
  };

  useEffect(() => {
    validateConfirmPasswordCheck();
  }, [inputValue]);

  const getProfile = async () => {
    showLoader();

    const res = await axiosCalls(`admin/profile/${profileName}`, "GET");

    if (res) {
      userLoading(false);
      if (res?.er) {
        hideLoader();
        console.log(res?.er);

        // toast.error("Oops, Error Please Refresh", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        setErrorLoad(true);

        console.log("errfff");
        userFetchError(true);
        // <NoUserDisplay />;
        return;
      }

      setErrorLoad(false);
      hideLoader();
      const resData = res?.data?.profile;

      setEmailData(resData);
      setDefaultColor(resData?.theme_color_primary ?? "");

      // setUpdateTheme(resData?.theme_color_primary);
      console.log(">>>>>>>>>>Res", resData);
    }
  };

  const getProfImage = async () => {
    // const image_name = localStorage.getItem("Profile Img");

    const resImage = await axiosCalls(`admin/profile/${profileName}`, "GET");

    if (resImage) {
      if (resImage?.er) {
        return;
      }
    }

    const image_name = resImage?.data?.profile?.profile_image;

    const res = await axiosCalls(`s3/${image_name}`, "GET");

    if (res) {
      if (res?.er) {
        console.log(res?.er);

        console.log("errfff");
        return;
      }

      hideLoader();
      const resData = res.data;
      setProfImage(resData, "prooooooooooooo");

      console.log(resData, "pppppppppppppppppppp");
      console.log(profImage, "proffffffffffrrrrrr");
      setLoadingImgName(true);
    }
  };

  const postPasswordData = async (e) => {
    e.preventDefault();

    const data = {
      profile_id: userId,

      old_pass: oldPassword,
      new_pass: newPassword,
      confirm_new_pass: confirmNewPassword,
    };
    showLoader();
    const res = await axiosCalls(`admin/password/change`, "POST", data);

    if (res) {
      hideLoader();
      console.log(res?.data);
      if (res?.er) {
        console.log(res?.data?.er);
        setOnErrorPage(true);
        setTimeout(() => {
          setOnErrorPage(false);
        }, 3000);
        return;
      }
      // window.location.href = "/";

      setOnSuccessPass(true);

      setTimeout(() => {
        setOnSuccessPass(false);
        window.location.href = "/";
      }, 2000);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log(inputValue);
  };

  const history = useHistory();
  const newPasswordVisiblity1 = () => {
    setIsVisibleOne((isVisibleOne) => !isVisibleOne);
  };

  const newPasswordVisiblity2 = () => {
    setIsVisibleTwo((isVisibleTwo) => !isVisibleTwo);
  };

  const oldPasswordVisiblity = () => {
    setIsVisibleThree((isVisibleThree) => !isVisibleThree);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    postPasswordData(e);
    // console.log(inputValue, "submit");
    // console.log(userId, "id pass");
  };

  return (
    <div className="totalResBodyChangePass">
      <div className="change_password-info-family">
        <div
          style={{
            background: emailData?.apply_theme_to_backdrop
              ? emailData?.theme_color_primary || "#171923"
              : "#171923",
          }}
          className="change_password-info-header"
        >
          <div>
            {/* <img src={backArrow} alt="back_arrow" /> */}
            <Burger
              userRole={userRole}
              profImage={profImage}
              emailData={emailData}
            />
          </div>

          <div className="change_password-info-text">Change password</div>
          <div></div>
        </div>
        {/* <div className="password_text_heading">Personalize your password</div> */}

        <form onSubmit={onSubmit}>
          <div className="password_change-input-family">
            <div className="password_input_flex_hide">
              <div
                className="passwordLock-img"
                // onClick={newPasswordVisiblity1}
              >
                <img src={passwordLockIcon} alt="view_password" />
              </div>
              <div className="change_pass-inputDefault">
                <input
                  type={isVisibleOne ? "text" : "password"}
                  placeholder=" "
                  // value={singleVideoLink.phone}
                  name="oldPassword"
                  // onChange={(e) => handleVideoLinkChange(e, index)}

                  value={oldPassword}
                  onChange={handleChange}
                />
                <span>Enter old password</span>
              </div>

              {/* <DefaultIconInput /> */}
              <div
                className="passwordVisible-animation"
                onClick={newPasswordVisiblity1}
              >
                {isVisibleOne ? (
                  <img src={viewPassword} alt="view_password" />
                ) : (
                  <img src={hidePassword} alt="hide_password" />
                )}
              </div>
            </div>

            <div className="password_input_flex_hide">
              <div
                className="passwordLock-img"
                // onClick={newPasswordVisiblity1}
              >
                <img src={passwordLockIcon} alt="view_password" />
              </div>
              <div class="change_pass-inputDefault">
                <input
                  type={isVisibleTwo ? "text" : "password"}
                  placeholder=" "
                  // value={singleVideoLink.phone}
                  name="newPassword"
                  // onChange={(e) => handleVideoLinkChange(e, index)}

                  value={newPassword}
                  onChange={(e) => {
                    handleChange(e);
                    handleValidateChange(e.target.value);
                  }}
                />
                <span>Enter new password</span>
              </div>
              <div onClick={newPasswordVisiblity2}>
                {isVisibleTwo ? (
                  <img src={viewPassword} alt="view_password" />
                ) : (
                  <img src={hidePassword} alt="hide_password" />
                )}
              </div>
            </div>

            <div className="password_input_flex_hide">
              <div
                className="passwordLock-img"
                // onClick={newPasswordVisiblity1}
              >
                <img src={passwordLockIcon} alt="view_password" />
              </div>
              <div class="change_pass-inputDefault">
                <input
                  type={isVisibleThree ? "text" : "password"}
                  placeholder=" "
                  // value={singleVideoLink.phone}
                  name="confirmNewPassword"
                  // onChange={(e) => handleVideoLinkChange(e, index)}

                  value={confirmNewPassword}
                  onChange={(e) => {
                    handleChange(e);
                    validateConfirmPasswordCheck();
                  }}
                />
                <span>Confirm new password</span>
              </div>
              <div onClick={oldPasswordVisiblity}>
                {isVisibleThree ? (
                  <img src={viewPassword} alt="view_password" />
                ) : (
                  <img src={hidePassword} alt="hide_password" />
                )}
              </div>
            </div>
            {confirmPasswordError && (
              <div className="confirmPass_matchGen">
                Password and Confirm Password does not match
              </div>
            )}

            <div className="chgPassValidCompo">
              <div className="chgPassValidGen">
                {
                  <div className="chgPassValiGen_img">
                    {lenghtValidated ? (
                      <ValiPass
                        fill={
                          defaultColor === "" ? `#36A6A4` : `${defaultColor}`
                        }
                      />
                    ) : (
                      <img src={nonValiPass} />
                    )}
                  </div>
                }
                <div className="chgPassValiText">At least 6 characters</div>
              </div>
              <div className="chgPassValidGen">
                {
                  <div className="chgPassValiGen_img">
                    {lowerValidated ? (
                      <ValiPass
                        fill={
                          defaultColor === "" ? `#36A6A4` : `${defaultColor}`
                        }
                      />
                    ) : (
                      <img src={nonValiPass} />
                    )}
                  </div>
                }
                <div className="chgPassValiText">
                  At least 1 lowecase letter{" "}
                </div>
              </div>
              <div className="chgPassValidGen">
                {
                  <div className="chgPassValiGen_img">
                    {upperValidated ? (
                      <ValiPass
                        fill={
                          defaultColor === "" ? `#36A6A4` : `${defaultColor}`
                        }
                      />
                    ) : (
                      <img src={nonValiPass} />
                    )}
                  </div>
                }
                <div className="chgPassValiText">
                  At least 1 uppercase letter
                </div>
              </div>
              <div className="chgPassValidGen">
                {
                  <div className="chgPassValiGen_img">
                    {numberValidated ? (
                      <ValiPass
                        fill={
                          defaultColor === "" ? `#36A6A4` : `${defaultColor}`
                        }
                      />
                    ) : (
                      <img src={nonValiPass} />
                    )}
                  </div>
                }
                <div className="chgPassValiText">
                  At least 1 numeric character
                </div>
              </div>
              <div className="chgPassValidGen">
                {
                  <div className="chgPassValiGen_img">
                    {specialValidated ? (
                      <ValiPass
                        fill={
                          defaultColor === "" ? `#36A6A4` : `${defaultColor}`
                        }
                      />
                    ) : (
                      <img src={nonValiPass} />
                    )}
                  </div>
                }
                <div className="chgPassValiText">
                  At least 1 special character
                </div>
              </div>
              <div className="chgPassValidGen">
                {
                  <div className="chgPassValiGen_img">
                    {maxLenghtValidated ? (
                      <ValiPass
                        fill={
                          defaultColor === "" ? `#36A6A4` : `${defaultColor}`
                        }
                      />
                    ) : (
                      <img src={nonValiPass} />
                    )}
                  </div>
                }
                <div className="chgPassValiText">Maximum 36 characters</div>
              </div>
            </div>

            {/* <div className="change-password-form">
              <input type="submit" value="Save" />
            </div> */}

            <div className="changePasswordForm-gen">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "90%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <DefaultSubmitBtn
                  defaultColor={
                    defaultColor === "" ? `#36A6A4` : `${defaultColor}`
                  }
                  styleList={{
                    opacity:
                      !oldPassword ||
                      !confirmNewPassword ||
                      !newPassword ||
                      !lowerValidated ||
                      !upperValidated ||
                      !numberValidated ||
                      !specialValidated ||
                      !lenghtValidated ||
                      confirmPasswordError ||
                      !maxLenghtValidated
                        ? "0.8"
                        : "1",
                  }}
                  disabled={
                    !oldPassword ||
                    !confirmNewPassword ||
                    !newPassword ||
                    !lowerValidated ||
                    !upperValidated ||
                    !numberValidated ||
                    !specialValidated ||
                    !lenghtValidated ||
                    confirmPasswordError ||
                    !maxLenghtValidated
                  }
                  valueText="Change password"
                />
              </div>
            </div>
          </div>
        </form>

        {/* {onSuccessPasswordChange && (
          <SuccessPage notifyText="Password Changed" />
        )} */}

        {onSuccessPass && (
          // <SuccessPage notifyText="Contact Information Saved" />

          <SuccessAlert alertMessage="😄 Password changed successfully" />
        )}
        {onErrorPage && <FailureAlert alertMessage="Oops, an error occured!" />}
      </div>
    </div>
  );
};

export default ChangePassword;
