import React from "react";

const DefaultBullet = ({ fill }) => {
  return (
    <div>
      <svg
        width="7"
        height="8"
        viewBox="0 0 7 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M7 4C7 4.69223 6.79473 5.36892 6.41014 5.9445C6.02556 6.52007 5.47893 6.96867 4.83939 7.23358C4.19985 7.49849 3.49612 7.5678 2.81718 7.43275C2.13825 7.2977 1.51461 6.96436 1.02513 6.47487C0.535642 5.98539 0.2023 5.36175 0.0672515 4.68282C-0.0677968 4.00388 0.00151493 3.30015 0.266422 2.66061C0.531328 2.02107 0.979932 1.47444 1.5555 1.08986C2.13108 0.705271 2.80777 0.5 3.5 0.5V4H7Z"
          fill={fill}
        />
        <circle cx="3.5" cy="4" r="2.5" fill={fill} />
      </svg>
    </div>
  );
};

export default DefaultBullet;
