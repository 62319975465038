import './defaultInputPrefix.css'


export const DefaultInputPrefix = ({
    value,
    name,
    onChange,
    placeholder,
    spanText,
    type,
    required,
  }) => {
    return (
      <div className="inputFieldPersonalizePrefix">
        <input
          type={type}
          value={value}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          className="no-auto-zoom"
        />
        <span> {spanText}</span>
      </div>
    );
  };