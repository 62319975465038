import React, { useState, useEffect } from "react";
import "./editLink.css";
import backArrow from "../../../assets/icons/backArrow.png";
import minus from "../../../assets/icons/minus.svg";
import plus from "../../../assets/icons/plus.svg";
import cameraIcon2 from "../../../assets/icons/cameraIcon2.png";
import linkPhoIcon from "../../../assets/icons/linkPhoIcon.svg";
import linkPhoIconBla from "../../../assets/icons/linkPhoIconBla.svg";

import { useHistory, useLocation, useParams } from "react-router-dom";
import { showLoader, hideLoader } from "../../../loader";
import { axiosCalls } from "../../../_api";
import SuccessPage from "../successScreen/SuccessPage";
import SectionsHeader from "../../../genComponents/sectionsHeader/SectionsHeader";
import {
  DefaultHalfInputLink,
  DefaultInput,
  DefaultSubmitBtn,
} from "../../../genComponents/defaultInput/DefaultInput";
import {
  DefaultMinusBtn,
  DefaultMinusBtnInput,
  DefaultMinusBtnSecond,
} from "../../../genComponents/defaultMinusBtn/DefaultMinusBtn";
import EditLinkThumbNail from "./editLinkThumbNail/EditLinkThumbNail";
import SuccessAlert from "../successAlert/SuccessAlert";
import FailureAlert from "../failureAlert/FailureAlert";
import uuid from "react-uuid";
import AddNew from "../../../assets/icons/AddNew";
import { DefaultInputPrefix } from "../../../genComponents/defaultInputPrefix/DefaultInputPrefix";

const EditLink = () => {
  const unique_id = uuid();

  const [thumbImg, setThumbImg] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  // const [editLinks, setEditLinks] = useState([
  //   { title: null, link_image_url: null, url: "" },
  // ]);
  const [onSuccessLinkPage, setOnSuccessLinkPage] = useState(false);
  const [onErrorPage, setOnErrorPage] = useState(false);

  const [thumbModal, setThumbModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [defaultColor, setDefaultColor] = useState("");

  const [inputValue, setInputValue] = useState([
    {
      // link_id: unique_id,
      title: null,
      link_image_url: null,
      url: "",
      isValid: true,
      inputStartsHttp: false,
    },
  ]);
  const [inputValueBtn, setInputValueBtn] = useState([
    {
      // link_id: unique_id,
      title: null,
      link_image_url: null,
      url: "",
      isValid: true,
      inputStartsHttp: false,
    },
  ]);

  const [url, setUrl] = useState("");
  const [isValidt, setIsValidt] = useState(true);
  const [inputStartsHttpCheck, setInputStartsHttpCheck] = useState(false);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleInputChange = (e, index) => {
    const prefixTextOne = "ftp://";
    const prefixText = "https://";
    const prefixTextOld = "http://";
    // const whatsAppPrefix = "https://wa.me/";
    // const whatsAppPrefixOld = "http://wa.me/";
    const inputData = [...inputValue];
    inputData[index].url = e.target.value;
    const value = e.target.value;

    if (value?.toLowerCase()?.startsWith(prefixText)) {
      inputData[index].url = value?.slice(8);
    } else if (value?.toLowerCase()?.startsWith(prefixTextOld)) {
      inputData[index].url = value?.slice(7);
    } else if (value?.toLowerCase()?.startsWith(prefixTextOne)) {
      inputData[index].url = value?.slice(6);
    } else {
      inputData[index].url = value;
    }

    setInputValue(inputData);

    // if (inputData[index].url.startsWith(prefixText || prefixTextOld)) {
    //   inputData[index].inputStartsHttp = true;
    //   setInputStartsHttpCheck(true);
    // } else {
    //   inputData[index].inputStartsHttp = false;
    //   setInputStartsHttpCheck(false);
    // }
    // setInputValue(inputData);

    // const value = event.target.value;
    // setUrl(value);
    // validateUrl(inputData[index].url, index);
  };

  const validateUrl = (value, index) => {
    const urlRegex = /^(http[s]?:\/\/|www\.|ftp:\/\/)/;

    if (urlRegex.test(value)) {
      const inputData = [...inputValue];
      inputData[index].isValid = true;
      setInputValue(inputData);
      setIsValidt(true);
    } else {
      const inputData = [...inputValue];
      inputData[index].isValid = false;
      setInputValue(inputData);
      setIsValidt(false);
    }
  };

  console.log("uuid", unique_id);
  useEffect(() => {
    getLinkData();
  }, []);

  const getLinkData = () => {
    let profNavLink = localStorage.getItem("Link");
    const parsedArr = JSON.parse(profNavLink);
    setInputValue(parsedArr);
    setInputValueBtn(parsedArr);

    console.log("local storage", inputValue);
    console.log("parsed", parsedArr);

    setDefaultColor(localStorage.getItem("Default Theme"));
  };

  const postProfileData = async (e) => {
    e.preventDefault();

    const data = {
      page: "link",
      link: inputValue,
    };
    showLoader();
    const res = await axiosCalls(`admin/profile`, "POST", data);

    if (res) {
      hideLoader();
      console.log(res?.data);
      if (res?.er) {
        console.log(res?.data?.er);
        setOnErrorPage(true);
        setTimeout(() => {
          setOnErrorPage(false);
        }, 3000);
        return;
      }
      // window.location.href = "/";

      setOnSuccessLinkPage(true);

      setTimeout(() => {
        setOnSuccessLinkPage(false);
        window.location.href = "/";
      }, 2000);
    }
  };

  const handleTitleChange = (e, index) => {
    const inputData = [...inputValue];
    inputData[index].title = e.target.value;
    setInputValue(inputData);
    console.log(inputValue);
  };

  const handleUrlChange = (e, index) => {
    const inputData = [...inputValue];
    inputData[index].url = e.target.value;
    setInputValue(inputData);
    console.log(inputValue);
  };

  const handleAddLinksImgAdd = () => {
    const abc = [
      ...inputValue,
      {
        // link_id: unique_id,
        title: null,
        link_image_url: null,
        url: "",
      },
    ];
    setInputValue(abc);
    setInputValueBtn(abc);
    console.log(inputValue);
  };

  const handleDeleteLinksTitleAdd = (index) => {
    const deleteVal = [...inputValue];
    deleteVal?.splice(index, 1);
    setInputValue(deleteVal);

    // setInputValue((oldValues) => {
    //   return oldValues.filter((inputValue) => inputValue.link_id !== id);
    // });
  };

  const history = useHistory();

  // const handleLinksAdd = () => {
  //   setEditLinks([
  //     ...editLinks,
  //     {
  //       title: null,
  //       link_image_url: null,
  //       url: "",
  //     },
  //   ]);
  // };

  // const handleLinksRemove = (index) => {
  //   const list = [...editLinks];
  //   list.splice(index, 1);
  //   setEditLinks(list);
  // };

  // const handleLinksChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const list = [...editLinks];
  //   list[index][name] = value;
  //   setEditLinks(list);
  // };

  // const handleLinksTitleChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const list = [...editLinks];
  //   list[index][name] = value;
  //   setEditLinks(list);

  //   console.log(editLinks);
  // };

  const handleLinkImageChange = (selectedLinkImg, index) => {
    const inputData = [...inputValue];

    inputData[index].link_image_url = selectedLinkImg;
    setInputValue(inputData);
    // console.log(editLinks);
  };

  // const handleLinksImageChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const list = [...editLinks];
  //   list[index][name] = value;
  //   setEditLinks(list);
  // };

  let coverRef;

  const handleThumbModal = () => {
    setThumbModal((prevOpen) => !prevOpen);
  };

  const prefixText = "https://";

  const onSubmit = (e) => {
    // postProfileData();
    // setOnSuccessLinkPage(true);
    e.preventDefault();
    console.log(inputValue);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className="totalResLinkBody">
      <div className="editLink-info-family">
        <SectionsHeader sectionName="Links" handleGoBack={handleGoBack} />
        <form onSubmit={postProfileData}>
          <div className="editLink-flexxBodyGen">
            {inputValue?.map((singleEditLink, index) => (
              <div style={{ marginBottom: "20px" }}>
                {/* <div className="modi-flexxx">
                  <div className="editLink-imgContFam">
                    {singleEditLink.link_image_url === null ? null : (
                      <div>
                        <img
                          src={singleEditLink.link_image_url}
                          alt="link_img"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                            position: "absolute",
                            left: "0",
                            right: "0",
                            marginRight: "auto",
                            marginLeft: "auto",
                            borderRadius: "21px",
                          }}
                        />
                      </div>
                    )}
                    <div
                      className="img-editLink-icon"
                      onClick={handleThumbModal}
                    >
                   

                      <img
                        src={
                          singleEditLink.link_image_url === null
                            ? linkPhoIcon
                            : linkPhoIconBla
                        }
                      />
                    </div>
                  </div>

                  <div>
                    <DefaultHalfInputLink
                      type="text"
                      placeholder=" "
                      value={singleEditLink?.title}
                      name="linksTitle"
                      onChange={(e) => handleTitleChange(e, index)}
                      spanText="Enter title (Optional)"
                    />
                  </div>
                </div> */}

                <div style={{ position: "relative" }}>
                  <DefaultInput
                    type="text"
                    placeholder=" "
                    value={singleEditLink?.title}
                    name="linksTitle"
                    onChange={(e) => handleTitleChange(e, index)}
                    spanText="Enter title (Optional)"
                    required=""
                  />

                  {/* {inputValue.length > 1 && ( */}
                  <DefaultMinusBtnSecond
                    onClick={() => {
                      handleDeleteLinksTitleAdd(index);
                      setIsValidt(true);
                    }}
                  />
                  {/* )} */}
                </div>

                <div style={{ position: "relative" }}>
                  <div className="prefix_genLink">{prefixText}</div>
                  <DefaultInputPrefix
                    // type="url"
                    placeholder=" "
                    // value={singleEditLink?.url}
                    name="links"
                    // onChange={(e) => {
                    //   handleUrlChange(e, index);

                    // }}
                    spanText="Type/paste link"
                    required={true}
                    type="text"
                    value={singleEditLink?.url}
                    onChange={(e) => {
                      handleInputChange(e, index);
                    }}
                  />

                  {/* {singleEditLink.isValid === false ? (
                    <p className="url_validText">
                      Please enter a valid URL, prefix must start with either
                      www. or http:// or https:// or ftp://
                    </p>
                  ) : null} */}
                </div>
                {/* {singleEditLink.inputStartsHttp ? (
                  <p className="url_validText">
                    Please remove prefix that starts with either http:// or
                    https:// or https://wa.me/
                  </p>
                ) : null} */}
              </div>
            ))}

            {inputValue?.length < 10 && (
              <div className="add-links-family">
                <div
                  onClick={handleAddLinksImgAdd}
                  className="add-phone-cont"
                  style={{
                    color: defaultColor === "" ? `#36A6A4` : `${defaultColor}`,
                  }}
                >
                  <div className="addCon_svg">
                    <AddNew
                      fill={defaultColor === "" ? `#36A6A4` : `${defaultColor}`}
                    />
                  </div>
                  <div className="addCon_tex">Add new </div>
                </div>
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <DefaultSubmitBtn
                disabled={
                  inputValueBtn?.length < 1 || inputStartsHttpCheck === true
                }
                valueText="Save"
                onClick={() => {
                  postProfileData();
                }}
                defaultColor={defaultColor}
              />
            </div>
          </div>
        </form>
        {thumbModal && (
          <div>
            {inputValue?.map((singleEditLink, index) => (
              <EditLinkThumbNail
                closeThumbModal={handleThumbModal}
                setThumbImg={setThumbImg}
                changeImgFunc={(setThumbImg) => {
                  handleLinkImageChange(setThumbImg, index);
                }}
                thumbImg={thumbImg}
              />
            ))}
          </div>
        )}

        {onSuccessLinkPage && (
          // <SuccessPage notifyText="Contact Information Saved" />

          <SuccessAlert alertMessage="😄 Link saved" />
        )}
        {onErrorPage && <FailureAlert alertMessage="Oops, an error occured!" />}
        {disabled && <FailureAlert alertMessage="Oops, Invalid Link" />}
      </div>
    </div>
  );
};

export default EditLink;
