import React, { useState, useEffect } from "react";
// import BackdropHeader from "./BackdropHeader";
import "./backdrop.css";
import backArrow from "../../../assets/icons/backArrow.svg";
import checkIcon from "../../../assets/icons/checkIcon.svg";
import lockIcon from "../../../assets/icons/lockIcon.png";
import closeBackdropImg from "../../../assets/icons/closeBackdropImg.svg";

import addlogoIcon from "../../../assets/icons/addlogoIcon.svg";

import cameraIcon from "../../../assets/icons/cameraIcon.svg";
import { useHistory, useLocation } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { SketchPicker } from "react-color";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import SuccessPage from "../successScreen/SuccessPage";
import WordsBullet from "../../../genComponents/wordsBullet/WordsBullet";
import SectionHeaderCancel from "../../../genComponents/sectionHeaderCancel/SectionHeaderCancel";
import { ToastContainer, toast } from "react-toastify";
import { axiosCalls } from "../../../_api";
import "react-toastify/dist/ReactToastify.css";
import SuccessAlert from "../successAlert/SuccessAlert";
import FailureAlert from "../failureAlert/FailureAlert";
import { showLoader, hideLoader } from "../../../loader";
import Cancel from "../../../assets/icons/Cancel";
import CameraIcon from "../../../assets/icons/CameraIcon";
import NewModal from "../../../genComponents/newModal/NewModal";
import Switch from "react-switch";
import AdminSwitchComp from "../../../genComponents/adminSwitchComp/AdminSwitchComp";

const Backdrop = ({}) => {
  // const [coverImage, setCoverImage] = useState(null);
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [color, setColor] = useState("#FFFFFF");
  const [customColor, setCustomColor] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [color, setColor] = useState("");
  const [defaultThemeBoo, setDefaultThemeBoo] = useState();
  const [coverImage, setCoverImage] = useState(null);
  const [defaultImage, setDefaultImage] = useState(null);

  const [onSuccessBackdropPage, setOnSuccessBackdropPage] = useState(false);

  const [onSuccessThemeColor, setOnSuccessThemeColor] = useState(false);
  const [onErrorThemeColor, setOnErrorThemeColor] = useState(false);

  const [tab, setTab] = useState(1);
  const [backdropSlider, setBackdropSlider] = useState(0);
  const [onSuccessProfileLogo, setOnSuccessProfileLogo] = useState(false);
  const [onErrorProfileLogo, setOnErrorProfileLogo] = useState(false);
  const [loadingDefaultLogo, setLoadingDefaultLogo] = useState(false);
  const [deleteConfirmLogo, setDeleteConfirmLogo] = useState(false);
  const [defaultTheme, setDefaultTheme] = useState("");
  const [onErrorProfileLogoDel, setOnErrorProfileLogoDel] = useState(false);
  const [onSuccessProfileLogoDel, setOnSuccessProfileLogoDel] = useState(false);
  const [firstProfImage, setfirstProfImage] = useState(null);
  const [checked, setChecked] = useState();

  const handleCheckedChange = () => {
    setChecked(!checked);
  };

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleCloseDelete = () => {
    setDeleteConfirmLogo(false);
  };

  const handleOpenDelete = () => {
    setDeleteConfirmLogo(true);
  };

  const handleFirstDelete = () => {
    setCoverImage(null);
  };
  useEffect(() => {
    // setID(localStorage.getItem("ID"));
    setColor(localStorage.getItem("Default Theme"));
    setfirstProfImage(localStorage.getItem("Profile Logo Name"));

    const savedBackdropValue = localStorage.getItem("Backdrop Theme");

    const parsedBackdropValue = savedBackdropValue
      ? JSON.parse(savedBackdropValue)
      : false;

    setChecked(parsedBackdropValue);

    console.log(color, "cooooollll");
  }, []);

  const handleChangeComplete = (color) => {
    //  this.setState({ background: color.hex });

    setColor(color.hex);
  };

  const handleTab1 = () => {
    setTab(1);
  };
  const callSliderRight = () => {
    setBackdropSlider(1);
  };
  const callSliderLeft = () => {
    setBackdropSlider(2);
  };

  const handleTab2 = () => {
    setTab(2);
  };

  const postProfileTheme = async (e) => {
    // e.preventDefault();

    const data = {
      page: "theme_color_primary",
      theme_color_primary: color,
      apply_theme_to_backdrop: checked,
    };
    showLoader();
    const res = await axiosCalls(`admin/profile`, "POST", data);

    if (res) {
      hideLoader();
      console.log(res?.data);
      if (res?.er) {
        console.log(res?.data?.er);
        setOnErrorThemeColor(true);
        setTimeout(() => {
          setOnErrorThemeColor(false);
        }, 3000);
        return;
      }
      // window.location.href = "/";

      setOnSuccessThemeColor(true);

      setTimeout(() => {
        setOnSuccessThemeColor(false);
        window.location.href = "/";
      }, 2000);
    }
  };

  const postProfileLogo = async (e) => {
    // e.preventDefault();

    // const data = {
    //   page: "profile_logo",
    //   profile_logo: coverImage,
    // };
    // showLoader();
    // const res = await axiosCalls(`admin/profile`, "POST", data);

    showLoader();
    const userID = localStorage.getItem("ID");

    const id = `profile_${userID}_${new Date().getTime()}.${
      coverImage?.blob.type.split("/")[1]
    }`; //replace userID

    console.log(id, "User Id");
    console.log(coverImage, "prof");
    await axiosCalls(`s3/upload?filename=${id}`, "POST", coverImage?.blob);

    const data = {
      page: "profile_logo",
      profile_logo: id,
    };

    const res = await axiosCalls(`admin/profile`, "POST", data);

    if (res) {
      hideLoader();
      console.log(res?.data);
      if (res?.er) {
        console.log(res?.data?.er);
        setOnErrorThemeColor(true);
        setTimeout(() => {
          setOnErrorProfileLogo(false);
        }, 3000);
        return;
      }
      // window.location.href = "/";

      setOnSuccessProfileLogo(true);

      setTimeout(() => {
        setOnSuccessProfileLogo(false);
        window.location.href = "/";
      }, 2000);
    }
  };

  const history = useHistory();

  useEffect(() => {
    Aos.init({ duration: 500 });
    getProfLogo();
  }, []);
  const postEmptyImage = async (e) => {
    // e.preventDefault();

    showLoader();
    const userID = localStorage.getItem("ID");
    setCoverImage(null);
    const id = null;

    console.log(id, "User Id");

    await axiosCalls(`s3/upload?filename=${id}`, "POST", coverImage);

    const data = {
      page: "profile_logo",
      profile_logo: id,
    };

    const res = await axiosCalls(`admin/profile`, "POST", data);

    if (res) {
      hideLoader();
      console.log(res?.data, "ooooooooooooooooooooo");
      if (res?.er) {
        console.log(res?.data?.er);
        setOnErrorProfileLogoDel(true);
        setTimeout(() => {
          setOnErrorProfileLogoDel(false);
        }, 3000);
        return;
      }

      setOnSuccessProfileLogoDel(true);
      setTimeout(() => {
        setOnSuccessProfileLogoDel(false);
      }, 3000);
      setDeleteConfirmLogo(false);
    }
  };
  const getProfLogo = async () => {
    const image_name = localStorage.getItem("Profile Logo Name");

    if (image_name == undefined || image_name == null || image_name == "") {
      return;
    }

    const res = await axiosCalls(`s3/${image_name}`, "GET");

    if (res) {
      if (res?.er) {
        console.log(res?.er);

        console.log("errfff");
        return;
      }

      hideLoader();
      const resData = res.data;
      setCoverImage({ previewUrl: resData });
      // setProfImg(resData);
      setLoadingDefaultLogo(true);

      console.log(resData, "ghghghgh");
    }
  };
  // const handleCoverImageChange = (event) => {
  //   console.log(event.target.files[0]);
  //   setCoverImage(event.target.files[0]);
  // };

  // const showToastMessage = () => {
  //   toast.success("Success Notification !", {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });
  // };

  // useEffect(() => {
  //   getNavAssets();
  // }, []);

  // const getNavAssets = () => {
  //   let defaultCoverImg = localStorage.getItem("Cover Image");

  //   // let profNavEmailAdd = localStorage.getItem("Contact");
  //   // const parsedArr = JSON.parse(profNavEmailAdd);
  //   // setNavEmailAdd(parsedArr);

  //   if (
  //     defaultCoverImg == undefined ||
  //     defaultCoverImg == null ||
  //     defaultCoverImg == ""
  //   ) {
  //     setCoverImage(lockIcon);
  //   } else {
  //     setCoverImage(defaultCoverImg);
  //   }

  //   console.log("Cover Image", coverImage);

  //   let assets = localStorage.getItem("assets");
  //   if (assets == undefined || assets == null || assets == "") {
  //     return;
  //   }
  //   let parseData = JSON.parse(assets);

  //   setDefaultImage(parseData?.image);
  // };

  let coverRef;

  const pickerStyles = {
    default: {
      picker: {
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "10px",
        width: "328px",
        // maxWidth: "500px",
        background: "#fbfbfb",
        boxShadow: "none",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "600",
      },
    },
  };

  const saveToStorage = () => {
    console.log(coverImage);
    console.log(color);
    let assets = {
      image: coverImage,
      color,
    };
    console.log(assets);
    let st = JSON.stringify(assets);
    console.log(st);
    localStorage.setItem("assets", st);
  };

  const removeBackdropImg = () => {
    setCoverImage(null);
    // localStorage.removeItem('assets')
  };

  console.log(defaultThemeBoo, "default Theme boo222222");
  return (
    <div className="totalResBackBody">
      <div className="backdrop-family-cont">
        <ToastContainer />
        <SectionHeaderCancel
          defaultTheme={color}
          defaultThemeBoo={checked}
          saveUpdate={() => {
            postProfileTheme();
            postProfileLogo();
          }}
        />
        {/* <div className="backdrop-select-text">
          <div>Add backdrop</div>
        </div> */}

        <div className="backDrop-switchFam">
          <div className="backdrop-btnGen">
            <div
              className="switcher-animation"
              style={{ transform: tab === 2 ? "translateX(158px)" : "" }}
            >
              {tab === 1 ? "  Theme" : "Add Logo"}
            </div>
            <div
              // style={{
              //   background: tab === 1 ? "#fff" : "",
              //   color: tab === 1 ? "#171923" : "#000",

              // }}
              className={`useImageSwitch 
              
           
              
              
              `}
              onClick={() => {
                handleTab1();
                callSliderRight();
              }}
            >
              Theme
            </div>
            <div
              // style={{
              //   background: tab === 2 ? "#fff" : null,
              //   color: tab === 2 ? "#b42930" : "#000",
              // }}
              className={`useColorSwitch 
              
              
           
              
              
              `}
              onClick={() => {
                handleTab2();
                callSliderLeft();
              }}
            >
              Add Logo
            </div>
          </div>

          <div className="dfdfdfdf">
            {tab === 1 && (
              <div>
                <SketchPicker
                  styles={pickerStyles}
                  color={color}
                  onChange={(updatedColor) => {
                    setColor(updatedColor.hex);
                    // setSelectedColor(null);
                    // setCoverImage(null);
                  }}
                />
                <div>
                  <AdminSwitchComp
                    handleCheckedChange={handleCheckedChange}
                    checked={checked}
                  />
                  <div
                    onClick={() => {
                      // saveToStorage();
                      // history.goBack();
                      postProfileTheme();
                      // setOnSuccessBackdropPage(true);
                    }}
                    className="backdrop-button__ center-upload-iiibtn__"
                    style={{
                      marginTop: "100px",
                      background: color === "" ? `#36A6A4` : `${color}`,
                    }}
                  >
                    Save Theme
                  </div>
                </div>
                <div style={{ marginTop: "5px" }}>
                  <div className="backdrop-Btn_color">
                    <div
                      className="backdrop-BackBtn_color"
                      onClick={history.goBack}
                    >
                      Go back
                    </div>
                  </div>
                </div>

                {/* <div style={{ marginTop: "50px" }}>
                  <WordsBullet bulletText="Select any of the colors below and have it replace the original" />
                </div> */}
              </div>
            )}

            {tab === 2 && (
              <div>
                <div
                  style={{
                    border: `2px dashed ${
                      color === "" ? `#36A6A4` : `${color}`
                    }`,
                    // background: color === "" ? `#36A6A4` : `${color}`,
                    // opacity: "0.2",
                  }}
                  className="backdrop-img-preview"
                >
                  {coverImage === null ? (
                    <div className="backdrop-preview-text">
                      <div className="backdrop__textGen">
                        <img src={addlogoIcon} />
                        <div className="backdropText__e">
                          Click to upload a .png format of your logo
                        </div>
                        <div className="backdropText__">Max sixe 30mb</div>
                      </div>
                      <div className="backdrop-uploadImgF cover-image-bg">
                        <div className="genIconCont_back">
                          <div
                            className="phone-icon-s_back"
                            style={{
                              background: color === "" ? `#36A6A4` : `${color}`,
                            }}
                          ></div>
                          <div className="childIcon_came">
                            <CameraIcon
                              fill={color === "" ? `#36A6A4` : `${color}`}
                            />
                          </div>
                        </div>

                        <input
                          type="file"
                          name="coverImage"
                          ref={(refParam) => (coverRef = refParam)}
                          onChange={(e) => {
                            setCoverImage({
                              blob: e.target.files[0],
                              previewUrl: URL.createObjectURL(
                                e.target.files[0]
                              ),
                            });

                            // setColor("");
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <img
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                      src={coverImage?.previewUrl}
                      alt="backdrop image"
                    />
                  )}

                  {coverImage !== null && (
                    <div
                      className="close-backdrop-img"
                      onClick={
                        firstProfImage ? handleOpenDelete : handleFirstDelete
                      }
                    >
                      <div className="genIconCont_back">
                        <div
                          className="phone-icon-s_back"
                          style={{
                            background: color === "" ? `#36A6A4` : `${color}`,
                          }}
                        ></div>
                        <div className="childIcon_back">
                          <Cancel
                            fill={color === "" ? `#36A6A4` : `${color}`}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="backdrop-BtnContainerGen">
                  <div className="backdrop-BackBtn" onClick={history.goBack}>
                    Go back
                  </div>
                  <div style={{ cursor: "pointer" }}>
                    {coverImage === null ? (
                      <div className="backdrop-upload-btn-center">
                        <div
                          className="backdrop-button cover-image-bg"
                          style={{
                            background: color === "" ? `#36A6A4` : `${color}`,
                          }}
                        >
                          <div>Upload Image </div>
                          <input
                            style={{
                              cursor: "pointer",
                              zIndex: "1000",
                              margin: "0px",
                              padding: "0px",
                            }}
                            type="file"
                            name="coverImage"
                            ref={(refParam) => (coverRef = refParam)}
                            onChange={(e) => {
                              setCoverImage(
                                URL.createObjectURL(e.target.files[0])
                              );
                              // setColor("");
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          // saveToStorage();

                          // setOnSuccessBackdropPage(true);

                          postProfileLogo();

                          console.log(coverImage, "cccccccc");
                        }}
                        className="backdrop-button center-upload-iii"
                        style={{
                          background: color === "" ? `#36A6A4` : `${color}`,
                        }}
                      >
                        Save image{" "}
                      </div>
                    )}{" "}
                  </div>
                </div>
                {/* <div style={{ marginTop: "50px" }}>
                  <WordsBullet
                    fill={color === "" ? `#36A6A4` : `${color}`}
                    bulletText="Images can always be changed after preview"
                  />
                  <WordsBullet
                    fill={color === "" ? `#36A6A4` : `${color}`}
                    bulletText="Save image and return to your admin"
                  />
                  <WordsBullet
                    fill={color === "" ? `#36A6A4` : `${color}`}
                    bulletText="Go back returns to the previous state"
                  />
                </div> */}
              </div>
            )}
          </div>
        </div>

        {/* {customColor && (
          <SketchPicker
            styles={pickerStyles}
            color={color}
            onChange={(updatedColor) => {
              setColor(updatedColor.hex);
              setSelectedColor(null);
              setCoverImage(null);
            }}
            style={{ marginTop: "-100px" }}
          />
        )} */}

        {onSuccessThemeColor && (
          // <SuccessPage notifyText="Personal Information Saved" />
          <SuccessAlert alertMessage="😄 Your theme color saved" />
        )}
        {onSuccessProfileLogoDel && (
          // <SuccessPage notifyText="Personal Information Saved" />
          <SuccessAlert alertMessage="😄 Profile Picture Deleted" />
        )}
        {onErrorThemeColor && (
          <FailureAlert alertMessage="Oops, an error occured!" />
        )}
        {onErrorProfileLogoDel && (
          <FailureAlert alertMessage="An error occured! Please try again" />
        )}
        {onSuccessProfileLogo && (
          // <SuccessPage notifyText="Personal Information Saved" />
          <SuccessAlert alertMessage="😄 Your profile logo saved" />
        )}

        {onErrorProfileLogo && (
          <FailureAlert alertMessage="Oops, an error occured!" />
        )}
      </div>
      {deleteConfirmLogo && (
        <NewModal
          closeNewModal={handleCloseDelete}
          headerName="Delete Logo?"
          // btnName="Got it"
          defaultTheme={color}
          headerColor="#FF0000"
        >
          <div className="delete_logoGen">
            Removing this logo will completely take out the logo. Do you want to
            continue?
          </div>
          <div
            onClick={() => {
              // handleProfImgCancel();
              postEmptyImage();
            }}
            className="delete_logoBtn_actv"
          >
            Yes
          </div>
          <div onClick={handleCloseDelete} className="delete_logoBtn_dis">
            No
          </div>
        </NewModal>
      )}
    </div>
  );
};

export default Backdrop;
