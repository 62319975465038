import React, { useState } from "react";
import "./forgotPasswordOtp.css";
import OtpInput from "react-otp-input";
import { Link, useHistory } from "react-router-dom";

import backArrow from "../../../assets/icons/backArrow.svg";

const ForgotPasswordOtp = () => {
  const [otp, setOtp] = useState("");

  const [isFocused, setIsFocused] = useState(false);

  const [counter, setCounter] = React.useState(60);
  const [activeBtn, setActiveBtn] = useState(true);

  const history = useHistory();

  const handleOnchangeOtp = () => {
    setOtp();
    console.log(otp, "otp");
  };

  // Third Attempts
  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  React.useEffect(() => {
    console.log(otp, "ootp");
    if (otp?.length >= 4) {
      setActiveBtn(false);
    } else {
      setActiveBtn(true);
    }
  }, [otp]);
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleGoBack = () => {
    history.goBack(); // Go back to the previous page
  };

  const handleVerifyClick = () => {
    history.push("/inputpassword");
  };
  const handleBlur = () => {
    setIsFocused(false);
  };
  return (
    <div className="totalResBodyFPassOtp">
      <div className="fPass-info-familyOtp">
        <div className="fPass-info-header_auth">
          <div style={{ cursor: "pointer" }} onClick={handleGoBack}>
            <img src={backArrow} alt="back_arrow" />
          </div>

          <div className="fPass-info-text_auth">
            {/* <img src={deetsBlackLogo} alt="deets_logo" /> */}
            Forgot password
          </div>
          <div></div>
        </div>{" "}
        <div className="otp_subHeading_text">Enter code</div>
        <div className="inputStyle">
          <OtpInput
            value={otp}
            onChange={setOtp}
            inputStyle={{ width: "33px", marginRight: "10px", height: "39px" }}
            numInputs={4}
            renderSeparator={<span> </span>}
            renderInput={(props) => (
              <input
                className={isFocused ? "input-focused" : "input"}
                {...props}
                onFocus={handleFocus}
              />
            )}
            // onFocus={handleFocus}
            inputType="number"
          />
        </div>
        <div className="otp_subHeading_timer">
          <div className="otp_subHeading_timerText">Expires in</div>
          <div className="otp_subHeading_timerCounter">00:{counter}</div>
        </div>
        <button
          disabled={activeBtn}
          onClick={handleVerifyClick}
          className="otp_subHeading_verify"
          style={{
            opacity: activeBtn && "0.4",
            cursor: activeBtn ? "not-allowed" : "pointer",
          }}
        >
          Verify
        </button>
        <button
          style={{ border: !counter ? "1px solid #838383" : "none" }}
          disabled={!counter}
          className="otp_subHeading_resend"
        >
          Resend
        </button>
      </div>
    </div>
  );
};

export default ForgotPasswordOtp;
