import React, { useEffect } from "react";
import SectionNavHamburger from "../../../genComponents/sectionNavHamburger/SectionNavHamburger";
import "./feedBackPage.css";
import feedbackBullet from "../../../assets/icons/feedbackBullet.svg";
import {
  FeedbackBigBtn,
  FeedbackSmallBtn,
} from "../../../genComponents/feedBackBtns/FeedbackBtns";

import { DefaultInput } from "../../../genComponents/defaultInput/DefaultInput";
import { useState } from "react";
import DefaultTextArea from "../../../genComponents/defaultTextArea/DefaultTextArea";
import { axiosCalls } from "../../../_api";
import { hideLoader, showLoader } from "../../../loader";
import SuccessAlert from "../successAlert/SuccessAlert";
import FailureAlert from "../failureAlert/FailureAlert";
import FeedBackBullet from "../../../assets/icons/FeedBackBullet";
import { useLocation } from "react-router-dom";

const FeedBackPage = () => {
  const [feedBackSelect, setFeedBackSelect] = useState(0);
  const [feedBackTitle, setfeedBackTitle] = useState("");
  const [titleContent, setTitleContent] = useState("");
  const [emailData, setEmailData] = useState();
  const [loading, userLoading] = useState(true);
  const [fetchError, userFetchError] = useState(false);
  const [errorLoad, setErrorLoad] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [profileName, setProfileName] = useState("fionaokpara");
  const [onErrorFeedPage, setOnErrorFeedPage] = useState(false);
  const [onSuccessFeedPage, setOnSuccessFeedPage] = useState(false);
  const [profileId, setProfileId] = useState();
  const [navColor, setNavColor] = useState("");
  const [profImage, setProfImage] = useState(null);
  const [loadingImgName, setLoadingImgName] = useState(false);
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    getProfile();
    getProfImage();
    setUserRole(localStorage.getItem("User Role"));
  }, []);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  //   const getData = () => {

  //   setNavColor(emailData?.theme_color_primary ?? "");
  // };

  const getProfile = async () => {
    showLoader();

    const res = await axiosCalls(`admin/profile/${profileName}`, "GET");

    if (res) {
      userLoading(false);
      if (res?.er) {
        hideLoader();
        console.log(res?.er);

        // toast.error("Oops, Error Please Refresh", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        setErrorLoad(true);

        console.log("errfff");
        userFetchError(true);
        // <NoUserDisplay />;
        return;
      }

      setErrorLoad(false);
      hideLoader();
      const resData = res?.data?.profile;

      setEmailData(resData);
      setProfileId(resData?.id);
      setNavColor(resData?.theme_color_primary ?? "");

      // setUpdateTheme(resData?.theme_color_primary);
      console.log(">>>>>>>>>>Res", resData);
    }
  };
  const getProfImage = async () => {
    // const image_name = localStorage.getItem("Profile Img");

    const resImage = await axiosCalls(`admin/profile/${profileName}`, "GET");

    if (resImage) {
      if (resImage?.er) {
        return;
      }
    }

    const image_name = resImage?.data?.profile?.profile_image;

    const res = await axiosCalls(`s3/${image_name}`, "GET");

    if (res) {
      if (res?.er) {
        console.log(res?.er);

        console.log("errfff");
        return;
      }

      hideLoader();
      const resData = res.data;
      setProfImage(resData, "prooooooooooooo");

      console.log(resData, "pppppppppppppppppppp");
      console.log(profImage, "proffffffffffrrrrrr");
      setLoadingImgName(true);
    }
  };
  const postFeedBackData = async (e) => {
    e.preventDefault();

    const data = {
      type: "feedback",
      rating: feedBackSelect,
      do_better_title: feedBackTitle,
      do_better: titleContent,
      profile_id: profileId,
    };
    showLoader();
    const res = await axiosCalls(`public/save`, "POST", data);

    if (res) {
      hideLoader();
      console.log(res?.data);
      if (res?.er) {
        console.log(res?.data?.er);
        setOnErrorFeedPage(true);
        setTimeout(() => {
          setOnErrorFeedPage(false);
        }, 3000);
        return;
      }
      // window.location.href = "/";

      setOnSuccessFeedPage(true);

      setTimeout(() => {
        setOnSuccessFeedPage(false);
        //  window.location.href = "/";
      }, 3000);

      setfeedBackTitle("");
      setTitleContent("");
      setFeedBackSelect(0);
    }
  };

  const handleSelectOne = () => {
    setFeedBackSelect(1);
  };

  const handleSelectTwo = () => {
    setFeedBackSelect(2);
  };

  const handleSelectThree = () => {
    setFeedBackSelect(3);
  };

  const handleSelectFour = () => {
    setFeedBackSelect(4);
  };

  const handleSelectFive = () => {
    setFeedBackSelect(5);
  };

  const handleTitleChange = (e) => {
    setfeedBackTitle(e.target.value);
  };

  const handleTitleContent = (e) => {
    setTitleContent(e.target.value);
  };

  return (
    <div className="totalResBodyFeedback">
      <SectionNavHamburger
        emailData={emailData}
        profImage={profImage}
        userRole={userRole}
        hamText="Feedback"
      />

      <div className="feedbackBody-padding">
        <div className="feedbackBody-head">
          <div className="feedbackBody-headImg">
            {/* <img src={feedbackBullet} /> */}

            <FeedBackBullet
              fill={navColor === "" ? `#36A6A4` : `${navColor}`}
            />
          </div>
          <div
            style={{ color: navColor === "" ? `#36A6A4` : `${navColor}` }}
            className="feedbackBody-headText"
          >
            Enjoying Deets?
          </div>
        </div>

        <div className="feedback-textQues">
          How would you rate your experience with DEETS?
        </div>
        <div className="feedbackGenFlexOne">
          <FeedbackSmallBtn
            feedBackText="Very Satisfied"
            feedBackNo={1}
            onClick={handleSelectOne}
            feedBackSelect={feedBackSelect}
            fill={
              feedBackSelect === 1
                ? "#f2f2f2"
                : navColor === ""
                ? `#36A6A4`
                : `${navColor}`
            }
            navColor={navColor}
          />
          <FeedbackSmallBtn
            feedBackText="Satisfied"
            feedBackNo={2}
            onClick={handleSelectTwo}
            feedBackSelect={feedBackSelect}
            fill={
              feedBackSelect === 1
                ? "#f2f2f2"
                : navColor === ""
                ? `#36A6A4`
                : `${navColor}`
            }
            navColor={navColor}
          />
          <FeedbackSmallBtn
            feedBackText="Neutral"
            feedBackNo={3}
            onClick={handleSelectThree}
            feedBackSelect={feedBackSelect}
            fill={
              feedBackSelect === 1
                ? "#f2f2f2"
                : navColor === ""
                ? `#36A6A4`
                : `${navColor}`
            }
            navColor={navColor}
          />
          <FeedbackSmallBtn
            feedBackText="Dissatisfied"
            feedBackNo={4}
            onClick={handleSelectFour}
            feedBackSelect={feedBackSelect}
            fill={
              feedBackSelect === 1
                ? "#f2f2f2"
                : navColor === ""
                ? `#36A6A4`
                : `${navColor}`
            }
            navColor={navColor}
          />
          <FeedbackSmallBtn
            feedBackText=" Very Dissatisfied"
            feedBackNo={5}
            onClick={handleSelectFive}
            feedBackSelect={feedBackSelect}
            fill={
              feedBackSelect === 1
                ? "#f2f2f2"
                : navColor === ""
                ? `#36A6A4`
                : `${navColor}`
            }
            navColor={navColor}
          />
        </div>

        <div className="feedbackMarginLine"></div>

        <div className="feedbackMarginText">
          What do you think we can do better?
        </div>
        <form className="feedBack-form-cont ">
          <DefaultInput
            name="feedBackTitle"
            spanText="Title"
            type="text"
            value={feedBackTitle}
            onChange={(e) => {
              handleTitleChange(e);
            }}
            placeholder=" "
          />

          <DefaultTextArea
            value={titleContent}
            name="titleContent"
            onChange={(e) => {
              handleTitleContent(e);
            }}
            placeholder="Type here"
          />

          <div
            style={{ background: navColor === "" ? `#36A6A4` : `${navColor}` }}
            className="feedBack-submitBtn"
            onClick={postFeedBackData}
          >
            Send
          </div>
        </form>
      </div>

      {onSuccessFeedPage && (
        // <SuccessPage notifyText="Personal Information Saved" />
        <SuccessAlert alertMessage="😄 Thanks for your feedback" />
      )}

      {onErrorFeedPage && (
        <FailureAlert alertMessage="Oops, an error occured!" />
      )}
    </div>
  );
};

export default FeedBackPage;
