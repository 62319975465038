import React from "react";
import PenButton from "../penButton/PenButton";
import "./sectionNavigator.css";

const SectionNavigator = ({ sectionName, linkPath }) => {
  return (
    <div className="about-header">
      <div className="about-header-text">{sectionName}</div>

      <PenButton linkPath={linkPath} />
    </div>
  );
};

export default SectionNavigator;
