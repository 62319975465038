import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./login.css";
import { FiEye } from "react-icons/fi";

import backArrow from "../../../assets/icons/backArrow.svg";
// import passwordHide from "../../../assets/icons/passwordHide.png";
// import passwordHide from "../../../assets/icons/passwordHide.png";
import hidePassword from "../../../assets/icons/hidePassword.svg";
import viewPassword from "../../../assets/icons/viewPassword.svg";
import { axiosCalls } from "../../../_api";
import Switch from "react-switch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
import { borderBottom } from "@mui/system";
import { showLoader, hideLoader } from "../../../loader";
import FormLabel from "./FormLabel";
import DefaultPassInput from "./DefaultPassInput";
import Header from "./Header";
import AuthHeadings from "./AuthHeadings";
import AuthFooter from "./AuthFooter";
import FailureAlert from "../failureAlert/FailureAlert";

const Login = () => {
  const [auth, setAuth] = useState({
    email: "",
    password: "",
  });
  const [checked, setChecked] = useState(false);

  const [isVisible, setIsVisible] = useState(false);

  const [emailAd, setEmailAd] = useState("");
  const [passwordAd, setPasswordAd] = useState("");

  const [emailError, setEmailError] = useState("");
  const [onErrorPage, setOnErrorPage] = useState(false);
  // const [PasswordErrorMessage, setPasswordErrorMessage] = useState("");

  // const validatePassword = (value) => {
  //   if (
  //     validator.isStrongPassword(value, {
  //       minLength: 8,
  //       minLowercase: 1,
  //       minUppercase: 1,
  //       minNumbers: 1,
  //       minSymbols: 1,
  //     })
  //   ) {
  //     setPasswordErrorMessage("Is Strong Password");
  //   } else {
  //     setPasswordErrorMessage("Is Not Strong Password");
  //   }
  // };

  // useEffect(() => {
  //   if (localStorage === null) {
  //     console.log("local storage is empty");
  //     const notify = () =>
  //       toast.error("Log Out Successful", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });

  //     notify();
  //   }
  // });

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const passwordVisiblity = () => {
    setIsVisible((isVisible) => !isVisible);
  };

  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Please Enter valid a Email!");
    }
  };

  const loginAuth = async (e) => {
    e.preventDefault();

    const data = {
      email: auth.email,
      pass: auth.password,
    };
    showLoader();
    const res = await axiosCalls("login", "POST", data);
    if (res) {
      hideLoader();
      console.log(res);
      if (res.er) {
        // setOnErrorPage(true);
        // setTimeout(() => {
        //   setOnErrorPage(false);
        // }, 3000);
        console.log(res?.er?.data);
        // const notify = () =>
        //   toast.error(res?.er?.data?.message, {
        //     position: "top-right",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //   });

        // notify();

        toast.error(res?.er?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        return;
      }
      console.log(">>>>>>>>RES", res?.data);
      localStorage.setItem("token", res?.data?.jwt?.token);

      window.location.href = "/";
    }
  };

  const handleCheckedChange = () => {
    setChecked(!checked);
  };

  const inputEmailFunc = (e) => {
    setAuth({ ...auth, email: e.target.value });
    validateEmail(e);
    setEmailAd(e.target.value);
  };

  const inputPasswordFunc = (e) => {
    setAuth({ ...auth, password: e.target.value });
    setPasswordAd(e.target.value);
    // validatePassword(e.target.value);
  };
  return (
    <div className="totalResBodyLogin">
      <div className="logout-info-family">
        <Header />

        <AuthHeadings
          headText="Login"
          subHeadText="Securely log into your Deets account"
        />
        <form className="formBodyGenFam" onSubmit={loginAuth}>
          <FormLabel
            labelText="Email"
            inputType="email"
            placeholder="e.g. foo@bar.com"
            name="uname"
            onChange={(e) => inputEmailFunc(e)}
            emailAd={emailAd}
            emailError={emailError}
          />
          {/* 
          {emailAd !== "" ? (
            <div className="email_errorCont">{emailError}</div>
          ) : null} */}

          <DefaultPassInput
            passType={isVisible ? "text" : "password"}
            placeholder="**************"
            name="pass"
            onChange={(e) => inputPasswordFunc(e)}
            passwordVisiblity={passwordVisiblity}
            isVisible={isVisible}
            labelText="Password"
          />

          {/* {PasswordErrorMessage !== "" ? (
          <span
            style={{
              fontWeight: "bold",
              color: "red",
            }}
          >
            {PasswordErrorMessage}
          </span>
        ) : null} */}

          {/* <div className="remember-me-fam">
            <div className="rem-text">Remember me?</div>
            <div className="login_switch_btn">
              <Switch
                onChange={handleCheckedChange}
                checked={checked}
                onColor="#36a6a4"
                onHandleColor="#36a6a4"
                handleDiameter={30}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={18}
                width={45}
                className="react-switch"
                id="material-switch"
              />
            </div>
          </div> */}
          <div
            className="login-save-form"
            style={{ opacity: !emailAd || !passwordAd ? "0.3" : "1" }}
          >
            <div className="login_btn-save">
              <input
                type="submit"
                value="Log In"
                disabled={!emailAd || !passwordAd}
              />
            </div>
          </div>
        </form>
        <div className="forgot-pass-line">
          <Link
            style={{ textDecoration: "none" }}
            to="/forgot_password"
            className="forgot-link_pre"
          >
            Forgot password?
          </Link>
        </div>
        {/* <AuthFooter
          linkText="Don’t have an account?"
          linkTo="/signup"
          // linkTo="#"
          linkNameText="Sign Up"
        /> */}

        <ToastContainer />

        {onErrorPage && (
          <FailureAlert alertMessage="invalid user or password" />
        )}
      </div>
    </div>
  );
};

export default Login;
