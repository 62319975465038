import React from "react";
import "./defaultTextArea.css";

const DefaultTextArea = ({ value, name, onChange, placeholder }) => {
  return (
    <>
      <div className="inputFieldPersonalArea">
        <textarea
          rows={4}
          cols={22}
          maxLength={250}
          //   placeholder="About yourself"
          //   value={about}
          //   onChange={(e) => setAbout(e.target.value)}

          value={value}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          className="no-auto-zoom"
        />
      </div>
    </>
  );
};

export default DefaultTextArea;
