import React, { useState, useEffect } from "react";
import "./profileImg.css";
import { useHistory, useLocation } from "react-router-dom";
import SectionHeaderCancel from "../../../genComponents/sectionHeaderCancel/SectionHeaderCancel";
import cameraIcon from "../../../assets/icons/cameraIcon.svg";
import cancelProfImg from "../../../assets/icons/cancelProfImg.svg";

import WordsBullet from "../../../genComponents/wordsBullet/WordsBullet";
import SuccessPage from "../successScreen/SuccessPage";
import { RenderCropper } from "../../cropper/cropper";
import { hideLoader, showLoader } from "../../../loader";
import { axiosCalls } from "../../../_api";
import SuccessAlert from "../successAlert/SuccessAlert";
import FailureAlert from "../failureAlert/FailureAlert";
import NewModal from "../../../genComponents/newModal/NewModal";
import { ThreeDots } from "react-loader-spinner";
import ProfImgCancel from "../../../assets/icons/ProfImgCancel";

const ProfileImg = () => {
  const [open, setOpen] = React.useState(false);

  const [profImg, setProfImg] = useState(null);
  const [onSuccessBackdropPage, setOnSuccessBackdropPage] = useState(false);
  const [showCropper, setShowCropper] = React.useState(false);
  const [onSuccessProfileImage, setOnSuccessProfileImage] = useState(false);
  const [onErrorProfileImage, setOnErrorProfileImage] = useState(false);
  const [defaultProfImage, setDefaultProfImage] = useState(null);
  const [loadingDefaultImg, setLoadingDefaultImg] = useState(false);
  const [defaultTheme, setDefaultTheme] = useState("");
  const [defaultThemeBoo, setDefaultThemeBoo] = useState();

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [onSuccessProfileImageDel, setOnSuccessProfileImageDel] =
    useState(false);
  const [onErrorProfileImageDel, setOnErrorProfileImageDel] = useState(false);
  const [firstProfImage, setfirstProfImage] = useState(null);
  const [pageData, setPageData] = useState();
  const image_name = localStorage.getItem("Profile Image Name");

  const anchorRef = React.useRef(null);

  const handleDeleteOpen = () => {
    setDeleteConfirm(true);
  };

  const handleFirstDelete = () => {
    setProfImg(null);
  };
  const handleDeleteClose = () => {
    setDeleteConfirm(false);
  };

  const history = useHistory();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  // const uploadHandler = async (filename, file) => {
  //   try {
  //     const response = await axios({
  //       url: `https://api.enterpriseinsight.co:8085/v1/deets/s3/upload?filename=${encodeURIComponent(
  //         filename
  //       )}`,
  //       data: file,
  //       method: "POST",
  //     });
  //     return response;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const postProfileImage = async (e) => {
  //   // e.preventDefault();

  //   const data = {
  //     page: "profile_image",
  //     profile_image: profImg,
  //   };

  //   // const id = `logo_3cd2c75f-5e17-4985-b93e-407fbe4a15b0_${file.name}`;
  //   // const id = `profile_3cd2c75f-5e17-4985-b93e-407fbe4a15b0_${file.name}`;

  //   const id = `profile_${userID}_${new Date().getTime()}${
  //     profImg.type.split("/")[1]
  //   }`;
  //   showLoader();
  //   const res = await axiosCalls(`s3/upload?filename=s.jpg`, "POST", data);

  //   if (res) {
  //     hideLoader();
  //     console.log(res?.data);
  //     if (res?.er) {
  //       console.log(res?.data?.er);
  //       setOnErrorProfileImage(true);
  //       setTimeout(() => {
  //         setOnErrorProfileImage(false);
  //       }, 3000);
  //       return;
  //     }
  //     // window.location.href = "/";

  //     setOnSuccessProfileImage(true);

  //     setTimeout(() => {
  //       setOnSuccessProfileImage(false);
  //       // window.location.href = "/";
  //     }, 2000);
  //   }
  // };

  const postEmptyImage = async (e) => {
    // e.preventDefault();

    showLoader();
    const userID = localStorage.getItem("ID");
    setProfImg(null);
    const id = null;

    console.log(id, "User Id");
    console.log(profImg, "prof");
    await axiosCalls(`s3/upload?filename=${id}`, "POST", profImg);

    const data = {
      page: "profile_image",
      profile_image: id,
    };

    const res = await axiosCalls(`admin/profile`, "POST", data);

    if (res) {
      hideLoader();
      console.log(res?.data, "ooooooooooooooooooooo");
      if (res?.er) {
        console.log(res?.data?.er);
        setOnErrorProfileImageDel(true);
        setTimeout(() => {
          setOnErrorProfileImageDel(false);
        }, 3000);
        return;
      }
      // window.location.href = "/";
      console.log(res, "ressssssssssssssssssss");
      setOnSuccessProfileImageDel(true);
      setTimeout(() => {
        setOnSuccessProfileImageDel(false);
      }, 3000);
      setDeleteConfirm(false);
    }
  };

  const postProfileImage = async (e) => {
    // e.preventDefault();

    showLoader();
    const userID = localStorage.getItem("ID");

    const id = `profile_${userID}_${new Date().getTime()}.${
      profImg?.blob.type.split("/")[1]
    }`; //replace userID

    console.log(id, "User Id");
    console.log(profImg, "prof");
    await axiosCalls(`s3/upload?filename=${id}`, "POST", profImg?.blob);

    const data = {
      page: "profile_image",
      profile_image: id,
    };

    const res = await axiosCalls(`admin/profile`, "POST", data);

    if (res) {
      hideLoader();
      console.log(res?.data, "ooooooooooooooooooooo");
      if (res?.er) {
        console.log(res?.data?.er);
        setOnErrorProfileImage(true);
        setTimeout(() => {
          setOnErrorProfileImage(false);
        }, 3000);
        return;
      }
      // window.location.href = "/";
      console.log(res, "ressssssssssssssssssss");

      setTimeout(() => {
        setOnSuccessProfileImage(true);
        window.location.href = "/";
      }, 3000);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    // getDefaultProfImg();
    getProfImage();
    setDefaultTheme(localStorage.getItem("Default Theme"));
    setfirstProfImage(localStorage.getItem("Profile Image Name"));

    const savedThemeValue = localStorage.getItem("Backdrop Theme");

    const parsedValue = savedThemeValue ? JSON.parse(savedThemeValue) : false;
    setDefaultThemeBoo(parsedValue);

    // const prof = localStorage.getItem("Default Profile Image");
    // setProfImg({ previewUrl: prof });
  }, []);
  console.log(defaultThemeBoo, "defaultTheme boooooo");
  const getProfImage = async () => {
    const image_name = localStorage.getItem("Profile Image Name");

    if (image_name == undefined || image_name == null || image_name == "") {
      return;
    }

    console.log("cccccccccccccccc");

    const res = await axiosCalls(`s3/${image_name}`, "GET");

    if (res) {
      if (res?.er) {
        console.log(res?.er);

        console.log("errfff");
        return;
      }

      hideLoader();
      const resData = res.data;
      setProfImg({ previewUrl: resData });
      // setProfImg(resData);
      setLoadingDefaultImg(true);

      console.log(resData, "ghghghgh");
    }
  };

  // const getDefaultProfImg = () => {
  //   const initProfilePics = localStorage.getItem("");
  //   if (
  //     initProfilePics == undefined ||
  //     initProfilePics == null ||
  //     initProfilePics == ""
  //   ) {
  //     return;
  //   }
  //   // const parsedArr = JSON.parse(initProfilePics);
  //   setProfImg(initProfilePics);
  // };

  const handleCropper = () => setShowCropper((prevValue) => !prevValue);

  let profileRef;

  const handleProfImgCancel = () => {
    setProfImg(null);
  };

  const saveToStorage = () => {
    let profAssets = {
      image: profImg,
    };
    console.log(profAssets);
    console.log("Profile Image", profImg);
    // let stf = JSON.stringify(profAssets);
    // console.log(stf);
    // localStorage.setItem("profAssets", stf);
  };

  return (
    <div className="totalResProfBody">
      <div className="profile-imgBodyGen">
        <SectionHeaderCancel
          defaultThemeBoo={defaultThemeBoo}
          defaultTheme={defaultTheme}
          saveUpdate={() => {
            postProfileImage();
          }}
        />
        {/* {loadingDefaultImg ? ( */}
        <>
          <div
            style={{
              border: `4px solid ${
                defaultTheme === "" ? `#36A6A4` : `${defaultTheme}`
              }`,
              // background: color === "" ? `#36A6A4` : `${color}`,
              // opacity: "0.2",
            }}
            className="profile-imgFrame"
          >
            <div className="profile-uploadedImgText">Image preview</div>

            {profImg !== null && (
              <div className="profile-uploadedImgPre">
                <img src={profImg?.previewUrl} alt="profile_img" />
              </div>
            )}
            {profImg === null ? (
              <div
                className="profile-imgUploadBtn"
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(event) => {
                  handleCropper();
                  handleClose(event);
                }}
              >
                <img src={cameraIcon} alt="up_icon" />
                {/* <input
                type="file"
                name="profileImage"
                ref={(refParam) => (profileRef = refParam)}
                onChange={(e) => {
                  setProImg(URL.createObjectURL(e.target.files[0]));
                }}
              /> */}
              </div>
            ) : (
              <div
                className="cancel_profImgSec"
                // onClick={handleProfImgCancel}
                onClick={firstProfImage ? handleDeleteOpen : handleFirstDelete}
              >
                {/* <img src={cancelProfImg} alt="cancel_Img" /> */}
                <ProfImgCancel
                  fill={defaultTheme === "" ? `#36A6A4` : `${defaultTheme}`}
                />
              </div>
            )}
          </div>
          <div className="profileImg-btnFamGen">
            {profImg === null ? (
              <div className="uploadProf-BtnFam">
                <div className="changeProf-btn" onClick={history.goBack}>
                  Go back
                  {/* <input
                  type="file"
                  name="profileImage"
                  ref={(refParam) => (profileRef = refParam)}
                  onChange={(e) => {
                    setProImg(URL.createObjectURL(e.target.files[0]));
                  }}
                /> */}
                </div>
                <div
                  className="selectProf-ImgBtn"
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={open ? "composition-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={(event) => {
                    handleCropper();
                    handleClose(event);
                  }}
                  style={{
                    background:
                      defaultTheme === "" ? `#36A6A4` : `${defaultTheme}`,
                  }}
                >
                  Select image
                  {/* <input
                type="file"
                name="profileImage"
                ref={(refParam) => (profileRef = refParam)}
                onChange={(e) => {
                  setProImg(URL.createObjectURL(e.target.files[0]));
                }}
              /> */}
                </div>
                {/* {image_name !== null && (
                    <div
                      onClick={() => {
                        setProfImg(null);
                      }}
                      className="selectProf-ImgBtn"
                      style={{ marginTop: "20px" }}
                    >
                      Clear Profile Image
                    </div>
                  )} */}
              </div>
            ) : (
              <div className="uploadProf-BtnFam">
                <div
                  className="changeProf-btn"
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={open ? "composition-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={(event) => {
                    handleCropper();
                    handleClose(event);
                  }}
                >
                  Change photo{" "}
                  {/* <input
                  type="file"
                  name="profileImage"
                  ref={(refParam) => (profileRef = refParam)}
                  onChange={(e) => {
                    setProImg(URL.createObjectURL(e.target.files[0]));
                  }}
                /> */}
                </div>
                <div
                  onClick={() => {
                    // saveToStorage();
                    postProfileImage();
                    console.log(profImg, "proffffff");

                    // setOnSuccessBackdropPage(true);
                  }}
                  style={{
                    background:
                      defaultTheme === "" ? `#36A6A4` : `${defaultTheme}`,
                  }}
                  className="uploadPro-mainBtn"
                >
                  Upload
                </div>
              </div>
            )}
          </div>

          <div style={{ marginLeft: "30px", marginTop: "50px" }}>
            <WordsBullet
              fill={defaultTheme === "" ? `#36A6A4` : `${defaultTheme}`}
              bulletText="File size must be less than 2MB"
            />
          </div>
        </>
        {/* ) : ( */}
        {/* <div className="_imageLoader">
            <ThreeDots
              height="70"
              width="70"
              radius="9"
              color="#777777"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div> */}
        {/* )} */}

        {/* {onSuccessBackdropPage && (
          <SuccessPage notifyText="Profile Picture saved successfully" />
        )} */}
        {onSuccessProfileImage && (
          // <SuccessPage notifyText="Personal Information Saved" />
          <SuccessAlert alertMessage="😄 Profile Picture saved " />
        )}

        {onSuccessProfileImageDel && (
          // <SuccessPage notifyText="Personal Information Saved" />
          <SuccessAlert alertMessage="😄 Profile Picture Deleted" />
        )}
        {onErrorProfileImage && (
          <FailureAlert alertMessage="Oops, an error occured!" />
        )}
        {onErrorProfileImageDel && (
          <FailureAlert alertMessage="An error occured! Please try again" />
        )}
        {showCropper && (
          <RenderCropper handleCropper={handleCropper} setAvatar={setProfImg} />
        )}
      </div>

      {deleteConfirm && (
        <NewModal
          closeNewModal={handleDeleteClose}
          headerName="Delete image?"
          // btnName="Got it"
          defaultTheme={defaultTheme}
          headerColor="#FF0000"
        >
          <div className="delete_imageGen">
            Removing this image will leave your profile image blank. Do you want
            to continue?
          </div>
          <div
            onClick={() => {
              // handleProfImgCancel();
              postEmptyImage();
            }}
            className="delete_imageBtn_actv"
          >
            Yes
          </div>
          <div onClick={handleDeleteClose} className="delete_imageBtn_dis">
            No
          </div>
        </NewModal>
      )}
    </div>
  );
};

export default ProfileImg;
