import React, { useState, useEffect } from "react";
import Burger from "../adminPage/Burger";
import "./preAccountPage.css";
import shareicon from "../../../assets/icons/shareicon.svg";
import deetslogo from "../../../assets/icons/deetslogo.svg";
import mailIconh from "../../../assets/icons/mailIconh.svg";
import minusAcct from "../../../assets/icons/minusAcct.svg";
import addAcct from "../../../assets/icons/addAcct.svg";
import preAcctEmail from "../../../assets/icons/preAcctEmail.svg";
import cardBullet from "../../../assets/icons/cardBullet.svg";
import cardCheckedBullet from "../../../assets/icons/cardCheckedBullet.svg";

import { Link } from "react-router-dom";
import PreAccountBtn from "./preAccountBtn/PreAccountBtn";

import NewModal from "../../../genComponents/newModal/NewModal";

import { DefaultInput } from "../../../genComponents/defaultInput/DefaultInput";
import ReactSwitch from "react-switch";

const PreAccountPage = () => {
  const [user, setUser] = useState({
    first_name: "Abdul",
    last_name: "Rahmanni",
    email: "AdbulRahman@icloud.com",
  });

  const [cardModal, setCardModal] = useState(false);
  const [cardName, setCardName] = useState("");
  const [checked, setChecked] = useState(true);
  const [cardPrice, setCardPrice] = useState(20000);

  let [num, setNum] = useState(1);

  const [cardDesignModal, setCardDesignModal] = useState(false);
  const [selectDesign, setSelectDesign] = useState(0);

  const handleCheckChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  useEffect(() => {
    handleLogoPrice();
  }, [checked]);

  const handleLogoPrice = () => {
    if (checked) {
      setCardPrice(cardPrice + 5000);
    } else {
      setCardPrice(cardPrice - 5000);
    }
  };

  const handleInputPrice = (e) => {
    setCardPrice(Number(e.target.value) * 20000);
  };
  const handleCardNameChange = (e) => {
    setCardName(e.target.value);
    console.log(cardName);
  };
  const showCardModal = () => {
    setCardModal(true);
    setCardDesignModal(false);
  };

  const closeCardModal = () => {
    setCardModal(false);
    setCardDesignModal(true);
  };

  const closeCardModalAll = () => {
    setCardModal(false);
    setCardDesignModal(false);
  };

  const showCardDesignModal = () => {
    setCardDesignModal(true);
  };

  const closeCardDesignModalPro = () => {
    setCardDesignModal(false);
    setCardModal(true);
  };
  const closeCardDesignModalAll = () => {
    setCardDesignModal(false);
    setCardModal(false);
  };
  let incNum = () => {
    if (num < 10) {
      setNum(Number(num) + 1);
      setCardPrice(cardPrice + 20000);
    }
  };
  let decNum = () => {
    if (num > 1) {
      setNum(num - 1);
      setCardPrice(cardPrice - 20000);
    }
  };
  let handleChange = (e) => {
    setNum(e.target.value);
  };
  return (
    <div className="totalResBodyPreAccount">
      <div className="sectionHam-preAccount">
        <div>
          <Burger />
        </div>

        <div className="sectionHam-preAccount-text">
          <img src={deetslogo} alt="deets_logo" />
        </div>
        <Link to="/share_link" style={{ textDecoration: "none" }}>
          <div className="shareicon-preAccount">
            <div className="share-preAccount">
              <img src={shareicon} alt="navicon" />
            </div>
          </div>
        </Link>
      </div>

      <div className="preAcct_profile-pics-cont">
        <div className="preAcct_backdrop-cont">
          <div
            className="preAcct_backdrop-img-main"
            // style={{ background: `${localAssets?.color}` }}
            style={{ background: "#ffffff" }}
          ></div>
        </div>
        <div className="preAcct_cont-all-avatar">
          <div className="preAcct_user-profile-pics">
            <div className="preAcct_uploaded-img">
              <div className="preAcct_user-profile-pics-text">
                {user?.first_name.toUpperCase().slice(0, 1)}
                {user?.last_name.toUpperCase().slice(0, 1)}
              </div>
            </div>
          </div>
          <div className="preAcct_edit-profile-pics-family"></div>
        </div>
        <div className="preAcct_user-profile-details">
          {user?.first_name && user?.last_name !== "" ? (
            <div className="preAcct_user-name">
              {user?.first_name} {user?.last_name}
            </div>
          ) : (
            <div className="preAcct_user-name">Name</div>
          )}
        </div>
      </div>

      <PreAccountBtn btnFunc={showCardModal} btnText="Get card" />
      <div className="preAcctContact_head">Contact info</div>

      {user?.email && (
        <>
          {/* <a
            href={`mailto:${mail}`}
            target="_blank"
            key={idx}
            style={{ textDecoration: "none" }}
          > */}
          <div className="preAcct_email-contAd">
            <div className="preAcct_phone-first-flexAd">
              <div className="preAcctemail-icon-sAd">
                <img src={mailIconh} alt="mail-icon" />
              </div>
              <div className="preAcctemail-addressAd"> {user?.email}</div>
            </div>
          </div>
          {/* </a> */}
        </>
      )}

      {cardModal && (
        <NewModal
          closeNewModal={closeCardModal}
          headerName="Get card"
          btnName="Pay for card"
          cancelBtn={closeCardModalAll}
        >
          <div className="preAcctModalGen">
            <div className="preAcctModal_headText">
              Enter your name the way you want it on your card
            </div>

            <div>
              <DefaultInput
                value={cardName}
                placeholder=" "
                onChange={(e) => {
                  handleCardNameChange(e);
                }}
                type="text"
                spanText="Full name"
              />
            </div>
            <div className="preAcctConter_Gen">
              <div className="preAcctCounter_Head">How many? :</div>
              <div className="preAcctCounter_FamFlex">
                <div className="preAcctCounter_minus">
                  <img src={minusAcct} onClick={decNum} />
                </div>

                <div className="preAcctCounter_input">
                  {/* <input
                    type="text"
                    value={num}
                    onChange={(e) => {
                      handleChange(e);
                      handleInputPrice(e);
                    }}
                  /> */}

                  {num}
                </div>

                <div className="preAcctCounter_add">
                  <img src={addAcct} onClick={incNum} />
                </div>
              </div>
            </div>

            <div className="preAcctSwitch_toggleGen">
              <div className="preAcctSwitch_text">Add Logo?</div>

              <ReactSwitch
                onChange={handleCheckChange}
                checked={checked}
                checkedIcon={false}
                uncheckedIcon={false}
                className="react-switch"
                onColor="#36a6a4"
                width={45}
                style={{ transform: "translateX(19px)" }}
                // disabled={true}
              />
            </div>
            <div className="preAcctModal_headText">
              Adding a logo comes with an additional ₦ 5,000
            </div>

            <div className="preAcct_emailCont">
              <div className="preAcct_emailtext">
                Email us a .SVG file of your logo to
              </div>
              <div className="preAcct_emailIcon">
                <img src={preAcctEmail} />
              </div>
            </div>

            <div className="preAcct_dashLine"></div>

            <div className="preAcct_TotalFlex">
              <div className="preAcct_headText">Total</div>
              <div className="preAcct_price">₦ {cardPrice}</div>
            </div>
          </div>
        </NewModal>
      )}

      {cardDesignModal && (
        <NewModal
          closeNewModal={closeCardDesignModalPro}
          headerName="Select logo placement"
          btnName="Continue"
          cancelBtn={closeCardDesignModalAll}
        >
          <div className="cardDesignBodyFam">
            <div
              className="cardDesignOneGen"
              onClick={() => {
                setSelectDesign(1);
              }}
            >
              <div className="cardDesignRow">
                <div className="cardDesignEachRow">Logo</div>
                <div className="cardDesignEachRow"></div>
                <div className="cardDesignEachRow"></div>
              </div>

              <div className="cardDesignRow">
                <div className="cardDesignEachRow"></div>
                <div className="cardDesignEachRow"></div>
                <div className="cardDesignEachRow">
                  {selectDesign === 1 ? (
                    <img src={cardCheckedBullet} />
                  ) : (
                    <img src={cardBullet} />
                  )}
                </div>
              </div>

              <div className="cardDesignRow">
                <div className="cardDesignEachRow">Name</div>
                <div className="cardDesignEachRow"></div>
                <div className="cardDesignEachRow"></div>
              </div>
            </div>

            <div
              className="cardDesignOneGen"
              onClick={() => {
                setSelectDesign(2);
              }}
            >
              <div className="cardDesignRow">
                <div className="cardDesignEachRow"></div>
                <div className="cardDesignEachRow"></div>
                <div className="cardDesignEachRow"></div>
              </div>

              <div className="cardDesignRow">
                <div className="cardDesignEachRow"></div>
                <div className="cardDesignEachRow">Logo</div>
                <div className="cardDesignEachRow">
                  {selectDesign === 2 ? (
                    <img src={cardCheckedBullet} />
                  ) : (
                    <img src={cardBullet} />
                  )}
                </div>
              </div>

              <div className="cardDesignRow">
                <div className="cardDesignEachRow"></div>
                <div className="cardDesignEachRow">Name</div>
                <div className="cardDesignEachRow"></div>
              </div>
            </div>

            <div
              className="cardDesignOneGen"
              onClick={() => {
                setSelectDesign(3);
              }}
            >
              <div className="cardDesignRow">
                <div className="cardDesignEachRow"></div>
                <div className="cardDesignEachRow">Logo</div>
                <div className="cardDesignEachRow"></div>
              </div>

              <div className="cardDesignRow">
                <div className="cardDesignEachRow"></div>
                <div className="cardDesignEachRow"></div>
                <div className="cardDesignEachRow">
                  {selectDesign === 3 ? (
                    <img src={cardCheckedBullet} />
                  ) : (
                    <img src={cardBullet} />
                  )}
                </div>
              </div>

              <div className="cardDesignRow">
                <div className="cardDesignEachRow"></div>
                <div className="cardDesignEachRow">Name</div>
                <div className="cardDesignEachRow"></div>
              </div>
            </div>

            <div
              className="cardDesignOneGen"
              onClick={() => {
                setSelectDesign(4);
              }}
            >
              <div className="cardDesignRow">
                <div className="cardDesignEachRow"></div>
                <div className="cardDesignEachRow"></div>
                <div className="cardDesignEachRow">Logo</div>
              </div>

              <div className="cardDesignRow">
                <div className="cardDesignEachRow"></div>
                <div className="cardDesignEachRow"></div>
                <div className="cardDesignEachRow">
                  {selectDesign === 4 ? (
                    <img src={cardCheckedBullet} />
                  ) : (
                    <img src={cardBullet} />
                  )}
                </div>
              </div>

              <div className="cardDesignRow">
                <div className="cardDesignEachRow"></div>
                <div className="cardDesignEachRow"></div>
                <div className="cardDesignEachRow">Name</div>
              </div>
            </div>
          </div>
        </NewModal>
      )}
    </div>
  );
};

export default PreAccountPage;
