import React from "react";
import "./defaultPassInput.css";
import hidePassword from "../../../assets/icons/hidePassword.svg";
import viewPassword from "../../../assets/icons/viewPassword.svg";

const DefaultPassInput = ({
  passType,
  placeholder,
  passName,
  onChange,
  passwordVisiblity,

  isVisible,
  labelText,

  errorMessage,
}) => {
  return (
    <div className="defaultPass_GenCont">
      <div className="defaultPass_ContainGenn">
        <div className="passwordLabelContain">
          <span className="passwordformLabelAs">*</span>{" "}
          <span className="passwordformLabelText">{labelText}</span>
        </div>
        <div className="defaultPass_flex_hide">
          <div className="defaultPass-inputField">
            <input
              type={passType}
              placeholder={placeholder}
              name={passName}
              required
              onChange={onChange}
              className="no-auto-zoom"
            />
          </div>
          <div className="defaultPassEye" onClick={passwordVisiblity}>
            {isVisible ? (
              <img src={viewPassword} alt="view_password" />
            ) : (
              <img src={hidePassword} alt="hide_password" />
            )}
          </div>
        </div>

        {errorMessage !== "" ? (
          <div className="passwordErrorContain">
            <span className="passwordformLabelAs">{errorMessage}</span>{" "}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DefaultPassInput;
