import React from "react";

const AddressIcon = ({ fill }) => {
  return (
    <svg
      width="13"
      height="17"
      viewBox="0 0 13 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.49935 8.50016C6.93477 8.50016 7.30764 8.345 7.61797 8.03466C7.92778 7.72486 8.08268 7.35225 8.08268 6.91683C8.08268 6.48141 7.92778 6.10854 7.61797 5.7982C7.30764 5.4884 6.93477 5.3335 6.49935 5.3335C6.06393 5.3335 5.69132 5.4884 5.38152 5.7982C5.07118 6.10854 4.91602 6.48141 4.91602 6.91683C4.91602 7.35225 5.07118 7.72486 5.38152 8.03466C5.69132 8.345 6.06393 8.50016 6.49935 8.50016ZM6.49935 16.4168C4.37504 14.6092 2.78854 12.9301 1.73985 11.3795C0.690627 9.82937 0.166016 8.39461 0.166016 7.07516C0.166016 5.096 0.80278 3.51926 2.07631 2.34495C3.34931 1.17065 4.82365 0.583496 6.49935 0.583496C8.17504 0.583496 9.64939 1.17065 10.9224 2.34495C12.1959 3.51926 12.8327 5.096 12.8327 7.07516C12.8327 8.39461 12.3083 9.82937 11.2596 11.3795C10.2104 12.9301 8.62365 14.6092 6.49935 16.4168Z"
        fill={fill}
      />
    </svg>
  );
};

export default AddressIcon;
