import React, { useState } from "react";
import Switch from "react-switch";
import "./teamSwitchButton.css";

const TeamSwitchButton = ({ lockFieldName }) => {
  const [checked, setChecked] = useState(false);

  const handleCheckedChange = () => {
    setChecked(!checked);
  };
  return (
    <div className="team_switchButtonGen_">
      <div className="team_switchButton_lockText">{lockFieldName}</div>

      <div className="team_switchlogin_switch_btn">
        {/* <Switch
          onChange={handleCheckedChange}
          checked={checked}
          onColor="#36a6a4"
          onHandleColor="#36a6a4"
          handleDiameter={30}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={18}
          width={45}
          className="react-teamswitch"
          id="material-switch"
        /> */}

        <Switch
          onChange={handleCheckedChange}
          checked={checked}
          className="react-switcher"
          onColor="#36a6a4"
          // onHandleColor="#36a6a4"
          // handleDiameter={30}
          uncheckedIcon={false}
          checkedIcon={false}
          activeBoxShadow="0 0 2px 3px #36a6a4"
        />
      </div>
    </div>
  );
};

export default TeamSwitchButton;
