import React from "react";

const ConnectModalPhone = ({ fill }) => {
  return (
    <>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.00758 0.938521C3.83914 0.531646 3.39508 0.315083 2.9707 0.431021L1.0457 0.956021C0.665078 1.06102 0.400391 1.40665 0.400391 1.8004C0.400391 7.21227 4.78852 11.6004 10.2004 11.6004C10.5941 11.6004 10.9398 11.3357 11.0448 10.9551L11.5698 9.03008C11.6857 8.60571 11.4691 8.16165 11.0623 7.99321L8.96227 7.11821C8.6057 6.96946 8.19227 7.07227 7.94945 7.37196L7.0657 8.4504C5.5257 7.72196 4.27883 6.47508 3.55039 4.93508L4.62883 4.05352C4.92852 3.80852 5.03133 3.39727 4.88258 3.04071L4.00758 0.940708V0.938521Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default ConnectModalPhone;
