import React from "react";
import "./personalizedCard.css";
import TeamIcon from "../teamIcon/TeamIcon";

const PersonalizeCard = ({
  handleTempCard,
  tempIcon,
  tempHeader,
  tempSubHeader,
  templocktag,
}) => {
  return (
    <div onClick={handleTempCard} className="personalize_genFam">
      <div className="personalize_firstFlex">
        <div className="personalize_IconCard">
          <img src={tempIcon} />
        </div>
        <div>
          <div className="personalize_headerCard_">{tempHeader}</div>
          <div className="personalizeSubHeader__">{tempSubHeader}</div>
        </div>
      </div>
      {templocktag && (
        <div className="personalize_lockIconTag">
          <img src={TeamIcon.lockpersontag} />
        </div>
      )}
    </div>
  );
};

export default PersonalizeCard;
