import React from "react";
import "../auth/login.css";

const AuthHeadings = ({ headText, subHeadText }) => {
  return (
    <div>
      <div className="log-in-email-text">{headText}</div>
      <div className="loginEmail_subText">{subHeadText}</div>
    </div>
  );
};

export default AuthHeadings;
