import React, { useState, useEffect } from "react";
import "./contactInfo.css";
import backArrow from "../../../assets/icons/backArrow.png";
import minus from "../../../assets/icons/minus.svg";
import plus from "../../../assets/icons/plus.svg";
import { useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import inputProps from "react-phone-number-input";
import SuccessPage from "../successScreen/SuccessPage";
import SectionsHeader from "../../../genComponents/sectionsHeader/SectionsHeader";
import DefaultInputNum from "../../../genComponents/defaultInputNum/DefaultInputNum";
import {
  DefaultInput,
  DefaultSubmitBtn,
} from "../../../genComponents/defaultInput/DefaultInput";
import {
  stateOptionData,
  cityOptionData,
  countriesList,
} from "../../../genComponents/locationOptionData/LocationOptionData";
import LocationSelectInput from "../../../genComponents/locationSelectnput/LocationSelectInput";
import { DefaultMinusBtnInput } from "../../../genComponents/defaultMinusBtn/DefaultMinusBtn";
import { axiosCalls } from "../../../_api";
import { showLoader, hideLoader } from "../../../loader";
import { useLocation, useParams, Link } from "react-router-dom";
import SuccessAlert from "../successAlert/SuccessAlert";
import FailureAlert from "../failureAlert/FailureAlert";
import { addressData } from "../../../genComponents/locationOptionData/LocationOptionData";

import Location from "./Location";
import zIndex from "@material-ui/core/styles/zIndex";
import AddNew from "../../../assets/icons/AddNew";
import { useForm } from "react-hook-form";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";

// import "react-phone-input-2/lib/material.css";

const ContactInfo = () => {
  const [phoneNumber, setPhoneNumber] = useState([{ phone: "" }]);
  const [newPhoneNum, setNewPhoneNum] = useState([""]);
  const [locationFamily, setLocationFamily] = useState([
    { street: "", city: "", state: "" },
  ]);

  const [onSuccessContactPage, setOnSuccessContactPage] = useState(false);
  const [onErrorPage, setOnErrorPage] = useState(false);

  const [emailAdd, setEmailAdd] = useState([""]);
  const [locationAdd, setLocationAdd] = useState([]);
  const [websiteAdd, setWebsiteAdd] = useState([""]);

  //  Address Break

  // const [countries, setCountries] = useState(addressData);
  // const [states, setStates] = useState([]);
  // const [cities, setCities] = useState([]);
  // const [selectedCountry, setSelectedCountry] = useState("Country");
  // const [selectedState, setSelectedState] = useState("State");
  const [defaultColor, setDefaultColor] = useState("");

  const [contact, setContact] = useState({
    address: [],
    email: [],
    phone: [],
    website_url: [],
  });

  const [fields, setFields] = useState([
    {
      address_id: 0,
      country: "",
      street: "",
      state: "",
      city: "",

      postal_code: "",
    },
  ]);

  const [emailAddBtn, setEmailAddBtn] = useState([""]);
  const [websiteAddBtn, setWebsiteAddBtn] = useState([""]);
  const [newPhoneNumBtn, setNewPhoneNumBtn] = useState([""]);
  const [fieldsBtn, setFieldsBtn] = useState([
    {
      address_id: 0,
      country: "",
      street: "",
      state: "",
      city: "",

      postal_code: "",
    },
  ]);

  const [selectedValue, setSelectedValue] = useState("option3");

  const [dropdownSets, setDropdownSets] = useState([
    {
      address_id: 0,
      country: "",
      state: "",
      city: "",

      street: "",
      postal_code: "",
    },
  ]);

  const [isPhoneNumberEmpty, setIsPhoneNumberEmpty] = useState(false);
  const handleAddDropdownSet = () => {
    const newFields = [
      ...dropdownSets,
      {
        address_id: 0,
        country: "",
        state: "",
        city: "",

        street: "",
        postal_code: "",
      },
    ];
    setDropdownSets(newFields);
    setFieldsBtn(newFields);
  };

  const handleRemoveDropdownSet = (index) => {
    const newDropdownSets = [...dropdownSets];
    newDropdownSets.splice(index, 1);
    setDropdownSets(newDropdownSets);
  };

  // const handleAddField = () => {
  //   const newFields = [
  //     ...fields,
  //     {
  //       address_id: 0,
  //       country: "",
  //       street: "",
  //       state: "",
  //       city: "",
  //       postal_code: "",
  //     },
  //   ];
  //   setFields(newFields);
  //   setFieldsBtn(newFields);
  // };

  // const handleRemoveField = (index) => {
  //   const newFields = [...fields];
  //   newFields.splice(index, 1);
  //   setFields(newFields);
  // };

  const handleCountryChange = (event, index) => {
    const newDropdownSets = [...dropdownSets];

    newDropdownSets[index].country = event.target.value;

    setDropdownSets(newDropdownSets);
  };

  const handleChangeStreet = (index, e) => {
    const newFields = [...dropdownSets];
    newFields[index].street = e;
    setDropdownSets(newFields);
    console.log(dropdownSets);
  };

  const handleStateChange = (index, e) => {
    const newDropdownSets = [...dropdownSets];

    newDropdownSets[index].state = e;

    setDropdownSets(newDropdownSets);
  };

  const handleCityChange = (index, e) => {
    const newDropdownSets = [...dropdownSets];

    newDropdownSets[index].city = e;

    setDropdownSets(newDropdownSets);
  };

  const handleChangePostalCode = (index, e) => {
    // setSelectedState(e);
    const newFields = [...dropdownSets];
    newFields[index].postal_code = e;
    setDropdownSets(newFields);
    console.log(dropdownSets);
  };

  const postProfileData = async (e) => {
    e.preventDefault();

    const data = {
      page: "contact",
      address: dropdownSets,
      email: emailAdd,
      phone: newPhoneNum,
      website_url: websiteAdd,
    };
    showLoader();
    const res = await axiosCalls(`admin/profile`, "POST", data);

    if (res) {
      hideLoader();
      console.log(res?.data);
      if (res?.er) {
        console.log(res?.data?.er);
        setOnErrorPage(true);
        setTimeout(() => {
          setOnErrorPage(false);
        }, 3000);
        return;
      }
      // window.location.href = "/";

      setOnSuccessContactPage(true);

      setTimeout(() => {
        setOnSuccessContactPage(false);
        window.location.href = "/";
      }, 2000);
    }
  };

  const handleNewPhoneAdd = () => {
    const abc = [...newPhoneNum, ""];
    setNewPhoneNum(abc);
    setNewPhoneNumBtn(abc);
  };

  const handleNewPhoneChange = (onChangeValue, i) => {
    const inputData = [...newPhoneNum];
    inputData[i] = onChangeValue;
    setNewPhoneNum(inputData);
  };

  const handleNewPhoneDelete = (value) => {
    // const deleteVal = [...newPhoneNum];
    // console.log(deleteVal, "spliced");

    // setNewPhoneNum((oldValues) => {
    //   return oldValues.filter((_, i) => i !== index);
    // });

    setNewPhoneNum((oldValues) => {
      return oldValues.filter((data) => data !== value);
    });
    // deleteVal.splice(index, 1);
    // setNewPhoneNum(deleteVal);
    // console.log(newPhoneNum, "spliced");

    //  const list = [...phoneNumber];
    //  list.splice(index, 1);
    //  setPhoneNumber(list);
  };

  const handleAddEmailAdd = () => {
    const abc = [...emailAdd, []];
    setEmailAdd(abc);
    setEmailAddBtn(abc);
  };

  const handleAddWebAdd = () => {
    const abc = [...websiteAdd, []];
    setWebsiteAdd(abc);
    setWebsiteAddBtn(abc);
  };

  const handleChangeEmailAdd = (onChangeValue, i) => {
    const inputData = [...emailAdd];
    inputData[i] = onChangeValue.target.value;
    setEmailAdd(inputData);
  };

  const handleChangeWebAdd = (onChangeValue, i) => {
    const inputData = [...websiteAdd];
    inputData[i] = onChangeValue.target.value;
    setWebsiteAdd(inputData);
  };

  const handleDeleteEmailAdd = (i) => {
    const deleteVal = [...emailAdd];
    deleteVal.splice(i, 1);
    setEmailAdd(deleteVal);
  };

  const handleDeleteWebAdd = (i) => {
    const deleteVal = [...websiteAdd];
    deleteVal.splice(i, 1);
    setWebsiteAdd(deleteVal);
  };

  const history = useHistory();

  useEffect(() => {
    getContactData();
    console.log(fields, "load fields");
    console.log(newPhoneNum, "load new phone ");
    console.log(emailAdd, "load new phone ");

    setDefaultColor(localStorage.getItem("Default Theme"));
  }, []);

  const getContactData = () => {
    let profNavEmailAdd = localStorage.getItem("Contact");
    const parsedArr = JSON.parse(profNavEmailAdd);
    setEmailAdd(parsedArr?.email);

    setLocationFamily(parsedArr?.address);
    setWebsiteAdd(parsedArr?.website_url);
    setNewPhoneNum(parsedArr?.phone);
    setFields(parsedArr?.address);
    setDropdownSets(parsedArr?.address);

    setEmailAddBtn(parsedArr?.email);
    setWebsiteAddBtn(parsedArr?.website_url);
    setNewPhoneNumBtn(parsedArr?.phone);
    setFieldsBtn(parsedArr?.address);
  };

  const saveToStorage = (e) => {
    e.preventDefault(e);
    const [{ phone }] = phoneNumber;
    console.log(newPhoneNum);

    console.log(emailAdd);
    console.log(websiteAdd);
    console.log(fields);
  };

  const handlePhoneNumberAdd = () => {
    setPhoneNumber([...phoneNumber, { phone: "" }]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    saveToStorage();
    // setOnSuccessContactPage(true);
    // history.goBack();
  };

  const handleGoBack = () => {
    history.goBack();
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    console.log("Selected value: ", selectedValue);
  };

  const hasEmptyPhone = newPhoneNum?.some((item) => item?.trim() === "");

  return (
    <div className="totalResContactBody">
      <div className="contact-info-family">
        <SectionsHeader
          sectionName="Contact info"
          handleGoBack={handleGoBack}
        />
        <form onSubmit={postProfileData}>
          <div>
            {newPhoneNum.map((data, index) => {
              return (
                <div>
                  <div className="phone-number-input">
                    <PhoneInput
                      international
                      defaultCountry="NG"
                      placeholder="Enter phone number"
                      value={data}
                      onChange={(e) => {
                        handleNewPhoneChange(e, index);
                      }}
                      inputProps={{
                        required: true,
                      }}
                    />

                    {/* <PhoneInput
                      // country="ng"
                      // enableAreaCodes={true}
                      placeholder="Enter phone number"
                      value={data}
                      onChange={(e) => {
                        handleNewPhoneChange(e, index);
                      }}
                      inputProps={{
                        name: "data",
                        required: true,
                        autoFocus: true,
                      }}
                      // containerClass="phone_contClass"
                      // inputClass="phone_inputClass"
                      // dropdownClass="phone_inputClass"
                      // buttonClass="phone_btnClass"
                      // prefix="+"
                    /> */}

                    {/* {newPhoneNum.length > 1 && ( */}
                    <div className="remove-icon">
                      <img
                        src={minus}
                        alt="remove"
                        onClick={() => handleNewPhoneDelete(data)}
                      />
                    </div>
                    {/* )} */}
                  </div>

                  {data?.length <= 0 ? (
                    <div className="enter_phoneError">
                      * Please enter your phone number
                    </div>
                  ) : null}
                </div>
              );
            })}

            {newPhoneNum.length < 4 && (
              <div className="add-phone-family">
                <div
                  onClick={() => handleNewPhoneAdd()}
                  className="add-phone-cont"
                  style={{
                    color: defaultColor === "" ? `#36A6A4` : `${defaultColor}`,
                  }}
                >
                  <div className="addCon_svg">
                    <AddNew
                      fill={defaultColor === "" ? `#36A6A4` : `${defaultColor}`}
                    />
                  </div>
                  <div className="addCon_tex">Add new phone number</div>
                </div>
              </div>
            )}
          </div>

          {/* <div>
            <button onClick={() => handleNewPhoneAdd()}>Add</button>
            {newPhoneNum.map((data, index) => {
              return (
                <div>
                  <div className="phone-number-input">
                    <PhoneInput
                      international
                      defaultCountry="NG"
                      placeholder="Enter phone number"
                      value={data}
                      onChange={(e) => {
                        handleNewPhoneChange(e, index);
                      }}
                    />
                  </div>
                  <button onClick={() => handleNewPhoneDelete(data)}>X</button>
                </div>
              );
            })}
          </div> */}

          <div className="div-location"></div>
          <div>
            {emailAdd.map((data, i) => (
              <div className="contact-email-family">
                <DefaultInput
                  type="email"
                  value={data}
                  name="email"
                  onChange={(e) => handleChangeEmailAdd(e, i)}
                  placeholder=" "
                  spanText="Email"
                  required={true}
                />
                {/* {emailAdd.length > 1 && ( */}
                <DefaultMinusBtnInput onClick={() => handleDeleteEmailAdd(i)} />
                {/* )} */}
              </div>
            ))}
            {emailAdd.length < 3 && (
              <div className="add-email-family">
                <div
                  onClick={handleAddEmailAdd}
                  className="add-phone-cont"
                  style={{
                    color: defaultColor === "" ? `#36A6A4` : `${defaultColor}`,
                  }}
                >
                  <div className="addCon_svg">
                    <AddNew
                      fill={defaultColor === "" ? `#36A6A4` : `${defaultColor}`}
                    />
                  </div>
                  <div className="addCon_tex">Add new email</div>
                </div>
              </div>
            )}
          </div>
          <div className="div-location"></div>
          <div>
            {websiteAdd.map((singleWebsiteAddress, index) => (
              <div className="contact-email-family">
                {/* <div className="inputFieldContact">
                  <input
                    type="text"
                    placeholder="Email"
                    value={singleWebsiteAddress.address}
                    onChange={(e) => handleWebsiteChange(e, index)}
                    name="website"
                  />
                  <span>Enter Website URL</span>
                </div> */}

                <DefaultInput
                  type="text"
                  value={singleWebsiteAddress}
                  name="website"
                  onChange={(e) => handleChangeWebAdd(e, index)}
                  placeholder=" "
                  spanText="Enter Website URL"
                  required={true}
                />
                {/* {websiteAdd.length > 1 && ( */}

                <DefaultMinusBtnInput
                  onClick={() => handleDeleteWebAdd(index)}
                />
                {/* )} */}
              </div>
            ))}
            {websiteAdd.length < 2 && (
              <div className="add-email-family">
                <div
                  onClick={handleAddWebAdd}
                  className="add-phone-cont"
                  style={{
                    color: defaultColor === "" ? `#36A6A4` : `${defaultColor}`,
                  }}
                >
                  <div className="addCon_svg">
                    <AddNew
                      fill={defaultColor === "" ? `#36A6A4` : `${defaultColor}`}
                    />
                  </div>
                  <div className="addCon_tex">Add new website</div>
                </div>
              </div>
            )}
          </div>
          <div className="div-location"></div>
          <div className="location-family-form">
            {dropdownSets.map((dropdownSet, index) => (
              <div key={index} className="contact-location-family">
                {/* <label htmlFor={`country-dropdown-${index}`}>
                  Select a country:
                </label> */}

                <div className="location-family-entire">
                  <div className="inputFieldContact-sele">
                    <select
                      id={`country-dropdown-${index}`}
                      value={dropdownSet.country}
                      onChange={(event) => handleCountryChange(event, index)}
                      placeholder="Country"
                      required={true}
                    >
                      <option value="">--Select a country--</option>
                      {countriesList.map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>

                  <DefaultMinusBtnInput
                    onClick={() => handleRemoveDropdownSet(index)}
                  />
                </div>

                <DefaultInput
                  type="text"
                  value={dropdownSet?.street}
                  name="address"
                  onChange={(e) => handleChangeStreet(index, e.target.value)}
                  placeholder=" "
                  spanText="Enter your address"
                  required=""
                />

                {/* <label htmlFor={`state-dropdown-${index}`}>
                  Select a state:
                </label> */}

                <DefaultInput
                  type="text"
                  value={dropdownSet?.city}
                  name="city"
                  onChange={(e) => handleCityChange(index, e.target.value)}
                  placeholder=" "
                  spanText="City"
                  required=""
                />

                <DefaultInput
                  type="text"
                  value={dropdownSet?.state}
                  name="state"
                  onChange={(e) => handleStateChange(index, e.target.value)}
                  placeholder=" "
                  spanText="State"
                  required={true}
                />

                {/* <label htmlFor={`city-dropdown-${index}`}>Select a city:</label> */}

                <DefaultInput
                  type="text"
                  value={dropdownSet?.postal_code}
                  name="address"
                  onChange={(e) =>
                    handleChangePostalCode(index, e.target.value)
                  }
                  placeholder=" "
                  spanText="Postal Code"
                  required=""
                />
              </div>
            ))}

            {dropdownSets.length < 3 && (
              <div className="add-email-family">
                <div
                  onClick={handleAddDropdownSet}
                  className="add-phone-cont"
                  style={{
                    color: defaultColor === "" ? `#36A6A4` : `${defaultColor}`,
                  }}
                >
                  <div className="addCon_svg">
                    <AddNew
                      fill={defaultColor === "" ? `#36A6A4` : `${defaultColor}`}
                    />
                  </div>
                  <div className="addCon_tex">Add new address</div>
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              alignItem: "center",
              justifyContent: "center",
              width: "90%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <DefaultSubmitBtn
              disabled={
                // emailAddBtn.length < 1 ||
                // websiteAddBtn.length < 1 ||
                // newPhoneNumBtn.length < 1 ||
                // fieldsBtn.length < 1 ||
                hasEmptyPhone
              }
              onClick={postProfileData}
              valueText="Save"
              defaultColor={defaultColor}
            />
          </div>
        </form>

        {onSuccessContactPage && (
          // <SuccessPage notifyText="Contact Information Saved" />

          <SuccessAlert alertMessage="😄 Your contact info is saved" />
        )}

        {onErrorPage && <FailureAlert alertMessage="Oops, an error occured!" />}
      </div>
    </div>
  );
};

export default ContactInfo;
