import React, { useState, useEffect } from "react";
import "./shareLinkOptions.css";
import { profileUrl } from "../../../constant/profileUrl";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  OKShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";

const ShareLinkOptions = ({ closeShareLink, handleChildClick }) => {
  const [profileName, setProfileName] = useState("fionaokpara");

  return (
    <div className="share-modal" onClick={closeShareLink}>
      <div className="totalResBodyTeamLinkmodal">
        <div className="share-family-modal" onClick={handleChildClick}>
          <div className="sharecontainer-family">
            <div>
              <FacebookShareButton
                url={`${profileUrl}/${profileName}`}
                className="Demo__some-network__share-button"
                style={{ marginRight: "10px" }}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </div>
            <div>
              <WhatsappShareButton
                url={`${profileUrl}/${profileName}`}
                style={{ marginRight: "12px" }}
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </div>
            <div>
              <TwitterShareButton
                url={`${profileUrl}/${profileName}`}
                style={{ marginRight: "12px" }}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </div>
            <div>
              <EmailShareButton
                url={`${profileUrl}/${profileName}`}
                style={{ marginRight: "12px" }}
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </div>{" "}
            <div>
              <LinkedinShareButton
                url={`${profileUrl}/${profileName}`}
                style={{ marginRight: "12px" }}
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </div>
            <div>
              <LivejournalShareButton
                url={`${profileUrl}/${profileName}`}
                style={{ marginRight: "12px" }}
              >
                <LivejournalIcon size={32} round />
              </LivejournalShareButton>
            </div>
            <div>
              <OKShareButton
                url={`${profileUrl}/${profileName}`}
                style={{ marginRight: "12px" }}
              >
                <OKIcon size={32} round />
              </OKShareButton>
            </div>
          </div>
          <div className="sharecontainer-family">
            <div>
              <PinterestShareButton
                url={`${profileUrl}/${profileName}`}
                style={{ marginRight: "12px" }}
              >
                <PinterestIcon size={32} round />
              </PinterestShareButton>
            </div>
            <div>
              <RedditShareButton
                url={`${profileUrl}/${profileName}`}
                style={{ marginRight: "12px" }}
              >
                <RedditIcon size={32} round />
              </RedditShareButton>
            </div>
            <div>
              <TelegramShareButton
                url={`${profileUrl}/${profileName}`}
                style={{ marginRight: "12px" }}
              >
                <TelegramIcon size={32} round />
              </TelegramShareButton>
            </div>
            <div>
              <TumblrShareButton
                url={`${profileUrl}/${profileName}`}
                style={{ marginRight: "12px" }}
              >
                <TumblrIcon size={32} round />
              </TumblrShareButton>
            </div>
            <div>
              <ViberShareButton
                url={`${profileUrl}/${profileName}`}
                style={{ marginRight: "12px" }}
              >
                <ViberIcon size={32} round />
              </ViberShareButton>
            </div>
            <div>
              <WorkplaceShareButton
                url={`${profileUrl}/${profileName}`}
                style={{ marginRight: "12px" }}
              >
                <WorkplaceIcon size={32} round />
              </WorkplaceShareButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareLinkOptions;
