import React from "react";
import { useState, useEffect } from "react";
import Burger from "../../components/screens/adminPage/Burger";
import "./sectionNavHamburger.css";

const SectionNavHamburger = ({ hamText, emailData, profImage, userRole }) => {
  const [genData, setGenData] = useState();
  const [defaultTheme, setDefaultTheme] = useState();
  const [defaultColor, setDefaultColor] = useState("");

  useEffect(() => {
    // setGenData(JSON.parse(localStorage.getItem("Email Data")));

    setDefaultTheme(emailData?.apply_theme_to_backdrop);
    setDefaultColor(emailData?.theme_color_primary);
  }, []);
  return (
    <div
      style={{
        background: emailData?.apply_theme_to_backdrop
          ? emailData?.theme_color_primary || "#171923"
          : "#171923",
      }}
      className="sectionHam-info-header"
    >
      <div>
        {/* <img src={backArrow} alt="back_arrow" /> */}
        <Burger
          userRole={userRole}
          profImage={profImage}
          emailData={emailData}
        />
      </div>

      <div className="sectionHam-info-text"> {hamText}</div>
      <div></div>
    </div>
  );
};

export default SectionNavHamburger;
