import React, { useState, useEffect } from "react";
import "./inviteMember.css";
import { useHistory, useLocation } from "react-router-dom";
import {
  DefaultInput,
  DefaultSubmitBtn,
} from "../../../genComponents/defaultInput/DefaultInput";
import SectionsHeader from "../../../genComponents/sectionsHeader/SectionsHeader";
import { DefaultMinusBtn } from "../../../genComponents/defaultMinusBtn/DefaultMinusBtn";
import { DefaultMinusBtnInput } from "../../../genComponents/defaultMinusBtn/DefaultMinusBtn";
import { axiosCalls } from "../../../_api";
import { hideLoader, showLoader } from "../../../loader";
import SuccessAlert from "../../screens/successAlert/SuccessAlert";
import FailureAlert from "../../screens/failureAlert/FailureAlert";
import AddNew from "../../../assets/icons/AddNew";
import TeamSwitchButton from "../../../genComponents/teamSwitchButton/TeamSwitchButton";

const InviteMember = () => {
  const [videoLinkEdit, setVideoLinkEdit] = useState([{ video_url: "" }]);
  const [onSuccessVideoPage, setOnSuccessVideoPage] = useState(false);
  const [onErrorPage, setOnErrorPage] = useState(false);
  const [videoLinkBtn, setVideoLinkBtn] = useState([{ video_url: "" }]);
  const [defaultColor, setDefaultColor] = useState("");
  const history = useHistory();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleVideoLinkAdd = () => {
    const abc = [...videoLinkEdit, { video_url: "" }];

    setVideoLinkEdit(abc);
    setVideoLinkBtn(abc);
  };

  const handleVideoLinkRemove = (index) => {
    const deletVal = [...videoLinkEdit];
    deletVal.splice(index, 1);
    setVideoLinkEdit(deletVal);
  };

  const handleVideoLinkChange = (e, index) => {
    const inputData = [...videoLinkEdit];
    inputData[index].video_url = e.target.value;
    setVideoLinkEdit(inputData);
    console.log(videoLinkEdit);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    console.log(videoLinkEdit);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className="totalResTeamInviteMem">
      <div className="video-Teaminvite-familyGen">
        <SectionsHeader
          sectionName="Invite member"
          handleGoBack={handleGoBack}
        />

        <div style={{ width: "100%", height: "5px" }}></div>
        <form
        // onSubmit={postProfileData}
        >
          <div>
            <DefaultInput
              type="url"
              placeholder=" "
              // value={singleVideoLink.video_url}
              name="videoLink"
              // onChange={(e) => handleVideoLinkChange(e, index)}
              spanText="john.doe@company.co"
            />

            <DefaultInput
              type="url"
              placeholder=" "
              // value={singleVideoLink.video_url}
              name="videoLink"
              // onChange={(e) => handleVideoLinkChange(e, index)}
              spanText="First name"
            />

            <DefaultInput
              type="url"
              placeholder=" "
              // value={singleVideoLink.video_url}
              name="videoLink"
              // onChange={(e) => handleVideoLinkChange(e, index)}
              spanText="Middle name (optional)"
            />

            <DefaultInput
              type="url"
              placeholder=" "
              // value={singleVideoLink.video_url}
              name="videoLink"
              // onChange={(e) => handleVideoLinkChange(e, index)}
              spanText="Surname"
            />

            <DefaultInput
              type="url"
              placeholder=" "
              // value={singleVideoLink.video_url}
              name="videoLink"
              // onChange={(e) => handleVideoLinkChange(e, index)}
              spanText="Job title/Position"
            />

            <div style={{ width: "100%", height: "20px" }}></div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <DefaultSubmitBtn
                disabled={videoLinkBtn.length < 1}
                // onClick={postProfileData}
                valueText="Send invite"
                defaultColor={defaultColor}
              />
            </div>
          </div>
        </form>
        {onSuccessVideoPage && (
          <SuccessAlert alertMessage="😄 Video link saved" />
        )}
        {onErrorPage && <FailureAlert alertMessage="Oops, an error occured!" />}
      </div>
    </div>
  );
};

export default InviteMember;
