import React from "react";
import SectionsHeader from "../../../../genComponents/sectionsHeader/SectionsHeader";
import "./singleConnectInfo.css";
import connectPhone from "../../../../assets/icons/connectPhone.svg";
import connectEmail from "../../../../assets/icons/connectEmail.svg";
import connectForward from "../../../../assets/icons/connectForward.svg";
import connectBack from "../../../../assets/icons/connectBack.svg";
import VcardComponent from "../../../../libs/Vcard";
import { useState } from "react";

const SingleConnectInfo = ({ handleSingleConnect, singleConnectData }) => {
  const [contactData, setContactData] = useState();
  const str = singleConnectData?.full_name;
  const [firstLetter, secondLetter] = str.split(" ").map((s) => s.charAt(0));
  const handleChildClick = (event) => {
    // 👇️ stop event propagation (won't trigger parent's onClick)
    event.stopPropagation();

    // console.log("child clicked");
  };
  return (
    <div className="singleConnectModal-genBody">
      <div className="singleConnectModal-Content">
        <div className="singleConnectModalbody-for-mobile">
          <div className="singleConnectModal-info-header">
            <div
              className="singleConnectModalarrowImg-cont"
              onClick={handleSingleConnect}
            >
              <img src={connectBack} alt="back_arrow" />
            </div>

            <div className="singleConnectModal-info-text">
              {" "}
              View Connections
            </div>
            <div></div>
          </div>

          <div className="singleConnectModal-pics-cont">
            <div className="singleConnectModalbackdrop-cont">
              {/* {singleConnectData?.cover_image_url ? (
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  src={singleConnectData?.cover_image_url}
                  alt="backdrop-img"
                />
              ) : ( */}
              <div
                className="singleConnectModal-img-main"
                style={{ background: "#f2f2f2" }}
              ></div>
              {/* )} */}
            </div>
            <div className="singleConnectModalcont-all-avatar">
              <div className="user-singleConnectModal-pics">
                <div className="singleConnectModal-uploaded-img">
                  {/* {singleConnectData?.contactImg ? (
                    <img
                      src={singleConnectData?.contactImg}
                      alt=" "
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : ( */}
                  <div className="user-singleConnectModal-text">
                    {" "}
                    {firstLetter} {secondLetter}
                  </div>
                  {/* )} */}
                </div>
              </div>
            </div>
            <div className="user-singleConnectModal-details">
              <div className="singleConnectModal-user-name">
                {singleConnectData?.full_name}
              </div>
              {/* {singleConnectData?.job_title ? (
                <div className="singleConnectModal-user-title">
                  {singleConnectData?.job_title}
                </div>
              ) : (
                <div className="singleConnectModal-user-title">-</div>
              )} */}
            </div>
          </div>

          <div className="singleConnectModal-con-cont">
            {/* <div className="exchange-cont-button">Save contact</div> */}
            <VcardComponent userData={singleConnectData} />
            {/* <div
            className="exchange-cont-button"
            //  onClick={handleSave}
            // onClick={openExchangeModal}
          >
            Save Contact
          </div> */}

            <div>
              <div className="view-singleConnectModal-button">Send invite</div>
            </div>
          </div>

          {/* {user?.about && (
            <>
              <div className="about-singleConnectModal-cont">
                <div className="about-singleConnectModal">About</div>
              </div>
              <div className="about-singleConnectModal-family">
                <div className="about-singleConnectModal-cont">
                  {user?.about}
                </div>
              </div>{" "}
            </>
          )} */}

          {singleConnectData?.phone_number || singleConnectData?.email ? (
            <div className="contact-singleConnectModal-cont">
              <div className="contact-singleConnectModal">Contact info</div>
            </div>
          ) : null}
          <div className="contact-singleConnectModal-family">
            {singleConnectData?.phone_number && (
              <>
                <a
                  href={`tel:${singleConnectData?.phone_number}`}
                  key={singleConnectData?.profile_id}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <div className="singleConnectModal-phone-cont">
                    <div className="singleConnectModalphone-first-flex">
                      <div className="singleConnectModalphone-icon-s">
                        <img src={connectPhone} alt="phone-icon" />
                      </div>
                      <div className="singleConnectModalphone-numb">
                        {singleConnectData?.phone_number}
                      </div>
                    </div>
                    <div className="singleConnectarrow-forwardIcon">
                      <img src={connectForward} alt="forward-icon" />
                    </div>
                  </div>
                </a>
              </>
            )}

            {singleConnectData?.email && (
              <>
                <a
                  href={`mailto:${singleConnectData?.email}`}
                  target="_blank"
                  key={singleConnectData?.profile_id}
                  style={{ textDecoration: "none" }}
                >
                  <div className="singleConnectModalemail-cont">
                    <div className="singleConnectModalphone-first-flex">
                      <div className="singleConnectModalemail-icon-s">
                        <img src={connectEmail} alt="mail-icon" />
                      </div>
                      <div className="singleConnectModalemail-address">
                        {" "}
                        {singleConnectData?.email}
                      </div>
                    </div>
                    <div className="singleConnectarrow-forwardIcon">
                      <img src={connectForward} alt="forward-icon" />
                    </div>
                  </div>
                </a>
              </>
            )}

            {/* {user?.contact?.address && (
              <>
                <a
                  href={`http://maps.google.com/?q=${user?.contact?.address}`}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <div className="singleConnectModallocation-cont">
                    <div className="singleConnectModalphone-first-flex">
                      <div>
                        <img src={locationIcon} alt="location-icon" />
                      </div>
                      <div className="singleConnectModallocation-address">
                        {" "}
                        {user?.contact?.address}
                      </div>
                    </div>
                    <div className="singleConnectarrow-forwardIcon">
                      <img src={forwardIcon} alt="forward-icon" />
                    </div>
                  </div>
                </a>
              </>
            )} */}
          </div>

          {/* {user?.social && (
            <div className="singleConnectsocial-header-cont">
              <div className="singleConnectsocial-header">Social connect</div>
            </div>
          )} */}
          {/* <div className="singleConnectsocial-media-group">
            {user?.social.map((soc, idx) => (
              <a href={soc?.url} target="blank">
                <div className="singleConnectsocial-media-icon" key={idx}>
                  <img src={soc?.icon_url} />
                  <div className="singleConnectsocial-media-tag">
                    {soc?.tag}
                  </div>
                </div>
              </a>
            ))}
          </div> */}

          {/* {user?.video.length > 0 && (
            <>
              <div className="singleConnectvideo-header-cont">
                <div className="singleConnectvideo-header">Video</div>
              </div>
              <div className="singleConnectvideo-group">
                <ReactPlayer
                  width="100%"
                  height={154}
                  url={user?.video?.url}
                  controls
                  border-radius="20px"
                />
              </div>
            </>
          )} */}
          {/* {user?.link && (
            <div className="singleConnectlinks-header-cont">
              <div className="singleConnectlinks-header">Links</div>
            </div>
          )} */}

          {/* <div className="singleConnectlinks-group">
            {user?.link.map((link, idx) => (
              <a
                href={link?.url}
                target="blank"
                key={idx}
                style={{ textDecoration: "none" }}
                className="singleConnectgenLinksDiv"
              >
                <div className="singleConnectlinks-cont">
                  <div className="singleConnectlinks-first-flex">
                    <div className="singleConnectlinks-icon-s">
                      {!link?.img ? (
                        <img src={linkImgDefault} alt="link-img" />
                      ) : (
                        <img
                          src={link?.img}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          alt="link-img"
                        />
                      )}
                    </div>
                    <div className="singleConnectmain-links">{link?.url}</div>
                  </div>
                  <div className="singleConnectarrow-forwardIcon">
                    <img src={forwardIcon} alt="forward-icon" />
                  </div>
                </div>
              </a>
            ))}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SingleConnectInfo;
