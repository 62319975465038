import React, { useState, useEffect } from "react";
import "./membersConnectionCard.css";

import defaultContCard from "../../assets/icons/defaultContCard.svg";
import optionIcons from "../../assets/icons/optionIcons.svg";

import jobTitleIcon from "../../assets/icons/jobTitleIcon.svg";

import ConnectMailIcon from "../../assets/icons/ConnectMailIcon";
import ConnectPhoneIcon from "../../assets/icons/ConnectPhoneIcon";
import ConnectModalPhone from "../../assets/icons/ConnectModalPhone";
import ConnectModalMail from "../../assets/icons/ConnectModalMail";

const MembersConnectionCard = ({
  connectsData,
  handleSingleConnect,
  index,
  menuId,

  active,
  onClick,
  singleConnectData,
  handleChildClick,
}) => {
  const [menuBtn, setMenuBtn] = useState(false);
  const [defaultTheme, setDefaultTheme] = useState("");
  const {
    first_name,
    middle_name,
    last_name,

    job_title,
    email,
    title_suffix,

    added_dt,
    profile_id,
    phone_number,
    category,
  } = connectsData;

  useEffect(() => {
    setDefaultTheme(localStorage.getItem("Default Theme"));
  }, []);

  const handleMenuBtnOpen = () => {
    setMenuBtn(!menuBtn);
  };

  const handleMenuBtnClose = () => {
    if (menuBtn === true) {
      setMenuBtn(false);
    }
  };

  //   const formatPhoneNumber = (phoneNumber) => {
  //     // Remove all non-digit characters from the phone number
  //     const digitsOnly = phoneNumber?.replace(/\D/g, "");

  //     // Apply the desired format: +000 000 000 0000
  //     const formattedPhoneNumber = `${digitsOnly?.slice(
  //       0,
  //       3
  //     )} ${digitsOnly?.slice(3, 6)} ${digitsOnly?.slice(
  //       6,
  //       9
  //     )} ${digitsOnly?.slice(9)}`;

  //     return formattedPhoneNumber;
  //   };

  //   const formattedPhoneNumber = formatPhoneNumber(phone_number);

  const dateStr = added_dt;
  const date = new Date(dateStr);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = date?.toLocaleString("en-US", options);

  console.log(formattedDate, "form date");

  return (
    <div
      style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
      onClick={handleChildClick}
    >
      <div
        className="connectionCard_bodyGen_member"
        onClick={() => {
          onClick(menuId);
          handleSingleConnect(connectsData);
        }}
      >
        <div className="connectionCard_SubBody_member">
          <div className="connectionCard_img_member">
            {/* <img src={defaultContCard} /> */}

            {first_name.slice(0, 1)}
            {last_name.slice(0, 1)}
          </div>

          <div className="connectionCard_Right_member">
            <div className="connectionCard_RightName_member ">
              {first_name} {middle_name} {last_name}
            </div>
            <div className="connects_detailFlex_member">
              {/* <div className="connects_detailsIcon_member">
                <ConnectMailIcon
                  fill={defaultTheme === "" ? `#36A6A4` : `${defaultTheme}`}
                />
              </div> */}
              <div className="connectionCard_RightDesc_member">
                {title_suffix}
              </div>
            </div>
            <div className="connects_detailFlex_membertitle">
              <div className="connects_detailsIcon_member">
                <img src={jobTitleIcon} />
              </div>
              <div className="connectionCard_RightDesc_member">{job_title}</div>
            </div>
          </div>
        </div>
        <div className="connectionCard_date_member">
          <div className="connections_optIcon_member">
            <div className="connections_optIcon_member_iconFam">
              <img onClick={() => onClick(menuId)} src={optionIcons} />
            </div>
          </div>
          {category === "Accepted" ? (
            <div className="connections_dateOpt_member">{formattedDate}</div>
          ) : (
            <div className="connections_status_pend">Pending</div>
          )}
        </div>
        {active && (
          <div>
            {category === "Accepted" ? (
              <div className="menu_genBody_member">
                <div className="connectModal_mailGen_member">
                  <div className="connectModal_viewconnect_member">View</div>
                  <div className="connectModal_moveconnect_member">
                    Move to template
                  </div>
                  <div className="connectModal_removeconnect_member">
                    Remove this member
                  </div>
                </div>

                {/* <VcardComponent
              defaultTheme={defaultTheme}
              userData={singleConnectData}
            /> */}
              </div>
            ) : (
              <div className="menu_genBody_member">
                <div className="connectModal_mailGen_member">
                  <div className="connectModal_moveconnect_member">
                    Resend invite
                  </div>
                  <div className="connectModal_removeconnect_member">
                    Revoke link
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MembersConnectionCard;
