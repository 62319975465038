import React, { useState, useEffect, useRef } from "react";
import AvatarEditor from "react-avatar-editor";
import "./adminPage.css";
import navicon from "../../../assets/icons/navicon.png";
import deetslogo from "../../../assets/icons/deetslogo.svg";
import shareicon from "../../../assets/icons/shareicon.svg";
import cameraIcon from "../../../assets/icons/cameraIcon.svg";
import bluepen from "../../../assets/icons/bluepen.svg";
import socialMediaSnapchat from "../../../assets/icons/socialMediaSnapchat.svg";
import socialMediaLinkedin from "../../../assets/icons/socialMediaLinkedin.svg";
import socialMediaInstagram from "../../../assets/icons/socialMediaInstagram.svg";
import socialMediaTwitter from "../../../assets/icons/socialMediaTwitter.svg";
import socialMediaFacebook from "../../../assets/icons/socialMediaFacebook.svg";
import callUpdate from "../../../assets/icons/callUpdate.svg";
import mailUpdate from "../../../assets/icons/mailUpdate.svg";
import locationUpdate from "../../../assets/icons/locationUpdate.svg";
import forward from "../../../assets/icons/forward.svg";
import Aos from "aos";
import mailIconh from "../../../assets/icons/mailIconh.svg";
import locationIcon from "../../../assets/icons/locationIcon.svg";
import phoneIcon from "../../../assets/icons/phoneIcon.svg";
import webIconh from "../../../assets/icons/webIconh.svg";
import forwardIcon from "../../../assets/icons/forwardIcon.svg";
import linkImgDefault from "../../../assets/icons/linkImgDefault.svg";
import anchorOpen from "../../../assets/icons/anchorOpen.svg";
import anchorClose from "../../../assets/icons/anchorClose.svg";

// import "aos/dist/aos.css";

import { Link, useLocation, useParams, useHistory } from "react-router-dom";

import { RenderCropper } from "../../cropper/cropper";
import LeftNav from "./LeftNav";
import Burger from "./Burger";
import { HideOn } from "react-hide-on-scroll";
import AddSocialTag from "./AddSocialTag";

import SectionNavigator from "../../../genComponents/sectionNavigator/SectionNavigator";
import { DefaultPlaceHolder } from "../../../genComponents/defaultPlaceHolder/DefaultPlaceHolder";
import StatsCont from "../../../genComponents/statsCont/StatsCont";
// import { profileUrl } from "../../../constant/profileUrl";
import { hideLoader, showLoader } from "../../../loader";
import { axiosCalls } from "../../../_api";
import ReactPlayer from "react-player";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminErrorPage from "../../../genComponents/adminErrorPage/AdminErrorPage";
import { LazyLoadImage } from "react-lazy-load-image-component";
import defaultProfPic from "../../../assets/icons/defaultProfPic.png";
import updateLogoIcon from "../../../assets/icons/updateLogoIcon.svg";
import copyUrl from "../../../assets/icons/copyUrl.svg";
import { ReactComponent as linkIcon2 } from "../../../assets/icons/linkIcon2.svg";

import newPattern from "../../../assets/icons/newPattern.png";
import PhoneIcon from "../../../assets/icons/PhoneIcon";
import EmailIcon from "../../../assets/icons/EmailIcon";
import AddressIcon from "../../../assets/icons/AddressIcon";
import WebsiteIcon from "../../../assets/icons/WebsiteIcon";
import LinkIcon from "../../../assets/icons/LinkIcon";
import LinkParamImg from "../../../genComponents/locationOptionData/LocationOptionData";
import NewModal from "../../../genComponents/newModal/NewModal";
import { profileUrl } from "../../../constant/profileUrl";
import copy from "copy-to-clipboard";
import SuccessAlert from "../successAlert/SuccessAlert";
import { QRCodeCanvas } from "qrcode.react";
import { ThreeDots } from "react-loader-spinner";
import StatsHalfCont from "../../../genComponents/statsHalfCont/StatsHalfCont";

// import Backdrop from "../backdrop/Backdrop";

const AdminPage = () => {
  // const [selectedImage, setSelectedImage] = useState(null);
  // const handleImageChange = (event) => {
  //   console.log(event.target.files[0]);
  //   setSelectedImage(event.target.files[0]);
  // };
  // let inputRef;
  // const [coverImage, setCoverImage] = useState(null);
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [color, setColor] = useState("#555555");
  // const [customColor, setCustomColor] = useState(false);
  // const [selectedColor, setSelectedColor] = useState(false);

  const [user, setUser] = useState();
  const [loading, userLoading] = useState(true);

  const [profileName, setProfileName] = useState(null);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [avatar, setAvatar] = React.useState("");

  const [isVisible, setIsVisible] = useState(true);
  const [height, setHeight] = useState(0);
  const [openSocialTags, setOpenSocialTags] = useState(false);
  const [localAssets, setLocalAssets] = useState({
    image: null,
    color: "",
    about: "",
    phone: "",
  });

  const [localProfImg, setLocalProfImg] = useState({
    image: null,
  });

  const [localImg, setLocalImg] = useState(null);

  const [fetchError, userFetchError] = useState(false);
  const [defaultLinkImg, setDefaultLinkImg] = useState(linkImgDefault);
  const [errorLoad, setErrorLoad] = useState(false);
  const [logoImage, setLogoImage] = useState(null);
  const [firstNameData, setFirstNameData] = useState();
  const [lastNameData, setLastNameData] = useState();
  const [emailData, setEmailData] = useState();
  const [defaultTheme, setDefaultTheme] = useState(null);
  const [allConnection, setAllConnection] = useState([]);
  const [profileImgName, setProfileImgName] = useState();

  const [burgerFirst, setBurgerFirst] = useState("");

  const [onLinkImgError, setOnLinkImgError] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  // const [value, setValue] = useState(`${profileUrl}/fionaokpara`);
  const [copied, setCopied] = useState(false);
  const [url, setUrl] = useState("");
  const [profImage, setProfImage] = useState(null);
  const [loadingImgName, setLoadingImgName] = useState(false);
  const [loadingLogo, setloadingLogo] = useState(false);
  const [getProfileImgUrl, setGetProfileImgUrl] = useState(null);
  const [openStats, setOpenStats] = useState(false);
  const [userRole, setUserRole] = useState("");

  // const location = useLocation();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleStatsFunc = (event) => {
    event.stopPropagation();
    setOpenStats(true);
  };

  const handleCloseStats = (event) => {
    event.stopPropagation();
    setOpenStats(false);
  };
  const qrRef = useRef();

  const qrcode = (
    <QRCodeCanvas
      id="qrCode"
      value={url}
      size={300}
      bgColor={"#fbfbfb"}
      level={"H"}
    />
  );

  const history = useHistory();

  const handleConnectRoute = () => {
    history.push("/view_connect");
  };

  const copyToClipboard = () => {
    copy(`${profileUrl}/${user?.id}`);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
    console.log("coooooo");
  };
  const qrCodeEncoder = () => {
    setUrl(`${profileUrl}/${user?.id}`);
  };

  const handleUpdateCode = (data) => {
    setProfileName(data);
    console.log(profileName, "jdjdjdjddjdjdjdjdjdjjd------");
  };

  const closeShareQrcode = () => {
    setOpenShareModal(!openShareModal);
  };

  const onError = () => {
    setOnLinkImgError(true);
  };
  const replaceImage = (error) => {
    error.target.src = <linkIcon2 className="link_iconColor" />;
  };
  const closeSocialTags = () => {
    setOpenSocialTags(!openSocialTags);
  };

  // --------------------------------------------------------------------

  useEffect(() => {
    getProfile();
    getAssets();
    getProfAssets();
    getProfImage();
    getLogoImage();

    // qrCodeEncoder();
    localStorage.setItem("Email Data", JSON.stringify(emailData));

    Aos.init({ duration: 1000 });
  }, []);

  // const getProfile = async () => {
  //   const res = await axiosCalls("profile", "GET");
  //   if (res) {
  //     console.log(res);
  //     if (res.er) {
  //       console.log(res.er);
  //       return;
  //     }
  //     // console.log(">>>>>>>>RES", res);
  //     // localStorage.setItem("token", res.jwt.token);
  //     console.log(">>>>>>>>RES", res);
  //   }
  // };

  // useEffect(() => {
  //   getProfile();
  // }, []);

  const setData = (data) => {
    let {
      about,
      company_name,
      contact,
      email,
      cover_image_url,
      first_name,
      id,
      industry,
      job_title,
      last_name,
      link,
      middle_name,
      profile_code,
      profile_image_url,
      social,
      video,
      theme_color_primary,
      connection,
      profile_image,
      profile_logo_image,
      name_suffix,
      apply_theme_to_backdrop,
    } = data;
    localStorage.setItem("ID", id);
    localStorage.setItem("First Name", first_name ?? "");
    localStorage.setItem("Last Name", last_name ?? "");
    localStorage.setItem("User Email", email ?? "");
    localStorage.setItem("Company Name", company_name ?? "");
    localStorage.setItem("Contact", JSON.stringify(contact));
    localStorage.setItem("Cover Image", cover_image_url);
    localStorage.setItem("Industry", industry ?? "");
    localStorage.setItem("Job Title", job_title ?? "");
    localStorage.setItem("Link", JSON.stringify(link));
    localStorage.setItem("Middle Name", middle_name ?? "");
    localStorage.setItem("Profile Code", profile_code ?? "");
    localStorage.setItem("Profile Image", profile_image_url ?? "");
    localStorage.setItem("Profile Image Name", profile_image ?? "");
    localStorage.setItem("Profile Logo Name", profile_logo_image ?? "");
    localStorage.setItem("Suffix Name", name_suffix ?? "");
    localStorage.setItem("Social", JSON.stringify(social));
    localStorage.setItem("Video", JSON.stringify(video));
    localStorage.setItem("About", about ?? "");
    localStorage.setItem("Default Theme", theme_color_primary ?? "");

    localStorage.setItem("Connection List", JSON.stringify(connection));

    localStorage.setItem(
      "Backdrop Theme",
      JSON.stringify(apply_theme_to_backdrop)
    );

    // setProfileName(profile_code);

    // let allData = JSON.stringify(emailData);
  };

  const { id } = useParams();

  const userId = location.pathname;
  console.log({ id });
  console.log(userId);

  const getProfile = async () => {
    showLoader();

    const res = await axiosCalls(`admin/profile/${profileName}`, "GET");

    if (res) {
      userLoading(false);
      if (res?.er) {
        hideLoader();
        console.log(res?.er);

        setErrorLoad(true);

        console.log("errfff");
        userFetchError(true);

        return;
      }

      setErrorLoad(false);
      hideLoader();
      const resData = res?.data?.profile;
      setUser(resData);
      setData(resData);
      setEmailData(resData);
      setDefaultTheme(resData?.theme_color_primary);
      setAllConnection(resData?.connection);
      setProfileImgName(resData?.profile_image);
      // setProfileName(resData?.profile_code);

      console.log(">>>>>>>>>>Res", resData);
      console.log("user-data", user);
      console.log(emailData, "email dataaa");
      // console.log(profileName, "llllllloooooo");

      if (resData?.is_pwd_change_req == true) {
        window.location.href = "/change_password";
      }
    }
  };

  // const checkPwdChange = async () => {
  //   showLoader();

  //   const res = await axiosCalls(`admin/profile/${profileName}`, "GET");

  //   if (res) {
  //     userLoading(false);
  //     if (res?.er) {
  //       hideLoader();
  //       console.log(res?.er);

  //       setErrorLoad(true);

  //       console.log("errfff");
  //       userFetchError(true);

  //       return;
  //     }

  //     setErrorLoad(false);
  //     hideLoader();
  //     const resData = res?.data?.profile;
  //     setUser(resData);
  //     setData(resData);
  //     setEmailData(resData);
  //     setDefaultTheme(resData?.theme_color_primary);
  //     setAllConnection(resData?.connection);
  //     setProfileImgName(resData?.profile_image);
  //     // setProfileName(resData?.profile_code);

  //     console.log(">>>>>>>>>>Res", resData);
  //     console.log("user-data", user);
  //     console.log(emailData, "email dataaa");
  //     // console.log(profileName, "llllllloooooo");
  //   }
  // };

  const getProfImage = async () => {
    // const image_name = localStorage.getItem("Profile Img");

    const resImage = await axiosCalls(`admin/profile/${profileName}`, "GET");

    if (resImage) {
      if (resImage?.er) {
        return;
      }
    }

    const image_name = resImage?.data?.profile?.profile_image;

    const res = await axiosCalls(`s3/${image_name}`, "GET");

    if (res) {
      if (res?.er) {
        console.log(res?.er);

        console.log("errfff");
        return;
      }

      hideLoader();
      const resData = res.data;
      setProfImage(resData, "prooooooooooooo");
      setGetProfileImgUrl(resData);

      localStorage.setItem("Default Profile Image", resData);
      console.log(resData, "pppppppppppppppppppp");
      console.log(profImage, "proffffffffffrrrrrr");
      setLoadingImgName(true);
    }
  };

  const getLogoImage = async () => {
    // showLoader();

    // const image_name = localStorage.getItem("Profile Img");

    const resLogoImage = await axiosCalls(
      `admin/profile/${profileName}`,
      "GET"
    );

    if (resLogoImage) {
      if (resLogoImage?.er) {
        return;
      }
    }

    const image_logo_name = resLogoImage?.data?.profile?.profile_logo_image;

    const res = await axiosCalls(`s3/${image_logo_name}`, "GET");

    if (res) {
      if (res?.er) {
        // hideLoader();
        console.log(res?.er);

        console.log("errfff");
        return;
      }

      // hideLoader();
      const resData = res.data;
      setLogoImage(resData);
      console.log(resData, "ttttttttttttttt");
      console.log(logoImage, "rrrrrrrrrrrrrrrrr");
      setloadingLogo(true);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleQrCodeOpen = () => {
    setOpenShareModal(true);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const [showCropper, setShowCropper] = React.useState(false);
  const handleCropper = () => setShowCropper((prevValue) => !prevValue);

  const getAssets = () => {
    let assets = localStorage.getItem("assets");
    setUserRole(localStorage.getItem("User Role"));
    if (assets == undefined || assets == null || assets == "") {
      return;
    }
    let parseData = JSON.parse(assets);
    console.log(">>>>>>>>>>vvvVVV", parseData);
    setLocalAssets(parseData);
    // setDefaultTheme(parseData?.color);
    // setImageData(parseData?.image);
    // setLocalImg(parseData?.);
  };

  const getProfAssets = () => {
    let profAssets = localStorage.getItem("profAssets");
    if (profAssets == undefined || profAssets == null || profAssets == "") {
      return;
    }
    let parseProfData = JSON.parse(profAssets);
    console.log(">>>>>>>>>>ppppPPP", parseProfData);
    setLocalProfImg(parseProfData);
  };

  return (
    <div className="totalResAdminBody">
      <div className="containertt">
        {/* <HideOn atHeight height={5}> */}
        <div
          // style={{
          //   background: defaultTheme === null ? "#171923" : `${defaultTheme}`,
          // }}

          style={{
            background: user?.apply_theme_to_backdrop
              ? defaultTheme || "#171923"
              : "#171923",
          }}
          className="navbar-cont"
        >
          {isVisible && (
            <div>
              {/* <img src={navicon} alt="navicon" style={{ opacity: "0" }} /> */}

              <Burger
                emailData={emailData}
                profImage={profImage}
                userRole={userRole}
              />
            </div>
          )}
          <div className="deets-admin-logo">
            <img src={deetslogo} alt="navicon" />
          </div>
          {/* <Link to="/share_link" style={{ textDecoration: "none" }}> */}
          <div
            onClick={() => {
              handleQrCodeOpen();
              qrCodeEncoder();
              // handleUpdateCode(localStorage.getItem("Profile Code"));
            }}
            className="shareicon-cont"
          >
            <div className="share-iii">
              <img src={shareicon} alt="navicon" />
            </div>
          </div>
          {/* </Link> */}
        </div>
        {/* </HideOn> */}

        <div className="new_profile-pics-cont">
          {/* {user?.apply_theme_to_backdrop ? (
            <div
              style={{
                background:
                  defaultTheme === null ? "#171923" : `${defaultTheme}`,
              }}
              className="new_back_background"
            ></div>
          ) : (
            <div
              style={{
                background: "#171923",
              }}
              className="new_back_background"
            ></div>
          )} */}

          <div
            style={{
              background: user?.apply_theme_to_backdrop
                ? defaultTheme || "#171923"
                : "#171923",
            }}
            className="new_back_background"
          ></div>
          <div className="new_profile_cardGenCover">
            <div className="new_pattern_backGen">
              <img src={newPattern} />
            </div>
            <div className="new_bannerCoverB">
              <div className="new_profile_logoCont">
                {/* {user?.profile_logo_url ? (
                  <img src={user?.profile_logo_url} alt="" />
                ) : (
                  "Logo"
                )} */}

                {loadingLogo && (
                  <>
                    {user?.profile_logo_image ? (
                      <img src={logoImage} alt="" />
                    ) : (
                      <div className="logo_textt">Logo</div>
                    )}{" "}
                  </>
                )}
              </div>
              <div className="new_bannerCard_flexGen">
                <div className="new_cont-all-avatar">
                  <div className="new_user-profile-pics">
                    <div className="new_uploaded-img">
                      {loadingImgName && (
                        <>
                          {user?.profile_image ? (
                            // <LazyLoadImage
                            //   src={user?.profile_image_url}
                            //   width={210}
                            //   height={210}
                            //   PlaceholderSrc={defaultProfPic}
                            //   effect="blur"
                            //   alt=""
                            // />

                            <img
                              src={profImage}
                              alt=""
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <div className="new_user-profile-pics-text">
                              {user?.first_name.slice(0, 1)}
                              {user?.last_name.slice(0, 1)}
                            </div>
                          )}
                        </>

                        // <div className="profile_imageLoader">
                        //   <ThreeDots
                        //     height="30"
                        //     width="30"
                        //     radius="9"
                        //     color="#777777"
                        //     ariaLabel="three-dots-loading"
                        //     wrapperStyle={{}}
                        //     wrapperClassName=""
                        //     visible={true}
                        //   />
                        // </div>
                      )}
                    </div>

                    <div className="edit-profile-pics-family">
                      <Link
                        className="edit-profile-pics-icon"
                        to="/profile_img"
                        style={{ textDecoration: "none" }}
                      >
                        <img src={cameraIcon} alt="editProfile" />{" "}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="new_user-profile-details">
                  <div className="new_user-name">
                    {" "}
                    {console.log(">>>>>", user)}
                    {user?.first_name} {user?.middle_name} {user?.last_name}
                  </div>
                  <div className="name_suffixGen"> {user?.name_suffix}</div>
                  <div className="new_user-title">
                    {user?.job_title_display}
                  </div>
                </div>
              </div>

              <Link
                to="/backdrop"
                style={{ textDecoration: "none" }}
                className="new_logo_edit"
              >
                <img src={updateLogoIcon} alt="" />
              </Link>

              {/* </div> */}
            </div>
          </div>
        </div>
        {/* </HideOn> */}
        {/* <div style={{ background: "#f2f2f2" }}>
          <div className="profile-pics-cont">
            <div className="backdrop-cont">
              {user?.cover_image_url === null ? (
                <div
                  className="backdrop-img-main"
                  // style={{ background: `${localAssets?.color}` }}
                  style={{ background: "#ffffff" }}
                ></div>
              ) : (
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  src={user?.cover_image_url}
                  alt=""
                />
              )}
              <div className="edit-blackpencont">
                <Link to="/backdrop" style={{ textDecoration: "none" }}>
                  <div className="edit-blackpen">
                    {" "}
                    <img src={cameraIcon} alt="editProfile" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="cont-all-avatar">
              <div
                className="user-profile-pics"
                style={{ border: user?.profile_image_url && "none" }}
              >
                <div className="uploaded-img">
                  {user?.profile_image_url === null ? (
                    <div className="user-profile-pics-text">
                      {user?.first_name.toUpperCase().slice(0, 1)}{" "}
                      {user?.last_name.toUpperCase().slice(0, 1)}
                    </div>
                  ) : (
                    // <img
                    //   src={user?.profile_image_url}
                    //   alt="avatar"
                    //   style={{
                    //     height: "100%",
                    //     width: "100%",
                    //     objectFit: "cover",
                    //   }}
                    // />

                    <LazyLoadImage
                      src={user?.profile_image_url}
                      width={210}
                      height={210}
                      PlaceholderSrc={defaultProfPic}
                      effect="blur"
                      alt=""
                    />
                  )}
                </div>
              </div>
              <div className="edit-profile-pics-family">
              

                <Link
                  className="edit-profile-pics-icon"
                  to="/profile_img"
                  style={{ textDecoration: "none" }}
                >
                  <img src={cameraIcon} alt="editProfile" />{" "}
              
                </Link>
              </div>
            </div>
            <div className="user-profile-details">
              {user?.first_name && user?.last_name !== "" ? (
                <div className="user-name">
                  {user?.first_name} {user?.last_name}
                </div>
              ) : (
                <div className="user-name">Name</div>
              )}

              {user?.job_title_display ? (
                <div className="user-title">{user?.job_title_display}</div>
              ) : (
                <div className="user-title">Job Title</div>
              )}
            </div>
          </div>
        </div> */}
        <div className="body-div">
          <div className="prof-con-cont">
            <a
              href={`${profileUrl}/${user?.id}`}
              style={{ textDecoration: "none" }}
              className="profile-button"
              target="_blank"
            >
              View Profile
            </a>
            <Link
              className="connect-button"
              style={{
                background:
                  defaultTheme === null ? "#36A6A4" : `${defaultTheme}`,
                textDecoration: "none",
              }}
              to="/view_connect"
            >
              View Connections
            </Link>
          </div>

          {/* Sidebar Family */}

          {/* About Family */}

          <div className="about-family">
            <SectionNavigator linkPath="/personal_info" sectionName="About" />
            <div className="about-user-text">
              <div>
                {user?.about ? (
                  <div className="note-content">{user?.about}</div>
                ) : (
                  <DefaultPlaceHolder placeHolder=" Tell Us something about you" />
                )}
              </div>
            </div>
          </div>

          {/* Social Connect Family */}

          <div className="social-family">
            <SectionNavigator
              linkPath="/social_info"
              sectionName="Social media"
            />
            <div className="social-user-text">
              <div>
                {user?.social.length > 0 ? (
                  <div className="social-media-groupAd">
                    {user?.social.map((soc, idx) => {
                      const handleAnchorClick = (e) => {
                        e.preventDefault(); // Prevents the default behavior of the anchor tag
                        const url = e;
                        window.open(url, "_blank");
                      };

                      const formatURL = (url) => {
                        // Check if the URL starts with "http://" or "www"
                        if (url?.startsWith("http://")) {
                          return url;
                        } else if (url?.startsWith("www.")) {
                          return "http://" + url; // Add "http://" prefix if not present
                        } else if (url?.startsWith("https://")) {
                          return url; // Add "http://" prefix if not present
                        } else if (url?.startsWith("https://wa.me/")) {
                          return "https://wa.me/" + url;
                        } else {
                          return "http://" + url;
                        }
                      };

                      return (
                        <a
                          // href={soc?.url}
                          style={{ textDecoration: "none" }}
                          // rel="noopener noreferrer"
                          href={formatURL(
                            `${
                              soc?.account_type === "Whatsapp"
                                ? "https://wa.me/"
                                : "https://"
                            }` + `${soc?.url}`
                          )}
                          onClick={(e) => {
                            handleAnchorClick(e.target.href);
                            console.log(e.target.href, "teeeeeee");
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="social-media-iconAd" key={idx}>
                            <img src={soc?.icon_url} />
                            {/* <div className="social-media-tagAd">
                            {soc?.account_type}
                          </div> */}
                          </div>
                        </a>
                      );
                    })}
                  </div>
                ) : (
                  <DefaultPlaceHolder placeHolder=" Add available socials" />
                )}
              </div>

              {/* <div className="social-media-big-entry"> */}
              {/* Snapchat entry */}
              {/* <div className="snapchat-entry">
                  <div className="social-snap-chat-icon">
                    <div>
                      <img src={socialMediaSnapchat} alt="snap-chat-icon" />
                    </div>
                  </div>
                  <div className="snap-chat-tag">
                    <div
                      onClick={() => setOpenSocialTags(true)}
                      className="snap-chat-tag-text"
                    >
                      +tag
                    </div>
                  </div>
                </div> */}

              {/* Linkedin entry */}
              {/* <div className="snapchat-entry">
                  <div className="social-snap-chat-icon">
                    <div>
                      <img src={socialMediaLinkedin} alt="snap-chat-icon" />
                    </div>
                  </div>
                  <div className="snap-chat-tag">
                    <div
                      onClick={() => setOpenSocialTags(true)}
                      className="snap-chat-tag-text"
                    >
                      +tag
                    </div>
                  </div>
                </div> */}

              {/* Facebook entry */}

              {/* <div className="snapchat-entry">
                  <div className="social-snap-chat-icon">
                    <div>
                      <img src={socialMediaFacebook} alt="snap-chat-icon" />
                    </div>
                  </div>
                  <div className="snap-chat-tag">
                    <div
                      onClick={() => setOpenSocialTags(true)}
                      className="snap-chat-tag-text"
                    >
                      +tag
                    </div>
                  </div>
                </div> */}
              {/* Twitter entry */}

              {/* <div className="snapchat-entry">
                  <div className="social-snap-chat-icon">
                    <div>
                      <img src={socialMediaTwitter} alt="snap-chat-icon" />
                    </div>
                  </div>
                  <div className="snap-chat-tag">
                    <div
                      onClick={() => setOpenSocialTags(true)}
                      className="snap-chat-tag-text"
                    >
                      +tag
                    </div>
                  </div>
                </div> */}
              {/* Instagram entry */}
              {/* <div className="snapchat-entry">
                  <div className="social-snap-chat-icon">
                    <div>
                      <img src={socialMediaInstagram} alt="snap-chat-icon" />
                    </div>
                  </div>
                  <div className="snap-chat-tag">
                    <div
                      onClick={() => setOpenSocialTags(true)}
                      className="snap-chat-tag-text"
                    >
                      +tag
                    </div>
                  </div>
                </div> */}
              {/* Whatsapp entry */}

              {/* Youtube entry */}
              {/* </div> */}
              {/*  */}
            </div>
          </div>

          {/* Contact Family */}
          <div className="contact-family">
            <SectionNavigator
              linkPath="/contact_info"
              sectionName="Contact information"
            />
            <div className="contact-user-text">
              {user?.contact?.phone.length > 0 ||
              user?.contact?.email.length > 0 ||
              user?.contact?.address.length > 0 ||
              user?.contact?.website_url.length > 0 ? (
                <div className="contact-info-familyAd">
                  {user?.contact?.phone && (
                    <>
                      {user?.contact?.phone.map((tel, idx) => {
                        // const formattedPhoneNumber = tel
                        //   ? tel.toLocaleString("en-US")
                        //   : "";

                        const formatPhoneNumber = (phoneNumber) => {
                          // Remove all non-digit characters from the phone number
                          const digitsOnly = phoneNumber?.replace(/\D/g, "");

                          // Apply the desired format: +000 000 000 0000
                          const formattedPhoneNumber = `+${digitsOnly?.slice(
                            0,
                            3
                          )} ${digitsOnly?.slice(3, 6)} ${digitsOnly?.slice(
                            6,
                            9
                          )} ${digitsOnly?.slice(9)}`;

                          return formattedPhoneNumber;
                        };

                        const formattedPhoneNumber = formatPhoneNumber(tel);

                        return (
                          <a
                            href={`tel:${tel}`}
                            key={idx}
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            <div className="phone-contAd">
                              <div className="phone-first-flexAd">
                                <div className="new_genIconCont">
                                  <div
                                    className="new_phone-icon-s"
                                    style={{
                                      background:
                                        defaultTheme === null
                                          ? "#36A6A4"
                                          : `${defaultTheme}`,
                                    }}
                                  ></div>
                                  <div className="new_childIcon">
                                    <PhoneIcon
                                      fill={
                                        defaultTheme === null
                                          ? "#36A6A4"
                                          : `${defaultTheme}`
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="phone-numbAd">
                                  {/* {formattedPhoneNumber} */}

                                  {tel}
                                </div>
                              </div>
                              <div className="arrow-forwardIconAd">
                                {/* <img src={forwardIcon} alt="forward-icon" /> */}
                              </div>
                            </div>
                          </a>
                        );
                      })}
                    </>
                  )}

                  {user?.contact?.email && (
                    <>
                      {user?.contact?.email.map((mail, idx) => (
                        <a
                          href={`mailto:${mail}`}
                          target="_blank"
                          key={idx}
                          style={{ textDecoration: "none" }}
                        >
                          <div className="email-contAd">
                            <div className="phone-first-flexAd">
                              <div className="new_genIconCont">
                                <div
                                  className="new_phone-icon-s"
                                  style={{
                                    background:
                                      defaultTheme === null
                                        ? "#36A6A4"
                                        : `${defaultTheme}`,
                                  }}
                                ></div>
                                <div className="new_childIcon">
                                  <EmailIcon
                                    fill={
                                      defaultTheme === null
                                        ? "#36A6A4"
                                        : `${defaultTheme}`
                                    }
                                  />
                                </div>
                              </div>
                              <div className="email-addressAd"> {mail}</div>
                            </div>
                            <div className="arrow-forwardIconAd">
                              {/* <img src={forwardIcon} alt="forward-icon" /> */}
                            </div>
                          </div>
                        </a>
                      ))}
                    </>
                  )}

                  {user?.contact?.address && (
                    <>
                      {user?.contact?.address.map((data, idx) => (
                        <a
                          href={`http://maps.google.com/?q=${user?.contact?.address}`}
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <div className="location-contAd">
                            <div className="phone-first-flexAd">
                              <div className="new_genIconCont">
                                <div
                                  className="new_phone-icon-s"
                                  style={{
                                    background:
                                      defaultTheme === null
                                        ? "#36A6A4"
                                        : `${defaultTheme}`,
                                  }}
                                ></div>
                                <div className="new_childAddIcon">
                                  <AddressIcon
                                    fill={
                                      defaultTheme === null
                                        ? "#36A6A4"
                                        : `${defaultTheme}`
                                    }
                                  />
                                </div>
                              </div>
                              <div className="location-addressAd">
                                {" "}
                                {data?.address_display}
                              </div>
                            </div>
                            <div className="arrow-forwardIconAd">
                              {/* <img src={forwardIcon} alt="forward-icon" /> */}
                            </div>
                          </div>
                        </a>
                      ))}
                    </>
                  )}

                  {user?.contact?.website_url && (
                    <>
                      {user?.contact?.website_url.map((website, idx) => {
                        const handleAnchorClick = (e) => {
                          e.preventDefault(); // Prevents the default behavior of the anchor tag
                          const url = e;
                          window.open(url, "_blank");
                        };

                        const formatURL = (url) => {
                          // Check if the URL starts with "http://" or "www"
                          if (url?.startsWith("http://")) {
                            return url;
                          } else if (url?.startsWith("www.")) {
                            return "http://" + url; // Add "http://" prefix if not present
                          } else if (url?.startsWith("https://")) {
                            return url; // Add "http://" prefix if not present
                          } else {
                            return "http://" + url;
                          }
                        };
                        return (
                          <a
                            // href={`http://${website}`}
                            // target="_blank"
                            // key={idx}
                            style={{ textDecoration: "none" }}
                            // rel="noopener noreferrer"
                            href={formatURL(`${website}`)}
                            onClick={(e) => {
                              handleAnchorClick(e.target.href);
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div className="email-contAd">
                              <div className="phone-first-flexAd">
                                <div className="new_genIconCont">
                                  <div
                                    className="new_phone-icon-s"
                                    style={{
                                      background:
                                        defaultTheme === null
                                          ? "#36A6A4"
                                          : `${defaultTheme}`,
                                    }}
                                  ></div>
                                  <div className="new_childWebIcon">
                                    <WebsiteIcon
                                      fill={
                                        defaultTheme === null
                                          ? "#36A6A4"
                                          : `${defaultTheme}`
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="email-addressAd">
                                  {" "}
                                  {website}
                                </div>
                              </div>
                              <div className="arrow-forwardIconAd">
                                {/* <img src={forwardIcon} alt="forward-icon" /> */}
                              </div>
                            </div>
                          </a>
                        );
                      })}
                    </>
                  )}
                </div>
              ) : (
                <DefaultPlaceHolder placeHolder=" Add contact information" />
              )}

              {/* Call Phone  */}
              {/* {localAssets?.phone !== "" ? (
                <div className="call-update-cont">
                  <div className="call-inside-cont">
                    <div className="call-icon-r">
                      <img src={callUpdate} alt="call-icon" />
                    </div>
                    <div>{localAssets?.phone}</div>
                  </div>
                  <div className="forward-icon-r">
                    <img src={forward} alt="forward-icon" />
                  </div>
                </div>
              ) : null} */}
              {/* 
              {emailAddress.length > 0 ? (
                <EmailConts emailAddress={emailAddress} />
              ) : null} */}

              {/* Call Address */}
              {/* <div className="call-update-cont">
                <div className="call-inside-cont">
                  <div className="call-icon-r">
                    {" "}
                    <img src={locationUpdate} alt="call-icon" />
                  </div>
                  <div className="address-admin">
                    {" "}
                    No 40 Sinclair street, main arrival, Oxford tower city,
                    Abuja
                  </div>
                </div>
                <div className="forward-icon-r">
                  <img src={forward} alt="forward-icon" />
                </div>
              </div> */}
            </div>
          </div>

          {/* Video Family */}

          <div className="video-family">
            <SectionNavigator linkPath="/video_link" sectionName="Video" />
            <div className="video-user-text">
              {user?.video.length > 0 ? (
                <>
                  {user?.video.map((data, idx) => (
                    <div className="video-groupAd">
                      <ReactPlayer
                        width="100%"
                        height={154}
                        url={data?.video_url}
                        controls
                        border-radius="20px"
                      />
                    </div>
                  ))}
                </>
              ) : (
                <DefaultPlaceHolder placeHolder=" Add a video" />
              )}
            </div>
          </div>

          {/* Links */}

          <div className="links-family">
            <SectionNavigator linkPath="/link" sectionName="Links" />
            <div className="links-user-text">
              {user?.link.length > 0 ? (
                <div className="links-groupAd">
                  {user?.link.map((link, idx) => {
                    // const imgLink = link?.url;
                    // let imgUrl = new URL(link?.url);
                    // let imgLink = imgUrl.host;
                    // let imgLink_url = `https://${imgLink}/favicon.ico`;
                    // console.log("iiiiii", imgLink);

                    const handleAnchorClick = (e) => {
                      e.preventDefault(); // Prevents the default behavior of the anchor tag
                      const url = e;
                      window.open(url, "_blank");
                    };

                    const formatURL = (url) => {
                      // Check if the URL starts with "http://" or "www"
                      if (url?.startsWith("http://")) {
                        return url;
                      } else if (url?.startsWith("www.")) {
                        return "http://" + url; // Add "http://" prefix if not present
                      } else if (url?.startsWith("https://")) {
                        return url; // Add "http://" prefix if not present
                      } else {
                        return "http://" + url;
                      }
                    };
                    return (
                      <a
                        // key={idx}
                        style={{ textDecoration: "none" }}
                        href={formatURL(`${"https://"}` + `${link?.url}`)}
                        onClick={(e) => {
                          handleAnchorClick(e.target.href);
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {/* {onLinkImgError && (
                          <div className="new_genLinkCont">
                            <div
                              className="new_link-icon-s"
                              style={{
                                background: `${defaultTheme}`,
                              }}
                            ></div>
                            <div className="new_childlink">
                          <LinkIcon fill={defaultTheme} />
                            </div>
                           </div>
                        )} */}
                        <div className="links-contAd genLinksDiv">
                          <div className="links-first-flexAd">
                            <div className="links-icon-sAd">
                              {/* {onLinkImgError ? (
                                <>
                               
                                  <LinkIcon fill={defaultTheme} />
                                </>
                              ) : (
                                <>
                                  {link?.link_image_url === null ? (
                                    <img
                                      src={imgLink_url}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                      alt=""
                                      onError={() => {
                                        onError();
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={link?.link_image_url}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  )}
                                </>
                              )} */}

                              <div className="new_genIconCont">
                                <div
                                  className="new_phone-icon-s"
                                  style={{
                                    background:
                                      defaultTheme === null
                                        ? "#36A6A4"
                                        : `${defaultTheme}`,
                                  }}
                                ></div>
                                <div className="new_childWebIcon">
                                  <LinkIcon
                                    fill={
                                      defaultTheme === null
                                        ? "#36A6A4"
                                        : `${defaultTheme}`
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            {link?.title_display === null ? (
                              <div className="main-linksAd">Add Title</div>
                            ) : (
                              <div className="main-linksAd">
                                {link?.title_display}
                              </div>
                            )}
                          </div>
                          <div className="arrow-forwardIconAd">
                            {/* <img src={forwardIcon} alt="forward-icon" /> */}
                          </div>
                        </div>
                      </a>
                    );
                  })}
                </div>
              ) : (
                <DefaultPlaceHolder placeHolder=" Add links and thumbnails" />
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            // borderTop: "1px solid #e9e9e9",
          }}
        ></div>

        <div
          onClick={handleCloseStats}
          className={` ${openStats ? "stats_modalGen" : "stats-cont"}`}
        >
          <div
            className="stats_modal_cont"
            style={{
              height: openStats ? "470px" : "100px",
            }}
            // onClick={handleStatsFunc}
          >
            {/* <div className="stats_header">
              <img src={openStats ? anchorOpen : anchorClose} />
            </div> */}
            {openStats && <div className="stats_SectionText">Overview</div>}
            <div className="stats_flexGen">
              <StatsCont
                figures={
                  user?.profile_stats?.viewed ? user?.profile_stats?.viewed : 0
                }
                labelName="Views"
                fill={defaultTheme === null ? "#36A6A4" : `${defaultTheme}`}
              />

              <StatsCont
                figures={
                  user?.profile_stats?.connects
                    ? user?.profile_stats?.connects
                    : 0
                }
                labelName="Connections"
                fill={defaultTheme === null ? "#36A6A4" : `${defaultTheme}`}
              />
              <StatsCont
                figures={
                  user?.profile_stats?.saved ? user?.profile_stats?.saved : 0
                }
                labelName="Saved"
                fill={defaultTheme === null ? "#36A6A4" : `${defaultTheme}`}
              />
            </div>

            <div>
              <div className="stats_SectionSecondText">Number of scans </div>
              <div className="stats_numberScan">
                <StatsHalfCont
                  figures={
                    user?.profile_stats?.viewed
                      ? user?.profile_stats?.viewed
                      : 0
                  }
                  labelName="Total scans"
                  fill={defaultTheme === null ? "#36A6A4" : `${defaultTheme}`}
                />
                <StatsHalfCont
                  figures={
                    user?.profile_stats?.viewed
                      ? user?.profile_stats?.viewed
                      : 0
                  }
                  labelName="Today"
                  fill={defaultTheme === null ? "#36A6A4" : `${defaultTheme}`}
                />
              </div>

              <div className="stats_numberScanSecond">
                <StatsCont
                  figures={
                    user?.profile_stats?.saved ? user?.profile_stats?.saved : 0
                  }
                  labelName="Yesterday"
                  fill={defaultTheme === null ? "#36A6A4" : `${defaultTheme}`}
                />
                <StatsCont
                  figures={
                    user?.profile_stats?.saved ? user?.profile_stats?.saved : 0
                  }
                  labelName="Last 7 days"
                  fill={defaultTheme === null ? "#36A6A4" : `${defaultTheme}`}
                />
                <StatsCont
                  figures={
                    user?.profile_stats?.saved ? user?.profile_stats?.saved : 0
                  }
                  labelName="Last 30 days"
                  fill={defaultTheme === null ? "#36A6A4" : `${defaultTheme}`}
                />
              </div>
            </div>
            <div onClick={handleCloseStats} className="close_statsGen">
              {" "}
              Close
            </div>
          </div>
        </div>

        {showCropper && (
          <RenderCropper handleCropper={handleCropper} setAvatar={setAvatar} />
        )}
        {/* <Backdrop /> */}
        {openSocialTags && <AddSocialTag closeSocialTags={closeSocialTags} />}

        <ToastContainer />
        {errorLoad && <AdminErrorPage />}

        {openShareModal && (
          <NewModal
            closeNewModal={closeShareQrcode}
            headerName="Scan QR Code to view profile"
            // btnName="Got it"
            defaultTheme={defaultTheme}
          >
            <div ref={qrRef} className="qr-codeImgModal">
              {qrcode}
            </div>

            <div
              style={{
                background:
                  defaultTheme === null ? "#36A6A4" : `${defaultTheme}`,
              }}
              className="newModal-Btn"
              onClick={copyToClipboard}
            >
              <div>Copy profile link</div>
              <div className="copyUrl_img">
                <img src={copyUrl} />
              </div>
            </div>
          </NewModal>
        )}
      </div>
      {copied ? <SuccessAlert alertMessage="😄 URL Copied!" /> : null}
    </div>
  );
};

export default AdminPage;
