import React, { useState, useEffect } from "react";
import "./singleTeamTemp.css";
import SectionsHeader from "../../../genComponents/sectionsHeader/SectionsHeader";
import { useHistory, useLocation } from "react-router-dom";
import PersonalizeCard from "../../../genComponents/personalizeCard/PersonalizeCard";
import TeamIcon from "../../../genComponents/teamIcon/TeamIcon";
import NewModal from "../../../genComponents/newModal/NewModal";
import DeleteModal from "../../../genComponents/deleteModal/DeleteModal";
import connectSearchIcon from "../../../assets/icons/connectSearchIcon.svg";
import sortDescending from "../../../assets/icons/sortDescending.svg";
import searchMemberIcon from "../../../assets/icons/searchMemberIcon.svg";

import optionFunnel from "../../../assets/icons/optionFunnel.svg";
import jobTitleIcon from "../../../assets/icons/jobTitleIcon.svg";

import MembersConnectionCard from "../../../genComponents/membersConnectionCard/MembersConnectionCard";

const SingleTeamTemp = ({
  teamInfo,
  handleSingleModalClose,
  handleSingleModalOpen,
}) => {
  const [tab, setTab] = useState(1);
  const [backdropSlider, setBackdropSlider] = useState(0);
  const [templocktag, setTemplocktag] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [error, setError] = useState("");

  const [membersList, setMembersList] = useState([
    {
      added_dt: "2023-05-17",
      title_suffix: "M.E, Ph.D, JP",
      first_name: "Jaye",
      middle_name: "Timini",
      last_name: "Dolapo",
      job_title: "VP, sales",
      profile_id: "669b882e-8d91-4b49-890e-c87cf92a4026",
      email: "temini@capital.com",
      phone_number: "091234443333",
      category: "Accepted",
    },

    {
      added_dt: "2023-05-17",
      title_suffix: "M.sc",
      first_name: "Frank ",
      middle_name: "",
      last_name: "Amieri",
      job_title: "Marketer",
      profile_id: "669b882e-8d91-4b49-890e-c87cf92a4026",
      email: "temini@capital.com",
      phone_number: "091234443333",
      category: "Accepted",
    },

    {
      added_dt: "2023-05-17",
      title_suffix: "",
      first_name: "Wade  ",
      middle_name: "",
      last_name: "Warren",
      job_title: "VP, sales",
      profile_id: "669b882e-8d91-4b49-890e-c87cf92a4026",
      email: "temini@capital.com",
      phone_number: "091234443333",
      category: "Pending",
    },

    {
      added_dt: "2023-05-17",
      title_suffix: "M.E, Ph.D, JP",
      first_name: "Jaye",
      middle_name: "Timini",
      last_name: "Dolapo",
      job_title: "VP, sales",
      profile_id: "669b882e-8d91-4b49-890e-c87cf92a4026",
      email: "temini@capital.com",
      phone_number: "091234443333",
      category: "Accepted",
    },

    {
      added_dt: "2023-05-17",
      title_suffix: "M.E, Ph.D, JP",
      first_name: "Jaye",
      middle_name: "Timini",
      last_name: "Dolapo",
      job_title: "VP, sales",
      profile_id: "669b882e-8d91-4b49-890e-c87cf92a4026",
      email: "temini@capital.com",
      phone_number: "091234443333",
      category: "Pending",
    },

    {
      added_dt: "2023-05-17",
      title_suffix: "M.E, Ph.D, JP",
      first_name: "Jaye",
      middle_name: "Timini",
      last_name: "Dolapo",
      job_title: "VP, sales",
      profile_id: "669b882e-8d91-4b49-890e-c87cf92a4026",
      email: "temini@capital.com",
      phone_number: "091234443333",
      category: "Pending",
    },

    {
      added_dt: "2023-05-17",
      title_suffix: "M.E, Ph.D, JP",
      first_name: "Jaye",
      middle_name: "Timini",
      last_name: "Dolapo",
      job_title: "VP, sales",
      profile_id: "669b882e-8d91-4b49-890e-c87cf92a4026",
      email: "temini@capital.com",
      phone_number: "091234443333",
      category: "Accepted",
    },
  ]);

  const [singleConnectData, setSingleConnectData] = useState();

  const [membersListOrder, setMembersListOrder] = useState([]);

  const [menuBtnH, setMenuBtnH] = useState(false);

  const [activeMenu, setActiveMenu] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchText, setSearchText] = useState("");

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    setMembersListOrder(membersList);
  }, []);

  const handleSingleConnect = (connectsDataB) => {
    // setOpenSingleConnect(!openSingleConnect);
    setSingleConnectData(connectsDataB);
    console.log(connectsDataB, "dujdjji");
  };

  const handleMenuBtnToggle = () => {
    setMenuBtnH(!menuBtnH);
  };

  const handleMenuBtnClose = () => {
    setMenuBtnH(false);
  };

  const handleMenuClick = (menuId) => {
    if (activeMenu === menuId) {
      setActiveMenu(null); // Close menu if it's already open
    } else {
      setActiveMenu(menuId); // Open the clicked menu
    }
  };

  const handleChildClick = (event) => {
    event.stopPropagation();
    console.log("Child clicked");
  };
  const history = useHistory();

  const handleClickEditTemp = () => {
    history.push("/team/edit_teamplate_name");
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleTab1 = () => {
    setTab(1);
  };
  const callSliderRight = () => {
    setBackdropSlider(1);
  };
  const callSliderLeft = () => {
    setBackdropSlider(2);
  };

  const handleTab2 = () => {
    setTab(2);
  };

  const handleTempCard = () => {};

  const handleTempCustomInfo = () => {
    history.push("/team/temp_customize");
  };

  const handleTempCompanyInfo = () => {
    history.push("/team/temp_companyinfo");
  };

  const handleTempSocialPageInfo = () => {
    history.push("/team/temp_socialpage");
  };

  const handleTempVideoPageInfo = () => {
    history.push("/team/temp_videolink");
  };

  const handleTempLinksPageInfo = () => {
    history.push("/team/temp_links");
  };
  const handleTempInvitePage = () => {
    history.push("/team/invite_member");
  };

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  // const handleSearch = (value) => {
  //   if (value.length < 1) return setMembersListOrder(membersList);
  //   const filteredData = membersList.filter((item) => {
  //     const categoryMatch =
  //       selectedCategory === "All" || item.category === selectedCategory;
  //     return (
  //       categoryMatch &&
  //       Object.values(item)
  //         .join(" ")
  //         .toLowerCase()
  //         .includes(value.toLowerCase())
  //     );
  //   });

  const filteredData = membersListOrder.filter((item) => {
    const categoryMatch =
      selectedCategory === "All" || item.category === selectedCategory;
    // const colorMatch =
    //   selectedColor === "All" || item.color === selectedColor;
    const searchTextLower = searchText.toLowerCase();
    const itemDataLower = [item.first_name, item.middle_name, item.last_name]
      .join(" ")
      .toLowerCase();
    return categoryMatch && itemDataLower.includes(searchTextLower);
  });

  // const filteredData = data.filter((item) => {
  //   const categoryMatch =
  //     selectedCategory === "All" || item.category === selectedCategory;
  //   const searchMatch = item.name
  //     .toLowerCase()
  //     .includes(searchText.toLowerCase());
  //   return categoryMatch && searchMatch;
  // });

  //   if (filteredData.length) {
  //     setMembersListOrder(filteredData);
  //     setError("");
  //   } else {
  //     setError("No Contacts Were Found");
  //   }
  // };

  return (
    <div
      onClick={() => {
        setActiveMenu(null);
      }}
      className="singleTeam_tempBody"
    >
      <div className="singleTeamModal-Content">
        <div className="fixedSingle_header">
          <SectionsHeader
            sectionName={teamInfo?.team_name}
            handleGoBack={handleSingleModalClose}
          />
        </div>
        <div className="singleTeam-switchFam">
          <div className="fixedSingle_switch">
            <div className="singleTeam-btnGen">
              <div
                className="switcher-animation_singleTeam"
                style={{ transform: tab === 2 ? "translateX(106%)" : "" }}
              >
                {tab === 1 ? "Template" : "Members"}
              </div>
              <div
                // style={{
                //   background: tab === 1 ? "#fff" : "",
                //   color: tab === 1 ? "#171923" : "#000",

                // }}
                className={`useImageSwitch_singleTeam 
              
           
              
              
              `}
                onClick={() => {
                  handleTab1();
                  callSliderRight();
                }}
              >
                Template
              </div>
              <div
                // style={{
                //   background: tab === 2 ? "#fff" : null,
                //   color: tab === 2 ? "#b42930" : "#000",
                // }}
                className={`useColorSwitch_singleTeam 
              
              
           
              
              
              `}
                onClick={() => {
                  handleTab2();
                  callSliderLeft();
                }}
              >
                Members
              </div>
            </div>
          </div>

          <div style={{ width: "100%" }}>
            {tab === 1 && (
              <div className="single_tab1_genn" style={{ width: "100%" }}>
                <div className="edit_tempNameGenFam">
                  <div className="tempName_headerFlex">
                    <div className="temp_nameHText">Template name</div>
                    <div
                      onClick={handleClickEditTemp}
                      className="temp_nameEditTextName"
                    >
                      Edit template name
                    </div>
                  </div>
                  <div className="tempName___">{teamInfo?.team_name}</div>
                </div>

                <div className="singleTeam_personGen">
                  <div className="singleTeam_personText_">Customize</div>
                  {/* <div className="singleTeam_personEditText__">
                    Edit template{" "}
                  </div> */}
                </div>

                <PersonalizeCard
                  handleTempCard={handleTempCompanyInfo}
                  tempIcon={TeamIcon.teamsuitcase}
                  tempHeader="Company info"
                  tempSubHeader="Personalise company info"
                  templocktag={templocktag}
                  handleSingleModalOpen={handleSingleModalOpen}
                />
                <PersonalizeCard
                  handleTempCard={handleTempCustomInfo}
                  tempIcon={TeamIcon.customizepersonicon}
                  tempHeader="Theme and logo"
                  tempSubHeader="Select a theme and logo for this template"
                  templocktag={templocktag}
                />
                <PersonalizeCard
                  handleTempCard={handleTempSocialPageInfo}
                  tempIcon={TeamIcon.socialpersonicon}
                  tempHeader="Social connects"
                  tempSubHeader="Add company social media accounts"
                  templocktag={templocktag}
                />

                <div className="singleteamOptional_text">Optional ad-ons</div>
                <PersonalizeCard
                  handleTempCard={handleTempVideoPageInfo}
                  tempIcon={TeamIcon.videoCameraIcon}
                  tempHeader="Video"
                  tempSubHeader="Add a business related video"
                  templocktag={templocktag}
                />
                <PersonalizeCard
                  handleTempCard={handleTempLinksPageInfo}
                  tempIcon={TeamIcon.linkpersonIcon}
                  tempHeader="Links"
                  tempSubHeader="Add extra links"
                  templocktag={templocktag}
                />
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "80%",
                  }}
                >
                  <button
                    onClick={handleTempInvitePage}
                    className="personalTemp_inviteBtn"
                  >
                    Invite member
                  </button>
                </div>
                <button
                  onClick={handleOpenDeleteModal}
                  className="personalTemp_deleteBtn"
                >
                  Delete template
                </button>
              </div>
            )}

            {tab === 2 && (
              <>
                {membersList.length < 1 ? (
                  <div className="team_noMembers_gen">
                    <div className="team_nomembersIcon">
                      <img src={TeamIcon.noTeamMembersIcon} />
                    </div>
                    <div className="team_nomembersHeader_">No member</div>
                    <div className="team_nomembersSub_text">
                      No member has been added to this template yet, you can
                      send an invite right away
                    </div>
                  </div>
                ) : (
                  <div style={{ paddingBottom: "20px" }}>
                    <div className="fixedSingle_tab2Search">
                      <div className="members_listgen_fam">
                        <div className="connect-searchWrapperMember">
                          <div className="connect-searchIconMember">
                            <img src={searchMemberIcon} />
                          </div>
                          <input
                            type="text"
                            placeholder="Search Contact"
                            // onChange={(e) => {
                            //   handleSearch(e.target.value);
                            // }}

                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                          />
                        </div>

                        <div className="member_optionsGenFam">
                          <div className="member_optionFamily">
                            <div className="member_option_funnel">
                              <img src={optionFunnel} />
                            </div>

                            <div className="member_opt_selectGen">
                              <select
                                value={selectedCategory}
                                onChange={(e) => {
                                  setSelectedCategory(e.target.value);
                                  // handleSearch(e.target.value);
                                }}
                                className="custom_selectmembersAll"
                              >
                                <option value="All">All</option>
                                <option value="Accepted">Accepted</option>
                                <option value="Pending">Pending</option>
                              </select>
                            </div>
                          </div>

                          <div className="member_sortFamily">
                            <div className="member_sort_funnel">
                              <img src={sortDescending} />
                            </div>
                            <div className="custom_selectMembers_sortAll">
                              <select className="custom_selectmembersAll_sort">
                                <option value="option1">All</option>
                                <option value="option2">New to old</option>
                                <option value="option3">Old to new</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="single_eachCard_body">
                      {filteredData.map((connectsData, index) => (
                        <MembersConnectionCard
                          handleSingleConnect={handleSingleConnect}
                          handleMenuBtnToggle={handleMenuBtnToggle}
                          menuBtnH={menuBtnH}
                          handleMenuBtnClose={handleMenuBtnClose}
                          connectsData={connectsData}
                          key={connectsData?.profile_id}
                          index={index}
                          menuId={index}
                          active={activeMenu === index}
                          onClick={handleMenuClick}
                          singleConnectData={singleConnectData}
                          handleChildClick={handleChildClick}
                        />
                      ))}
                    </div>
                  </div>
                )}

                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "80%",
                    marginTop: "40px",
                  }}
                >
                  <button
                    onClick={handleTempInvitePage}
                    className="teamnomember_invite_btn"
                  >
                    Invite member
                  </button>
                </div>
              </>
            )}
          </div>

          {/* {openLogoutPage ? (
            <LogoutPage
              closeLogoutPage={closeLogoutBtn}
              openLogoutPage={openLogoutPage}
              navColor={navColor}
            />
          ) : null} */}

          {deleteModal && (
            <DeleteModal
              navColor=""
              deleteTextHeading="Delete template?"
              deleteParagraphText="Confirming this action will totally remove this template and all the members added to it. 
Are you sure you want to continue?"
              closeLogoutPage={handleCloseDeleteModal}
              secondButtonText="No"
              firstButtonText="Yes"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleTeamTemp;
