import React from "react";
import "./defaultInputNum.css";

const DefaultInputNum = ({
  value,
  name,
  onChange,
  placeholder,
  //   spanNameText,
}) => {
  return (
    <>
      <div className="inputFieldPersonalize-num">
        <input
          type="tel"
          value={value}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          //   spanName={spanName}
        />
        {/* <span> {spanName}</span> */}
      </div>
    </>
  );
};

export default DefaultInputNum;
