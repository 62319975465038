import React from "react";
import { ProtectedLayout } from "./protected";
import Login from "../src/components/screens/auth/Login";
import ForgotPassword from "../src/components/screens/auth/ForgotPassword";
import HomePageRoute from "../src/components/screens/adminPage/AdminPage";
import BackdropPageRoute from "../src/components/screens/backdrop/Backdrop";
import PersonalInfoPageRoute from "../src/components/screens/personalInfo/PersonalInfo";
import ContactInfoPageRoute from "../src/components/screens/contactInfo/ContactInfo";
import SocialConnectPageRoute from "../src/components/screens/socialInfo/SocialInfo";
import VideoLinkPageRoute from "../src/components/screens/videoLink/VideoLink";
import EditLinkPageRoute from "../src/components/screens/editLinks/EditLink";
import ViewConnectPageRoute from "../src/components/screens/viewConnect/ViewConnect";
import ShareLinkPageRoute from "../src/components/screens/shareLink/ShareLink";
import SupportPageRoute from "../src/components/screens/support/Support";
import ChangePasswordPageRoute from "../src/components/screens/changePassword/ChangePassword";
import TeamAdminPage from "../src/components/teamScreen/teamAdminPage/TeamAdminPage";
import TeamChangePasswordPageRoute from "./components/teamScreen/teamChangePasswordPage/TeamChangePasswordPage";
import TeamPersonalPageRoute from "./components/teamScreen/teamPersonalPage/TeamPersonalPage";
import TeamContactPageRoute from "./components/teamScreen/teamContactPage/TeamContactPage";
import TeamSocialPageRoute from "./components/teamScreen/teamSocialPage/TeamSocialPage";
import TeamVideoPageRoute from "./components/teamScreen/teamVideoPage/TeamVideoPage";
import TeamLinksPageRoute from "./components/teamScreen/teamLinksPage/TeamLinksPage";
import TeamSupportPageRoute from "./components/teamScreen/teamSupportPage/TeamSupportPage";
import ActiveTemplatePageRoute from "./components/teamScreen/activeTemplatePage/ActiveTemplatePage";
import CreateTemplatePageRoute from "./components/teamScreen/createTemplatePage/CreateTemplatePage";

import ProfileInfoTempRoute from "./components/teamScreen/selectTeamTemplateScreens/profileInfoTemp/ProfileInfoTemp";
import ContactInfoTempRoute from "./components/teamScreen/selectTeamTemplateScreens/contactInfoTemp/ContactInfoTemp";
import SocialConnectTempRoute from "./components/teamScreen/selectTeamTemplateScreens/socialConnectTemp/SocialConnectTemp";
import VideoLinkTempRoute from "./components/teamScreen/selectTeamTemplateScreens/videoLinkTemp/VideoLinkTemp";
import LinksTempRoute from "./components/teamScreen/selectTeamTemplateScreens/linksTemp/LinksTemp";
import TeamCardsMembersRoute from "./components/teamScreen/teamCards/TeamCardsMembers";
import TeamCardsPersonalInfoRoute from "./components/teamScreen/teamCardsPersonalInfo/TeamCardsPersonalInfo";
import TeamCardsViewPersonalRoute from "./components/teamScreen/teamCardsViewPersonal/TeamCardsViewPersonal";
import MembersAdminPageRoute from "./components/teamScreen/membersAdminPages/membersAdminPage/MembersAdminPage";
import MembersLinksPageRoute from "./components/teamScreen/membersAdminPages/membersLinksPage/MembersLinksPage";

import MembersPersonalPageRoute from "./components/teamScreen/membersAdminPages/membersPersonalPage/MembersPersonalPage";

import MembersSocialPageRoute from "./components/teamScreen/membersAdminPages/membersSocialPage/MembersSocialPage";
import MembersSupportPageRoute from "./components/teamScreen/membersAdminPages/membersSupportPage/MembersSupportPage";
import MembersVideoPageRoute from "./components/teamScreen/membersAdminPages/membersVideoPage/MembersVideoPage";
import MembersContactPageRoute from "./components/teamScreen/membersAdminPages/membersContactPage/MembersContactPage";
import TeamConnectionsRoute from "./components/teamScreen/teamConnectionsPage/TeamConnections";
import AddManualConnectionRoute from "./components/teamScreen/addManualConnection/AddManualConnection";
import MembersSendInviteRoute from "./components/teamScreen/membersSendInvite/MembersSendInvite";
import MembersClickedInfoRoute from "./components/teamScreen/membersClickedInfo/MembersClickedInfo";

import ProfileImgRoute from "./components/screens/profileImg/ProfileImg";
import FeedBackPageRoute from "./components/screens/feedBackPage/FeedBackPage";
import SignUpCont from "./components/screens/auth/signupCont/SignUpCont";
import PreAccountPage from "./components/screens/preAccountPage/PreAccountPage";
import ContactOd from "./components/screens/contactInfo/ContactOd";
import ForgotPasswordOtp from "./components/screens/auth/ForgotPasswordOtp";
import InputPassword from "./components/screens/auth/InputPasword";
import TeamHome from "./components/teamDesign/teamHome/TeamHome";
import CreateTeamTemp from "./components/teamDesign/createTeamTemp/CreateTeamTemp";
import SingleTeamTemp from "./components/teamDesign/singleTeamTemp/SingleTeamTemp";
import EditTempname from "./components/teamDesign/editTempName/EditTempname";
import TempCompanyInfo from "./components/teamDesign/tempCompanyInfo/TempCompanyInfo";
import TempCustomize from "./components/teamDesign/tempCustomize/TempCustomize";
import TempSocialConnect from "./components/teamDesign/tempSocialConnect/TempSocialConnect";
import TempVideoLink from "./components/teamDesign/tempVideoLink/TempVideoLink";
import TempLinks from "./components/teamDesign/tempLinks/TempLinks";
import InviteMember from "./components/teamDesign/inviteMember/InviteMember";

export const HomePage = () => {
  return (
    <ProtectedLayout>
      <HomePageRoute />
    </ProtectedLayout>
  );
};

export const FeedBackScreenPage = () => {
  return (
    <ProtectedLayout>
      <FeedBackPageRoute />
    </ProtectedLayout>
  );
};

export const ProfileImgPage = () => {
  return (
    <ProtectedLayout>
      <ProfileImgRoute />
    </ProtectedLayout>
  );
};

export const ProfileInfoTempPage = () => {
  return (
    <ProtectedLayout>
      <ProfileInfoTempRoute />
    </ProtectedLayout>
  );
};

export const TeamHomePage = () => {
  return (
    <ProtectedLayout>
      <TeamHome />
    </ProtectedLayout>
  );
};

export const createTeamTempPage = () => {
  return (
    <ProtectedLayout>
      <CreateTeamTemp />
    </ProtectedLayout>
  );
};

export const SingleTeamTempPage = () => {
  return (
    <ProtectedLayout>
      <SingleTeamTemp />
    </ProtectedLayout>
  );
};

export const EditTempNamePage = () => {
  return (
    <ProtectedLayout>
      <EditTempname />
    </ProtectedLayout>
  );
};

export const TempCompanyInfoPage = () => {
  return (
    <ProtectedLayout>
      <TempCompanyInfo />
    </ProtectedLayout>
  );
};

export const TempCustomizePage = () => {
  return (
    <ProtectedLayout>
      <TempCustomize />
    </ProtectedLayout>
  );
};

export const TempSocialConnectPage = () => {
  return (
    <ProtectedLayout>
      <TempSocialConnect />
    </ProtectedLayout>
  );
};

export const TempVideoLinkPage = () => {
  return (
    <ProtectedLayout>
      <TempVideoLink />
    </ProtectedLayout>
  );
};

export const TempLinksPage = () => {
  return (
    <ProtectedLayout>
      <TempLinks />
    </ProtectedLayout>
  );
};

export const InviteMemberPage = () => {
  return (
    <ProtectedLayout>
      <InviteMember />
    </ProtectedLayout>
  );
};
// export const TeamAdminHomePage = () => {
//   return (
//     <ProtectedLayout>
//       <TeamAdminPage />
//     </ProtectedLayout>
//   );
// };

// export const TeamSupportPage = () => {
//   return (
//     <ProtectedLayout>
//       <TeamSupportPageRoute />
//     </ProtectedLayout>
//   );
// };

// export const ActiveTemplatePage = () => {
//   return (
//     <ProtectedLayout>
//       <ActiveTemplatePageRoute />
//     </ProtectedLayout>
//   );
// };

// export const CreateTemplatePage = () => {
//   return (
//     <ProtectedLayout>
//       <CreateTemplatePageRoute />
//     </ProtectedLayout>
//   );
// };

// export const MembersSendInvitePage = () => {
//   return (
//     <ProtectedLayout>
//       <MembersSendInviteRoute />
//     </ProtectedLayout>
//   );
// };

// export const MembersClickedInfoPage = () => {
//   return (
//     <ProtectedLayout>
//       <MembersClickedInfoRoute />
//     </ProtectedLayout>
//   );
// };

// export const TeamContactPage = () => {
//   return (
//     <ProtectedLayout>
//       <TeamContactPageRoute />
//     </ProtectedLayout>
//   );
// };

// export const TeamChangePasswordPage = () => {
//   return (
//     <ProtectedLayout>
//       <TeamChangePasswordPageRoute />
//     </ProtectedLayout>
//   );
// };

// export const TeamPersonalPage = () => {
//   return (
//     <ProtectedLayout>
//       <TeamPersonalPageRoute />
//     </ProtectedLayout>
//   );
// };

// export const TeamSocialPage = () => {
//   return (
//     <ProtectedLayout>
//       <TeamSocialPageRoute />
//     </ProtectedLayout>
//   );
// };

// export const TeamCardsViewPersonalPage = () => {
//   return (
//     <ProtectedLayout>
//       <TeamCardsViewPersonalRoute />
//     </ProtectedLayout>
//   );
// };

// export const TeamVideoPage = () => {
//   return (
//     <ProtectedLayout>
//       <TeamVideoPageRoute />
//     </ProtectedLayout>
//   );
// };

// export const TeamLinksPage = () => {
//   return (
//     <ProtectedLayout>
//       <TeamLinksPageRoute />
//     </ProtectedLayout>
//   );
// };

// export const TeamCardsMembersPage = () => {
//   return (
//     <ProtectedLayout>
//       <TeamCardsMembersRoute />
//     </ProtectedLayout>
//   );
// };

// export const TeamCardsPersonalInfoPage = () => {
//   return (
//     <ProtectedLayout>
//       <TeamCardsPersonalInfoRoute />
//     </ProtectedLayout>
//   );
// };

// export const TeamConnectionsPage = () => {
//   return (
//     <ProtectedLayout>
//       <TeamConnectionsRoute />
//     </ProtectedLayout>
//   );
// };

// export const MembersAdminPage = () => {
//   return (
//     <ProtectedLayout>
//       <MembersAdminPageRoute />
//     </ProtectedLayout>
//   );
// };

// export const MembersLinksPage = () => {
//   return (
//     <ProtectedLayout>
//       <MembersLinksPageRoute />
//     </ProtectedLayout>
//   );
// };

// export const MembersPersonalPage = () => {
//   return (
//     <ProtectedLayout>
//       <MembersPersonalPageRoute />
//     </ProtectedLayout>
//   );
// };

// export const MembersSupportPage = () => {
//   return (
//     <ProtectedLayout>
//       <MembersSupportPageRoute />
//     </ProtectedLayout>
//   );
// };

// export const MembersVideoPage = () => {
//   return (
//     <ProtectedLayout>
//       <MembersVideoPageRoute />
//     </ProtectedLayout>
//   );
// };

// export const MembersContactPage = () => {
//   return (
//     <ProtectedLayout>
//       <MembersContactPageRoute />
//     </ProtectedLayout>
//   );
// };

// export const MembersSocialPage = () => {
//   return (
//     <ProtectedLayout>
//       <MembersSocialPageRoute />
//     </ProtectedLayout>
//   );
// };

// export const ContactInfoTempPage = () => {
//   return (
//     <ProtectedLayout>
//       <ContactInfoTempRoute />
//     </ProtectedLayout>
//   );
// };

// export const SocialConnectTempPage = () => {
//   return (
//     <ProtectedLayout>
//       <SocialConnectTempRoute />
//     </ProtectedLayout>
//   );
// };

// export const VideoLinkTempPage = () => {
//   return (
//     <ProtectedLayout>
//       <VideoLinkTempRoute />
//     </ProtectedLayout>
//   );
// };

// export const LinksTempPage = () => {
//   return (
//     <ProtectedLayout>
//       <LinksTempRoute />
//     </ProtectedLayout>
//   );
// };

export const BackdropPage = () => {
  return (
    <ProtectedLayout>
      <BackdropPageRoute />
    </ProtectedLayout>
  );
};

export const PersonalInfoPage = () => {
  return (
    <ProtectedLayout>
      <PersonalInfoPageRoute />
    </ProtectedLayout>
  );
};

export const SocialConnectPage = () => {
  return (
    <ProtectedLayout>
      <SocialConnectPageRoute />
    </ProtectedLayout>
  );
};

export const VideoLinkPage = () => {
  return (
    <ProtectedLayout>
      <VideoLinkPageRoute />
    </ProtectedLayout>
  );
};

export const EditLinkPage = () => {
  return (
    <ProtectedLayout>
      <EditLinkPageRoute />
    </ProtectedLayout>
  );
};

export const ContactInfoPage = () => {
  return (
    <ProtectedLayout>
      <ContactInfoPageRoute />
    </ProtectedLayout>
  );
};

export const ContactOdPage = () => {
  return (
    <ProtectedLayout>
      <ContactOd />
    </ProtectedLayout>
  );
};

export const ViewConnectPage = () => {
  return (
    <ProtectedLayout>
      <ViewConnectPageRoute />
    </ProtectedLayout>
  );
};
export const ShareLinkPage = () => {
  return (
    <ProtectedLayout>
      <ShareLinkPageRoute />
    </ProtectedLayout>
  );
};
export const SupportPage = () => {
  return (
    <ProtectedLayout>
      <SupportPageRoute />
    </ProtectedLayout>
  );
};

export const AddManualConnectionPage = () => {
  return (
    <ProtectedLayout>
      <AddManualConnectionRoute />
    </ProtectedLayout>
  );
};

export const ChangePasswordPage = () => {
  return (
    <ProtectedLayout>
      <ChangePasswordPageRoute />
    </ProtectedLayout>
  );
};

export const LoginPage = () => {
  return <Login />;
};

export const PreAccountPageCont = () => {
  return <PreAccountPage />;
};

export const ForgotPasswordPage = () => {
  return <ForgotPassword />;
};

export const SignUpContPage = () => {
  return <SignUpCont />;
};

export const ForgotPassOtpPage = () => {
  return <ForgotPasswordOtp />;
};

export const InputPassPage = () => {
  return <InputPassword />;
};
