import icontwitter from "../../assets/icons/twitterBl.svg";
import iconinstagram from "../../assets/icons/instagramBl.svg";
import iconlinkedin from "../../assets/icons/linkedinBl.svg";
import iconmessenger from "../../assets/icons/MessengerBl.svg";
import iconpinterest from "../../assets/icons/pinterestBl.svg";
import iconsnapchat from "../../assets/icons/snapchatBl.svg";
import icontumblr from "../../assets/icons/tumblrBl.svg";
import iconwhatsapp from "../../assets/icons/whatsappBl.svg";
import linkImgDefault from "../../assets/icons/linkImgDefault.svg";
import youtubeIcon from "../../assets/icons/youtubeBl.svg";
import fbfacebook from "../../assets/icons/facebookBl.svg";
import tiktokIcon from "../../assets/icons/tiktokIcon.svg";
import threads from "../../assets/icons/threads.svg";
import new_twitter_logo from "../../assets/icons/new_twitter_logo.svg";

export default {
  icontwitter,
  iconinstagram,
  iconlinkedin,
  iconmessenger,
  iconpinterest,
  iconsnapchat,
  icontumblr,
  iconwhatsapp,
  linkImgDefault,
  youtubeIcon,
  fbfacebook,
  tiktokIcon,
  threads,
  new_twitter_logo,
};
