import React from "react";
import FeedbackBtnBullet from "../../assets/icons/feedbackBtnBullet";
import "./feedbackBtns.css";

export const FeedbackSmallBtn = ({
  feedBackText,
  feedBackNo,
  fill,
  onClick,
  feedBackSelect,
  navColor,
}) => {
  return (
    <div
      className="feedbackSmall-flex"
      onClick={onClick}
      style={{
        background:
          feedBackSelect !== feedBackNo
            ? "#F8FAFC"
            : navColor === ""
            ? `#36A6A4`
            : `${navColor}`,
        border: feedBackSelect === feedBackNo ? "none" : "1.5px solid #E2E8F0",

        boxSizing: "border-box",
      }}
    >
      <div className="feedbackSmall-secondFlex">
        <div
          className="feedbackSmall-number"
          style={{
            color: feedBackSelect === feedBackNo ? "#f2f2f2" : "#333333",
          }}
        >
          {feedBackNo}
        </div>
        <div className="feedbackSmall-icon">
          <FeedbackBtnBullet
            fill={
              feedBackSelect === feedBackNo
                ? "#F8FAFC"
                : navColor === ""
                ? `#36A6A4`
                : `${navColor}`
            }
          />
        </div>
      </div>

      <div
        style={{
          color: feedBackSelect === feedBackNo ? "#f2f2f2" : "#333333",
          opacity: feedBackSelect === feedBackNo ? "1" : "0.7",
        }}
        className="feedbackSmall-text"
      >
        {" "}
        {feedBackText}
      </div>
    </div>
  );
};
