import noTeamTemplate from "../../assets/icons/noTeamTemplate.svg";
import teamsuitcase from "../../assets/icons/teamsuitcase.svg";
import lockpersontag from "../../assets/icons/lockpersontag.svg";
import customizepersonicon from "../../assets/icons/customizepersonicon.svg";
import socialpersonicon from "../../assets/icons/socialpersonicon.svg";
import videoCameraIcon from "../../assets/icons/videoCameraIcon.svg";
import linkpersonIcon from "../../assets/icons/linkpersonIcon.svg";
import noTeamMembersIcon from "../../assets/icons/noTeamMembersIcon.svg";

export default {
  noTeamTemplate,
  teamsuitcase,
  lockpersontag,
  customizepersonicon,
  socialpersonicon,
  videoCameraIcon,
  linkpersonIcon,
  noTeamMembersIcon,
};
