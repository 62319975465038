import { vCardFormatter } from "./vcard_utils";

export const deetsCard = function () {
  function getPhoto() {
    return {
      url: "",
      mediaType: "",
      base64: false,

      attachFromUrl: function (url, mediaType) {
        this.url = url;
        this.mediaType = mediaType;
        this.base64 = false;
      },

      embedFromString: function (base64String, mediaType) {
        this.mediaType = mediaType;
        this.url = base64String;
        this.base64 = true;
      },
    };
  }

  function getMailingAddress() {
    return {
      label: "",

      street: "",

      city: "",

      stateProvince: "",

      postalCode: "",

      countryRegion: "",
    };
  }

  function getSocialUrls() {
    return {
      facebook: "",
      linkedIn: "",
      twitter: "",
      flickr: "",
    };
  }

  return {
    uid: "",

    birthday: "",

    cellPhone: "",

    pagerPhone: "",

    email: "",

    workEmail: "",

    firstName: "",

    formattedName: "",

    gender: "",

    homeAddress: getMailingAddress(),

    homePhone: "",

    homeFax: "",

    lastName: "",

    logo: getPhoto(),

    middleName: "",

    namePrefix: "",

    nameSuffix: "",

    nickname: "",

    note: "",

    organization: "",

    photo: getPhoto(),

    role: "",

    socialUrls: getSocialUrls(),

    source: "",

    title: "",

    url: "",

    workUrl: "",

    workAddress: getMailingAddress(),

    workPhone: "",

    workFax: "",

    version: "3.0",

    getMajorVersion: function () {
      var majorVersionString = this.version ? this.version.split(".")[0] : "4";
      if (!isNaN(majorVersionString)) {
        return parseInt(majorVersionString);
      }
      return 4;
    },

    getFormattedString: function () {
      return vCardFormatter().getFormattedString(this);
    },
  };
};
