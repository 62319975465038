import React, { useState, useEffect } from "react";
import "./inputPassword.css";
import { Link } from "react-router-dom";

import backArrow from "../../../assets/icons/backArrow.svg";
import nonValiPass from "../../../assets/icons/nonValiPass.svg";
import passwordLockIcon from "../../../assets/icons/passwordLockIcon.svg";

import viewPassword from "../../../assets/icons/viewPassword.svg";
import hidePassword from "../../../assets/icons/hidePassword.svg";

import FormLabel from "./FormLabel";
import NewAuthModal from "./newAuthModal/NewAuthModal";
import { useHistory } from "react-router-dom";
import ValiPass from "../../../assets/icons/ValiPass";
import { DefaultSubmitBtn } from "../../../genComponents/defaultInput/DefaultInput";
// import NewModal from "./newModal/NewModal";

const InputPassword = () => {
  const [inputValue, setInputValue] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });
  const { newPassword, confirmNewPassword } = inputValue;
  const [isVisibleOne, setIsVisibleOne] = useState(false);
  const [isVisibleTwo, setIsVisibleTwo] = useState(false);
  const [isVisibleThree, setIsVisibleThree] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);
  const [lenghtValidated, setLenghtValidated] = useState(false);
  const [maxLenghtValidated, setMaxLenghtValidated] = useState(true);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const history = useHistory();
  const handleValidateChange = (e) => {
    const lower = new RegExp("(?=.*[a-z])");
    const upper = new RegExp("(?=.*[A-Z])");
    const number = new RegExp("(?=.*[0-9])");
    const special = new RegExp("(?=.*[!@#$%^&*])");
    const lenght = new RegExp("(?=.{6,})");
    const maxLenght = new RegExp("(?=.*.{36})");

    // Lower Case Validation

    if (lower.test(e)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }
    //    Upper Case Validation

    if (upper.test(e)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }

    //  Number Validation

    if (number.test(e)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }

    //  Special Validation

    if (special.test(e)) {
      setSpecialValidated(true);
    } else {
      setSpecialValidated(false);
    }

    //  Lenght Validation

    //    Upper Case Validation

    if (upper.test(e)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }

    //  Number Validation

    if (number.test(e)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }

    //  Lenght Validation

    if (lenght.test(e)) {
      setLenghtValidated(true);
    } else {
      setLenghtValidated(false);
    }

    //  MaxLenght Validation

    //    Upper Case Validation

    if (upper.test(e)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }

    //  Number Validation

    if (number.test(e)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }

    //  Special Validation

    if (maxLenght.test(e)) {
      setMaxLenghtValidated(false);
    } else {
      setMaxLenghtValidated(true);
    }
  };

  const validateConfirmPasswordCheck = () => {
    // var firstName = e.target.value;
    if (confirmNewPassword !== "") {
      if (newPassword !== confirmNewPassword) {
        setConfirmPasswordError(true);
      } else {
        setConfirmPasswordError(false);
      }
      return;
    }

    return;
  };

  useEffect(() => {
    validateConfirmPasswordCheck();
  }, [inputValue]);

  const handleCloseModalFunc = () => {
    setOpenModal(false);
  };

  const handleopenModalFunc = (e) => {
    e.preventDefault();
    setOpenModal(true);
  };

  const handleGoBack = () => {
    history.goBack(); // Go back to the previous page
  };

  const handleClick = () => {
    history.push("/forgotpassword/otp");
  };

  const newPasswordVisiblity2 = () => {
    setIsVisibleTwo((isVisibleTwo) => !isVisibleTwo);
  };

  const oldPasswordVisiblity = () => {
    setIsVisibleThree((isVisibleThree) => !isVisibleThree);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log(inputValue);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    //  postPasswordData(e);
    // console.log(inputValue, "submit");
    // console.log(userId, "id pass");
  };
  return (
    <div className="totalResBodyInpass">
      <div className="inPass-info-family">
        <div className="inPass-info-header">
          <div style={{ cursor: "pointer" }} onClick={handleGoBack}>
            <img src={backArrow} alt="back_arrow" />
          </div>

          <div className="inPass-info-text">
            {/* <img src={deetsBlackLogo} alt="deets_logo" /> */}
            Change Password
          </div>
          <div></div>
        </div>

        <form onSubmit={onSubmit}>
          <div className="__password_change-input-family">
            <div className="password_input_flex_hide_inPass">
              <div
                className="passwordLock-img_inPass"
                // onClick={newPasswordVisiblity1}
              >
                <img src={passwordLockIcon} alt="view_password" />
              </div>
              <div class="change_pass-inputDefault_inPass">
                <input
                  type={isVisibleTwo ? "text" : "password"}
                  placeholder=" "
                  // value={singleVideoLink.phone}
                  name="newPassword"
                  // onChange={(e) => handleVideoLinkChange(e, index)}

                  value={newPassword}
                  onChange={(e) => {
                    handleChange(e);
                    handleValidateChange(e.target.value);
                  }}
                />
                <span>New password</span>
              </div>
              <div onClick={newPasswordVisiblity2}>
                {isVisibleTwo ? (
                  <img src={hidePassword} alt="hide_password" />
                ) : (
                  <img src={viewPassword} alt="view_password" />
                )}
              </div>
            </div>

            <div className="password_input_flex_hide_inPass">
              <div
                className="passwordLock-img_inPass"
                // onClick={newPasswordVisiblity1}
              >
                <img src={passwordLockIcon} alt="view_password" />
              </div>
              <div class="change_pass-inputDefault_inPass">
                <input
                  type={isVisibleThree ? "text" : "password"}
                  placeholder=" "
                  // value={singleVideoLink.phone}
                  name="confirmNewPassword"
                  // onChange={(e) => handleVideoLinkChange(e, index)}

                  value={confirmNewPassword}
                  onChange={(e) => {
                    handleChange(e);
                    validateConfirmPasswordCheck();
                  }}
                />
                <span>Confirm password</span>
              </div>
              <div onClick={oldPasswordVisiblity}>
                {isVisibleThree ? (
                  <img src={hidePassword} alt="hide_password" />
                ) : (
                  <img src={viewPassword} alt="view_password" />
                )}
              </div>
            </div>
            {confirmPasswordError && (
              <div className="confirmPass_matchGen_inPass">
                Password and Confirm Password does not match
              </div>
            )}

            <div className="chgPassValidCompo_inPass">
              <div className="chgPassValidGen_inPass">
                {
                  <div className="chgPassValiGen_img_inPass">
                    {lenghtValidated ? (
                      <ValiPass fill="#36A6A4" />
                    ) : (
                      <img src={nonValiPass} />
                    )}
                  </div>
                }
                <div className="chgPassValiText_inPass">
                  At least 6 characters
                </div>
              </div>
              <div className="chgPassValidGen_inPass">
                {
                  <div className="chgPassValiGen_img_inPass">
                    {lowerValidated ? (
                      <ValiPass fill="#36A6A4" />
                    ) : (
                      <img src={nonValiPass} />
                    )}
                  </div>
                }
                <div className="chgPassValiText_inPass">
                  At least 1 lowecase letter{" "}
                </div>
              </div>
              <div className="chgPassValidGen_inPass">
                {
                  <div className="chgPassValiGen_img_inPass">
                    {upperValidated ? (
                      <ValiPass fill="#36A6A4" />
                    ) : (
                      <img src={nonValiPass} />
                    )}
                  </div>
                }
                <div className="chgPassValiText_inPass">
                  At least 1 uppercase letter
                </div>
              </div>
              <div className="chgPassValidGen_inPass">
                {
                  <div className="chgPassValiGen_img_inPass">
                    {numberValidated ? (
                      <ValiPass fill="#36A6A4" />
                    ) : (
                      <img src={nonValiPass} />
                    )}
                  </div>
                }
                <div className="chgPassValiText_inPass">
                  At least 1 numeric character
                </div>
              </div>
              <div className="chgPassValidGen_inPass">
                {
                  <div className="chgPassValiGen_img_inPass">
                    {specialValidated ? (
                      <ValiPass fill="#36A6A4" />
                    ) : (
                      <img src={nonValiPass} />
                    )}
                  </div>
                }
                <div className="chgPassValiText_inPass">
                  At least 1 special character
                </div>
              </div>
              <div className="chgPassValidGen_inPass">
                {
                  <div className="chgPassValiGen_img_inPass">
                    {maxLenghtValidated ? (
                      <ValiPass fill="#36A6A4" />
                    ) : (
                      <img src={nonValiPass} />
                    )}
                  </div>
                }
                <div className="chgPassValiText_inPass">
                  Maximum 36 characters
                </div>
              </div>
            </div>

            {/* <div className="change-password-form">
              <input type="submit" value="Save" />
            </div> */}

            <div className="changePasswordForm-gen_inPass">
              <DefaultSubmitBtn
                defaultColor="#36A6A4"
                // styleList={{
                //   opacity:
                //     !oldPassword ||
                //     !confirmNewPassword ||
                //     !newPassword ||
                //     !lowerValidated ||
                //     !upperValidated ||
                //     !numberValidated ||
                //     !specialValidated ||
                //     !lenghtValidated ||
                //     confirmPasswordError ||
                //     !maxLenghtValidated
                //       ? "0.8"
                //       : "1",
                // }}
                // disabled={
                //   !oldPassword ||
                //   !confirmNewPassword ||
                //   !newPassword ||
                //   !lowerValidated ||
                //   !upperValidated ||
                //   !numberValidated ||
                //   !specialValidated ||
                //   !lenghtValidated ||
                //   confirmPasswordError ||
                //   !maxLenghtValidated
                // }
                valueText="Change password"
              />
            </div>
          </div>
        </form>
      </div>
      {/* <NewModal>
        <div>Children</div>
      </NewModal> */}
    </div>
  );
};

export default InputPassword;
