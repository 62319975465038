import React, { useState, useEffect } from "react";
import sidemenuAvatar from "../../../assets/icons/sidemenuAvatar.png";
import navicon from "../../../assets/icons/navicon.png";
import feedbackIcon from "../../../assets/icons/feedbackIcon.svg";
import supportIcon from "../../../assets/icons/supportIcon.svg";
import teamsOptIcon from "../../../assets/icons/teamsOptIcon.svg";
import profileIcon from "../../../assets/icons/profileIcon.svg";
import deetslogo from "../../../assets/icons/deetslogo.svg";

import changePasswordIcon from "../../../assets/icons/changePasswordIcon.svg";
import logoutIcon from "../../../assets/icons/logoutIcon.svg";
import "./leftNav.css";
import { Link } from "react-router-dom";
import LogoutPage from "./LogoutPage";
import LogoutIcon from "../../../assets/icons/LogoutIcon";
import { useLocation } from "react-router-dom";

const LeftNav = ({
  open,
  openLogoutBtn,
  closeLogoutBtn,
  openLogoutPage,
  navEmailAdd,
  navImg,
  navFirstName,
  navLastName,
  navColor,
  profImage,
  checkImgUrl,
  userRole,
  backdropTheme,
}) => {
  // const [navImg, setNavImg] = useState(null);
  // const [navFirstName, setNavFirstName] = useState("");
  // const [navLastName, setNavLastName] = useState("");
  // const [navEmailAdd, setNavEmailAdd] = useState();

  useEffect(() => {
    console.log(navImg, "nnnnnnnn");
  }, []);

  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");
  // const getNavAssets = () => {
  //   let profNavImg = localStorage.getItem("Profile Image");
  //   let profNavFirstName = localStorage.getItem("First Name");
  //   let profNavLastName = localStorage.getItem("Last Name");
  //   let profNavEmailAdd = localStorage.getItem("Contact");
  //   const parsedArr = JSON.parse(profNavEmailAdd);
  //   setNavEmailAdd(parsedArr);
  //   console.log("Nav Email", navEmailAdd);
  //   if (profNavImg == undefined || profNavImg == null || profNavImg == "") {
  //     return;
  //   }

  //   setNavImg(profNavImg);
  //   setNavFirstName(profNavFirstName);
  //   setNavLastName(profNavLastName);
  // };

  return (
    <div

    // className="totalResBodyLeftNav"
    >
      <div
        open={open}
        style={{
          transform: `${open ? "translateX(0)" : "translateX(100%)"}`,
          transition: "transform 0.3s ease-in-out",
        }}
        className="sidemenu-family-cont"
      >
        <div className="top-navbar-rel">
          <div className="totalResBodyLeftNav">
            <div
              // style={{
              //   background: navColor === "" ? `#36A6A4` : `${navColor}`,
              // }}
              style={{
                background: backdropTheme ? navColor || "#171923" : "#171923",
              }}
              className="sidemenu-user-details"
            >
              {/* <div className="left-align-user-details"> */}
              <div className="cancel-menu">
                <img src={navicon} alt="cancel" />
              </div>
              {/* </div> */}

              <Link
                className="deetsLogoCont"
                style={{ textDecoration: "none" }}
                to="/"
              >
                <img src={deetslogo} alt="deets_logo" />
              </Link>
              <div style={{ width: "40px" }}></div>
            </div>
            <div className="profileNavDetails-left">
              <div
                className="menu-avatar-cont"
                style={{
                  border: `3px solid ${
                    navColor === "" ? `#36A6A4` : `${navColor}`
                  }`,
                }}
              >
                {checkImgUrl ? (
                  <img src={profImage} alt="menu_avatar" />
                ) : (
                  <div className="profileImageText-nav">
                    {" "}
                    {navFirstName.toUpperCase().slice(0, 1)}{" "}
                    {navLastName.toUpperCase().slice(0, 1)}
                  </div>
                )}
              </div>
              <div className="details-left-cont">
                {navFirstName && navLastName === "" ? (
                  <div className="nav-username">Name </div>
                ) : (
                  <div className="nav-username">
                    {navFirstName} {navLastName}
                  </div>
                )}

                {!navEmailAdd ? (
                  <div className="nav-email">Email Address </div>
                ) : (
                  <div className="nav-email">{navEmailAdd}</div>
                )}
              </div>
            </div>

            <div className="navMargin-profile"></div>
            <Link style={{ textDecoration: "none" }} to="/">
              <div
                className={
                  splitLocation[1] === ""
                    ? "side-menu-option_active side-menu-option"
                    : "side-menu-option"
                }

                // className="side-menu-option"
              >
                <div className="options-img">
                  <img src={profileIcon} alt="profile_icon" />
                </div>
                <div className="option-label">My Profile</div>
              </div>
            </Link>

            {userRole === "deets_teams_admin" && (
              <Link style={{ textDecoration: "none" }} to="/team">
                <div
                  className={
                    splitLocation[1] === "team"
                      ? "side-menu-option_active side-menu-option"
                      : "side-menu-option"
                  }
                  // className="side-menu-option"
                >
                  <div className="options-img">
                    <img src={teamsOptIcon} alt="profile_icon" />
                  </div>
                  <div className="option-label">Team</div>
                </div>
              </Link>
            )}

            <Link style={{ textDecoration: "none" }} to="/feedback">
              <div
                style={{ marginTop: "20px" }}
                className={
                  splitLocation[1] === "feedback"
                    ? "side-menu-option_active side-menu-option"
                    : "side-menu-option"
                }
                // className="side-menu-option"
              >
                <div className="options-img">
                  <img src={feedbackIcon} alt="feedback_icon" />
                </div>
                <div className="option-label">Feedback</div>
                {/* <div className="added-to-fit"></div> */}
              </div>
            </Link>

            <Link style={{ textDecoration: "none" }} to="/support">
              <div
                // className="side-menu-option"
                className={
                  splitLocation[1] === "support"
                    ? "side-menu-option_active side-menu-option"
                    : "side-menu-option"
                }
              >
                <div className="options-img">
                  <img src={supportIcon} alt="support_icon" />
                </div>
                <div className="option-label">Support</div>
              </div>
            </Link>

            <Link style={{ textDecoration: "none" }} to="/change_password">
              <div
                className={
                  splitLocation[1] === "change_password"
                    ? "side-menu-option_active side-menu-option"
                    : "side-menu-option"
                }
                // className="side-menu-option"
              >
                <div className="options-img">
                  <img src={changePasswordIcon} alt="change_password_icon" />
                </div>
                <div className="option-label">Change Password</div>
              </div>
            </Link>

            <div className="logout-family-cont" onClick={openLogoutBtn}>
              <div className="logoutIcon-imgNav">
                {/* <img src={logoutIcon} alt="logout_icon" /> */}
                <LogoutIcon fill="#6C6C6C" />
              </div>
              <div className="logout-text">Log Out</div>
            </div>
          </div>
          {openLogoutPage ? (
            <LogoutPage
              closeLogoutPage={closeLogoutBtn}
              openLogoutPage={openLogoutPage}
              navColor={navColor}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default LeftNav;
