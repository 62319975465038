import React from "react";

const ConnectMailIcon = ({ fill }) => {
  return (
    <>
      <svg
        width="9"
        height="8"
        viewBox="0 0 9 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.90059 2.55535V6.4752C8.90059 6.83987 8.75572 7.18961 8.49786 7.44747C8.24 7.70533 7.89026 7.8502 7.52559 7.8502H1.47559C1.11091 7.8502 0.761177 7.70533 0.503314 7.44747C0.245452 7.18961 0.100586 6.83987 0.100586 6.4752V2.55535L4.36089 5.06225C4.40321 5.08721 4.45145 5.10037 4.50059 5.10037C4.54972 5.10037 4.59796 5.08721 4.64029 5.06225L8.90059 2.55535ZM7.52559 0.700195C7.86392 0.700142 8.19039 0.824829 8.44255 1.0504C8.69471 1.27598 8.85485 1.5866 8.89234 1.92285L4.50059 4.5062L0.108836 1.92285C0.146323 1.5866 0.306464 1.27598 0.558623 1.0504C0.810782 0.824829 1.13726 0.700142 1.47559 0.700195H7.52559Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default ConnectMailIcon;
