import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import backArrow from "../../../assets/icons/backArrow.svg";
import iconShareSec from "../../../assets/icons/iconShareSec.svg";
import iconQrSec from "../../../assets/icons/iconQrSec.svg";
import defaultForwardIcon from "../../../assets/icons/defaultForwardIcon.svg";
import qrCodeImg from "../../../assets/icons/qrCodeImg.svg";

// import "web-social-share";
import "./shareLink.css";
import ShareLinkOptions from "./ShareLinkOptions";
import ShareQrCode from "./ShareQrCode";
import SectionsHeader from "../../../genComponents/sectionsHeader/SectionsHeader";
import ShareTabCom from "../../../genComponents/shareTabCom/ShareTabCom";
import NewModal from "../../../genComponents/newModal/NewModal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { profileUrl } from "../../../constant/profileUrl";
import SuccessAlert from "../successAlert/SuccessAlert";
import copy from "copy-to-clipboard";

const ShareLink = () => {
  const [openShareLink, setOpenShareLink] = useState(false);
  const [openShareQrcode, setOpenShareQrcode] = useState(false);
  const [openCopyLink, setOpenCopyLink] = useState(false);

  const [value, setValue] = useState(`${profileUrl}/fionaokpara`);
  const [copied, setCopied] = useState(false);
  const [userName, setUserName] = useState("");

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    setUserName(localStorage.getItem("Profile Code"));
  }, []);

  const handleInputChange = (e) => {
    setValue(e.target.value);
    setCopied(false);
  };

  const copyToClipboard = () => {
    copy(`${profileUrl}/${userName}`);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
    console.log("coooooo");
  };
  const handleCopy = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const closeShareLink = () => {
    setOpenShareLink(!openShareLink);
  };

  const closeShareQrcode = () => {
    setOpenShareQrcode(!openShareQrcode);
  };

  const closeShareCopyLink = () => {
    setOpenCopyLink(false);
  };

  const handleChildClick = (event) => {
    // 👇️ stop event propagation (won't trigger parent's onClick)
    event.stopPropagation();

    // console.log("child clicked");
  };
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };
  return (
    <div className="totalResBodyTeamShare">
      <div className="share-family">
        <SectionsHeader
          sectionName="QR Code/Link"
          handleGoBack={handleGoBack}
        />

        <div className="share-flex-cont2">
          <ShareTabCom
            onClick={() => setOpenShareLink(true)}
            icon={iconShareSec}
            shareName="URL"
            shareDesc="You can click here to 
share your personal
profile URL"
            borderColor="#36A6A4"
            backgroundColor="#D5FFFE"
            iconBackground="#36A6A4"
            altName="share_icon"
          />
          <ShareTabCom
            onClick={() => setOpenCopyLink(true)}
            icon={iconShareSec}
            shareName="URL"
            shareDesc="You can click here to copy your profile URL"
            borderColor="#36A6A4"
            backgroundColor="#D5FFFE"
            iconBackground="#36A6A4"
            altName="share_icon"
          />
          <ShareTabCom
            onClick={() => setOpenShareQrcode(true)}
            icon={iconQrSec}
            shareName="QR Code"
            shareDesc="Click here to show and 
Scan QR Code"
            borderColor="#171923"
            backgroundColor="#F2F2F2"
            iconBackground="#171923"
            altName="qr_icon"
          />
        </div>
        {openShareLink && (
          <ShareLinkOptions
            handleChildClick={handleChildClick}
            closeShareLink={closeShareLink}
          />
        )}
        {/* {openShareQrcode && <ShareQrCode closeShareQrcode={closeShareQrcode} />} */}
        {openShareQrcode && (
          <NewModal
            closeNewModal={closeShareQrcode}
            headerName="Scan QR Code to view profile"
            btnName="Got it"
          >
            <div className="qr-codeImgModal">
              <img src={qrCodeImg} />
            </div>
          </NewModal>
        )}

        {openCopyLink && (
          <NewModal
            closeNewModal={closeShareCopyLink}
            headerName="Click the button to copy your url"
            // btnName="Copy URL"
          >
            <div className="qr-codeImgModal">
              <div className="copy_linkText" onClick={copyToClipboard}>
                {`${profileUrl}/${userName}`}
              </div>

              <div className="newModal-Btn" onClick={copyToClipboard}>
                <div
                // text={value}
                // onCopy={() => {
                //   handleCopy();
                //   console.log("sfsfsfsf");
                // }}
                >
                  <div>Copy URL</div>
                </div>
              </div>
              {/* {copied ? <span style={{ color: "red" }}>Copied.</span> : null} */}
              {copied ? <SuccessAlert alertMessage="😄 URL Copied!" /> : null}
            </div>
          </NewModal>
        )}
      </div>
    </div>
  );
};

export default ShareLink;
